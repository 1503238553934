import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {debounce} from 'debounce';
import {setkpiLandingPageOn} from '../features/Statsview/dashboardSlice';

function Sidebar(props) {
  // Declare/import additional hooks
  const dispatch = useDispatch();

  // Declare/import external states from store
  const displaySettings = useSelector((state) => {
    return state.framework;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const authenticationStatus = useSelector((state) => {
    return state.app.authenticationStatus;
  });

  // Component effects
  // Set listener for ui clicks outside of the side
  useEffect(() => {
    // Close sidebar if clicked outside of sidebar
    $(document).on('click', function (event) {
      if (
        !$(event.target).closest('#accordionSidebar').length &&
        !$(event.target).closest('#sidebarToggleTop').length &&
        !$('#accordionSidebar').hasClass('toggled')
      ) {
        closeSidebar();
      }
    });
  }, []);

  // Component functions
  // Sidebar set open
  function openSidebar() {
    props.handleSetSideMenuOpened(true);
  }
  // Sidebar set close
  function closeSidebar() {
    props.handleSetSideMenuOpened(false);
  }

  // Nav to landing
  function closeSidebarNavToDashboard() {
    props.handleSetSideMenuOpened(false);
    dispatch(setkpiLandingPageOn(true));
  }

  function navToDashboard() {
    dispatch(setkpiLandingPageOn(true));
  }

  return (
    <div
      id='sidenav'
      onMouseEnter={!displaySettings.smallScreen ? openSidebar : undefined}
      onMouseLeave={!displaySettings.smallScreen ? closeSidebar : undefined}
    >
      <ul
        className={`navbar-nav bg-dark sidebar sidebar-dark accordion ${!props.sideMenuOpened && 'toggled'}`}
        id='accordionSidebar'
        style={{width: '100vw'}}
      >
        {/* Sidebar - Brand */}
        <div className='sidebar-brand px-2'>
          {displaySettings.smallScreen ? (
            <li className='nav-item'>
              <div>
                {props.sideMenuOpened && (
                  <div className='row w-100'>
                    <div className='w-100'>
                      <Link className='nav-link w-100' to='/' onClick={debounce(closeSidebar, 150)}>
                        <img src='/img/Logo_Text_NoSlogan_White.png' className='sidebar-logo' />
                      </Link>
                    </div>
                    <div className='w-10'>
                      <button className='close sidenav-close' onClick={debounce(closeSidebar, 150)}>
                        <span>&times;</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </li>
          ) : (
            <li className='nav-item'>
              <div>
                <Link
                  className='nav-link w-100 d-flex pl-0 py-2'
                  to='/'
                  onClick={props.sideMenuOpened ? closeSidebar : undefined}
                >
                  <img
                    src={props.sideMenuOpened ? '/img/Logo_Text_NoSlogan_White.png' : '/img/Logo_Shared_White.png'}
                    className='sidebar-logo d-flex'
                  />
                </Link>
              </div>
            </li>
          )}
        </div>

        {/* Divider */}
        <hr className='sidebar-divider d-flex align-items-center mt-3' />

        {/* Nav Item - CabView */}
        {authenticationStatus.cabviewEnabled && (
          <li className={`nav-item ${props.activePage == 'cabview' && 'active bg-light'}`}>
            <Link
              className={`nav-link ${displaySettings.smallScreen && 'w-100'}`}
              to='/cabview'
              onClick={displaySettings.smallScreen ? closeSidebar : undefined}
            >
              <i className={`fas fa-fw fa-tractor ${props.activePage == 'cabview' && 'text-secondary'}`} />
              <span className={`nav-name ${props.activePage == 'cabview' && 'text-secondary'}`}>&nbsp;CabView</span>
            </Link>
          </li>
        )}

        {/* Nav Item - CropView */}
        {authenticationStatus.cropviewEnabled && (
          <li className={`nav-item ${props.activePage == 'cropview' && 'active bg-light'}`}>
            <Link
              className={`nav-link ${displaySettings.smallScreen && 'w-100'}`}
              to='/cropview'
              onClick={displaySettings.smallScreen ? closeSidebar : undefined}
            >
              <i className={`fas fa-fw fa-seedling ${props.activePage == 'cropview' && 'text-secondary'}`} />
              <span className={`nav-name ${props.activePage == 'cropview' && 'text-secondary'}`}>&nbsp;CropView</span>
            </Link>
          </li>
        )}

        {/* Nav Item - ShopView */}
        {authenticationStatus.shopviewEnabled && (
          <li className={`nav-item ${props.activePage == 'shopview' && 'active bg-light'}`}>
            <Link
              className={`nav-link ${displaySettings.smallScreen && 'w-100'}`}
              to='/shopview'
              onClick={displaySettings.smallScreen ? closeSidebar : undefined}
            >
              <i className={`fas fa-fw fa-wrench ${props.activePage == 'shopview' && 'text-secondary'}`} />
              <span className={`nav-name ${props.activePage == 'shopview' && 'text-secondary'}`}>&nbsp;ShopView</span>
            </Link>
          </li>
        )}

        {/* Nav Item - Inspection Report */}
        {authenticationStatus.inspectionEnabled &&
          (displaySettings.smallScreen || customerSettings.inspection.inspectionFormWideModeEnabled) && (
            <li className={`nav-item ${props.activePage == 'inspectionForm' && 'active bg-light'}`}>
              <Link
                className={`nav-link ${displaySettings.smallScreen && 'w-100'}`}
                to='/inspectionForm'
                onClick={displaySettings.smallScreen ? closeSidebar : undefined}
              >
                <i className={`fas fa-fw fa-list-check ${props.activePage == 'inspectionForm' && 'text-secondary'}`} />
                <span className={`nav-name ${props.activePage == 'inspectionForm' && 'text-secondary'}`}>
                  &nbsp;Inspection
                </span>
              </Link>
            </li>
          )}

        {/* Nav Item - AlertView */}
        {authenticationStatus.alertviewEnabled && (
          <li className={`nav-item ${props.activePage == 'alertview' && 'active bg-light'}`}>
            <Link
              className={`nav-link ${displaySettings.smallScreen && 'w-100'}`}
              to='/alertview'
              onClick={displaySettings.smallScreen ? closeSidebar : undefined}
            >
              <i className={`fas fa-fw fa-bell ${props.activePage == 'alertview' && 'text-secondary'}`} />
              <span className={`nav-name ${props.activePage == 'alertview' && 'text-secondary'}`}>&nbsp;AlertView</span>
            </Link>
          </li>
        )}

        {/* Nav Item - StatsView / Dashboard */}
        {authenticationStatus.dashboardEnabled && (
          <li className={`nav-item ${props.activePage == 'statsview' && 'active bg-light'}`}>
            <Link
              className={`nav-link ${displaySettings.smallScreen && 'w-100'}`}
              to='/statsview'
              onClick={displaySettings.smallScreen ? closeSidebarNavToDashboard : navToDashboard}
            >
              <i className={`fa fa-bar-chart ${props.activePage == 'statsview' && 'text-secondary'}`} />
              <span className={`nav-name ${props.activePage == 'statsview' && 'text-secondary'}`}>&nbsp;StatsView</span>
            </Link>
          </li>
        )}

        {/* Nav Item - MapView / ZoneInfo */}
        {/* Can display zoneinfo for samsara only accounts if geofences are availible */}
        {customerSettings.general.geoFencesProvided && authenticationStatus.zoneInfoEnabled && (
          <li className={`nav-item ${props.activePage == 'mapview' && 'active bg-light'}`}>
            <Link
              className={`nav-link ${displaySettings.smallScreen && 'w-100'}`}
              to='/mapview'
              onClick={displaySettings.smallScreen ? closeSidebar : undefined}
            >
              <i className={`fas fa-fw fa-map-location-dot ${props.activePage == 'mapview' && 'text-secondary'}`} />
              <span className={`nav-name ${props.activePage == 'mapview' && 'text-secondary'}`}>&nbsp;MapView</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}

export {Sidebar};
