// Import for framework tools
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  sendGAPageview,
  machineTypeMapping,
  rowApplicationTypeMapping,
  sortVehicleNamesHelper,
  unitsLengthDisplayConversion,
  unitsLengthSubmitConversion,
  fetchPostAuthSafe,
  trimObjectStrings,
} from '../../../../app/utils';
import {Menu} from './Menu';
import {DateTime} from 'luxon';
// Import dependent components
import {VehicleInfoTable} from './VehicleInfoTable';
import {updateVehicleData, updateLoading, updateDisplayedTable, updateDeviceStatuses} from './vehicleInfoSlice';
import {getImplementsData, getTaskConfigsData, updateDevices, initializeBulkEditVehicles} from '../../settingsSlice';
import {BulkUploadModal} from '../../../../components/BulkUploadModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TabMenuTableWrapper} from '../../../../components/TabMenuTableWrapper';

function VehicleInfo(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vehicleData = useSelector((state) => {
    return state.vehicleinfo.vehicleData;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const implementSNDict = useSelector((state) => {
    return state.settings.implementSNDict;
  });
  const sortMethod = useSelector((state) => {
    return state.vehicleinfo.sortMethod;
  });
  const activeDevices = useSelector((state) => {
    return state.settings.activeDevices;
  });
  const btHubs = useSelector((state) => {
    return state.settings.btHubs;
  });
  const onlyShowVehiclesWithoutManualEntry = useSelector((state) => {
    return state.vehicleinfo.onlyShowVehiclesWithoutManualEntry;
  });
  const showShopviewOnlyVehicle = useSelector((state) => {
    return state.vehicleinfo.showShopviewOnlyVehicle;
  });
  const showArchivedVehicles = useSelector((state) => {
    return state.vehicleinfo.showArchivedVehicles;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });

  const defaultFilter = {
    vehicleName: [],
    machineType: [],
    rapType: [],
    linkedImplement: [],
    linkedTask: [],
    vin: [],
    make: [],
    model: [],
    gpsDevice: [],
    notes: [],
    vehicleInfoIcActive: [],
    vehicleInfoCabviewActive: [],
    vehicleInfoShopActive: [],
    vehicleInfoInspectionActive: [],
    vehicleInfoCoverageActive: [],
  };

  // States for async data
  const [deviceHarnessTypes, setDeviceHarnessTypes] = useState(null);
  const [devicePowerTypes, setDevicePowerTypes] = useState(null);
  const [deviceIgnStates, setDeviceIgnStates] = useState(null);
  const [deviceLastIgn, setDeviceLastIgn] = useState(null);
  const [deviceCommStatuses, setDeviceCommStatuses] = useState(null);
  const [deviceGpsStatuses, setDeviceGpsStatuses] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false);
  const [vehicleNamesList, setVehicleNamesList] = useState([]);
  const [bulkEditMode, setBulkEditMode] = useState(false);

  const [filterOptions, setFilterOptions] = useState({
    vehicleName: [],
    machineType: Object.keys(machineTypeMapping).map((key) => {
      return machineTypeMapping[key];
    }),
    rapType: Object.keys(rowApplicationTypeMapping).map((key) => {
      return rowApplicationTypeMapping[key];
    }),
    linkedImplement: [],
    linkedTask: [],
    vin: [],
    make: [],
    model: [],
    gpsDevice: [],
    notes: [],
    vehicleInfoIcActive: ['Yes', 'No'],
    vehicleInfoCabviewActive: ['Yes', 'No'],
    vehicleInfoShopActive: ['Yes', 'No'],
    vehicleInfoInspectionActive: ['Yes', 'No'],
    vehicleInfoCoverageActive: ['Yes', 'No'],
  });

  const [filters, setFilters] = useState(defaultFilter);

  const [dims, setDims] = useState({
    w: window.innerWidth,
    h: window.innerHeight / 2,
  });

  useEffect(() => {
    document.title = 'IntelliCulture | Vehicle Information';
    sendGAPageview(document.title);
    setDisplayedTable();
    getVehicleAsyncData();
    getVehicleDataForSettings();
  }, []);

  function setFilterDefault() {
    setFilters(defaultFilter);
  }

  function handleToggleBulkEditMode() {
    setBulkEditMode((state) => {
      return !state;
    });
  }

  useEffect(() => {
    const tempVehicleNamesList = vehicleData.map((vehicle) => {
      return vehicle.name;
    });
    setVehicleNamesList(tempVehicleNamesList);
    initBulkEditVehicles();
  }, [vehicleData]);

  useEffect(() => {
    processDeviceStatuses();
  }, [
    vehicleData,
    deviceHarnessTypes,
    devicePowerTypes,
    deviceIgnStates,
    deviceLastIgn,
    deviceCommStatuses,
    deviceGpsStatuses,
    activeDevices,
  ]);

  useEffect(() => {
    filterTableData();
  }, [
    vehicleData,
    taskConfigDict,
    implementSNDict,
    sortMethod,
    onlyShowVehiclesWithoutManualEntry,
    showShopviewOnlyVehicle,
    showArchivedVehicles,
    activeDevices,
    deviceCommStatuses,
  ]);

  useEffect(() => {
    setFilterDefault();
  }, [showArchivedVehicles]);

  /**
   *
   * @param {*} updateDoc
   */
  async function postVehicleUpdates(updateDoc) {
    if (Object.keys(updateDoc).length > 0) {
      trimObjectStrings(updateDoc);
      const postData = updateDoc;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: JSON.stringify(postData),
      };
      const url = '/settings/updateVehicleDocs';

      const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
      const result = await response.json();
      if (result.errorMsg) {
        navigate('/error', {state: {errorMsg: result.errorMsg}});
      }
      return result;
    } else {
      return {success: false, status: 400, error: 'Request body is empty'};
    }
  }

  function setDisplayedTable() {
    if (userSettings.roleViewAccess['vehicleAttributesManagement']) {
      dispatch(updateDisplayedTable('attributes'));
    } else if (userSettings.roleViewAccess['vehicleStatusManagement']) {
      dispatch(updateDisplayedTable('status'));
    } else {
      dispatch(updateDisplayedTable('features'));
    }
  }

  function sortData(dataList) {
    // Make copy of list or else will get error when trying to sort
    const vehicleList = JSON.parse(JSON.stringify(dataList));

    // Sort zones and vehicle list within zone by method specified
    // Will sort a after b if compare function returns > 0 and sort a before b if compare function returns < 0
    if (sortMethod == 'vehiclename') {
      vehicleList.sort((a, b) => {
        return sortVehicleNamesHelper(a.name, b.name);
      });
    } else if (sortMethod == 'make') {
      vehicleList.sort((a, b) => {
        if (!a.make) {
          return 1;
        } else if (!b.make) {
          return -1;
        }
        return a.make.localeCompare(b.make);
      });
    } else if (sortMethod == 'model') {
      vehicleList.sort((a, b) => {
        if (!a.model) {
          return 1;
        } else if (!b.model) {
          return -1;
        }
        return a.model.localeCompare(b.model);
      });
    } else if (sortMethod == 'notes') {
      vehicleList.sort((a, b) => {
        if (!a.notes) {
          return 1;
        } else if (!b.notes) {
          return -1;
        }
        return a.notes.localeCompare(b.notes);
      });
    } else if (sortMethod == 'enginehours') {
      vehicleList.sort((a, b) => {
        return a.engineHoursValue < b.engineHoursValue ? 1 : -1;
      });
    } else if (sortMethod == 'odometer') {
      vehicleList.sort((a, b) => {
        return a.odometerValue < b.odometerValue ? 1 : -1;
      });
    } else if (sortMethod == 'lastCommunicationUp') {
      vehicleList.sort((a, b) => {
        if (deviceCommStatuses == null) {
          return 0;
        }

        const aLastComm = deviceCommStatuses[a.serialNumber].lastCommTimeString;
        const bLastComm = deviceCommStatuses[b.serialNumber].lastCommTimeString;

        if (!aLastComm) {
          return 1;
        } else if (!bLastComm) {
          return -1;
        }
        return aLastComm < bLastComm ? 1 : -1;
      });
    } else if (sortMethod == 'lastCommunicationDown') {
      vehicleList.sort((a, b) => {
        if (deviceCommStatuses == null) {
          return 0;
        }

        const aLastComm = deviceCommStatuses[a.serialNumber].lastCommTimeString;
        const bLastComm = deviceCommStatuses[b.serialNumber].lastCommTimeString;

        if (!aLastComm) {
          return -1;
        } else if (!bLastComm) {
          return 1;
        }
        return aLastComm < bLastComm ? -1 : 1;
      });
    } else if (sortMethod == 'linkedTaskId') {
      vehicleList.sort((a, b) => {
        const aValue = a[sortMethod];
        const bValue = b[sortMethod];
        const sortResult =
          aValue && bValue ? taskConfigDict[aValue].name.localeCompare(taskConfigDict[bValue].name) : !aValue - !bValue;
        return sortResult;
      });
    } else if (sortMethod == 'linkedImplementSN') {
      vehicleList.sort((a, b) => {
        const aValue = a[sortMethod];
        const bValue = b[sortMethod];
        const sortResult =
          aValue && bValue
            ? implementSNDict[aValue].name.localeCompare(implementSNDict[bValue].name)
            : !aValue - !bValue;
        return sortResult;
      });
    } else if (sortMethod == 'machineType') {
      vehicleList.sort((a, b) => {
        return sortVehicleNamesHelper(a.name, b.name);
      });

      vehicleList.sort((a, b) => {
        if (!a.machineType) {
          return 1;
        } else if (!b.machineType) {
          return -1;
        }

        return a.machineType < b.machineType ? 1 : -1;
      });
    } else {
      vehicleList.sort((a, b) => {
        const aValue = a[sortMethod];
        const bValue = b[sortMethod];
        const sortResult = aValue && bValue ? aValue > bValue : !aValue - !bValue;
        return sortResult;
      });
    }
    return vehicleList;
  }

  async function filterTableData() {
    let vehicles = [];
    const vehicleFilterOptions = [];
    const vinFilterOptions = [];
    const makeFilterOptions = [];
    const modelFilterOptions = [];
    const notesFilterOptions = [];
    const linkedImplementFilterOptions = [];
    const linkedTaskFilterOptions = [];
    const gpsDeviceFilterOptions = [];

    const userVehFilter = userSettings?.general?.excludedVehicles;

    const vehicleDataExcludedFiltered = [];
    vehicleData.forEach((vehicle) => {
      if (typeof userVehFilter !== 'undefined' && userVehFilter.includes(vehicle.serialNumber)) {
        // Skip
      } else {
        vehicleDataExcludedFiltered.push(vehicle);
      }
    });

    // Have to do this or get errors with trying to extend an object from state
    vehicleDataExcludedFiltered.forEach((vehicle) => {
      const newVehicle = JSON.parse(JSON.stringify(vehicle));

      const archivedCheck = (showArchivedVehicles && newVehicle.archived) || (!showArchivedVehicles && !newVehicle.archived); 
      const vehicleIcActive = newVehicle?.icActive == true;
      if (vehicleIcActive && archivedCheck) {
        vehicleFilterOptions.push(newVehicle.name);

        if (newVehicle.vin) {
          vinFilterOptions.push(newVehicle.vin);
        }

        if (newVehicle.make) {
          makeFilterOptions.push(newVehicle.make);
        }

        if (newVehicle.model) {
          modelFilterOptions.push(newVehicle.model);
        }

        if (newVehicle.notes) {
          notesFilterOptions.push(newVehicle.notes);
        }

        if (newVehicle?.linkedImplementSN != '' && typeof newVehicle?.linkedImplementSN != 'undefined') {
          const implementInFilter = linkedImplementFilterOptions.find((implementOption) => {
            return implementOption.value == newVehicle?.linkedImplementSN;
          });
          if (
            !implementInFilter &&
            Object.prototype.hasOwnProperty.call(implementSNDict, newVehicle?.linkedImplementSN) &&
            implementSNDict[newVehicle?.linkedImplementSN]
          ) {
            linkedImplementFilterOptions.push({
              text: implementSNDict[newVehicle?.linkedImplementSN].name,
              value: newVehicle?.linkedImplementSN,
            });
          }

          const impDoc = implementSNDict[newVehicle?.linkedImplementSN];
          const impLinkedTaskId = impDoc.linkedTaskId;

          if (Object.prototype.hasOwnProperty.call(taskConfigDict, impLinkedTaskId)) {
            const impLinkedTask = taskConfigDict[impLinkedTaskId];
            newVehicle.linkedTaskId = impLinkedTaskId;

            const taskInFilter = linkedTaskFilterOptions.find((taskOption) => {
              return taskOption.value == impLinkedTaskId;
            });

            if (!taskInFilter && impLinkedTask) {
              linkedTaskFilterOptions.push({
                text: impLinkedTask.name,
                value: impLinkedTaskId,
              });
            }
          }
        } else if (newVehicle?.linkedTaskId != '' && typeof newVehicle?.linkedTaskId != 'undefined') {
          const taskInFilter = linkedTaskFilterOptions.find((taskOption) => {
            return taskOption.value == vehicle?.linkedTaskId;
          });
          if (!taskInFilter && taskConfigDict[newVehicle?.linkedTaskId]) {
            linkedTaskFilterOptions.push({
              text: taskConfigDict[newVehicle?.linkedTaskId].name,
              value: newVehicle?.linkedTaskId,
            });
          }
        }

        if (
          typeof newVehicle?.geotabDevice?.serialNumber != 'undefined' &&
          newVehicle?.geotabDevice?.serialNumber != '' &&
          newVehicle?.geotabDevice?.serialNumber != '000-000-0000'
        ) {
          // Try to find beWhereSerial from the geotab device comment
          let beWhereSerial = undefined;
          if (newVehicle?.geotabDevice?.serialNumber) {
            // BeWhere Serial in lower case
            const bewhereSerialRegex = /btsae[0-9]{14}/g;
            const geotabComment = activeDevices.geotab[newVehicle?.geotabDevice?.id]?.comment;
            if (geotabComment) {
              // convert comment to all lowercase to avoid missing serial due to case sensitivity
              const beWhereSerialFound = geotabComment.toLowerCase().match(bewhereSerialRegex);
              if (beWhereSerialFound) {
                // convert matched serial to uppercase for display
                beWhereSerial = beWhereSerialFound[0].toUpperCase();
              }
            }
          }
          gpsDeviceFilterOptions.push({
            text: beWhereSerial ?? newVehicle?.geotabDevice?.serialNumber,
            value: newVehicle?.geotabDevice?.serialNumber,
          });
        }
      }

      // Get attached bt hub
      newVehicle.btHubId = '';
      const btHubMatch = btHubs.find((btHub) => {
        return btHub.data.vehicleSN == newVehicle.serialNumber;
      });
      if (btHubMatch) {
        newVehicle.btHubId = btHubMatch.data.id;
      }

      const manualEntryCheck = !onlyShowVehiclesWithoutManualEntry || !newVehicle.lastKnownExists;

      const shopviewOnly = newVehicle?.geotabDevice?.serialNumber ? newVehicle?.geotabDevice?.serialNumber : '';
      const shopviewOnlyCheck = !showShopviewOnlyVehicle || !shopviewOnly;

      if (vehicleIcActive && manualEntryCheck && shopviewOnlyCheck && archivedCheck) {
        vehicles.push(newVehicle);
      }
    });

    vehicleFilterOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    vinFilterOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    makeFilterOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    modelFilterOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    notesFilterOptions.sort((a, b) => {
      return a.localeCompare(b);
    });
    linkedImplementFilterOptions.sort((a, b) => {
      const sortResult = a.text && b.text ? a.text.localeCompare(b.text) : !a.value - !b.value;
      return sortResult;
    });
    linkedTaskFilterOptions.sort((a, b) => {
      const sortResult = a.text && b.text ? a.text.localeCompare(b.text) : !a.value - !b.value;
      return sortResult;
    });
    gpsDeviceFilterOptions.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });
    setFilterOptions({
      ...filterOptions,
      vehicleName: vehicleFilterOptions,
      vin: vinFilterOptions,
      make: makeFilterOptions,
      model: modelFilterOptions,
      notes: notesFilterOptions,
      linkedImplement: [...linkedImplementFilterOptions, {text: 'None', value: ''}],
      linkedTask: [...linkedTaskFilterOptions, {text: 'None', value: ''}],
      gpsDevice: [...gpsDeviceFilterOptions, {text: 'Not Installed', value: ''}],
    });

    vehicles = sortData(vehicles);
    setTableData(vehicles);
  }

  async function getVehicleAsyncData() {
    setDeviceHarnessTypes(null);
    setDevicePowerTypes(null);
    setDeviceIgnStates(null);
    setDeviceLastIgn(null);
    setDeviceCommStatuses(null);
    setDeviceGpsStatuses(null);

    // Calls that update react state after page load up, do not await
    fetch('/settings/getDeviceHarnessTypes', {cache: 'no-store'}).then(async (response) => {
      const devicesHarnessTypes = await response.json();
      setDeviceHarnessTypes(devicesHarnessTypes);
    });

    fetch('/settings/getDevicePowerTypes', {cache: 'no-store'}).then(async (response) => {
      const devicePowerTypes = await response.json();
      setDevicePowerTypes(devicePowerTypes.powerTypes);
      setDeviceIgnStates(devicePowerTypes.ignStates);
      setDeviceLastIgn(devicePowerTypes.ignData);
    });

    fetch('/settings/getVehiclesCommStatus', {cache: 'no-store'}).then(async (response) => {
      const commData = await response.json();
      setDeviceCommStatuses(commData);
    });

    fetch('/settings/getVehiclesGpsStatus', {cache: 'no-store'}).then(async (response) => {
      const gpsData = await response.json();
      setDeviceGpsStatuses(gpsData);
    });
  }

  async function getVehicleDataForSettings() {
    dispatch(updateLoading(true));

    // Calls required for page load up
    const getVehiclesRequest = fetch('/settings/getAllVehicles', {cache: 'no-store'});
    const getEngineHoursRequest = fetch('/shopview/getVehiclesEngineHours', {cache: 'no-store'});
    const getOdometerRequest = fetch('/shopview/getVehiclesOdometer', {cache: 'no-store'});
    const getDevicesRequest = fetch('/getDevices', {cache: 'no-store'});

    const [getVehiclesResponse, getEngineHoursResponse, getOdometerResponse, getDevicesResponse] = await Promise.all([
      getVehiclesRequest,
      getEngineHoursRequest,
      getOdometerRequest,
      getDevicesRequest,
    ]);
    const devices = await getDevicesResponse.json();
    const vehicles = await getVehiclesResponse.json();
    const engineHours = await getEngineHoursResponse.json();
    const odometer = await getOdometerResponse.json();

    vehicles.forEach((vehicle) => {
      const serialNumber = vehicle.serialNumber;
      vehicle.engineHoursValue = isNaN(engineHours[serialNumber]) ? 0 : engineHours[serialNumber];
      vehicle.odometerValue = isNaN(odometer[serialNumber]) ? 0 : odometer[serialNumber];
    });
    dispatch(updateDevices(devices));
    dispatch(updateVehicleData(vehicles));
    dispatch(updateLoading(false));
  }

  function initBulkEditVehicles() {
    const tempVehicleNamesList = vehicleData.map((vehicle) => {
      return vehicle.name;
    });
    setVehicleNamesList(tempVehicleNamesList);
    const tempVehicleBulkEdit = {};
    vehicleData.forEach((vehicle) => {
      tempVehicleBulkEdit[vehicle.serialNumber] = {};
    });
    dispatch(initializeBulkEditVehicles(tempVehicleBulkEdit));
  }

  function handleBulkUploadModalOpen(openState) {
    setBulkUploadModalOpen(openState);
  }

  const vehiclesCsvColumns = [
    {
      key: 'vehicleName',
      required: true,
      description: 'Name of the Equipment',
    },
    {
      key: 'machineType',
      required: false,
      description: `Equipment Type, Accepted Values: 
        ${Object.keys(machineTypeMapping)
          .map((key) => {
            return `'${machineTypeMapping[key]}'`;
          })
          .join(' ,')}, 
        Default: 'Not Assigned'`,
    },
    {
      key: 'vin',
      required: false,
      description: 'VIN of the Equipment',
    },
    {
      key: 'make',
      required: false,
      description: 'Make of the Equipment',
    },
    {
      key: 'model',
      required: false,
      description: 'Model of the Equipment',
    },
    {
      key: 'notes',
      required: false,
      description: 'Additional Notes',
    },
    {
      key: 'odometer',
      required: false,
      description: `Current Odometer Value (${unitsLengthSystem == 'imperial' ? 'mi' : 'km'}) of the Equipment`,
    },
    {
      key: 'engineHours',
      required: false,
      description: 'Current Engine Hour Value of the Equipment',
    },
    {
      key: 'idleTimeTarget',
      required: false,
      description: 'Idle time Target (%) for the Equipment',
    },
  ];

  function bulkUploadDataPreview(rowData, index, totalCount) {
    return (
      <div className='card mb-2 text-left' key={index}>
        <div className='card-header row mx-0 px-0'>
          <div className='col-8'>
            Name: {rowData.name}{' '}
            {rowData.errors && rowData.errors.length > 0 ? (
              <FontAwesomeIcon className='text-danger' icon='fas fa-warning' />
            ) : rowData.warnings && rowData.warnings.length > 0 ? (
              <FontAwesomeIcon className='text-warning' icon='fas fa-warning' />
            ) : (
              <FontAwesomeIcon className='text-success' icon='fas fa-check-circle' />
            )}
          </div>
          <div className='col-4 text-right'>
            {index + 1}/{totalCount}
          </div>
        </div>
        <div className='row mx-0 px-0'>
          <div className='col-md-6'>Type: {machineTypeMapping[rowData.machineType]}</div>
          <div className='col-md-6'>VIN: {rowData.vin}</div>
          <div className='col-md-6'>Make: {rowData.make}</div>
          <div className='col-md-6'>Model: {rowData.model}</div>
          <div className='col-md-6'>Notes: {rowData.notes}</div>
          <div className='col-md-6'>
            {'Odometer: '}
            {`${
              unitsLengthSystem == 'imperial'
                ? unitsLengthDisplayConversion(rowData.odometer, 'mi').toFixed(0)
                : rowData.odometer.toFixed(0)
            } ${unitsLengthSystem == 'imperial' ? 'mi' : 'km'}`}
          </div>
          <div className='col-md-6'>Engine Hours: {rowData.engineHours} hr</div>
          <div className='col-md-6'>
            Idle Time Target: {rowData.idleTimeTarget > 0 ? `${rowData.idleTimeTarget} %` : 'None'}{' '}
          </div>
        </div>
        {((rowData.errors && rowData.errors.length > 0) || (rowData.warnings && rowData.warnings.length > 0)) && (
          <div className='card-footer'>
            {rowData.errors.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
            {rowData.warnings.map((errorMsg) => {
              return <div key={errorMsg}>{errorMsg}</div>;
            })}
          </div>
        )}
      </div>
    );
  }

  function validateAndMapBulkUploadedData(data, dupCheckObject) {
    const requiredColumns = vehiclesCsvColumns
      .filter((column) => {
        return column.required;
      })
      .map((column) => {
        return column.key;
      });

    const noDupColumns = ['vehicleName'];

    const defaultVehicle = {
      'name': '',
      'vin': '',
      'make': '',
      'model': '',
      'notes': '',
      'odometer': 0,
      'engineHours': 0,
      'machineType': 0,
      'idleTimeTarget': 0,
    };

    const tempDoc = {
      ...defaultVehicle,
      ...(Object.hasOwnProperty.call(data, 'vehicleName') ? {name: data['vehicleName']} : {}),
      ...(Object.hasOwnProperty.call(data, 'vin') ? {vin: data['vin']} : {}),
      ...(Object.hasOwnProperty.call(data, 'make') ? {make: data['make']} : {}),
      ...(Object.hasOwnProperty.call(data, 'model') ? {model: data['model']} : {}),
      ...(Object.hasOwnProperty.call(data, 'notes') ? {notes: data['notes']} : {}),
    };

    const errors = [];
    const warnings = [];

    noDupColumns.forEach((col) => {
      if (!Object.keys(dupCheckObject).includes(col)) {
        dupCheckObject[col] = [];
        dupCheckObject[col].push(data[col]);
      } else if (dupCheckObject[col].includes(data[col])) {
        errors.push(`Duplicate ${col}`);
      } else {
        dupCheckObject[col].push(data[col]);
      }
    });

    if (Object.hasOwnProperty.call(data, 'odometer')) {
      try {
        const odometerValue = parseFloat(data['odometer']);
        if (isNaN(odometerValue)) {
          warnings.push(`Odometer Value is not a number, Entered value: ${data['odometer']}`);
        } else if (odometerValue < 0) {
          warnings.push(`Odometer Value cannot be negative, Entered value: ${data['odometer']}`);
        } else {
          tempDoc['odometer'] =
            unitsLengthSystem == 'imperial' ? unitsLengthSubmitConversion(odometerValue, 'mi') : odometerValue;
        }
      } catch {
        warnings.push(`Odometer Value input is invalid, Entered value: ${data['odometer']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'engineHours')) {
      try {
        const engineHoursValue = parseFloat(data['engineHours']);
        if (isNaN(engineHoursValue)) {
          warnings.push(`Engine Hours Value is not a number, Entered value: ${data['engineHours']}`);
        } else if (engineHoursValue < 0) {
          warnings.push(`Engine Hours Value cannot be negative, Entered value: ${data['engineHours']}`);
        } else {
          tempDoc['engineHours'] = engineHoursValue;
        }
      } catch {
        warnings.push(`Engine Hours Value input is invalid, Entered value: ${data['engineHours']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'idleTimeTarget')) {
      try {
        const idleTimeTargetValue = parseInt(data['idleTimeTarget']);
        if (isNaN(idleTimeTargetValue)) {
          warnings.push(`Idle Time Target is not a number, Entered value: ${data['idleTimeTarget']}`);
        } else if (idleTimeTargetValue < 0) {
          warnings.push(`Idle Time Target cannot be negative, Entered value: ${data['idleTimeTarget']}`);
        } else if (idleTimeTargetValue > 100) {
          warnings.push(`Idle Time Target cannot be greater than 100, Entered value: ${data['idleTimeTarget']}`);
        } else {
          tempDoc['idleTimeTarget'] = idleTimeTargetValue;
        }
      } catch {
        warnings.push(`Idle Time Target input is invalid, Entered value: ${data['idleTimeTarget']}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'machineType')) {
      const machineTypeList = Object.keys(machineTypeMapping);
      const enteredVehicleType = data['machineType'].trim();
      let machineTypeFound = false;
      for (let i = 0; i < machineTypeList.length; i++) {
        const machineTypeName = machineTypeMapping[machineTypeList[i]];
        if (enteredVehicleType.toLowerCase() == machineTypeName.toLowerCase()) {
          tempDoc['machineType'] = machineTypeList[i];
          machineTypeFound = true;
          break;
        }
      }
      if (!machineTypeFound) {
        warnings.push(`Vehicle Type cannot be found, Entered value: ${enteredVehicleType}`);
      }
    }

    if (Object.hasOwnProperty.call(data, 'vehicleName') && vehicleNamesList.includes(data['vehicleName'])) {
      errors.push(`Vehicle with the same name already Exist, Entered value: ${data['vehicleName']}`);
    }

    for (let i = 0; i < requiredColumns.length; i++) {
      if (!data[requiredColumns[i]] || data[requiredColumns[i]] == '') {
        errors.push(`Column ${requiredColumns[i]} from CSV cannot be empty`);
      }
    }

    tempDoc.errors = errors;
    tempDoc.warnings = warnings;

    return tempDoc;
  }

  async function postBulkVehicles(newVehicleDocs) {
    const postData = {docsList: newVehicleDocs, uploadType: 'vehicle'};
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(postData),
    };
    const url = '/settings/bulkUpload';
    const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    }

    return result;
  }

  function processDeviceStatuses() {
    const GREEN_THRESH_HOURS = 1;
    const YELLOW_THRESH_HOURS = 24;

    const deviceStatuses = {};

    const customerTimezone = customerSettings.general.timeZone;

    for (let i = 0; i < vehicleData.length; i++) {
      const vehicle = vehicleData[i];

      // Get comms time
      let lastCommunicatedTime = null;
      let lastCommunicatedTimeLocaleStr = null;
      if (deviceCommStatuses != null) {
        if (
          deviceCommStatuses.hasOwnProperty(vehicle.serialNumber) &&
          (!deviceCommStatuses[vehicle.serialNumber].hasOwnProperty('lastCommTimeString') ||
            !deviceCommStatuses[vehicle.serialNumber].lastCommTimeString ||
            typeof deviceCommStatuses[vehicle.serialNumber].lastCommTimeString == 'undefined')
        ) {
          lastCommunicatedTime = undefined;
          lastCommunicatedTimeLocaleStr = 'Not Installed';
          if (
            typeof vehicle?.geotabDevice?.serialNumber != 'undefined' &&
            vehicle?.geotabDevice?.serialNumber != '' &&
            vehicle?.geotabDevice?.serialNumber != '000-000-0000'
          ) {
            lastCommunicatedTimeLocaleStr = 'Has Not Communicated';
          }
        } else if (
          deviceCommStatuses.hasOwnProperty(vehicle.serialNumber) &&
          deviceCommStatuses[vehicle.serialNumber].hasOwnProperty('lastCommTimeString')
        ) {
          const lastDateObj = DateTime.fromISO(deviceCommStatuses[vehicle.serialNumber].lastCommTimeString);
          const localeStr = lastDateObj.setZone(customerTimezone).toLocaleString(DateTime.DATETIME_MED);
          lastCommunicatedTime = deviceCommStatuses[vehicle.serialNumber].lastCommTimeString;
          lastCommunicatedTimeLocaleStr = localeStr;
        }
      }

      // Get gps time
      let firstGpsTime = null;
      let lastGpsTime = null;
      if (deviceGpsStatuses != null) {
        if (
          deviceGpsStatuses.hasOwnProperty(vehicle.serialNumber) &&
          deviceGpsStatuses[vehicle.serialNumber].hasOwnProperty('firstGpsTimeString') &&
          deviceGpsStatuses[vehicle.serialNumber].firstGpsTimeString
        ) {
          const dateObj = DateTime.fromISO(deviceGpsStatuses[vehicle.serialNumber].firstGpsTimeString);
          firstGpsTime = dateObj.setZone(customerTimezone).toISODate();
        } else {
          firstGpsTime = undefined;
        }

        if (
          deviceGpsStatuses.hasOwnProperty(vehicle.serialNumber) &&
          deviceGpsStatuses[vehicle.serialNumber].hasOwnProperty('lastGpsTimeString') &&
          deviceGpsStatuses[vehicle.serialNumber].lastGpsTimeString
        ) {
          lastGpsTime = deviceGpsStatuses[vehicle.serialNumber].lastGpsTimeString;
        } else {
          lastGpsTime = undefined;
        }
      }

      // Try to find beWhereSerial from the geotab device comment
      let beSolType = false;
      if (vehicle?.geotabDevice?.serialNumber) {
        // BeWhere Serial in lower case
        const bewhereSerialRegex = /btsae[0-9]{14}/g;
        const geotabComment = activeDevices.geotab[vehicle?.geotabDevice?.id]?.comment;
        if (geotabComment) {
          // convert comment to all lowercase to avoid missing serial due to case sensitivity
          const beWhereSerialFound = geotabComment.toLowerCase().match(bewhereSerialRegex);
          if (beWhereSerialFound) {
            beSolType = true;
          }
        }
      }

      // Get harness type
      let harnessType = null;
      if (beSolType) {
        harnessType = 'BeSol / No Harness';
      } else if (deviceHarnessTypes != null) {
        if (Object.prototype.hasOwnProperty.call(deviceHarnessTypes, vehicle.serialNumber)) {
          harnessType = deviceHarnessTypes[vehicle.serialNumber];
        } else {
          harnessType = 'Unknown';
        }
      }

      // Get power type
      let powerType = null;
      if (beSolType) {
        powerType = 'Self-Powered / Battery';
      } else if (devicePowerTypes != null) {
        if (Object.prototype.hasOwnProperty.call(devicePowerTypes, vehicle.serialNumber)) {
          powerType = devicePowerTypes[vehicle.serialNumber];
        } else {
          powerType = 'Unknown';
        }
      }

      // Get ign state
      let ignState = null;
      if (beSolType) {
        ignState = '--';
      } else if (deviceIgnStates != null) {
        if (Object.prototype.hasOwnProperty.call(deviceIgnStates, vehicle.serialNumber)) {
          ignState = deviceIgnStates[vehicle.serialNumber];
        } else {
          ignState = 'Unknown';
        }
      }

      // Determine status lights. Do not process status lights if power type not yet determined
      let lastCommStatus = null;
      let lastGpsStatus = null;
      if (powerType !== null && lastCommunicatedTime !== null && lastGpsTime !== null) {
        let lastCommTimeObj = undefined;
        let lastGpsTimeObj = undefined;

        if (lastCommunicatedTime) {
          lastCommTimeObj = DateTime.fromISO(lastCommunicatedTime);
        }

        if (lastGpsTime) {
          lastGpsTimeObj = DateTime.fromISO(lastGpsTime);
          if (!lastCommTimeObj || lastGpsTimeObj > lastCommTimeObj) {
            lastCommTimeObj = lastGpsTimeObj;
          }
        }

        if (lastCommTimeObj) {
          // Last Data (Comm or Gps) Color Code:
          // Green - within 1 Hour or 24 hours if device asleep for more than 72 hours
          // Yellow - between 1 - 24 Hour
          // Red - Over 24 Hour (with device is initialized with previous Data)

          const now = DateTime.now();
          let greenThresh = GREEN_THRESH_HOURS;
          const yellowTresh = YELLOW_THRESH_HOURS;

          // If no ignition for more than 72 hours, then the device heartbeat changes to every 23 hours
          // Change green color threshold to 24 hours
          if (
            powerType == 'Constant Power' &&
            deviceLastIgn !== null &&
            typeof deviceLastIgn !== 'undefined' &&
            Object.prototype.hasOwnProperty.call(deviceLastIgn, vehicle.serialNumber) &&
            deviceLastIgn[vehicle.serialNumber].length > 0 &&
            now.diff(DateTime.fromISO(deviceLastIgn[vehicle.serialNumber][0].dateTime), 'hours').toObject().hours > 72
          ) {
            greenThresh = YELLOW_THRESH_HOURS;
          }

          if (lastCommTimeObj > now.minus({hours: greenThresh})) {
            lastCommStatus = 'green';
          } else if (lastCommTimeObj > now.minus({hours: yellowTresh})) {
            lastCommStatus = 'yellow';
          } else {
            lastCommStatus = 'red';
          }

          // Process GPS Status
          if (lastGpsTimeObj) {
            if (lastGpsTimeObj > now.minus({hours: greenThresh})) {
              lastGpsStatus = 'green';
            } else if (lastGpsTimeObj > now.minus({hours: yellowTresh})) {
              lastGpsStatus = 'yellow';
            } else {
              lastGpsStatus = 'red';
            }
          } else {
            lastGpsStatus = 'red';
          }
        } else {
          // Grey out both if device does not have any previous Comm Data
          lastCommStatus = 'grey';
          lastGpsStatus = 'grey';
        }

        // Override switched power type or not powered to grey out in not green. Do not override besols
        if (['Non-Constant Power', 'Has Not Been Powered', 'Unknown'].includes(powerType) || beSolType) {
          if (lastCommStatus != 'green') {
            lastCommStatus = 'grey';
          }
          if (lastGpsStatus != 'green' && lastCommStatus == 'grey') {
            lastGpsStatus = 'grey';
          }
        }
      }

      deviceStatuses[vehicle.serialNumber] = {
        lastCommunicatedTime: lastCommunicatedTime,
        lastCommunicatedTimeLocaleStr: lastCommunicatedTimeLocaleStr,
        firstGpsTime: firstGpsTime,
        cell: lastCommStatus,
        gps: lastGpsStatus,
        harnessType: harnessType,
        powerType: powerType,
        ignState: ignState,
      };
    }

    dispatch(updateDeviceStatuses(deviceStatuses));
  }

  return (
    <React.Fragment>
      <TabMenuTableWrapper
        menu={
          <Menu
            setFilterDefault={setFilterDefault}
            handleBulkUploadModalOpen={handleBulkUploadModalOpen}
            bulkEditMode={bulkEditMode}
            toggleBulkEditMode={handleToggleBulkEditMode}
            postVehicleUpdates={postVehicleUpdates}
            resetBulkEditData={initBulkEditVehicles}
            refreshData={getVehicleDataForSettings}
          />
        }
        table={
          <VehicleInfoTable
            type='vehicles'
            tableData={tableData}
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            dims={dims}
            sortMethod={sortMethod}
            getVehicleDataForSettings={getVehicleDataForSettings}
            getVehicleAsyncData={getVehicleAsyncData}
            bulkEditMode={bulkEditMode}
            postVehicleUpdates={postVehicleUpdates}
          />
        }
      />
      <BulkUploadModal
        entityName={'Vehicles'}
        modalOpen={bulkUploadModalOpen}
        handleModalOpen={handleBulkUploadModalOpen}
        dataValidationAndMapping={validateAndMapBulkUploadedData}
        dataPreview={bulkUploadDataPreview}
        acceptedColumnHeaders={vehiclesCsvColumns}
        bulkUploadSubmit={postBulkVehicles}
        refreshData={getVehicleDataForSettings}
      />
    </React.Fragment>
  );
}

export {VehicleInfo};
