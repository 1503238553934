import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../components/Table';
import {FilterCardCropview} from '../../components/Card';
import {Alert, Table, TableHead, TableBody, TableCell, TableRow, CircularProgress} from '@mui/material';
import {Tailselect} from '../../components/Tailselect';
import {DateTime} from 'luxon';
import {searchFind, sendGAEvent} from '../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {updateZoneZoom, updateMapView} from './cropviewSlice';

function SprayTable(props) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.cropview.loading;
  });
  const sprayCompleteDict = useSelector((state) => {
    return state.cropview.sprayCompleteDict;
  });
  const taskConfigIdDict = useSelector((state) => {
    return state.cropview.taskConfigIdDict;
  });
  const zonesData = useSelector((state) => {
    return state.cropview.zonesData;
  });
  const displayedTable = useSelector((state) => {
    return state.cropview.displayedTable;
  });
  const mapView = useSelector((state) => {
    return state.cropview.mapView;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const [tableData, setTableData] = useState([]);
  const [sprayCompleteData, setSprayCompleteData] = useState([]);
  const [overdueQty, setOverdueQty] = useState(0);
  const [upcomingQty, setUpcomingQty] = useState(0);
  const [overdueFilter, setOverdueFilter] = useState(false);
  const [upcomingFilter, setUpcomingFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    zones: [],
    tasks: [],
  });
  const [filters, setFilters] = useState({
    zones: [],
    tasks: [],
  });

  useEffect(() => {
    generateSprayCompleteData();
  }, [sprayCompleteDict, taskConfigIdDict, zonesData]);

  useEffect(() => {
    filterTableRows(sprayCompleteData);
  }, [sprayCompleteData, filters, overdueFilter, upcomingFilter, searchText]);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  async function filterTableRows(tableData) {
    // Filter props.tablerows based on filters and pagination to render limited amount of data on tables
    // Promise.all is used to avoid desync between states update and rerendering due to slow filter on large array

    // Generate an array based on rowFilter function (return true or false) base on filters
    const result = tableData.filter((rowData) => {
      return rowFilter(rowData);
    });

    setTableData(result);
  }

  function rowFilter(rowData) {
    // Check if zone in filter
    const zoneInFilter = filters.zones.includes(rowData.blockName) || filters.zones.length == 0;

    // Check if task in filter
    const taskInFilter = filters.tasks.includes(rowData.taskName) || filters.tasks.length == 0;

    // Check if reminder in filter
    const reminderInFilter =
      (overdueFilter && rowData.overdue) || (upcomingFilter && rowData.upcoming) || (!overdueFilter && !upcomingFilter);

    // Check if search filters table
    const rowValues = Object.values(rowData);
    const search = searchFind(<div>{JSON.stringify(rowValues)}</div>, searchText.toLowerCase().trim());

    return zoneInFilter && taskInFilter && reminderInFilter && search;
  }

  function zoomToZone(zone) {
    let delay = 0;
    // If map was hidden on small screen, delay the zoom until the map loads
    if (mapView == 0 && smallScreen) {
      delay = 10;
      dispatch(updateMapView(1));
    }
    setTimeout(() => {
      dispatch(updateZoneZoom(zone));
    }, delay);
    sendGAEvent('zoom_to_zone', displayedTable, 'cropview');
  }

  function generateSprayCompleteData() {
    const newData = [];
    let totalOverdue = 0;
    let totalUpcoming = 0;

    // Create block lookup
    const blockIdDict = {};
    if (zonesData.blocks) {
      zonesData.blocks.forEach((blockDoc) => {
        blockIdDict[blockDoc.id] = blockDoc;
      });
    }

    // Iterate through value of dict
    const blockNameList = [];
    const taskNameList = [];
    Object.keys(sprayCompleteDict).forEach((blockId) => {
      Object.keys(sprayCompleteDict[blockId]).forEach((taskId) => {
        const completion = sprayCompleteDict[blockId][taskId];

        // Get block name
        let blockName = '';
        if (Object.prototype.hasOwnProperty.call(blockIdDict, completion.blockId)) {
          blockName = blockIdDict[completion.blockId].name;
        }

        // Get task name and cycle
        let taskName = '';
        let taskCycleDays = '';
        if (Object.prototype.hasOwnProperty.call(taskConfigIdDict, completion.taskId)) {
          taskName = taskConfigIdDict[completion.taskId].name;
          taskCycleDays = taskConfigIdDict[completion.taskId].cycleDays;
        }

        // Only display if block and task found
        if (blockName && taskCycleDays) {
          // Get last and next spray date
          const customerTimezone = customerSettings.general.timeZone;

          let taskCycleUpcomingWindowDays = 0;
          if (customerSettings.cropview.taskCycleUpcomingWindowDays !== 'undefined') {
            taskCycleUpcomingWindowDays = customerSettings.cropview.taskCycleUpcomingWindowDays;
          }

          const lastSprayDateObj = DateTime.fromISO(completion.coverageThresholdMetTime['@ts'])
            .setZone(customerTimezone)
            .startOf('day');
          let nextSprayDateObj = lastSprayDateObj;
          if (typeof taskCycleDays == 'number') {
            nextSprayDateObj = nextSprayDateObj.plus({days: taskCycleDays});
          }
          const lastSprayDateStr = lastSprayDateObj.toLocaleString(DateTime.DATE_MED);
          const nextSprayDateStr = nextSprayDateObj.toLocaleString(DateTime.DATE_MED);

          // Get due in time
          const todayStart = DateTime.now().setZone(customerTimezone).startOf('day');
          const dueInDays = nextSprayDateObj.diff(todayStart, 'days').toObject().days;

          const rowData = {
            blockName: blockName,
            taskName: taskName,
            cycleDays: taskCycleDays,
            lastSprayDate: lastSprayDateStr,
            nextSprayDate: nextSprayDateStr,
            dueInDays: dueInDays,
            overdue: dueInDays < 0,
            upcoming: dueInDays >= 0 && dueInDays <= taskCycleUpcomingWindowDays,
          };

          // Add to overdue counter
          if (rowData.overdue) {
            totalOverdue += 1;
          }

          // Add to upcoming counter
          if (rowData.upcoming) {
            totalUpcoming += 1;
          }

          if (taskCycleDays) {
            newData.push(rowData);
            blockNameList.push(rowData.blockName);
            taskNameList.push(rowData.taskName);
          }
        }
      });
    });

    // Set block and task name filters
    blockNameList.sort((a, b) => {
      return a.localeCompare(b);
    });
    taskNameList.sort((a, b) => {
      return a.localeCompare(b);
    });
    setFilterOptions({
      ...filterOptions,
      zones: blockNameList,
      tasks: taskNameList,
    });

    // Sort table
    newData.sort((a, b) => {
      return a.dueInDays > b.dueInDays ? 1 : -1;
    });

    // Set data
    setSprayCompleteData(newData);
    setOverdueQty(totalOverdue);
    setUpcomingQty(totalUpcoming);
  }

  function generateTableBody() {
    let tableBody;
    let colorFlip = false;

    tableBody = (
      <React.Fragment>
        {tableData.map((rowData, index) => {
          colorFlip = !colorFlip;

          const borderLeft = rowData.overdue ? '0.5rem solid #e74a3b' : rowData.upcoming ? '0.5rem solid #f6c23e' : '';

          const row = (
            <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
              <TableCell sx={{borderLeft: borderLeft}}>
                <div className='font-weight-bold'>{rowData.blockName}</div>
              </TableCell>
              <TableCell>
                <div className='font-weight-normal'>{rowData.lastSprayDate}</div>
              </TableCell>
              <TableCell>
                <div className='font-weight-normal'>{rowData.taskName}</div>
              </TableCell>
              <TableCell>
                <div className='font-weight-normal'>{`${rowData.cycleDays} days`}</div>
              </TableCell>
              <TableCell>
                <div className='font-weight-normal'>
                  {rowData.nextSprayDate}{' '}
                  {rowData.overdue ? (
                    <span className='text-danger'>{`(${Math.abs(rowData.dueInDays)} days overdue)`}</span>
                  ) : (
                    <span>{`(in ${rowData.dueInDays} days)`}</span>
                  )}
                </div>
              </TableCell>
              <TableCell>
                <button
                  className='btn btn-light'
                  style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                  onClick={() => {
                    zoomToZone(rowData.blockName);
                  }}
                >
                  <FontAwesomeIcon icon='fa fa-map-marker-alt' />
                </button>
              </TableCell>
            </TableRow>
          );

          return row;
        })}
      </React.Fragment>
    );

    // Display text if no data found
    if (tableData.length == 0) {
      tableBody = (
        <TableRow>
          <TableCell colSpan={100} sx={{textAlign: 'center', fontSize: '25px'}}>
            No Task Cycles Found For This Year
          </TableCell>
        </TableRow>
      );
    }

    // Display loading icon if loading
    if (loading) {
      tableBody = (
        <TableRow>
          <TableCell colSpan={100}>
            <CircularProgress className='mt-4 mx-auto d-block' size={80} />
          </TableCell>
        </TableRow>
      );
    }

    return tableBody;
  }

  function generateTableHeaders() {
    return (
      <TableHead>
        <TableRow>
          <TableCell sx={{width: '20%'}}>
            <TableHeadFilterButtonTitleWrapper title={'Block'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#cropview-spray-block-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.zones.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='cropview-spray-block-select'
                name={'zones'}
                multiple={true}
                search={true}
                value={filters['zones']}
                options={filterOptions['zones']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          {/* {!smallScreen && <TableCell sx={{width: '20%'}}>Last Communication</TableCell>} */}
          <TableCell sx={{width: '20%'}}>Last Spray Date</TableCell>
          <TableCell sx={{width: '20%'}}>
            <TableHeadFilterButtonTitleWrapper title={'Task'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#cropview-spray-task-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.tasks.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='cropview-spray-task-select'
                name={'tasks'}
                multiple={true}
                search={true}
                value={filters['tasks']}
                options={filterOptions['tasks']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell sx={{width: '10%'}}>Cycle</TableCell>
          <TableCell sx={{width: '20%'}}>Next Spray Date</TableCell>
          <TableCell sx={{width: '10%'}}>{/* Table Column for Edit Button */}</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div className={`table-responsive`} style={{width: smallScreen ? '100%' : props.dims.w + 'px'}}>
      {(!smallScreen || mapView == 0) && (
        <div className='row my-1 my-sm-1 tab-top-row'>
          <div className='col-6 my-1'>
            <FilterCardCropview
              color='danger'
              value={overdueQty}
              text='Overdue'
              icon='fa-exclamation-circle'
              name='overdue'
              filterOnClick={() => {
                setOverdueFilter(!overdueFilter);
              }}
              filterActive={overdueFilter}
            />
          </div>
          <div className='col-6 my-1'>
            <FilterCardCropview
              color='warning'
              value={upcomingQty}
              text='Upcoming'
              icon='fa-stopwatch'
              name='upcoming'
              filterOnClick={() => {
                setUpcomingFilter(!upcomingFilter);
              }}
              filterActive={upcomingFilter}
            />
          </div>
        </div>
      )}
      <div className='mb-1'>
        <Alert
          variant='outlined'
          severity='info'
          sx={{
            '& .MuiAlert-message': {
              width: '100%',
            },
          }}
        >
          <div>
            <p className='my-n1 text-xs'>
              <strong>Table data based on current time: </strong>
              {DateTime.now().toLocaleString(DateTime.DATETIME_MED)}
            </p>
            <p className='my-n1 text-xs'>
              <strong>Map data based on selected time range. </strong>
            </p>
          </div>
        </Alert>
      </div>
      <div className='table-responsive'>
        <div className='card min-height-table'>
          <div className='table-wrapper'>
            <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
              {generateTableHeaders()}
              <TableBody>{generateTableBody()}</TableBody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export {SprayTable};
