import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {APIProvider} from '@vis.gl/react-google-maps';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {AlertViewMap} from './Map';

import {Divider, Box, Modal, Button} from '@mui/material';

function AlertViewModal(props) {
  const dispatch = useDispatch();

  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [selectedAlertEventId, setSelectedAlertEventId] = useState(null);
  const [zoomLocation, setZoomLocation] = useState({
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
  });

  // Element styling
  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smallScreen ? '100%' : 1500,
    maxWidth: '90vw',
    maxHeight: smallScreen ? '100%' : '90vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY: 'auto',
    p: 2,
  };

  // Modal actions
  function modalClose() {
    props.handleResetAlertEventData();
    return props.handleModalOpen(false);
  }

  function convertTimeDisplay(timeValueSeconds) {
    const hours = Math.floor(timeValueSeconds / 3600);
    const minutes = Math.floor((timeValueSeconds % 3600) / 60);

    if (timeValueSeconds >= 3600) {
      return `${hours} h ${minutes} min`;
    } else {
      return `${minutes} min`;
    }
  }

  function generateOccurrenceDetails(alertEvent, key) {
    let targetsData = null;
    if (props.alertEventData?.rule?.type == 'farmSpeeding' || props.alertEventData?.rule?.type == 'roadSpeeding') {
      targetsData = (
        <div>
          <div>
            <span className='font-weight-bold'>Target Speed: </span>
            {`${alertEvent.targetValue} ${alertEvent.units}`}
          </div>
          <div>
            <span className='font-weight-bold'>Measured Speed: </span>
            {`${alertEvent.measuredValue} ${alertEvent.units}`}
          </div>
        </div>
      );
    } else if (props.alertEventData?.rule?.type == 'idling') {
      targetsData = (
        <div>
          <div>
            <span className='font-weight-bold'>Target Max Idling Time: </span>
            {convertTimeDisplay(alertEvent.targetValue)}
          </div>
          <div>
            <span className='font-weight-bold'>Measured Idling Time: </span>
            {convertTimeDisplay(alertEvent.measuredValue)}
          </div>
        </div>
      );
    }

    return (
      <div key={key} className='mt-2 px-1 rounded border border-light shadow-sm' style={{backgroundColor: '#f2f2f2'}}>
        <div>
          <span className='font-weight-bold'>Date: </span>
          {alertEvent.dateString}
          <span>
            <button
              type='button'
              className='btn'
              onClick={(e) => {
                const tempZoomLocation = {
                  latMax: null,
                  latMin: null,
                  lngMax: null,
                  lngMin: null,
                };

                alertEvent.path.forEach((point) => {
                  if (point.latitude > tempZoomLocation.latMax || tempZoomLocation.latMax == null) {
                    tempZoomLocation.latMax = point.latitude;
                  }
                  if (point.latitude < tempZoomLocation.latMin || tempZoomLocation.latMin == null) {
                    tempZoomLocation.latMin = point.latitude;
                  }
                  if (point.longitude > tempZoomLocation.lngMax || tempZoomLocation.lngMax == null) {
                    tempZoomLocation.lngMax = point.longitude;
                  }
                  if (point.longitude < tempZoomLocation.lngMin || tempZoomLocation.lngMin == null) {
                    tempZoomLocation.lngMin = point.longitude;
                  }
                });

                setZoomLocation(tempZoomLocation);
                setSelectedAlertEventId(alertEvent.id);
              }}
            >
              <FontAwesomeIcon icon='fas fa-map-marker-alt' />
            </button>
          </span>
        </div>

        {targetsData}
      </div>
    );
  }

  return (
    <Modal
      open={props.modalOpen}
      onClose={modalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={boxStyle}>
        <div className='row mx-0 px-1 pb-3'>
          <div className='row col-md-5 m-0 p-0'>
            <div className='col-sm-12 px-1'>
              <h4 className='font-weight-bold mt-2'>ALERT: {props.alertEventData.alert}</h4>
              <div className='mt-3'>
                <span className='font-weight-bold'>Vehicle: </span>
                {props.alertEventData.vehicle}
              </div>
              <div>
                <span className='font-weight-bold'>Conditions: </span>
                {props.alertEventData?.conditionString}
              </div>
              {props.alertEventData.alertEventList && (
                <div className='mt-3'>
                  <div className='font-weight-bold'>Occurences: </div>
                  <div style={{overflowY: 'scroll', maxHeight: '50vh'}}>
                    {props.alertEventData.alertEventList.map((alertEvent, index) => {
                      return generateOccurrenceDetails(alertEvent, index);
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='col-md-7 px-0 mt-3' style={{minHeight: '50vh'}}>
            <APIProvider apiKey='AIzaSyANXSBQLKbCPDicQsIvWsMvUWaTinztW6Q'>
              <AlertViewMap
                alertEventData={props.alertEventData}
                zoomLocation={zoomLocation}
                selectedAlertEventId={selectedAlertEventId}
              />
            </APIProvider>
          </div>
        </div>

        <div className='flex-row mb-3 col-12 px-1'>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <Button variant='ic-button' color='secondary' onClick={modalClose}>
              Close
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export {AlertViewModal};
