import React, {useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DateTime} from 'luxon';
import {
  sendGAEvent,
  unitsLengthDisplayConversion,
  unitsLengthSubmitConversion,
  fetchPostAuthSafe,
  dollarRegex,
  handleAllowOnlyNumbers,
} from '../../../app/utils';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalColumnSection,
  ModalWideColumnSection,
  ModalInputSection,
  ModalBody,
  ModalWarning,
  ModalNote,
} from '../../../components/Modal';
import {getServiceData} from '../shopviewSlice';

const defaultInputs = {
  engineHours: '',
  date: '',
  odometer: '',
  workOrder: '',
  laborCost: '',
  laborTime: '',
  invoice: '',
  partsCost: '',
  supplier: '',
  reviewedBy: '',
  notes: '',
};
let currValues = defaultInputs;

function EditHistoryModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = useSelector((state) => {
    return state.historyModal;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [inputs, setInputs] = useState(defaultInputs);
  const [warnings, setWarnings] = useState({});
  const [forceTriggered, setForceTriggered] = useState(false);
  const [unitsLength, setUnitsLength] = useState('km');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    // Determine length units based on customer length units system
    if (unitsLengthSystem == 'imperial') {
      setUnitsLength('mi');
    } else {
      setUnitsLength('km');
    }
  }, [unitsLengthSystem]);

  useEffect(() => {
    function modalOpen() {
      sendGAEvent('modal_open', 'EditHistory', 'shopview');
    }
    // Set default values when modal opened
    $('#shopview-edit-history-modal').on('show.bs.modal', modalOpen);
    return () => {
      // Remove event listener when component is unmounted
      $('#shopview-edit-history-modal').off('show.bs.modal', modalOpen);
    };
  }, []);

  useEffect(() => {
    currValues = initialValues;
    setDefault();
  }, [initialValues]);

  useEffect(() => {
    // Clear warnings if values change
    setWarnings({});
    setForceTriggered(false);
  }, [inputs.engineHours, inputs.odometer, inputs.date]);

  // Handle changes in form values
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (
      ['laborCost', 'laborTime', 'partsCost', 'engineHours', 'odometer'].includes(name) &&
      value &&
      (!dollarRegex.test(value) || value.includes('-'))
    ) {
      return;
    }

    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  function setDefault() {
    setWarnings({});

    let odometerDisplay = '';
    if (currValues.odometer !== '') {
      odometerDisplay = parseFloat(unitsLengthDisplayConversion(currValues.odometer, unitsLength)).toFixed(0);
    }

    // Set input values to those of the selected task to edit
    setInputs((values) => {
      return {
        ...values,
        engineHours: currValues.engineHours === '' ? '' : Math.round(currValues.engineHours),
        odometer: odometerDisplay,
        date: currValues.date,
        workOrder: currValues.workOrder,
        invoice: currValues.invoice,
        laborCost: currValues.laborCost,
        partsCost: currValues.partsCost,
        laborTime: currValues.laborTime,
        supplier: currValues.supplier,
        reviewedBy: currValues.reviewedBy,
        notes: currValues.notes,
      };
    });
  }

  async function submitModal() {
    /*
    Check the criteria for accepting the modal entry
    Since parentTask has the serviceTask schema,
    we check against it to determine what the serviceTask's required intervals are
    */
    const engineHoursEmpty = inputs.engineHours === '' && initialValues.parentTask.engine_hours_interval;
    const odometerEmpty = inputs.odometer === '' && initialValues.parentTask.odometer_interval;
    // Always require date
    const dateEmpty = inputs.date == '';

    const localTimeZone = DateTime.local().zoneName;
    // Get the current dateTime at the system local timezone midnight
    const currentDate = DateTime.local({zone: localTimeZone}).set({hour: 0, minute: 0, second: 0, millisecond: 0});
    // Get the entryDate dateTime at the system local timezone midnight
    const entryDate = DateTime.fromFormat(inputs.date, 'yyyy-LL-dd', {zone: localTimeZone});

    // Determine if the entered date is in the future
    const dateFuture = entryDate > currentDate;

    // Set any warnings
    setWarnings((values) => {
      return {
        ...values,
        warningEngineHours: engineHoursEmpty,
        warningOdometer: odometerEmpty,
        warningDate: dateEmpty,
        warningDateGreaterFault: dateFuture,
      };
    });

    // If all submit criterias are met POST the data to the server
    if (!engineHoursEmpty && !odometerEmpty && !dateEmpty && !dateFuture && !submitting) {
      setSubmitting(true);
      let engineHours = parseFloat(inputs.engineHours);
      if (isNaN(engineHours)) {
        engineHours = '';
      }
      let odometer = parseFloat(unitsLengthSubmitConversion(inputs.odometer, unitsLength));
      if (isNaN(odometer)) {
        odometer = '';
      }

      // Interpret cost
      let laborCostVal = parseFloat(inputs.laborCost);
      if (isNaN(laborCostVal)) {
        const laborCostString = `${inputs.laborCost}`;
        laborCostVal = parseFloat(laborCostString.replace(/[^0-9.\-]/g, '')) || 0;
      }

      let partsCostVal = parseFloat(inputs.partsCost);
      if (isNaN(partsCostVal)) {
        const partsCostString = `${inputs.partsCost}`;
        partsCostVal = parseFloat(partsCostString.replace(/[^0-9.\-]/g, '')) || 0;
      }

      const postData = {
        vehicle: initialValues.vehicle,
        vehicleSN: initialValues.vehicleSN,
        task: initialValues.task,
        engineHours: engineHours,
        odometer: odometer,
        date: inputs.date,
        workOrder: inputs.workOrder.trim(),
        invoice: inputs.invoice.trim(),
        laborCost: laborCostVal,
        partsCost: partsCostVal,
        laborTime: parseFloat(inputs.laborTime) || '',
        supplier: inputs.supplier.trim(),
        reviewedBy: inputs.reviewedBy.trim(),
        notes: inputs.notes.trim(),
        oldServiceEntry: initialValues.serviceEntry,
        serviceTask: initialValues.parentTask,
      };

      // If warnings showing a submission still happens, use the force method to update
      if (forceTriggered) {
        postData.force = true;
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };

      const updateEntryReq = await fetchPostAuthSafe(
        '/shopview/updateEntry',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const updateEntryRepData = await updateEntryReq.json();
      if (updateEntryRepData.errorMsg) {
        navigate('/error', {state: {errorMsg: updateEntryRepData.errorMsg}});
      }

      if (
        updateEntryRepData.odometerFault ||
        updateEntryRepData.engineHourFault ||
        updateEntryRepData.odometerGreaterThanCurrent ||
        updateEntryRepData.engineHourGreaterThanCurrent ||
        updateEntryRepData.engineHoursExpectedValue ||
        updateEntryRepData.engineHoursMaximumValue
      ) {
        const warningEngineHoursExpectedValue = updateEntryRepData.engineHoursExpectedValue;
        const warningEngineHoursMaximumValue = updateEntryRepData.engineHoursMaximumValue;
        const warningOdometerMoreThanCurrent = updateEntryRepData.odometerGreaterThanCurrent == 1;
        const warningEngineHoursMoreThanCurrent = updateEntryRepData.engineHourGreaterThanCurrent == 1;

        setWarnings((values) => {
          return {
            ...values,
            warningEngineHoursExpectedValue: warningEngineHoursExpectedValue,
            warningEngineHoursMaximumValue: warningEngineHoursMaximumValue,
            warningOdometerMoreThanCurrent: warningOdometerMoreThanCurrent,
            warningEngineHoursMoreThanCurrent: warningEngineHoursMoreThanCurrent,
            warningOdometerGreaterFault: updateEntryRepData.odometerFault == 1,
            warningOdometerLesserFault: updateEntryRepData.odometerFault == -1,
            warningEngineHoursGreaterFault: updateEntryRepData.engineHourFault == 1,
            warningEngineHoursLesserFault: updateEntryRepData.engineHourFault == -1,
          };
        });

        // Set forceTriggered for resubmitting
        if (
          warningOdometerMoreThanCurrent ||
          warningEngineHoursMoreThanCurrent ||
          warningEngineHoursExpectedValue ||
          warningEngineHoursMaximumValue
        ) {
          setForceTriggered(true);
        }
      } else {
        $('#shopview-edit-history-modal').modal('hide');
        setTimeout(function () {
          dispatch(getServiceData());
        }, 500);
      }

      sendGAEvent('modal_submit', 'EditHistory', 'shopview');
      setSubmitting(false);
    }
  }

  async function deleteModal() {
    const postData = {
      oldServiceEntry: initialValues.serviceEntry,
      serviceTask: initialValues.parentTask,
    };

    postData.oldServiceEntry = {
      ...postData.oldServiceEntry,
      vehicle: initialValues.vehicle,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    };

    if (!submitting) {
      setSubmitting(true);
      const deleteEntryReq = await fetchPostAuthSafe(
        '/shopview/deleteEntry',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const deleteEntryRepData = await deleteEntryReq.json();
      if (deleteEntryRepData.errorMsg) {
        navigate('/error', {state: {errorMsg: deleteEntryRepData.errorMsg}});
      }

      $('#shopview-edit-history-modal').modal('hide');
      setTimeout(function () {
        dispatch(getServiceData());
      }, 500);

      sendGAEvent('modal_submit', 'EditHistory', 'shopview');
      setSubmitting(false);
    }
  }

  return createPortal(
    <ModalFramework id='shopview-edit-history-modal'>
      <ModalHeader title='Edit Service Entry'>
        <p className='mb-n2 h5 small'>
          <strong>Updated By: </strong>
          {initialValues.user.includes('_icadmin') ? 'IntelliCulture Admin' : initialValues.user}
        </p>
      </ModalHeader>
      <ModalBody>
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <p>Vehicle</p>
            <p className='font-weight-bold'>{initialValues.vehicle}</p>
          </ModalColumnSection>
          <ModalColumnSection>
            <p>Service</p>
            <p className='font-weight-bold'>{initialValues.task}</p>
          </ModalColumnSection>
          {initialValues.parentTask?.notes && <ModalNote text={initialValues.parentTask.notes} />}
        </ModalRowSection>
        {warnings.warningEngineHours && <ModalWarning text='Enter Engine Hours' />}
        {warnings.warningOdometer && <ModalWarning text='Enter Odometer Value' />}
        {warnings.warningDate && <ModalWarning text='Enter Date Completed' />}
        {warnings.warningDateGreaterFault && <ModalWarning text='Entry of future dates is not enabled' />}
        {warnings.warningOdometerGreaterFault && (
          <ModalWarning text='The input odometer value is greater than a following entry' />
        )}
        {warnings.warningOdometerLesserFault && (
          <ModalWarning text='The input odometer value is less than a previous entry' />
        )}
        {warnings.warningEngineHoursGreaterFault && (
          <ModalWarning text='The input engine hours value is greater than a following entry' />
        )}
        {warnings.warningEngineHoursLesserFault && (
          <ModalWarning text='The input engine hours value is less than a previous entry' />
        )}
        {warnings.warningOdometerMoreThanCurrent && (
          <ModalWarning
            text={`The input odometer value is more than the measured on the vehicle. 
              Please click submit again to confirm.`}
          />
        )}
        {warnings.warningEngineHoursMoreThanCurrent && (
          <ModalWarning
            text={`The input engine hours value is more than the measured on the vehicle. 
              Please click submit again to confirm.`}
          />
        )}
        {!!warnings.warningEngineHoursExpectedValue && (
          <ModalWarning
            text={`Your input (${inputs.engineHours} hrs) is much different than IntelliCulture's reading
              (${Math.floor(warnings.warningEngineHoursExpectedValue)} hrs). 
              Clicking submit will override IntelliCulture's hours.`}
          />
        )}
        {!!warnings.warningEngineHoursMaximumValue && (
          <ModalWarning
            text={`Your input (${inputs.engineHours} hrs) is very large. Please verify your input. 
              Clicking submit will override IntelliCulture's hours.`}
          />
        )}
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <ModalInputSection label='Engine Hours'>
              <input
                type='text'
                name='engineHours'
                className='form-control flex-fill'
                value={inputs.engineHours}
                step={0.01}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
            <ModalInputSection label='Date Completed'>
              <input
                type='date'
                name='date'
                className='form-control flex-fill'
                value={inputs.date}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label={`Odometer (${unitsLength})`}>
              <input
                type='text'
                name='odometer'
                className='form-control flex-fill'
                value={inputs.odometer}
                step={0.01}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <ModalInputSection label='Work Order'>
              <input
                type='text'
                name='workOrder'
                className='form-control flex-fill'
                value={inputs.workOrder}
                onChange={handleChange}
              />
            </ModalInputSection>
            <ModalInputSection label='Labor Cost ($)'>
              <input
                type='text'
                name='laborCost'
                className='form-control flex-fill'
                value={inputs.laborCost}
                onChange={handleChange}
              />
            </ModalInputSection>
            <ModalInputSection label='Labor Time (Hrs)'>
              <input
                type='text'
                name='laborTime'
                className='form-control flex-fill'
                value={inputs.laborTime}
                step={0.01}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Invoice Number'>
              <input
                type='text'
                name='invoice'
                className='form-control flex-fill'
                value={inputs.invoice}
                onChange={handleChange}
              />
            </ModalInputSection>
            <ModalInputSection label='Parts Cost ($)'>
              <input
                type='text'
                name='partsCost'
                className='form-control flex-fill'
                value={inputs.partsCost}
                onChange={handleChange}
              />
            </ModalInputSection>
            <ModalInputSection label='Supplier Name'>
              <input
                type='text'
                name='supplier'
                className='form-control flex-fill'
                value={inputs.supplier}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalWideColumnSection>
            <ModalInputSection label='Reviewed By'>
              <input
                type='text'
                name='reviewedBy'
                className='form-control flex-fill'
                value={inputs.reviewedBy}
                onChange={handleChange}
              />
            </ModalInputSection>
            <ModalInputSection label='Notes'>
              <input
                type='textarea'
                name='notes'
                className='form-control flex-fill'
                value={inputs.notes}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter onDelete={deleteModal} onSubmit={submitModal} />
    </ModalFramework>,
    document.getElementById('app')
  );
}

export {EditHistoryModal};
