import {createSlice} from '@reduxjs/toolkit';

const cropviewSlice = createSlice({
  name: 'cropview',
  initialState: {
    loading: true,
    dates: {start: null, end: null, max: null},
    zonesDicts: {},
    geofencesDict: {},
    vehicleSNDict: {},
    implementSNDict: {},
    taskConfigIdDict: {},
    zoneAnalytics: [],
    equipmentAnalytics: [],
    displayedTable: 'block',
    displayedColumn: 'coverage',
    sortMethod: 'name',
    mapView: 1,
    zoneZoom: '',
    todayOnly: false,
    editCropview: false,
    editCropviewValues: [],
    editCropviewRecValues: [],
    vehicleZoom: '',
    lastTripTaskEndTime: '',
    processingInProgress: false,
    reiActiveDict: {},
    recList: [],
    refreshRequired: false,
    taskAcreageCapping: false,
    taskAcreageCapTracker: {},
    taskAcreageCapTrackerTask: '',
    sprayCompleteDict: [],
    gpsLoaded: false,
    pathsLoading: true,
    pathQueryStatus: {
      success: true,
      pathDataTooLarge: false,
    },
    inZonePathData: [],
    outOfZonePathData: [],
    vehicleLatestLocationDict: {},
    vehicleSerialNumbersInDisplayedAnalytics: [],
    pathsVehicleDays: 0,
    numDaysInReq: 0,
  },
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
    updatePathsVehicleDays(state, action) {
      state.pathsVehicleDays = action.payload;
    },
    updateNumDaysInReq(state, action) {
      state.numDaysInReq = action.payload;
    },
    updatePathsLoading(state, action) {
      state.pathsLoading = action.payload;
    },
    updateVehicleSerialNumbersInDisplayedAnalytics(state, action) {
      state.vehicleSerialNumbersInDisplayedAnalytics = action.payload;
    },
    updateCoverageData(state, action) {
      state.vehicleSNDict = action.payload.vehicleSNDict;
      state.implementSNDict = action.payload.implementSNDict;
      state.zonesDicts = action.payload.zonesDicts;
      state.geofencesDict = action.payload.geofencesDict;
      state.taskConfigIdDict = action.payload.taskConfigIdDict;
      state.zoneAnalytics = action.payload.zoneAnalytics;
      state.equipmentAnalytics = action.payload.equipmentAnalytics;
      state.recList = action.payload.recList;
    },
    updateClearData(state, action) {
      state.zoneAnalytics = [];
      state.equipmentAnalytics = [];
    },
    updateDisplayedTable(state, action) {
      state.displayedTable = action.payload;
    },
    updateDisplayedColumn(state, action) {
      state.displayedColumn = action.payload;
    },
    updateSortMethod(state, action) {
      state.sortMethod = action.payload;
    },
    updateMapView(state, action) {
      // 0: Only table, 1: Table and map, 2: Only map
      const mapSetting = action.payload;
      if (mapSetting == undefined) {
        state.mapView = (state.mapView + 1) % 3;
      } else {
        state.mapView = mapSetting;
      }
    },
    updateZoneZoom(state, action) {
      state.zoneZoom = action.payload;
    },
    updateVehicleZoom(state, action) {
      state.vehicleZoom = action.payload;
    },
    updateTodayOnly(state, action) {
      state.todayOnly = action.payload;
    },
    updateEditCropview(state, action) {
      const editCropviewBool = action.payload;

      state.editCropview = editCropviewBool;
      if (!editCropviewBool) {
        state.editCropviewValues = [];
        state.editCropviewRecValues = [];
      }
    },
    updateEditCropviewValues(state, action) {
      state.editCropviewValues.push(action.payload);
    },
    updateEditCropviewRecValues(state, action) {
      state.editCropviewRecValues.push(action.payload);
    },
    updateDates(state, action) {
      state.dates = action.payload;
    },
    updateLastTripTaskEndTime(state, action) {
      state.lastTripTaskEndTime = action.payload;
    },
    updateProcessingInProgress(state, action) {
      state.processingInProgress = action.payload;
    },
    updateReiActive(state, action) {
      const reiList = action.payload;
      const reiActiveDict = {};

      reiList.map((rei) => {
        reiActiveDict[rei.zoneId] = rei;
      });

      state.reiActiveDict = reiActiveDict;
    },
    updateRefreshRequired(state, action) {
      state.refreshRequired = action.payload;
    },
    updateTaskAcreageCapping(state, action) {
      state.taskAcreageCapping = action.payload;
    },
    updateTaskAcreageCapTracker(state, action) {
      state.taskAcreageCapTracker = action.payload;
    },
    updateTaskAcreageCapTrackerTask(state, action) {
      state.taskAcreageCapTrackerTask = action.payload;
    },
    updateSpraysComplete(state, action) {
      state.sprayCompleteDict = action.payload;
    },
    updateGpsLoaded(state, action) {
      state.gpsLoaded = action.payload;
    },
    updatePathQueryStatus(state, action) {
      state.pathQueryStatus = action.payload;
    },
    updateInZonePathData(state, action) {
      state.inZonePathData = action.payload;
    },
    updateVehicleLatestLocationDict(state, action) {
      state.vehicleLatestLocationDict = action.payload;
    },
    updateOutOfZonePathData(state, action) {
      state.outOfZonePathData = action.payload;
    },
  },
});

export const {
  updateLoading,
  updateDates,
  updateCoverageData,
  updateClearData,
  updateDisplayedTable,
  updateDisplayedColumn,
  updateSortMethod,
  updateMapView,
  updateZoneZoom,
  updateVehicleZoom,
  updateTodayOnly,
  updateEditCropview,
  updateEditCropviewValues,
  updateEditCropviewRecValues,
  updateLastTripTaskEndTime,
  updateProcessingInProgress,
  updateReiActive,
  updateRefreshRequired,
  updateTaskAcreageCapping,
  updateTaskAcreageCapTracker,
  updateTaskAcreageCapTrackerTask,
  updateSpraysComplete,
  updateGpsLoaded,
  updatePathsLoading,
  updatePathQueryStatus,
  updateInZonePathData,
  updateVehicleLatestLocationDict,
  updateOutOfZonePathData,
  updateVehicleSerialNumbersInDisplayedAnalytics,
  updatePathsVehicleDays,
  updateNumDaysInReq,
} = cropviewSlice.actions;
export default cropviewSlice.reducer;
