import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {sendGAEvent} from '../../app/utils';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  sortVehicleNamesHelper,
  machineTypeMapping,
  unitsLengthDisplayConversion,
  unitsAreaConversion,
} from '../../app/utils';
import {Grid, Button} from '@mui/material';

function ExportButton(props) {
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const taskConfigs = useSelector((state) => {
    return state.dashboard.taskConfigs;
  });
  const datesCache = useSelector((state) => {
    return state.dashboard.dates;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const inspectionsData = useSelector((state) => {
    return state.dashboard.inspectionsData;
  });
  const servicesData = useSelector((state) => {
    return state.dashboard.servicesData;
  });
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const inspectionsDict = useSelector((state) => {
    return state.dashboard.inspectionsDict;
  });
  const kpiLandingPageOn = useSelector((state) => {
    return state.dashboard.kpiLandingPageOn;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });
  const selectedKPI = useSelector((state) => {
    return state.dashboard.selectedKPI;
  });

  const [exportToggles, setExportToggles] = useState({
    vehicles: false,
    regions: false,
    fields: false,
    blocks: false,
    tasks: false,
    inspections: false,
    services: false,
  });
  const [distanceUnits, setDistanceUnits] = useState('km');
  const [speedUnits, setSpeedUnits] = useState('kph');

  useEffect(() => {
    let distUnits = 'km';
    if (unitsLengthSystem == 'imperial') {
      distUnits = 'mi';
    }
    setDistanceUnits(distUnits);

    let spdUnits = 'kph';
    if (unitsLengthSystem == 'imperial') {
      spdUnits = 'mph';
    }
    setSpeedUnits(spdUnits);
  }, [unitsLengthSystem]);

  // We have to set dates using the start time of first active task to NOW
  const [dates, setDates] = useState({
    start: null,
    end: null,
  });

  useEffect(() => {
    setDates({
      start: DateTime.fromISO(datesCache.start).setZone(customerSettings.general.timeZone, {}),
      end: DateTime.fromISO(datesCache.end).setZone(customerSettings.general.timeZone, {}),
    });
  }, []);

  useEffect(async () => {
    setDates({
      start: DateTime.fromISO(datesCache.start).setZone(customerSettings.general.timeZone, {}),
      end: DateTime.fromISO(datesCache.end).setZone(customerSettings.general.timeZone, {}),
    });
  }, [datesCache]);

  function sortHeadersWithSubstringFirst(headers) {
    headers.sort((a, b) => {
      if (a.includes('Name') && !b.includes('Name')) {
        return -1;
      } else if (!a.includes('Name') && b.includes('Name')) {
        return 1;
      }
      return 0;
    });
    return headers;
  }

  function downloadCsv(data, title, tasks = false) {
    const csvList = [];
    let headerKeys;
    let headerText;
    let headerDict = {};
    
    let unitsArea = 'ac';
    if (unitsAreaSystem == 'hectares') {
      unitsArea = 'ha';
    }

    if (tasks) {
      const acPerHrLabel = unitsAreaSystem == 'acre' ? 'ac/hr' : 'ha/hr';
      const acreageLabel = unitsAreaSystem == 'acre' ? 'Acreage' : 'Hectares';
      headerDict = {
        'taskName': 'Task Name',
        'zoneName': 'Zone Name',
        'zoneLevel': 'Zone Level',
        'acPerHr': acPerHrLabel,
        'acreage': acreageLabel,
        'avgSpeed': `Ave Speed (${speedUnits})`,
        'distance': `Distance (${distanceUnits})`,
        'duration': 'Operation Duration (hr)',
      };

      const zoneLevels = ['Block', 'Field', 'Region'];

      Object.keys(data).forEach((key) => {
        const taskObj = data[key];
        zoneLevels.forEach((zoneLevel) => {
          const zoneObj = taskObj[zoneLevel];
          Object.keys(zoneObj).forEach((zoneName) => {
            const row = [];
            const innerMostObj = zoneObj[zoneName];
            Object.keys(headerDict).forEach((headerKey) => {
              if (headerKey == 'taskName') {
                if (taskConfigs.hasOwnProperty(key) && taskConfigs[key].hasOwnProperty('name')) {
                  row.push(`\"${taskConfigs[key]['name']}\"`);
                } else {
                  row.push(`\"No Task Found\"`);
                }
              } else if (headerKey == 'zoneName') {
                row.push(`\"${zoneName}\"`);
              } else if (headerKey == 'zoneLevel') {
                row.push(`\"${zoneLevel}\"`);
              } else if (['distance', 'avgSpeed'].includes(headerKey)) {
                const distanceUnitConverted =
                  unitsLengthDisplayConversion(innerMostObj[headerKey] * 100, distanceUnits) / 100;
                row.push(`\"${distanceUnitConverted}\"`);
              } else if (headerKey == 'acPerHr') {
                const acPerHrConverted = unitsAreaConversion(innerMostObj[headerKey], unitsArea, 'ac');
                row.push(`\"${acPerHrConverted}\"`);
              } else if (headerKey == 'acreage') {
                const acreageConverted = unitsAreaConversion(innerMostObj[headerKey], unitsArea, 'ac');
                row.push(`\"${acreageConverted}\"`);
              } else {
                row.push(`\"${innerMostObj[headerKey]}\"`);
              }
            });
            csvList.push(row);
          });
        });
      });
    } else if (title == 'Vehicles') {
      const acPerHrLabel = unitsAreaSystem == 'acre' ? 'ac/hr' : 'ha/hr';
      const acreageLabel = unitsAreaSystem == 'acre' ? 'Acreage' : 'Hectares';
      headerDict = {
        'vehicleName': 'Vehicle Name',
        'type': 'Type',
        'inFieldTime': 'In Field Time (hr)',
        'outOfFieldTime': 'Out Of Field Time (hr)',
        'drivingDuration': 'Driving Duration (hr)',
        'idleTimeInHours': 'Idle Time In Hours (hr)',
        'totalDuration': 'Total Duration (hr)',
        'acPerHr': acPerHrLabel,
        'acreage': acreageLabel,
        'avgSpeed': `Ave Speed (${speedUnits})`,
        'distance': `Distance (${distanceUnits})`,
        'enteredTaskAcreage': `${acreageLabel} - Task Entered`,
        'missingTaskAcreage': `${acreageLabel} - Task Missing`,
        'enteredTaskHours': 'In Field Hours - Task Entered',
        'missingTaskHours': 'In Field Hours - Task Missing',
        'idlePercent': 'Idle Percent',
      };

      const items = Object.keys(data).map(function (key) {
        return data[key];
      });

      items.sort((vehicleA, vehicleB) => {
        const aName = vehicleA['vehicleName'];
        const bName = vehicleB['vehicleName'];

        return sortVehicleNamesHelper(aName, bName);
      });

      items.forEach((item) => {
        const row = [];
        const obj = item;
        Object.keys(headerDict).forEach((headerKey) => {
          let cellContent = obj[headerKey];
          if (headerKey == 'type') {
            cellContent = machineTypeMapping[obj[headerKey]];
          } else if (headerKey == 'acPerHr') {
            cellContent = unitsAreaConversion(obj[headerKey], unitsArea, 'ac');
          } else if (headerKey == 'acreage') {
            cellContent = unitsAreaConversion(obj[headerKey], unitsArea, 'ac');
          } else if (headerKey == 'enteredTaskAcreage') {
            cellContent = unitsAreaConversion(obj[headerKey], unitsArea, 'ac');
          } else if (headerKey == 'missingTaskAcreage') {
            cellContent = unitsAreaConversion(obj[headerKey], unitsArea, 'ac');
          }

          if (['distance', 'avgSpeed'].includes(headerKey)) {
            const distanceUnitConverted = unitsLengthDisplayConversion(obj[headerKey] * 100, distanceUnits) / 100;
            cellContent = distanceUnitConverted;
          }
          row.push(`\"${cellContent}\"`);
        });
        csvList.push(row);
      });
    } else if (title == 'Inspections') {
      headerDict = {
        'vehicle': 'Vehicle Name',
        'pass': 'Pass',
        'fail': 'Fail',
        'failedItems': 'Failed Items',
      };

      Object.keys(data).forEach((key) => {
        const row = [];
        const obj = data[key];
        Object.keys(headerDict).forEach((headerKey) => {
          row.push(`\"${obj[headerKey]}\"`);
        });
        csvList.push(row);
      });
    } else if (title == 'Services') {
      headerDict = {
        'vehicleName': 'Vehicle Name',
        'onTimeServices': 'On-time Services',
        'lateServices': 'Late Services',
      };

      Object.keys(data).forEach((key) => {
        const row = [];
        const obj = data[key];
        Object.keys(headerDict).forEach((headerKey) => {
          row.push(`\"${obj[headerKey]}\"`);
        });
        csvList.push(row);
      });
    } else {
      headerKeys =
        Object.keys(data).length > 0 ? sortHeadersWithSubstringFirst(Object.keys(data[Object.keys(data)[0]])) : [];
      headerText = headerKeys;

      Object.keys(data).forEach((key) => {
        const row = [];
        const obj = data[key];
        headerKeys.forEach((headerKey) => {
          row.push(`\"${obj[headerKey]}\"`);
        });
        csvList.push(row);
      });
    }

    // Export the header text
    let csv = '';
    const exportHeaderKeys = Object.keys(headerDict);
    for (let i = 0; i < exportHeaderKeys.length; i++) {
      if (i + 1 == exportHeaderKeys.length) {
        csv += headerDict[exportHeaderKeys[i]];
        csv += '\n';
      } else {
        csv += headerDict[exportHeaderKeys[i]] + ',';
      }
    }

    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';

    const start = dates.start;
    const end = dates.end;

    const fileName = `${title}_${DateTime.fromISO(start)
      .setZone(customerSettings.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}_${DateTime.fromISO(end)
      .setZone(customerSettings.timeZone)
      .toFormat('yyyy-MM-dd_HH-mm')}`;
    hiddenElement.download = `${fileName}.csv`;
    hiddenElement.click();

    sendGAEvent('csv_download', 'kpi', 'dashboard');
  }

  function exportData() {
    Object.keys(exportToggles).forEach((key) => {
      if (exportToggles[key]) {
        if (key == 'vehicles') {
          downloadCsv(operationsData.vehiclesObject, 'Vehicles', false);
        }
        if (key == 'tasks') {
          downloadCsv(operationsData.tasksObject, 'Tasks', true);
        }
        if (key == 'inspections') {
          // Need to parse inspections data to be readable
          const inspectionsCsvData = [];

          Object.keys(inspectionsData.vehicleResults).forEach((veh) => {
            let failedItemsString = '';
            inspectionsData.vehicleResults[veh].failedItems.forEach((item) => {
              failedItemsString += inspectionsDict[item.id].name + ',';
            });
            const inspectionsCsvObject = {
              vehicle: veh,
              pass: inspectionsData.vehicleResults[veh].pass,
              fail: inspectionsData.vehicleResults[veh].fail,
              failedItems: failedItemsString.slice(0, -1),
            };
            inspectionsCsvData.push(inspectionsCsvObject);
          });
          downloadCsv(inspectionsCsvData, 'Inspections', false);
        }
        if (key == 'services') {
          downloadCsv(servicesData.vehicleServices, 'Services', false);
        }

        // Not active / available
        if (key == 'regions') {
          downloadCsv(operationsData.regionsObject, 'Regions', false);
        }
        if (key == 'fields') {
          downloadCsv(operationsData.fieldsObject, 'Fields', false);
        }
        if (key == 'blocks') {
          downloadCsv(operationsData.blocksObject, 'Blocks', false);
        }
      }
    });
  }

  return (
    <React.Fragment>
      <button
        className='btn border-dark btn-light col-12 cropview-menu-text'
        data-toggle='dropdown'
        disabled={loading || kpiLandingPageOn}
      >
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25px'}}>
          <FontAwesomeIcon
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '18px'}}
            icon='fas fa-download'
          />
        </div>
      </button>
      <div className='dropdown-menu border-dark' style={{minWidth: '0'}}>
        <div className='dropdown-header cropview-menu-text px-3'>Export</div>
        <button
          className='dropdown-item cropview-menu-text px-3'
          onClick={(e) => {
            e.stopPropagation();
            setExportToggles({...exportToggles, vehicles: !exportToggles.vehicles});
          }}
        >
          Vehicles {exportToggles.vehicles && <FontAwesomeIcon icon='fas fa-check' />}
        </button>
        <button
          className='dropdown-item cropview-menu-text px-3'
          onClick={(e) => {
            e.stopPropagation();
            setExportToggles({...exportToggles, tasks: !exportToggles.tasks});
          }}
        >
          Tasks {exportToggles.tasks && <FontAwesomeIcon icon='fas fa-check' />}
        </button>
        <button
          className='dropdown-item cropview-menu-text px-3'
          onClick={(e) => {
            e.stopPropagation();
            setExportToggles({...exportToggles, inspections: !exportToggles.inspections});
          }}
        >
          Inspections {exportToggles.inspections && <FontAwesomeIcon icon='fas fa-check' />}
        </button>
        <button
          className='dropdown-item cropview-menu-text px-3'
          onClick={(e) => {
            e.stopPropagation();
            setExportToggles({...exportToggles, services: !exportToggles.services});
          }}
        >
          Services {exportToggles.services && <FontAwesomeIcon icon='fas fa-check' />}
        </button>
        <div style={{display: 'flex', alignItems: 'center'}}></div>
        <div className='dropdown-item cropview-menu-text px-3'>
          <Button
            variant='ic-button'
            onClick={() => {
              exportData();
            }}
            color='success'
          >
            Export
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
}

export {ExportButton};
