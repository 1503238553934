import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  machineTypeMapping,
  fetchPostAuthSafe,
  emailRegex,
  sortVehicleNamesHelper,
  handleAllowOnlyNumbers,
  KM_TO_MI,
} from '../../../app/utils';

import {MuiChipsInput} from 'mui-chips-input';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {Divider, Box, Modal, Switch, TextField, Select, MenuItem, Button} from '@mui/material';

import {
  ModalSubmitting,
  ViewOnlyText,
  CollapsableSection,
  GeneralSearchSelect,
  InlineTooltip,
} from '../../../components/GeneralComponents';

function RuleEditModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vehicles = useSelector((state) => {
    return state.alertview.vehiclesUnfiltered;
  });
  const taskConfigDict = useSelector((state) => {
    return state.alertview.taskConfigDict;
  });
  const zonesDicts = useSelector((state) => {
    return state.alertview.zonesDicts;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });

  const viewOnly = !userSettings.roleAccess['alertviewManagement'];

  const defaultRuleConfig = {
    name: '',
    disabled: false,
    notifications: false,
    emails: [],
    type: 'farmSpeeding',
    logic: 'greaterThan',
    value: '',
    valueType: 'inputValue',
    taskIds: [],
    vehicleSns: [],
    vehicleTypes: [],
    inZoneState: 'inZone',
    blockIds: [],
    fieldIds: [],
    regionIds: [],
    ruleDuration: 10,
  };

  const [ruleConfig, setRuleConfig] = useState(defaultRuleConfig);
  const [submitStatus, setSubmitStatus] = useState('pending');
  const [modalError, setModalError] = useState('');
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  useEffect(() => {
    // Reset State of Edit Modal
    setSubmitStatus('pending');
    setShowAdvancedSettings(false);
    setModalError('');
    if (Object.keys(props.ruleConfig).length > 0) {
      setRuleConfig({
        ...defaultRuleConfig,
        ...props.ruleConfig,
        ...props.ruleConfig.conditions,
        ...props.ruleConfig.filters,
      });
    } else {
      setRuleConfig({...defaultRuleConfig});
    }
  }, [props.ruleConfig]);

  // Element styling
  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smallScreen ? '100%' : 1000,
    maxWidth: '90vw',
    maxHeight: smallScreen ? '100%' : '90vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY: 'auto',
    p: 2,
  };

  // Modal actions
  function modalClose() {
    setSubmitStatus('pending');
    props.handleResetRuleConfig();
    setModalError('');
    return props.handleModalOpen(false);
  }

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    setRuleConfig((values) => {
      return {...values, [name]: value};
    });

    // Handle changing default conditions based on rule type
    if (name == 'type') {
      const defaultValues = {
        logic: 'greaterThan',
        value: '',
        valueType: 'inputValue',
      };

      if (value == 'farmSpeeding') {
        defaultValues.inZoneState = 'inZone';
        defaultValues.ruleDuration = 10;
      } else if (value == 'roadSpeeding') {
        defaultValues.inZoneState = 'outOfZone';
        defaultValues.blockIds = [];
        defaultValues.fieldIds = [];
        defaultValues.regionIds = [];
        defaultValues.ruleDuration = 10;
      } else if (value == 'idling') {
        defaultValues.inZoneState = 'none';
        defaultValues.ruleDuration = '';
      } else if (value == 'afterHours') {
        defaultValues.logic = 'true';
        defaultValues.value = {startHour: 0, startMinute: 0, endHour: 0, endMinute: 0};
        defaultValues.inZoneState = 'none';
        defaultValues.ruleDuration = 200;
      }

      setRuleConfig((values) => {
        return {...values, ...defaultValues};
      });
    } else if (name == 'valueType') {
      let defaultValue = '';

      if (value == 'taskTargetSpeed') {
        defaultValue = 'taskTargetSpeed';
      }

      setRuleConfig((values) => {
        return {...values, value: defaultValue};
      });
    }
  }

  function handleValueInputChange(event) {
    const name = event.target.name;

    let newValue = '';
    if (!isNaN(parseInt(event.target.value))) {
      newValue = parseInt(event.target.value);
    }

    setRuleConfig((values) => {
      return {...values, [name]: newValue};
    });

    // Change rule duration if changing idling time
    if (ruleConfig.type == 'idling') {
      setRuleConfig((values) => {
        return {...values, ruleDuration: newValue * 60};
      });
    }
  }

  async function handleSubmit() {
    let ruleValue = ruleConfig.value;
    if (
      ['farmSpeeding', 'roadSpeeding'].includes(ruleConfig.type) &&
      typeof ruleValue != 'string' &&
      unitsLengthSystem == 'imperial'
    ) {
      ruleValue = ruleValue / KM_TO_MI;
    }

    const ruleConfigFormatted = {
      name: ruleConfig.name.trim(),
      disabled: ruleConfig.disabled,
      type: ruleConfig.type,
      notifications: ruleConfig.notifications,
      emails: ruleConfig.emails,
      conditions: {
        logic: ruleConfig.logic,
        value: ruleValue,
        valueType: ruleConfig.valueType,
        ruleDuration: ruleConfig.ruleDuration,
      },
      filters: {
        taskIds: ruleConfig.taskIds,
        vehicleSns: ruleConfig.vehicleSns,
        vehicleTypes: ruleConfig.vehicleTypes,
        inZoneState: ruleConfig.inZoneState,
        blockIds: ruleConfig.blockIds,
        fieldIds: ruleConfig.fieldIds,
        regionIds: ruleConfig.regionIds,
      },
    };

    // Check if there are any errors
    let errorString = '';
    // Check if rule name is blank
    if (ruleConfigFormatted.name === '') {
      errorString += 'Rule name cannot be empty.';
    } else {
      // Check if rule name already exists
      const existingRuleNames = Object.values(props.rulesDict)
        .filter((rule) => {
          return rule.id != ruleConfig.id;
        })
        .map((rule) => {
          return rule.name;
        });
      if (existingRuleNames.includes(ruleConfigFormatted.name)) {
        errorString += 'Rule name already exists.';
      }
    }

    // Check if rule value is blank
    if (ruleConfigFormatted.conditions.value === '') {
      errorString += ' Rule value cannot be empty.';
    }

    // Check if rule duration is blank
    if (ruleConfigFormatted.conditions.ruleDuration === '') {
      errorString += ' Rule duration cannot be empty.';
    }

    if (errorString) {
      setModalError(errorString);
      return;
    }

    let updateDoc = {};
    let shouldSubmit = true;
    if (typeof ruleConfig.id != 'undefined') {
      Object.keys(props.rulesDict[ruleConfig.id]).forEach((key) => {
        if (key != 'id' && !_.isEqual(props.rulesDict[ruleConfig.id][key], ruleConfigFormatted[key])) {
          updateDoc[key] = ruleConfigFormatted[key];
        }
      });
      if (Object.keys(updateDoc).length == 0) {
        shouldSubmit = false;
      } else {
        updateDoc.id = ruleConfig.id;
        updateDoc.disabled = ruleConfig.disabled;
      }
    } else {
      updateDoc = ruleConfigFormatted;
    }

    if (shouldSubmit) {
      setSubmitStatus('submitting');
      await postRuleUpdates(updateDoc);
      modalClose();
      props.getRules();
      setSubmitStatus('success');
    } else {
      modalClose();
    }
  }

  async function postRuleUpdates(updateDoc) {
    const postData = updateDoc;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(postData),
    };
    const url = '/settings/updateRule';

    const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    }

    return result;
  }

  // Generate modal options
  function renderTaskOptions() {
    const options = [];

    Object.keys(taskConfigDict).forEach((taskId) => {
      if (taskConfigDict[taskId]?.archived != true) {
        options.push({
          value: taskId,
          label: taskConfigDict[taskId].name,
        });
      }
    });

    options.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    return options;
  }

  function renderVehicleOptions() {
    const vehicleOptions = [];

    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].icActive) {
        const vehicleSN = vehicles[i].serialNumber.toLowerCase();

        const vehicleDisabled = userSettings.general.excludedVehicles.includes(vehicleSN) || vehicles[i].archived;
        if (ruleConfig.vehicleSns.includes(vehicleSN) || !vehicleDisabled) {
          vehicleOptions.push({
            label: vehicles[i].name + (vehicles[i].archived ? ' (Archived)' : ''),
            value: vehicleSN,
          });
        }
      }
    }
    vehicleOptions.sort((a, b) => {
      return sortVehicleNamesHelper(a.label, b.label);
    });

    return vehicleOptions;
  }

  function generateConditionsOptions() {
    const ruleTypeSelector = (
      <Select
        disabled={viewOnly || ruleConfig.id != undefined}
        size='small'
        name='type'
        value={ruleConfig.type}
        onChange={handleChange}
      >
        <MenuItem value='farmSpeeding'>farm speed</MenuItem>
        {/* <MenuItem value='roadSpeeding'>road speed</MenuItem> */}
        <MenuItem value='idling'>idling duration</MenuItem>
        <MenuItem value='afterHours'>after hours work</MenuItem>
      </Select>
    );

    let logicText = '';
    if (ruleConfig.type == 'farmSpeeding') {
      logicText = (
        <Select disabled={viewOnly} size='small' name='logic' value={ruleConfig.logic} onChange={handleChange}>
          <MenuItem value='greaterThan'>greater than</MenuItem>
          <MenuItem value='lessThan'>less than</MenuItem>
          <MenuItem value='notEqualTo'>not equal to</MenuItem>
        </Select>
      );
    } else if (ruleConfig.type == 'roadSpeeding') {
      logicText = 'greater than speed limit by';
    } else if (ruleConfig.type == 'idling') {
      logicText = 'greater than';
    } else if (ruleConfig.type == 'afterHours') {
      logicText = 'performed outside of the hours';
    }

    // Value inputs
    let valueText = '';
    if (['roadSpeeding', 'idling'].includes(ruleConfig.type)) {
      valueText = (
        <React.Fragment>
          <TextField
            disabled={viewOnly}
            size='small'
            value={ruleConfig.value}
            name='value'
            onChange={handleValueInputChange}
            onKeyDown={handleAllowOnlyNumbers}
          />
          <span> {ruleConfig.type == 'idling' ? 'minutes' : unitsLengthSystem == 'imperial' ? 'MPH' : 'KPH'}</span>
        </React.Fragment>
      );
    } else if (ruleConfig.type == 'farmSpeeding') {
      valueText = (
        <React.Fragment>
          <div>
            <Select
              disabled={viewOnly}
              size='small'
              name='valueType'
              value={ruleConfig.valueType}
              onChange={handleChange}
            >
              <MenuItem value='inputValue'>input value</MenuItem>
              <MenuItem value='taskTargetSpeed'>task target speed</MenuItem>
            </Select>
          </div>
          {ruleConfig.valueType == 'inputValue' && (
            <div className='mt-2 py-auto'>
              <TextField
                disabled={viewOnly}
                size='small'
                value={ruleConfig.value}
                name='value'
                onChange={handleValueInputChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
              <span> {unitsLengthSystem == 'imperial' ? 'MPH' : 'KPH'}</span>
            </div>
          )}
        </React.Fragment>
      );
    } else if (ruleConfig.type == 'afterHours') {
      valueText = (
        <React.Fragment>
          <div>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimePicker
                disabled={viewOnly}
                slotProps={{textField: {size: 'small'}}}
                value={DateTime.now().set({
                  hour: ruleConfig.value.startHour,
                  minute: ruleConfig.value.startMinute,
                })}
                onChange={(event) => {
                  setRuleConfig((values) => {
                    return {...values, value: {...values.value, startHour: event.hour, startMinute: event.minute}};
                  });
                }}
              />
            </LocalizationProvider>
            <span> and</span>
          </div>
          <div className='mt-2 py-auto'>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimePicker
                disabled={viewOnly}
                slotProps={{textField: {size: 'small'}}}
                value={DateTime.now().set({
                  hour: ruleConfig.value.endHour,
                  minute: ruleConfig.value.endMinute,
                })}
                onChange={(event) => {
                  setRuleConfig((values) => {
                    return {...values, value: {...values.value, endHour: event.hour, endMinute: event.minute}};
                  });
                }}
              />
            </LocalizationProvider>
          </div>
        </React.Fragment>
      );
    }

    const conditionDialogue = (
      <React.Fragment>
        <span className='font-weight-bold'>
          <div>
            <span>If </span>
            {ruleTypeSelector}
          </div>

          <div className='mt-2'>
            <span>is </span>
            {logicText}
          </div>

          <div className='mt-2 py-auto'>{valueText}</div>
        </span>
      </React.Fragment>
    );

    return conditionDialogue;
  }

  if (submitStatus == 'submitting') {
    return (
      <Modal
        open={props.modalOpen}
        onClose={modalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={boxStyle}>
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            <ModalSubmitting />
          </div>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={props.modalOpen}
      onClose={modalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={boxStyle}>
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
          <h3>
            <strong>Rule Configuration</strong>
          </h3>
        </div>
        {viewOnly && <ViewOnlyText />}
        <div className='row mx-0 px-1 pb-3'>
          <div className='row col-md-6 m-0 p-0'>
            <div className='col-sm-12 px-1'>
              <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                <label className='mb-0 mt-2'>
                  <strong>Rule Name</strong>
                </label>
                <div className='mt-1'>
                  <TextField
                    disabled={viewOnly}
                    size='small'
                    fullWidth
                    value={ruleConfig.name}
                    name='name'
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* <div className='col-sm-12 px-1'>
              <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                <label className='mb-0 mt-2'>
                  <strong>Notifications</strong>
                </label>
                <div className='mt-1'>
                  <Switch
                    disabled={viewOnly}
                    size='small'
                    name='notifications'
                    checked={ruleConfig.notifications}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div> */}
            {/* <div className='col-sm-12 px-1'>
              <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                <label className='mb-0 mt-2'>
                  <strong>Emails</strong>
                  <InlineTooltip
                    title={`Emails entered will receive notifcations for this alert`}
                    icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                  />
                </label>
                <div className='mt-1'>
                  <MuiChipsInput
                    disabled={viewOnly}
                    size='small'
                    fullWidth
                    hideClearAll
                    disableDeleteOnBackspace
                    name='emails'
                    value={ruleConfig.emails}
                    onChange={handleChange}
                    validate={(newValue) => {
                      return {
                        isError: !emailRegex.test(newValue),
                        textError: 'Please enter an email address',
                      };
                    }}
                  />
                </div>
              </div>
            </div> */}
          </div>

          <div className='col-md-6 px-1'>
            <div
              className='flex-row mb-3 col-12 m-1 p-1 pb-3 rounded border border-light shadow-sm'
              style={{backgroundColor: '#ece6f0'}}
            >
              <label className='mb-0 mt-2'>
                <strong>Conditions</strong>
                <InlineTooltip
                  title={`Conditions to trigger the alert`}
                  icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                />
              </label>
              <div className='mt-1'>{generateConditionsOptions()}</div>
            </div>
          </div>
        </div>

        <Divider />

        <div className='row mx-0 px-1 pb-3'>
          <h5 className='mt-2' style={{color: 'black'}}>
            Filters
          </h5>
          <React.Fragment>
            <div className='row w-100 mx-0 px-0'>
              <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>Task Filter</strong>
                    <InlineTooltip
                      title={`The tasks for which this rule should apply to`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </label>
                  <div className='mt-1'>
                    <GeneralSearchSelect
                      disabled={viewOnly}
                      multiple={true}
                      name='taskIds'
                      value={ruleConfig.taskIds}
                      options={renderTaskOptions()}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>Vehicle Filter</strong>
                    <InlineTooltip
                      title={`The vehicles for which this rule should apply to`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </label>
                  <div className='mt-1'>
                    <GeneralSearchSelect
                      disabled={viewOnly}
                      multiple={true}
                      name='vehicleSns'
                      value={ruleConfig.vehicleSns}
                      options={renderVehicleOptions()}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>Vehicle Type Filter</strong>
                    <InlineTooltip
                      title={`The vehicle types for which this rule should apply to`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </label>
                  <div className='mt-1'>
                    <GeneralSearchSelect
                      disabled={viewOnly}
                      multiple={true}
                      name='vehicleTypes'
                      value={ruleConfig.vehicleTypes}
                      options={Object.keys(machineTypeMapping).map((typeEnum) => {
                        return {value: parseInt(typeEnum), label: machineTypeMapping[typeEnum]};
                      })}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>In-Zone Filter</strong>
                    <InlineTooltip
                      title={`The in-zone state for which this rule should apply to`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </label>
                  <div className='mt-1'>
                    <Select
                      disabled={viewOnly || ['farmSpeeding', 'roadSpeeding'].includes(ruleConfig.type)}
                      fullWidth
                      size='small'
                      name='inZoneState'
                      value={ruleConfig.inZoneState}
                      onChange={handleChange}
                    >
                      <MenuItem value='inZone'>In Zone</MenuItem>
                      <MenuItem value='outOfZone'>Out of Zone</MenuItem>
                      <MenuItem value='none'>None</MenuItem>
                    </Select>
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>Block Filter</strong>
                    <InlineTooltip
                      title={`The blocks for which this rule should apply to`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </label>
                  <div className='mt-1'>
                    <GeneralSearchSelect
                      disabled={viewOnly || ruleConfig.type == 'roadSpeeding'}
                      multiple={true}
                      name='blockIds'
                      value={ruleConfig.blockIds}
                      options={Object.values(zonesDicts.blocks).map((zone) => {
                        return {value: zone.id, label: zone.name};
                      })}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>Field Filter</strong>
                    <InlineTooltip
                      title={`The fields for which this rule should apply to`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </label>
                  <div className='mt-1'>
                    <GeneralSearchSelect
                      disabled={viewOnly || ruleConfig.type == 'roadSpeeding'}
                      multiple={true}
                      name='fieldIds'
                      value={ruleConfig.fieldIds}
                      options={Object.values(zonesDicts.fields).map((zone) => {
                        return {value: zone.id, label: zone.name};
                      })}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>Region Filter</strong>
                    <InlineTooltip
                      title={`The regions for which this rule should apply to`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </label>
                  <div className='mt-1'>
                    <GeneralSearchSelect
                      disabled={viewOnly || ruleConfig.type == 'roadSpeeding'}
                      multiple={true}
                      name='regionIds'
                      value={ruleConfig.regionIds}
                      options={Object.values(zonesDicts.regions).map((zone) => {
                        return {value: zone.id, label: zone.name};
                      })}
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>

        <Divider />

        {/* Advanced Settings Collapsable */}
        <CollapsableSection
          title='Advanced Settings'
          collapseOpen={showAdvancedSettings}
          setCollapseOpen={setShowAdvancedSettings}
          section='Advanced Settings'
          viewOnly={viewOnly}
        >
          <div className='row mx-0 px-1 pb-3'>
            <div className='col-md-6 p-1'>
              <div className='d-flex align-items-center m-1 bg-light rounded border border-light h-100 w-100 shadow-sm'>
                <div className='p-2 w-100'>
                  <strong>Rule Duration (seconds)</strong>
                  <InlineTooltip
                    title={`Length of time condition must be active for alert to trigger`}
                    icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                  />
                </div>
                <div className='p-2 flex-shrink-1'>
                  <TextField
                    disabled={viewOnly || ruleConfig.type == 'idling'}
                    name='ruleDuration'
                    type='text'
                    value={ruleConfig.ruleDuration}
                    onChange={handleValueInputChange}
                    onKeyDown={handleAllowOnlyNumbers}
                  />
                </div>
              </div>
            </div>
          </div>
        </CollapsableSection>

        {/* Archive Rule Toggle */}
        {ruleConfig.id != undefined && (
          <React.Fragment>
            <Divider />
            <div className='row w-100 mx-auto px-0 py-2 justify-content-center'>
              <div className='col-sm-6 col-md-4 px-1'>
                <div className='d-flex align-items-center m-1 p-1 bg-light rounded border border-light shadow-sm'>
                  <div className='p-2 w-100'>
                    <strong>Archive Rule</strong>
                    <InlineTooltip
                      title={`Toggle on and save to remove this Rule`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </div>
                  <div className='p-2 flex-shrink-1'>
                    <Switch
                      disabled={viewOnly}
                      name={'disabled'}
                      checked={ruleConfig.disabled}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        <div className='flex-row mb-3 col-12 px-1'>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <Button variant='ic-button' color='secondary' onClick={modalClose}>
              Cancel
            </Button>
            {!viewOnly && (
              <Button variant='ic-button' color='primary' onClick={handleSubmit}>
                Submit
              </Button>
            )}
          </div>
        </div>
        <div style={{color: 'red', textAlign: 'center'}} className='flex-row mb-3 col-12 px-1'>
          {modalError}
        </div>
      </Box>
    </Modal>
  );
}

export {RuleEditModal};
