import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useBeforeunload} from 'react-beforeunload';
import io from 'socket.io-client';
import PageVisibility from 'react-page-visibility';

import {sendGAPageview} from '../../app/utils';
import {Framework} from '../../components/Framework';
import {TabMenuTableWrapper} from '../../components/TabMenuTableWrapper';
import {Map} from './Map';
import {Menu} from './Menu';
import {setRefreshCabviewDataStream} from './cabviewSlice';
import packageInfo from '../../../package.json';

import {
  updateCabviewVehicles,
  updateHazards,
  updateTasks,
  updateImplements,
  updateGeofences,
  updateZonesData,
  setTaskActive,
  setSelectedVehicle,
  setSelectedTask,
  updateReiActive,
  updateDisplayedPlotLines,
  updateDisplayClusters,
} from './cabviewSlice';

const CABVIEW_API_CYCLE_SEC = 60;
const CABVIEW_API_CYCLE_MOBILE_SEC = 60;
const port = process.env.SERVER_PORT || 8080;
let URL = undefined;

if (window.location.hostname == 'localhost') {
  URL = `http://localhost:${port}`;
}

// Websocket client side manager parameters
const socket = io(URL, {
  transports: ['websocket'], // Want to only use websockets
  autoConnect: false, // keep false
  reconnection: true, // Explicitly enable auto reconnection
  reconnectionDelay: 2500, // ms
  reconnectionDelayMax: 10000, // ms
  randomizationFactor: 0.5, // Adds a little jitter to reconnection attempts
  timeout: 20000, // ms, connection timeout before emitting a error event
});

function Cabview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [forceMenuOpen, setForceMenuOpen] = useState(false);
  const [cabviewInitialized, setCabviewInitialized] = useState(false);
  const [streamTime, setStreamTime] = useState(-1);
  const [appVisible, setAppVisible] = useState(true);
  const [cabviewRequestCycleSec, setCabviewRequestCycleSec] = useState(CABVIEW_API_CYCLE_SEC);

  const displaySettings = useSelector((state) => {
    return state.framework;
  });
  const refreshCabviewDataStream = useSelector((state) => {
    return state.cabview.refreshCabviewDataStream;
  });

  // Connect socket if not connected
  if (!socket.connected) {
    socket.connect();
  }

  useBeforeunload(() => {
    // Disconnect socket if closing tab/browser
    disconnectCabview();
  });

  useEffect(() => {
    document.title = 'IntelliCulture | CabView';
    sendGAPageview(document.title);

    if (displaySettings.smallScreen) {
      setCabviewRequestCycleSec(CABVIEW_API_CYCLE_MOBILE_SEC);
    } else {
      setCabviewRequestCycleSec(CABVIEW_API_CYCLE_SEC);
    }

    initCabview();

    return () => {
      // Disconnect socket when component is unmounted
      disconnectCabview();
    };
  }, []);

  useEffect(() => {
    if (cabviewInitialized && appVisible) {
      if (refreshCabviewDataStream) {
        console.log('Forcing refresh of cabview data stream');
        // console.log('Socket Status: ', socket.id, socket.connected);
        dispatch(setRefreshCabviewDataStream(false)); // Reset refresh flag

        // Force get data
        socket.emit('getCabviewData', {timeLapse: 0});
        setStreamTime(0); // Reset stream time
      } else {
        const interval = setInterval(() => {
          const timeLapse = streamTime + cabviewRequestCycleSec;

          console.log('Getting cabview data. Time lapse:', timeLapse, 's');
          socket.emit('getCabviewData', {timeLapse});
          setStreamTime(timeLapse);
        }, cabviewRequestCycleSec * 1000);

        return () => {
          return clearInterval(interval);
        };
      }
    }
  }, [cabviewInitialized, appVisible, cabviewRequestCycleSec, streamTime, refreshCabviewDataStream]);

  useEffect(() => {
    if (displaySettings.smallScreen) {
      setCabviewRequestCycleSec(CABVIEW_API_CYCLE_MOBILE_SEC);
    } else {
      setCabviewRequestCycleSec(CABVIEW_API_CYCLE_SEC);
    }
  }, [displaySettings]);

  async function initCabview() {
    // Start liveData stream and set handlers for incoming socket messages
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('liveData', liveDataFeed);
    socket.on('reload', reloadPage);
    socket.on('logoutEvent', logoutEvent);
    socket.onAnyOutgoing((event) => {
      if (event != 'checkVersion') {
        socket.emit('checkVersion', packageInfo.version);
      }
    });

    // Get init cabview data package
    const initDataRequest = fetch('/cabview/getInitCabviewData', {cache: 'no-store'});
    const getZonesDataRequest = fetch('/getZonesData', {cache: 'no-store'});

    // Make all calls
    const [initDataResponse, getZonesDataResponse] = await Promise.all([
      initDataRequest,
      getZonesDataRequest,
    ]);

    // Get cabview data
    const initData = await initDataResponse.json();

    // Get zones data
    const zonesData = await getZonesDataResponse.json();
    const geofences = zonesData.geofences;

    // Update store with data
    dispatch(updateDisplayedPlotLines(initData.displayedPlotLines));
    dispatch(updateDisplayClusters(initData.displayClusters));
    dispatch(updateCabviewVehicles(initData.vehicleDataPack));
    dispatch(updateReiActive(initData.reiActive));
    dispatch(updateHazards(initData.hazards));
    dispatch(updateTasks(initData.taskConfigs));
    dispatch(updateImplements(initData.implements));
    dispatch(updateGeofences(geofences));
    dispatch(updateZonesData(zonesData));

    // Check if cabview opened via asset scan
    // Check if a vehicle is already being tracked in backend session
    let vehicleSN = '';
    let taskId = '';
    if (initData.trackingPack.vehicleSN != '' && initData.trackingPack.taskId != '') {
      vehicleSN = initData.trackingPack.vehicleSN;
      taskId = initData.trackingPack.taskId;

      dispatch(setTaskActive(true));
      dispatch(setSelectedVehicle(initData.trackingPack.vehicleSN));
      dispatch(setSelectedTask(initData.trackingPack.taskId));
      setForceMenuOpen(initData.selectionPack.menuToggled);
    } else {
      // Attempt to find the selected vehicle if its available in cabview
      if (initData.selectionPack.vehicleSN != '') {
        const selectedVehicle = initData.vehicleDataPack.filter((vehicle) => {
          return vehicle.serialNumber == initData.selectionPack.vehicleSN;
        });
        if (typeof selectedVehicle != 'undefined' && selectedVehicle.length == 1 && selectedVehicle[0].cabviewActive) {
          vehicleSN = initData.selectionPack.vehicleSN;
        }
      }

      dispatch(setTaskActive(false));
      dispatch(setSelectedVehicle('')); // Force cabview to see update on selectedVehicle
      dispatch(setSelectedVehicle(vehicleSN));

      let implementObj;
      const vehicleObj = initData.vehicleDataPack.find((vehicle) => {
        return vehicle.serialNumber == vehicleSN;
      });
      if (vehicleObj) {
        implementObj = initData.implements.find((implement) => {
          return implement.serialNumber == vehicleObj.linkedImplementSN;
        });
      }

      // Task
      if (implementObj && implementObj.linkedTaskId != '') {
        taskId = implementObj.linkedTaskId;
      } else if (vehicleObj && vehicleObj.linkedTaskId != '') {
        taskId = vehicleObj.linkedTaskId;
      } else if (initData.selectionPack.taskId != '') {
        taskId = initData.selectionPack.taskId;
      }
      dispatch(setSelectedTask(taskId));

      // Open cabview menu if implement was scanned to display task
      if (initData.selectionPack.scannedAssetType == 'implement') {
        setForceMenuOpen(true);
      } else {
        setForceMenuOpen(initData.selectionPack.menuToggled);
      }
    }

    socket.emit('setSelectedVehicle', {vehicleSN});
    socket.emit('setSelectedTask', {taskId});

    // Triggers stream to start
    setCabviewInitialized(true);
    setStreamTime(0);
  }

  function disconnectCabview() {
    // Disconnect socket
    socket.disconnect();

    socket.off('connect', onConnect);
    socket.off('disconnect', onDisconnect);
    socket.off('liveData', liveDataFeed);
    socket.off('reload', reloadPage);
    socket.off('logoutEvent', logoutEvent);
    socket.offAnyOutgoing();
  }

  function onConnect(data) {
    console.log('socket connected');
  }

  function onDisconnect(data) {
    console.log('socket disconnected');
  }

  function liveDataFeed(liveData) {
    // Update redux store with incoming live data
    dispatch(updateCabviewVehicles(liveData.vehDataPack));
    dispatch(updateReiActive(liveData.reiActive));
  }

  function reloadPage(data) {
    window.location.reload();
  }

  function logoutEvent(data) {
    navigate('/error', {state: {errorMsg: 'Session exipred. Please login again.'}});
  }

  function handleVisibilityChange(isVisibleStatus) {
    // console.log('handleVisibilityChange isVisibleStatus:', isVisibleStatus);
    if (isVisibleStatus) {
      setAppVisible(true);
    } else {
      setAppVisible(false);
    }
  }

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <Framework activePage='cabview' pageName='CabView' topBarSearch={false}>
        <TabMenuTableWrapper
          table={<Map socket={socket} />}
          pagination={<Menu socket={socket} forceMenuOpen={forceMenuOpen} />}
          tableNoMargin={true}
        />
      </Framework>
    </PageVisibility>
  );
}

export {Cabview};
