import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getOperationsData, getServicesData} from './Menu';
import {useSearchParams} from 'react-router-dom';
import {DateTime} from 'luxon';
import {
  setCompareByServicesData,
  setCompareByOperationsData,
  setCompareByTaskConfigs,
  setCompareByInspectionsData,
  setCompareByVehicles,
  setCompareByInspectionsDict,
  setCompareByToggle,
  setCompareByDatesCache,
  setComparisonLoading,
  setCompareByInOutTime,
} from './dashboardSlice';

import {Checkbox, CircularProgress} from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

let abortController;

function CompareBy(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const datesCache = useSelector((state) => {
    return state.dashboard.dates;
  });

  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });

  const scorecardsIdDict = useSelector((state) => {
    return state.dashboard.scorecardsIdDict;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });

  const selectedScorecardId = useSelector((state) => {
    return state.dashboard.selectedScorecardId;
  });

  const selectedKPI = useSelector((state) => {
    return state.dashboard.selectedKPI;
  });

  const selectedScorecardPageIndex = useSelector((state) => {
    return state.dashboard.selectedScorecardPageIndex;
  });

  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });

  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });

  const keyRef = useRef(DateTime.now());
  const dispatch = useDispatch();
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const [dates, setDates] = useState({
    start: null,
    end: null,
    max: null,
  });

  function getTodayDates() {
    // Get startDate for today
    const now = DateTime.local({zone: customerSettings.general.timeZone});
    const todayStart = now.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    // Get endDate for today
    const todayEnd = todayStart.plus({days: 1, milliseconds: -1});

    return [todayStart, todayEnd];
  }

  async function initDates() {
    // Determine if we have cached dates
    const cachedStart =
      datesCache.start != null
        ? DateTime.fromISO(datesCache.start).setZone(customerSettings.general.timeZone, {
            keepLocalTime: true,
          })
        : null;
    const cachedEnd =
      datesCache.end != null
        ? DateTime.fromISO(datesCache.end).setZone(customerSettings.general.timeZone, {
            keepLocalTime: true,
          })
        : null;

    // Init using current shift
    const [todayStart, todayEnd] = getTodayDates();

    // Check if values were inputted through url
    const startParam = cachedStart == null ? decodeURIComponent(searchParams.get('start')) : cachedStart;
    const endParam = cachedEnd == null ? decodeURIComponent(searchParams.get('end')) : cachedEnd;

    let startTime = DateTime.fromISO(startParam).setZone(customerSettings.general.timeZone);
    let endTime = DateTime.fromISO(endParam).setZone(customerSettings.general.timeZone);

    // If cache dates are available, that means data was previously loaded into redux and Menu unmounted
    let updateData = !(cachedStart && cachedEnd);

    // Ensure the url values are valid or else they need to be re-initilizationed
    if (!startTime.isValid || !endTime.isValid || (startTime >= todayEnd && endTime >= todayEnd)) {
      // If dates are after the lastUploadTime, or the values are invalid, set to the 24hr before
      startTime = todayStart;
      endTime = todayEnd;
    } else if (startTime.isValid && endTime.isValid && startTime < todayEnd && endTime >= todayEnd) {
      // Otherwise the dates are valid, check if the end time is after the lastUploadTime
      endTime = todayEnd;
    }

    if (endTime >= todayEnd) {
      // Always Force Reload Data when today is selected
      updateData = true;
    }

    keyRef.current = DateTime.now();
    const newDates = {
      start: startTime,
      end: endTime,
      max: todayEnd,
    };

    setDates(newDates);
    dispatch(setCompareByDatesCache({start: newDates.start.toISO(), end: newDates.end.toISO()}));
    retrieveData(newDates);
  }

  useEffect(() => {
    if (dates.start == null || dates.end == null) {
      // Null values means initilization still needs to occur
      initDates();
    }
  }, []);

  useEffect(() => {
    const datesStartISO = dates?.start?.toISO();
    const datesEndISO = dates?.end?.toISO();

    if (
      compareByDatesCache.start != null &&
      compareByDatesCache.end != null &&
      compareByDatesCache.start != datesStartISO &&
      compareByDatesCache.end != datesEndISO
    ) {
      setDates((values) => {
        const newDateObject = {
          ...values,
          start: DateTime.fromISO(compareByDatesCache.start).setZone(customerSettings.general.timeZone, {}),
          end: DateTime.fromISO(compareByDatesCache.end).setZone(customerSettings.general.timeZone, {}),
        };
        return newDateObject;
      });
      retrieveData({
        start: DateTime.fromISO(compareByDatesCache.start).setZone(customerSettings.general.timeZone, {}),
        end: DateTime.fromISO(compareByDatesCache.end).setZone(customerSettings.general.timeZone, {}),
      });
    }
  }, [compareByDatesCache]);

  function dateSelection(event, picker) {
    const [todayStart, todayEnd] = getTodayDates();
    const startDate = DateTime.fromISO(picker.startDate.toISOString()).setZone(customerSettings.general.timeZone, {
      keepLocalTime: true,
    });
    const endDate = DateTime.fromISO(picker.endDate.toISOString())
      .set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      })
      .setZone(customerSettings.general.timeZone, {keepLocalTime: true});

    const newDataDates = {
      start: startDate,
      end: endDate,
      max: todayEnd,
    };
    setDates(newDataDates);

    dispatch(setCompareByDatesCache({start: newDataDates.start.toISO(), end: newDataDates.end.toISO()}));
    retrieveData(newDataDates);
  }

  function datePickerRender() {
    let buttonDisplayString = '';

    if (
      typeof dates.start !== 'undefined' &&
      typeof dates.end !== 'undefined' &&
      dates.start != null &&
      dates.end != null
    ) {
      buttonDisplayString = ` ${dates.start.toFormat('L/d/yy')} ` + `- ${dates.end.toFormat('L/d/yy')} `;
      return !comparisonLoading ? (
        <DateRangePicker
          key={keyRef.current}
          onApply={dateSelection}
          initialSettings={{
            startDate: dates.start.toFormat('MM/dd/yyyy'),
            endDate: dates.end.toFormat('MM/dd/yyyy'),
            maxDate: dates.max.toFormat('MM/dd/yyyy'),
            // timePicker: false,
            // timePicker24Hour: false,
            locale: {
              format: 'MM/DD/YYYY',
            },
          }}
          disabled={loading}
        >
          <button  className='btn btn-light cropview-menu-text h-100'>
          <i className='fas fa-calendar-alt' />
            {buttonDisplayString}
          </button>
        </DateRangePicker>
      ) : (
          <CircularProgress style={{'marginLeft':'10px', 'marginRight':'10px'}} size={30} />
        
      );
    }
  }

  async function retrieveData(newDataDates) {
    if (typeof abortController !== 'undefined') {
      abortController.abort();
    }
    dispatch(setComparisonLoading(true));
    abortController = new AbortController();
    const operationsDataRequest = getOperationsData(newDataDates, abortController);
    const servicesRequest = getServicesData(newDataDates, abortController);

    const [operationsDataResponse, servicesResponse] = await Promise.all([operationsDataRequest, servicesRequest]);
    dispatch(setCompareByOperationsData(operationsDataResponse.data.operationsReport));
    dispatch(setCompareByInspectionsData(operationsDataResponse.data.inspectionsReport));
    dispatch(setCompareByServicesData(servicesResponse));
    dispatch(setCompareByTaskConfigs(operationsDataResponse.data.taskConfigIdDict));
    dispatch(setCompareByInspectionsDict(operationsDataResponse.data.inspectionItemsDict));
    dispatch(setCompareByVehicles(operationsDataResponse.data.vehicleSNDict));
    dispatch(setComparisonLoading(false));
    dispatch(
      setCompareByInOutTime({
        inFieldTime: operationsDataResponse.data.inFieldTotal,
        outFieldTime: operationsDataResponse.data.outFieldTotal,
      })
    );
  }

  if (selectedKPI != 'Scorecards' && datesCache.start != null) {
    return (
            <div style={{
        border: '1px solid #5a5c69',
         borderRadius:'.35rem',
         marginLeft:'5px',
         display:'flex',
         alignItems:'center'
         }}>
        <FormControlLabel
          label={compareByToggle ? 'Compare to -' : 'Compare'}
          sx={{
            mr: 1,
            ml: 0.5,
            mb: 0.5,
            mt: 0.5,
            color: '#3a3b45'
          }}
          control={
            <Checkbox
              size='small'
              sx={{
                padding: 1,
              }}
              onChange={() => {
                dispatch(setCompareByToggle(!compareByToggle));
              }}
              checked={compareByToggle}
            />
          }
        />
        {compareByToggle && datePickerRender()}

        </div>
      
    );
  } else {
    return <div></div>;
  }
}

export {CompareBy};
