// Import for framework tools
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {sendGAPageview} from '../../app/utils';

// Import dependent components
import {Framework} from '../../components/Framework';
import {ZoneInfoTable} from './ZoneInfoTable';
import {Map} from './Map';
import {Menu} from './Menu';

function Mapview() {
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.zoneinfo.loading;
  });
  const displayedTable = useSelector((state) => {
    return state.zoneinfo.displayedTable;
  });
  const mapView = useSelector((state) => {
    return state.zoneinfo.mapView;
  });
  const sortMethod = useSelector((state) => {
    return state.zoneinfo.sortMethod;
  });
  const statusFilter = useSelector((state) => {
    return state.zoneinfo.statusFilter;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const zonesDicts = useSelector((state) => {
    return state.zoneinfo.zonesDicts;
  });
  const geofencesDict = useSelector((state) => {
    return state.zoneinfo.geofencesDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [tableData, setTableData] = useState({
    blocks: [],
    fields: [],
    regions: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    blocks: [],
    fields: [],
    regions: [],
  });
  const [filters, setFilters] = useState({
    blocks: [],
    fields: [],
    regions: [],
  });

  const [drag, setDrag] = useState({
    active: false,
    x: '',
    y: '',
  });

  const [dims, setDims] = useState({
    w: window.innerWidth / 1.75,
    h: window.innerHeight / 1.75,
  });

  useEffect(() => {
    document.title = 'IntelliCulture | MapView';
    sendGAPageview(document.title);
  }, []);

  useEffect(() => {
    getZoneInfoData();
  }, [zonesDicts, sortMethod]);

  function startResize(e) {
    setDrag({
      active: true,
      x: e.clientX || e.touches[0].pageX,
      y: e.clientY || e.touches[0].pageY,
    });
  }

  function resizeFrame(e) {
    const {active, x, y} = drag;
    if (active) {
      const pointerX = e.clientX || e.touches[0].pageX;
      const pointerY = e.clientY || e.touches[0].pageY;
      if (!smallScreen) {
        const xDiff = Math.abs(x - pointerX);
        let newW = x > pointerX ? dims.w - xDiff : dims.w + xDiff;

        const minWidth = 200;
        const maxWidth = window.innerWidth - 300;
        newW = newW < minWidth ? minWidth : newW;
        newW = newW > maxWidth ? maxWidth : newW;

        setDrag({...drag, x: pointerX, y: pointerY});
        setDims({w: newW, h: dims.h});
      } else {
        const yDiff = Math.abs(y - pointerY) * 3;
        const newH = y > pointerY ? dims.h - yDiff : dims.h + yDiff;
        setDrag({...drag, x: pointerX, y: pointerY});
        setDims({w: dims.w, h: newH});
      }
    }
  }

  function stopResize() {
    setDrag({...drag, active: false});
  }

  function setFilterDefault() {
    setFilters({
      blocks: [],
      fields: [],
      regions: [],
    });
  }

  function renderTable() {
    switch (displayedTable) {
      case 'block':
        return (
          <ZoneInfoTable
            type='blocks'
            tableData={
              userSettings.general.cropviewEnabled && customerSettings.general.geoFencesProvided ? tableData.blocks : []
            }
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'field':
        return (
          <ZoneInfoTable
            type='fields'
            tableData={
              userSettings.general.cropviewEnabled && customerSettings.general.geoFencesProvided ? tableData.fields : []
            }
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
      case 'region':
        return (
          <ZoneInfoTable
            type='regions'
            tableData={
              userSettings.general.cropviewEnabled && customerSettings.general.geoFencesProvided
                ? tableData.regions
                : []
            }
            filterOptions={filterOptions}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
            dims={dims}
          />
        );
    }
  }

  function getZoneInfoData() {
    const blocks = [];
    const fields = [];
    const regions = [];
    const filterOptionsBlocks = [];
    const filterOptionsFields = [];
    const filterOptionsRegions = [];

    const now = DateTime.now().setZone('utc');;

    Object.values(zonesDicts.regions).forEach((region) => {
      const tempRegionData = {
        name: region.name,
        latMax: region.latMax,
        latMin: region.latMin,
        lngMax: region.lngMax,
        lngMin: region.lngMin,
        areaMeters2: region.areaMeters2,
      };
      const tempRegionFilterOption = {
        value: tempRegionData.name,
        text: tempRegionData.name,
      };
      filterOptionsRegions.push(tempRegionFilterOption);
      regions.push(tempRegionData);
    });
    Object.values(zonesDicts.fields).forEach((field) => {
      const tempFieldData = {
        name: field.name,
        latMax: field.latMax,
        latMin: field.latMin,
        lngMax: field.lngMax,
        lngMin: field.lngMin,
        areaMeters2: field.areaMeters2,
        regionIds: field.regionIds,
      };
      const tempFieldFilterOption = {
        value: tempFieldData.name,
        text: tempFieldData.name,
      };
      filterOptionsFields.push(tempFieldFilterOption);
      fields.push(tempFieldData);
    });
    Object.values(zonesDicts.blocks).forEach((block) => {
      let rowSpacingMeters = '';
      if (typeof block.rowSpacingMeters != 'undefined') {
        rowSpacingMeters = block.rowSpacingMeters;
      }
      const tempBlockData = {
        name: block.name,
        id: block.id,
        active: true,
        latMax: block.latMax,
        latMin: block.latMin,
        lngMax: block.lngMax,
        lngMin: block.lngMin,
        rowSpacingMeters: block.rowSpacingMeters,
        fieldId: block.fieldId,
        regionId: block.regionId,
        areaMeters2: block.areaMeters2,
        rowPassEnabled: 'No', // Yes, No, Mixed
        cropPrimaryClass: block.cropPrimaryClass,
        cropSubClass: block.cropSubClass,
        cropVariety: block.cropVariety,
      };

      // Determine row pass status and active state of block
      let intelliblockRowPassEnabledNum = 0;
      let blockActive = false;
      let blockLastDeactivationDate;
      const blockIntelliBlockNums = block.intelliblockNums;
      for (let n = 0; n < blockIntelliBlockNums.length; n++) {
        if (Object.prototype.hasOwnProperty.call(geofencesDict, blockIntelliBlockNums[n])) {
          const geofence = geofencesDict[blockIntelliBlockNums[n]];
          // Determine if geofence is rowpass enabled, product may not necessarily be using it
          if (geofence.rowPassEnabled && geofence.rowBearing != -1) {
            intelliblockRowPassEnabledNum += 1;
          }

          // Determine if geofence is active now
          const geofenceActiveFromDate = DateTime.fromISO(geofence.activeFromDate);
          const geofenceActiveToDate = DateTime.fromISO(geofence.activeToDate);
          if (geofenceActiveFromDate <= now && geofenceActiveToDate >= now) {
            blockActive = true;

            if (!blockLastDeactivationDate || geofenceActiveToDate > blockLastDeactivationDate) {
              // Get last deactivation date
              blockLastDeactivationDate = geofenceActiveToDate;
            }
          }
        }
      }

      // Set rowpass enabled text
      let activeText = 'Archived';
      let blockActiveToDate = '';
      if (blockActive) {
        if (blockLastDeactivationDate.diff(now, 'years').years < 50) {
          activeText = `Active until ${blockLastDeactivationDate.toLocaleString(DateTime.DATE_MED)}`;
          blockActiveToDate = blockLastDeactivationDate.setZone(customerSettings.general.timeZone).toISO().slice(0, 16);
        } else {
          activeText = 'Active';
        }
      }

      // Set activation text
      let rowPassEnabledStatus = 'No';
      if (intelliblockRowPassEnabledNum == blockIntelliBlockNums.length) {
        rowPassEnabledStatus = 'Yes';
      } else if (intelliblockRowPassEnabledNum > 0) {
        rowPassEnabledStatus = 'Mixed';
      }

      // Assign data to block
      tempBlockData.rowPassEnabled = rowPassEnabledStatus;
      tempBlockData.active = blockActive;
      tempBlockData.activeText = activeText;
      tempBlockData.blockActiveToDate = blockActiveToDate;

      // // Skip block based on status filter
      // if ((statusFilter == 'active' && !tempBlockData.active) || (statusFilter == 'archived' && tempBlockData.active)) {
      //   return;
      // }

      const tempBlockFilterOption = {
        value: tempBlockData.name,
        text: tempBlockData.name,
      };
      filterOptionsBlocks.push(tempBlockFilterOption);
      blocks.push(tempBlockData);
    });

    const regionTableData = sortZoneData(regions);
    const fieldTableData = sortZoneData(fields);
    const blockTableData = sortZoneData(blocks);

    // Sort filters
    filterOptionsBlocks.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });
    filterOptionsFields.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });
    filterOptionsRegions.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });

    setFilterOptions((values) => {
      return {
        ...values,
        blocks: [...new Set(filterOptionsBlocks)],
        fields: [...new Set(filterOptionsFields)],
        regions: [...new Set(filterOptionsRegions)],
      };
    });

    setTableData({
      blocks: blockTableData,
      fields: fieldTableData,
      regions: regionTableData,
    });
  }

  function sortZoneData(dataList) {
    // Make copy of list or else will get error when trying to sort
    const zoneList = JSON.parse(JSON.stringify(dataList));
    // Sort zones and vehicle list within zone by method specified
    // Will sort a after b if compare function returns > 0 and sort a before b if compare function returns < 0
    if (sortMethod == 'name') {
      zoneList.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    } else if (sortMethod == 'acreage') {
      zoneList.sort((a, b) => {
        return a.areaMeters2 < b.areaMeters2 ? 1 : -1;
      });
    }
    return zoneList;
  }

  return (
    <React.Fragment>
      <Framework activePage='mapview' pageName='MapView'>
        <div
          className='container-fluid'
          id='tab-wrapper'
          onMouseMove={resizeFrame}
          onTouchMove={resizeFrame}
          onMouseUp={stopResize}
          onMouseLeave={stopResize}
          onTouchEnd={stopResize}
        >
          <Menu setFilterDefault={setFilterDefault} />
          {smallScreen ? (
            <React.Fragment>
              {mapView <= 1 && renderTable()}
              {mapView >= 1 && <Map filters={filters} />}
            </React.Fragment>
          ) : (
            <div className='d-flex flex-row cropview-main'>
              {renderTable()}
              <div className='cropview-handle-horizontal ml-1 mr-3 mb-5' onPointerDown={startResize}></div>
              <div className='flex-grow-1 pt-2 pb-0'>
                <Map filters={filters} />
              </div>
            </div>
          )}
        </div>
      </Framework>
    </React.Fragment>
  );
}

export {Mapview};
