import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {
  customChartProperties,
  machineTypeMapping,
  machineTypeReverseMapping,
  roundDecimalPlaces,
  unionLabelsAndDataVehicles,
  deepCopy,
} from '../../../app/utils';
import {inVsOutFieldData} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  KpiTable,
  StatsViewReportFiltersWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportSideControlWrapper,
  StatsViewReportGraphAndControlStyle,
} from '../dashboardUtils';
import {Select, MenuItem, CircularProgress, FormControl, InputLabel, Grid, Box} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {setFilterOptions, setCompareByDatesCache, setCompareByToggle} from '../dashboardSlice';

ChartJS.register(annotationPlugin);
const timeAxes = ['Days', 'Weeks', 'Months', 'Years'];

function KpiOperationalEfficiency(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const inOutTime = useSelector((state) => {
    return state.dashboard.inOutTime;
  });
  const compareByInOutTime = useSelector((state) => {
    return state.dashboard.compareByInOutTime;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const compareByOperationsData = useSelector((state) => {
    return state.dashboard.compareByOperationsData;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });

  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);
  const [inVsOutContent, setInVsOutContent] = useState(null);
  const [targetInFieldPercentage, setTargetInFieldPercentage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [selectedMachineType, setSelectedMachineType] = useState('All');
  const [machineTypeOptions, setMachineTypeOptions] = useState([]);
  const [targetsObject, setTargetsObject] = useState({});
  const [displayedAxis, setDisplayedAxis] = useState('Total');
  const [filters, setFilters] = useState({
    'oppEffVehSNs': [],
  });

  function handleFilters(value) {
    setFilters({
      'oppEffVehSNs': value,
    });
  }

  useEffect(() => {
    // Reset selection if compare by is toggled
    if(compareByToggle && timeAxes.includes(displayedAxis)){
      setDisplayedAxis('Total');
    }
  },[compareByToggle])

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [
    operationsData,
    vehicleSNDict,
    selectedMachineType,
    filters,
    compareByToggle,
    compareByOperationsData,
    compareByInOutTime,
    props.pageStates,
  ]);

  useEffect(() => {
    if (props.pageStates?.selectedMachineType) {
      setSelectedMachineType(props.pageStates?.selectedMachineType);
    } else {
      setSelectedMachineType('All');
    }
    if (props.pageStates?.filters) {
      setFilters(props.pageStates.filters);
    }
    if(props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle){
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange))
    }
    if(props.pageStates?.compareByToggle){
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle))
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if(props.hasOwnProperty('pageStates')){
      dispatch(setCompareByToggle(false))
    }

    if(props.pageStates?.displayedAxis){
      setDisplayedAxis(props.pageStates?.displayedAxis)
    }
    else{
      setDisplayedAxis("Total")
    }
  }, [props.pageStates]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  useEffect(() => {
    // Build filter options
    if (operationsData != null) {
      const typeOptions = [];
      Object.keys(machineTypeMapping).forEach((key) => {
        if (operationsData.vehicleHoursByTypeObject.hasOwnProperty(key)) {
          typeOptions.push({value: machineTypeMapping[key], label: machineTypeMapping[key]});
        }
      });
      typeOptions.unshift({value: 'All', label: 'All'});
      
      // Populate filters
      setMachineTypeOptions(typeOptions);
    }
  }, [operationsData]);

  function generateData() {

    // Build table & targets
    const [tableData, tempTargetsObject, inFieldTarget] = generateTableData();

    // Generate filters & in vs out data
    const [inFieldSum, outFieldSum] = genFilteredInOutFieldTimes(operationsData);

    // Generate chart data
    const inVsOutContentFiltered = inVsOutFieldData(inFieldSum, outFieldSum);

    // if compare by, add compare by data
    if(compareByToggle && compareByOperationsData != null){
      const [inFieldSumCompareBy, outFieldSumCompareBy] = genFilteredInOutFieldTimes(compareByOperationsData, true);
      const inVsOutContentCompareBy = inVsOutFieldData(inFieldSumCompareBy, outFieldSumCompareBy, true);

      // Combine the datasets
      inVsOutContentFiltered.datasets = [
        ...inVsOutContentFiltered.datasets,
        ...inVsOutContentCompareBy.datasets
      ]
    }

    // Add target line seperately to ensure 
    // there is only 1 copy in the dataset
    inVsOutContentFiltered.datasets.push(
      {
        type: 'line',
        label: ['Target'],
        backgroundColor: [customChartProperties.colorRed],
        data: [],
        yAxisID: 'TargetAxis',
      }
    )

    // Set data
    setTableData(tableData);
    setTargetsObject(tempTargetsObject);
    setTargetInFieldPercentage(inFieldTarget);
    if (inVsOutContentFiltered) {
    setInVsOutContent(inVsOutContentFiltered);
    } else {
      setInVsOutContent(null);
    }
  }

  function generateTableData(){

    // Targets data
    const inFieldTarget = generateTargetsData();
    const tempTargetsObject = {
      'All': inFieldTarget,
    };

    // Table data
    const tableData = [];
    const vehicleKeysFiltered = Object.keys(operationsData.vehiclesObject).filter((key) => {
      return filters['oppEffVehSNs'].includes(key) || filters['oppEffVehSNs'].length == 0;
    });
    vehicleKeysFiltered.forEach((vehicleSN) => {
      const vehicle = operationsData.vehiclesObject[vehicleSN];
      const inFieldDenominator = vehicle.inFieldTime + vehicle.outOfFieldTime;

      let inFieldPercent = 0;
      if (inFieldDenominator != 0) {
        inFieldPercent = Math.round((vehicle.inFieldTime / inFieldDenominator) * 100);
      }

      if (
        Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN) &&
        (selectedMachineType == 'All' ||
          machineTypeMapping[vehicleSNDict[vehicleSN].machineType] == selectedMachineType)
      ) {
        let vehicleInFieldTarget = inFieldTarget;
        if (customerSettings?.targets?.typeInFieldPercent[vehicleSNDict[vehicleSN].machineType]) {
          vehicleInFieldTarget = customerSettings?.targets?.typeInFieldPercent[vehicleSNDict[vehicleSN].machineType];
        }
        tableData.push({
          vehicleName: vehicle.vehicleName,
          inFieldPercent: inFieldPercent,
          type: machineTypeMapping[vehicleSNDict[vehicleSN].machineType],
          inFieldTarget:
            props.hasOwnProperty('pageStates') &&
            props.pageStates != undefined &&
            props.pageStates.hasOwnProperty('pageTargets') &&
            props.pageStates.pageTargets.hasOwnProperty(vehicleSN)
              ? props.pageStates.pageTargets[vehicleSN]
              : vehicleInFieldTarget,
        });
        tempTargetsObject[vehicleSN] = vehicleInFieldTarget;
      }
    });

    return [tableData, tempTargetsObject, inFieldTarget];
  }

  function generateTargetsData() {
    // Get targets
    let inFieldTarget = customerSettings?.targets?.totalInFieldPercent ?? 0;
    if (selectedMachineType != 'All') {
      inFieldTarget = customerSettings?.targets?.typeInFieldPercent[machineTypeReverseMapping[selectedMachineType]];
      if (typeof inFieldTarget === 'undefined') {
        if (customerSettings?.targets?.totalInFieldPercent) {
          inFieldTarget = customerSettings?.targets?.totalInFieldPercent;
        } else {
          inFieldTarget = 0;
        }
      }
    }

    if (
      props.hasOwnProperty('pageStates') &&
      props.pageStates != undefined &&
      props.pageStates.hasOwnProperty('pageTargets')
    ) {
      inFieldTarget = props.pageStates.pageTargets['All'];
    }

    return inFieldTarget;
  }

  function genFilteredInOutFieldTimes(opsData, compareBy = false) {
    
    // Build filter options
    const inVsOutObject = {
      'All': {
        // Arbitrate totals based on compare by
        inField: compareBy ? compareByInOutTime.inFieldTime : inOutTime.inFieldTime,
        outField: compareBy ? compareByInOutTime.outFieldTime : inOutTime.outFieldTime,
      },
    };
    
    Object.keys(machineTypeMapping).forEach((key) => {
      if (opsData.vehicleHoursByTypeObject.hasOwnProperty(key)) {
        inVsOutObject[machineTypeMapping[key]] = {
          inField: opsData.vehicleHoursByTypeObject[key].inFieldTime,
          outField: opsData.vehicleHoursByTypeObject[key].outOfFieldTime,
        };
      }
    });

    // Get in vs out data based on filters
    let inFieldSum = inVsOutObject[selectedMachineType]?.inField;
    let outFieldSum = inVsOutObject[selectedMachineType]?.outField;
    if (filters['oppEffVehSNs'].length > 0) {
      inFieldSum = 0; // Reset to re-sum based on filters
      outFieldSum = 0; // Reset to re-sum based on filters
      if (selectedMachineType == 'All') {
        Object.keys(opsData.vehiclesObject).forEach((vehicleSN) => {
          if (filters['oppEffVehSNs'].includes(vehicleSN)) {
            inFieldSum += opsData.vehiclesObject[vehicleSN]['inFieldTime'];
            outFieldSum += opsData.vehiclesObject[vehicleSN]['outOfFieldTime'];
          }
        });
      } else {
        Object.keys(opsData.vehiclesObject).forEach((vehicleSN) => {
          if (
            filters['oppEffVehSNs'].includes(vehicleSN) &&
            opsData.vehiclesObject[vehicleSN]['type'] == machineTypeReverseMapping[selectedMachineType]
          ) {
            inFieldSum += opsData.vehiclesObject[vehicleSN]['inFieldTime'];
            outFieldSum += opsData.vehiclesObject[vehicleSN]['outOfFieldTime'];
          }
        });
      }
    }

    return [inFieldSum, outFieldSum];
  }

  function operationalEfficiencyGraphData() {
    let inFieldList = [];
    let outFieldList = [];
    let labelsFormatted = [];

    // Union keys between compare by and non compare by
    // for vehicles if compare by is toggled
    const opsDataCopy = deepCopy(operationsData)
    const compareByOpsDataCopy = deepCopy(compareByOperationsData)
    if(compareByToggle){
      const [newOpsVehiclesObject, newCompareByOpsVehiclesObject] = unionLabelsAndDataVehicles(
        operationsData.vehiclesObject,
        compareByOperationsData.vehiclesObject, ['type', 'vehicleName']
      )
      opsDataCopy.vehiclesObject = newOpsVehiclesObject
      compareByOpsDataCopy.vehiclesObject = newCompareByOpsVehiclesObject
    }

    // Populate data based on time axis
    if (timeAxes.includes(displayedAxis)) {
      [inFieldList, outFieldList, labelsFormatted] = operationalEfficiencyTimeData(operationsData);
    }
    // Populate data based on vehicle
    else if (displayedAxis === 'Vehicle') {
      [inFieldList, outFieldList, labelsFormatted] = operationalEfficiencyVehicleData(opsDataCopy.vehiclesObject);
    }

    // Build the graph datasets
    const graphData = generateGraphDatasets(inFieldList, outFieldList, labelsFormatted);

    // if compare by, add compare by data
    if(compareByToggle && compareByOperationsData != null){
      const [
        inFieldListCompareBy, 
        outFieldListCompareBy, 
        labelsFormattedCompareBy
      ] = operationalEfficiencyVehicleData(compareByOpsDataCopy.vehiclesObject);
      const graphDataCompareBy = generateGraphDatasets(inFieldListCompareBy, outFieldListCompareBy, labelsFormattedCompareBy, true);

      // Combine the datasets
      graphData.datasets = [
        ...graphData.datasets,
        ...graphDataCompareBy.datasets
      ]
    }

    return graphData;
  }

  function operationalEfficiencyTimeData(opsData) {
    const inFieldList = [];
    const outFieldList = [];
    const labelsFormatted = [];
    let labels = [];

    // Populate data based on time axis
    const timeData = {
      'Days': opsData.opsByDay,
      'Weeks': opsData.opsByWeek,
      'Months': opsData.opsByMonth,
      'Years': opsData.opsByYear,
    };
    const selectedTimeData = timeData[displayedAxis];
    labels = Object.keys(selectedTimeData);
    labels.forEach((label) => {
      const vehObject = selectedTimeData[label]['operationsReport']['vehiclesObject'];

      let periodInFieldTotal = 0;
      let periodOutFieldTotal = 0;

      Object.keys(vehObject).forEach((veh) => {
        // If no filters
        if (
          (filters['oppEffVehSNs'].length == 0 &&
            (selectedMachineType == 'All' || selectedMachineType == vehicleSNDict[veh].machineType)) ||
          ((selectedMachineType == 'All' ||
            selectedMachineType == machineTypeMapping[vehicleSNDict[veh].machineType]) &&
            (filters['oppEffVehSNs'].includes(veh) || filters['oppEffVehSNs'].length == 0))
        ) {
          periodInFieldTotal += vehObject[veh].inFieldTime;
          periodOutFieldTotal += vehObject[veh].outOfFieldTime;
        }
      });
      inFieldList.push(periodInFieldTotal);
      outFieldList.push(periodOutFieldTotal);
      labelsFormatted.push(label.slice(0, 10)); // Format labels to only be in YYYY-MM-DD format
    });

    return [inFieldList, outFieldList, labelsFormatted];
  }

  function operationalEfficiencyVehicleData(vehiclesObject) {
    const inFieldList = [];
    const outFieldList = [];
    const labelsFormatted = [];

    // Populate data based on time axis
    Object.keys(vehiclesObject).forEach((veh) => {
      // If no filters
      if (
        (filters['oppEffVehSNs'].length == 0 &&
          (selectedMachineType == 'All' || selectedMachineType == vehicleSNDict[veh].machineType)) ||
        ((selectedMachineType == 'All' ||
          selectedMachineType == machineTypeMapping[vehicleSNDict[veh].machineType]) &&
          (filters['oppEffVehSNs'].includes(veh) || filters['oppEffVehSNs'].length == 0))
      ) {
        inFieldList.push(vehiclesObject[veh].inFieldTime);
        outFieldList.push(vehiclesObject[veh].outOfFieldTime);
        labelsFormatted.push(vehiclesObject[veh].vehicleName);
      }
    });

    return [inFieldList, outFieldList, labelsFormatted];
  }

  function generateGraphDatasets(inFieldList, outFieldList, labelsFormatted, compareBy = false) {
    
    // Generate graph data
    const graphData = {
      labels: labelsFormatted,
      datasets: [
        {
          type: 'bar',
          label: compareBy ? [`Comparison In Field Time`] : [`In Field Time`],
          data: inFieldList,
          id: 'inField',
          borderWidth: 1,
          order: 2,
          backgroundColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
          borderColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
          yAxisID: 'leftAxis',
          stack: compareBy ? "Compare Stack" : "Regular Stack",
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const infieldTimeString = `${roundDecimalPlaces(formattedValue, 2)} Hours`;
                const tooltipRows = ["In Field: ", infieldTimeString];
                return tooltipRows;
              },
            },
          },
        },
        {
          type: 'bar',
          label: compareBy ? [`Comparison Out of Field Time`] : [`Out of Field Time`],
          data: outFieldList,
          id: 'outField',
          borderWidth: 1,
          order: 2,
          backgroundColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
          borderColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
          yAxisID: 'leftAxis',
          stack: compareBy ? "Compare Stack" : "Regular Stack",
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const infieldTimeString = `${roundDecimalPlaces(formattedValue, 2)} Hours`;
                const tooltipRows = ['Out of Field:', infieldTimeString];
                return tooltipRows;
              },
            },
          },
        },
      ],
    };

    return graphData;
  }

  function operationalEfficiencyBarGraph() {
    if (displayedAxis !== 'Total') {
      // Get data
      const graphData = operationalEfficiencyGraphData();

      return (
        <div style={{height: '50vh', maxHeight: '400px'}}>
          <BarChart
            data={graphData}
            options={{
              maintainAspectRatio: false,
              plugins: {
                title: {
                  text: 'In Field vs. Out of Field Time',
                  display: true,
                  font: {
                    size: customChartProperties.titleFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                legend: {
                  onClick: (e) => {
                    return false;
                  },
                  labels: {
                    font: {
                      size: customChartProperties.legendFontSize,
                    },
                  },
                },
              },
              scales: {
                leftAxis: {
                  stacked: true,
                  title: {
                    text: 'Hours',
                    display: true,
                    font: {
                      size: customChartProperties.axisTitleFontSize,
                      weight: customChartProperties.axisTitleFontWeight,
                    },
                  },
                  type: 'linear',
                  position: 'left',
                },
                x: {
                  stacked: true,
                  ticks: {
                    autoSkip: graphData.labels.length > customChartProperties.autoSkipLimit,
                    maxRotation: 90,
                    minRotation: 0,
                    font: {
                      size: customChartProperties.xLabelFontSize,
                      lineHeight: customChartProperties.lineHeight,
                    },
                  },
                  title: {
                    display: false,
                  }
                },
              },
            }}
          />
        </div>
      );
    } else return null;
  }

  function operationalEfficiencyTotalBarGraph() {
    return (
      <div style={{height: '50vh', maxHeight: '400px', alignItems: 'center'}}>
        {/* Chart */}
        <BarChart
          data={inVsOutContent}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: 'In Field vs. Out of Field Time',
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  // Filter out the target legend item if the target is 0
                  filter: function (legendItem, data) {
                    return legendItem.text != 'Target' || targetInFieldPercentage;
                  },
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },

              // Target Line
              annotation: {
                annotations: {
                  line1: {
                    type: 'line',
                    scaleID: 'y',
                    value: targetInFieldPercentage,
                    endValue: targetInFieldPercentage,
                    borderColor: customChartProperties.colorRed,
                    borderWidth: 2,
                    display: targetInFieldPercentage && targetInFieldPercentage !== 0,
                  },
                },
              },
            },
            scales: {
              y: {
                title: {
                  text: '% In Field',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                position: 'left',
                stacked: true,
                ticks: {
                  count: 5,
                  min: 0, // Minimum value
                  max: 100, // Maximum value
                  beginAtZero: true,
                  autoSkip: false,
                  stepSize: 20,
                  callback: function (val, index) {
                    return `${val} %`;
                  },
                },
              },
              x: {
                stacked: true,
                display: false,
              },

              // Don't show a seperate axis for the target line
              TargetAxis: {
                title: {
                  text: '',
                  display: false,
                },
                display: false,
              },
            },
          }}
        />
      </div>
    );
  }

  function filterControlComponents() {
    return [
      <IntelliSearchSelect
        key='operationEfficiencyVehicleFilter'
        id='op-eff-vehicle-name-select'
        name={'oppEffVehSNs'}
        allNoneEnabled
        multiple
        label='Vehicles'
        size='small'
        displayEmpty
        value={filters['oppEffVehSNs']}
        options={addCompareByVehicles(filterOptions.vehicles.vehicleSNs).map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(e) => {
          handleFilters(e);
        }}
      />,
      <IntelliSearchSelect
        key='operationEfficiencyVehicleTypeFilter'
        label='Vehicle Type'
        size='small'
        displayEmpty
        value={selectedMachineType}
        onChange={(e) => {
          setSelectedMachineType(e);
        }}
        options={machineTypeOptions}
      />,
    ];
  }

  function addCompareByVehicles(ogVehicles) {
    let combined = deepCopy(ogVehicles);
    if (compareByToggle && compareByOperationsData != null) {
      const mainSetVehicleSN = ogVehicles.map((option) => {
        return option.value;
      });
      combined = [
        ...ogVehicles,
        ...Object.keys(compareByOperationsData.vehiclesObject)
          .filter((key) => {
            return !mainSetVehicleSN.includes(key);
          })
          .map((opt) => {
            return {'text': vehicleSNDict[opt].name, 'value': opt};
          }),
      ];
      combined = [...combined].sort((optA, optB) => {
        return optA.text.localeCompare(optB.text);
      });
    }
    return combined;
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        {!inVsOutContent ? (
          <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
        ) : (
          <React.Fragment>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              {displayedAxis === 'Total' ? operationalEfficiencyTotalBarGraph() : operationalEfficiencyBarGraph()}
            </div>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              <KpiTable
                pageStates={props.pageStates}
                tableData={tableData}
                type={'operationalEff'}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard*/}
      <ScorecardSelectHolder
        autofillPageName={'Farming Operational Efficiency'}
        pageStates={{
          pageTargets: targetsObject,
          selectedMachineType: selectedMachineType,
          filters: filters,
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
          displayedAxis: displayedAxis
        }}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
      />

      {/* In Small screen mode Filters and Sort by display */}
      {smallScreen && (
        <StatsViewReportFiltersWrapper key={`operationEfficiencyFilter`}>
          {filterControlComponents()}
        </StatsViewReportFiltersWrapper>
      )}
      {!inVsOutContent ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* Graph and XY Axis Control */}
          <Grid container spacing={2} sx={{padding: 1}}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9}>
              <Box sx={StatsViewReportGraphAndControlStyle}>
                <Grid item xs={12} md={6} sx={{textAlign: 'center', alignItems: 'center'}}>
                  {displayedAxis === 'Total' ? operationalEfficiencyTotalBarGraph() : operationalEfficiencyBarGraph()}
                  <FormControl sx={{m: 1}}>
                    <InputLabel sx={{maxWidth: 'none'}}>Measure by</InputLabel>
                    <Select
                      sx={{padding: '3px'}}
                      size='small'
                      label='Measure by'
                      value={displayedAxis}
                      onChange={(e) => {
                        setDisplayedAxis(e.target.value);
                      }}
                    >
                      <MenuItem value={'Total'}>{'Total'}</MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Days'}>
                        {'Days'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Weeks'}>
                        {'Weeks'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Months'}>
                        {'Months'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Years'}>
                        {'Years'}
                      </MenuItem>
                      <MenuItem value={'Vehicle'}>{'Vehicle'}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} sx={{textAlign: 'center', alignItems: 'center'}}>
                  <KpiTable
                    pageStates={props.pageStates}
                    tableData={tableData}
                    type={'operationalEff'}
                  />
                </Grid>
              </Box>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'block',
                }}
              >
                <StatsViewReportSideControlWrapper filterControlComponents={filterControlComponents()} />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiOperationalEfficiency};
