import { data } from 'jquery';
import {
  customChartProperties,
  machineTypeMapping,
  roundDecimalPlaces,
  unitsAreaConversion,
  unitsLengthDisplayConversion,
  checkIfDeviceIsBesol,
} from '../../app/utils';

const timeAxes = ['Days', 'Weeks', 'Months', 'Years'];

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

function generateChartTimeXAxis(
  opsNotCombined,
  hourDollarRate,
  areaDollarRate,
  efficiencyFilters,
  displayedAxis,
  selectedInnerSubset,
  selectedZoneLevel,
  showDetailedTooltipData,
  unitsLength,
  unitsArea,
  unitsAreaSystem,
  acHrLabel,
  hrAcLabel,
  acreageLabel,
) {
  const opsCombined = {};
  opsNotCombined.forEach((rep) => {
    const combinedUnitObj = {
      'acreage': 0,
      'distance': 0,
      'duration': 0,
      'start': rep.start,
    };

    // Use the tasks object as this is most accurate object if task / zone / row spacing filters are applied
    Object.keys(rep.tasksObject).forEach((taskId) => {
      if (selectedInnerSubset[0] == 'Total' || selectedInnerSubset == 'Total' || selectedInnerSubset.includes(taskId)) {
        Object.keys(rep.tasksObject[taskId][selectedZoneLevel]).forEach((zoneName) => {
          const taskZoneData = rep.tasksObject[taskId][selectedZoneLevel][zoneName];

          combinedUnitObj['acreage'] += taskZoneData['acreage'];
          combinedUnitObj['distance'] += taskZoneData['distance'];
          combinedUnitObj['duration'] += taskZoneData['duration'];
        });
      }
    });

    combinedUnitObj['acPerHr'] =
      combinedUnitObj['duration'] != 0 ? combinedUnitObj['acreage'] / combinedUnitObj['duration'] : 0;
    combinedUnitObj['avgSpeed'] =
      combinedUnitObj['duration'] != 0 ? combinedUnitObj['distance'] / combinedUnitObj['duration'] : 0;
    opsCombined[rep.start] = combinedUnitObj;
  });

  const acHrs = [];
  const hrPerAc = [];
  const speeds = [];
  const totalDollars = [];

  let keys = Object.keys(opsCombined);

  keys.sort((keyA, keyB) => {
    const opsCombinedObjA = opsCombined[keyA];
    const opsCombinedObjB = opsCombined[keyB];
    if (displayedAxis == 'Ac/Hr' || displayedAxis == 'Ha/Hr') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? opsCombinedObjB.acPerHr - opsCombinedObjA.acPerHr
        : opsCombinedObjA.acPerHr - opsCombinedObjB.acPerHr;
    } else if (displayedAxis == 'Speed') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? opsCombinedObjB.avgSpeed - opsCombinedObjA.avgSpeed
        : opsCombinedObjA.avgSpeed - opsCombinedObjB.avgSpeed;
    } else if (displayedAxis == 'Hr/Ac' || displayedAxis == 'Hr/Ha') {
      if (opsCombinedObjB.acPerHr == 0 && opsCombinedObjA.acPerHr == 0) {
        return 0;
      } else if (opsCombinedObjA.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
      } else if (opsCombinedObjB.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
      } else {
        return efficiencyFilters.efficiencyZoneSort == 'Desc'
          ? 1 / opsCombinedObjB.acPerHr - 1 / opsCombinedObjA.acPerHr
          : 1 / opsCombinedObjA.acPerHr - 1 / opsCombinedObjB.acPerHr;
      }
    } else if (displayedAxis == 'Total $') {
      let calculatedDollarAmountA = 0;
      let calculatedDollarAmountB = 0;
      if (hourDollarRate != 0 && hourDollarRate != '') {
        calculatedDollarAmountA = hourDollarRate * opsCombinedObjA['duration'];
        calculatedDollarAmountB = hourDollarRate * opsCombinedObjB['duration'];
      }
      if (areaDollarRate  != 0 && areaDollarRate  != '') {
        calculatedDollarAmountA = areaDollarRate  * opsCombinedObjA['acreage'];
        calculatedDollarAmountB = areaDollarRate  * opsCombinedObjB['acreage'];
      }
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? calculatedDollarAmountB - calculatedDollarAmountA
        : calculatedDollarAmountA - calculatedDollarAmountB;
    }
  });

  if (efficiencyFilters.efficiencyZoneSort == 'Chronological') {
    keys.sort((keyA, keyB) => {
      const opsCombinedObjA = opsCombined[keyA];
      const opsCombinedObjB = opsCombined[keyB];
      return opsCombinedObjA.start > opsCombinedObjB.start ? 1 : -1;
    });
  }

  if (efficiencyFilters.efficiencyZoneCount > 0) {
    if (keys.length > efficiencyFilters.efficiencyZoneCount) {
      keys = keys.slice(0, efficiencyFilters.efficiencyZoneCount);
    }
  }

  const timeInfoObject = {};

  keys.forEach((key, idx) => {
    timeInfoObject[idx] = opsCombined[key];
    if (unitsAreaSystem == 'acre') {
      acHrs.push(opsCombined[key]['acPerHr']);
      hrPerAc.push(opsCombined[key]['acPerHr'] != 0 ? 1 / opsCombined[key]['acPerHr'] : 0);
    } else {
      const acPerHrConverted = unitsAreaConversion(opsCombined[key]['acPerHr'], 'ha', 'ac');
      acHrs.push(acPerHrConverted);
      hrPerAc.push(acPerHrConverted != 0 ? 1 / acPerHrConverted : 0);
    }

    speeds.push(opsCombined[key]['avgSpeed']);
    let calculatedDollarAmount = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmount = hourDollarRate * opsCombined[key]['duration'];
    }
    if (areaDollarRate  != 0 && areaDollarRate  != '') {
      const areaValue =
        unitsAreaSystem == 'acre'
          ? opsCombined[key]['acreage']
          : unitsAreaConversion(opsCombined[key]['acreage'], 'ha', 'ac');
      calculatedDollarAmount = areaDollarRate  * areaValue;
    }
    totalDollars.push(calculatedDollarAmount);
  });

  const keysFormatted = keys.map((key) => {
    return key.slice(0, 10);
  });

  const chartDataToReturn = {
    labels: keysFormatted,
    datasets: [
      {
        label: acHrLabel,
        displayedAxis: acHrLabel,
        data: acHrs,
        borderWidth: 1,
        yAxisID: acHrLabel.replace('/', ''),
        backgroundColor: customChartProperties.colorGreen,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${acHrLabel}`];
              if (showDetailedTooltipData) {
                const timeData = timeInfoObject[context.dataIndex];
                if (timeData?.acreage && timeData?.duration) {
                  const areaValue =
                    unitsAreaSystem == 'acre' ? timeData.acreage : unitsAreaConversion(timeData.acreage, 'ha', 'ac');
                  const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(areaValue, 2)} ${unitsArea}`;
                  const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(timeData.duration, 2)} hr`;
                  tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                }
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: hrAcLabel,
        displayedAxis: hrAcLabel,
        data: hrPerAc,
        borderWidth: 1,
        yAxisID: hrAcLabel.replace('/', ''),
        backgroundColor: customChartProperties.colorGreen,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${hrAcLabel}`];
              if (showDetailedTooltipData) {
                const timeData = timeInfoObject[context.dataIndex];
                if (timeData?.acreage && timeData?.duration) {
                  const areaValue =
                    unitsAreaSystem == 'acre' ? timeData.acreage : unitsAreaConversion(timeData.acreage, 'ha', 'ac');
                  const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(areaValue, 2)} ${unitsArea}`;
                  const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(timeData.duration, 2)} hr`;
                  tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                }
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: 'Speed',
        data: speeds,
        borderWidth: 1,
        yAxisID: 'Speed',
        displayedAxis: 'Speed', // Need this to display the correct axis in measure by
        backgroundColor: customChartProperties.colorGreen,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} ${unitsLength}/hr`;
            },
          },
        },
      },
      {
        label: 'Total $',
        data: totalDollars,
        borderWidth: 1,
        yAxisID: 'Total $',
        displayedAxis: 'Total $', // Need this to display the correct axis in measure by
        backgroundColor: customChartProperties.colorGreen,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
            },
          },
        },
      },
      {
        type: 'line',
        label: 'Target',
        backgroundColor: 'red',
        data: [],
        yAxisID: 'TargetAxis',
      },
    ],
  };

  return chartDataToReturn;
}

function generateChartTaskXAxis(
  taskInfoObject, // Should be already pre-filtered
  hourDollarRate,
  areaDollarRate,
  efficiencyFilters,
  displayedAxis,
  selectedInnerSubset,
  selectedZoneLevel,
  showDetailedTooltipData,
  unitsLength,
  unitsArea,
  unitsAreaSystem,
  acHrLabel,
  hrAcLabel,
  acreageLabel,
  taskConfigDict,
  sortingDataset,
  isCompareBy
) {
  let keys = [];
  const opsCombined = {};
  const opsCombinedSortingObject = {}
  Object.keys(taskInfoObject).forEach((taskId) => {

    // Filter out tasks selected in the task filter
    if (selectedInnerSubset !== 'Total' && selectedInnerSubset[0] !== 'Total' && !selectedInnerSubset.includes(taskId)) {
      return;
    }

    // Get task name
    let taskName = 'No Task Found';
    if (typeof taskConfigDict[taskId] !== 'undefined') {
      taskName = taskConfigDict[taskId].name;
    }
    
    // Stats
    const combinedUnitObj = {
      'acreage': 0,
      'distance': 0,
      'duration': 0,
    };

    const sortingCombinedUnitObj = {
      'acreage': 0,
      'distance': 0,
      'duration': 0,
    }

    // Use the applied zone level to filter based on the zone level selections if applicable
    if (selectedInnerSubset[0] == 'Total' || selectedInnerSubset == 'Total' || selectedInnerSubset.includes(taskId)) {
      Object.keys(taskInfoObject[taskId][selectedZoneLevel]).forEach((zoneName) => {
        const taskZoneData = taskInfoObject[taskId][selectedZoneLevel][zoneName];
        combinedUnitObj['acreage'] += taskZoneData['acreage'];
        combinedUnitObj['distance'] += taskZoneData['distance'];
        combinedUnitObj['duration'] += taskZoneData['duration'];
      });

      Object.keys(sortingDataset[taskId][selectedZoneLevel]).forEach((zoneName) => {
        const taskZoneData = sortingDataset[taskId][selectedZoneLevel][zoneName];
        sortingCombinedUnitObj['acreage'] += taskZoneData['acreage'];
        sortingCombinedUnitObj['distance'] += taskZoneData['distance'];
        sortingCombinedUnitObj['duration'] += taskZoneData['duration'];
      });
    }

    // Add rate units
    combinedUnitObj['acPerHr'] = combinedUnitObj['duration'] != 0 ? combinedUnitObj['acreage'] / combinedUnitObj['duration'] : 0;
    combinedUnitObj['avgSpeed'] = combinedUnitObj['duration'] != 0 ? combinedUnitObj['distance'] / combinedUnitObj['duration'] : 0;

    // For sorting obj as well
    sortingCombinedUnitObj['acPerHr'] = sortingCombinedUnitObj['duration'] != 0 ? sortingCombinedUnitObj['acreage'] / sortingCombinedUnitObj['duration'] : 0;
    sortingCombinedUnitObj['avgSpeed'] = sortingCombinedUnitObj['duration'] != 0 ? sortingCombinedUnitObj['distance'] / sortingCombinedUnitObj['duration'] : 0;
    
    // Index by task name
    opsCombined[taskName] = combinedUnitObj;
    opsCombinedSortingObject[taskName] = sortingCombinedUnitObj;
    keys.push(taskName);
  });

  // Data values
  const acHrs = [];
  const hrPerAc = [];
  const speeds = [];
  const totalDollars = [];
  const taskDataObject = {};

  // Sort keys based on selection
  keys.sort((keyA, keyB) => {
    // Sort using sorting object
    const opsCombinedObjA = opsCombinedSortingObject[keyA];
    const opsCombinedObjB = opsCombinedSortingObject[keyB];
    if (displayedAxis == 'Ac/Hr' || displayedAxis == 'Ha/Hr') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? opsCombinedObjB.acPerHr - opsCombinedObjA.acPerHr
        : opsCombinedObjA.acPerHr - opsCombinedObjB.acPerHr;
    } else if (displayedAxis == 'Speed') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? opsCombinedObjB.avgSpeed - opsCombinedObjA.avgSpeed
        : opsCombinedObjA.avgSpeed - opsCombinedObjB.avgSpeed;
    } else if (displayedAxis == 'Hr/Ac' || displayedAxis == 'Hr/Ha') {
      if (opsCombinedObjB.acPerHr == 0 && opsCombinedObjA.acPerHr == 0) {
        return 0;
      } else if (opsCombinedObjA.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
      } else if (opsCombinedObjB.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
      } else {
        return efficiencyFilters.efficiencyZoneSort == 'Desc'
          ? 1 / opsCombinedObjB.acPerHr - 1 / opsCombinedObjA.acPerHr
          : 1 / opsCombinedObjA.acPerHr - 1 / opsCombinedObjB.acPerHr;
      }
    } else if (displayedAxis == 'Total $') {
      let calculatedDollarAmountA = 0;
      let calculatedDollarAmountB = 0;
      if (hourDollarRate != 0 && hourDollarRate != '') {
        calculatedDollarAmountA = hourDollarRate * opsCombinedObjA['duration'];
        calculatedDollarAmountB = hourDollarRate * opsCombinedObjB['duration'];
      }
      if (areaDollarRate  != 0 && areaDollarRate  != '') {
        calculatedDollarAmountA = areaDollarRate  * opsCombinedObjA['acreage'];
        calculatedDollarAmountB = areaDollarRate  * opsCombinedObjB['acreage'];
      }
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? calculatedDollarAmountB - calculatedDollarAmountA
        : calculatedDollarAmountA - calculatedDollarAmountB;
    }
  });

  if (efficiencyFilters.efficiencyZoneSort == 'Chronological') {
    keys.sort((keyA, keyB) => {
      const opsCombinedObjA = opsCombinedSortingObject[keyA];
      const opsCombinedObjB = opsCombinedSortingObject[keyB];
      return opsCombinedObjA.start > opsCombinedObjB.start ? 1 : -1;
    });
  }

  if (efficiencyFilters.efficiencyZoneCount > 0) {
    if (keys.length > efficiencyFilters.efficiencyZoneCount) {
      keys = keys.slice(0, efficiencyFilters.efficiencyZoneCount);
    }
  }

  // Check unit conversions and dollar rates
  keys.forEach((key, idx) => {
    taskDataObject[idx] = opsCombined[key];
    if (unitsAreaSystem == 'acre') {
      acHrs.push(opsCombined[key]['acPerHr']);
      hrPerAc.push(opsCombined[key]['acPerHr'] != 0 ? 1 / opsCombined[key]['acPerHr'] : 0);
    } else {
      const acPerHrConverted = unitsAreaConversion(opsCombined[key]['acPerHr'], 'ha', 'ac');
      acHrs.push(acPerHrConverted);
      hrPerAc.push(acPerHrConverted != 0 ? 1 / acPerHrConverted : 0);
    }

    speeds.push(opsCombined[key]['avgSpeed']);
    let calculatedDollarAmount = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmount = hourDollarRate * opsCombined[key]['duration'];
    }
    if (areaDollarRate  != 0 && areaDollarRate  != '') {
      const areaValue =
        unitsAreaSystem == 'acre'
          ? opsCombined[key]['acreage']
          : unitsAreaConversion(opsCombined[key]['acreage'], 'ha', 'ac');
      calculatedDollarAmount = areaDollarRate  * areaValue;
    }
    totalDollars.push(calculatedDollarAmount);
  });

  const chartDataToReturn = {
    labels: keys,
    datasets: [
      {
        label: !isCompareBy ? acHrLabel : "Comparison " + acHrLabel,
        displayedAxis: acHrLabel,
        data: acHrs,
        borderWidth: 1,
        yAxisID: acHrLabel.replace('/', ''),
        backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${acHrLabel}`];
              if (showDetailedTooltipData) {
                const timeData = taskDataObject[context.dataIndex];
                if (timeData?.acreage && timeData?.duration) {
                  const areaValue =
                    unitsAreaSystem == 'acre' ? timeData.acreage : unitsAreaConversion(timeData.acreage, 'ha', 'ac');
                  const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(areaValue, 2)} ${unitsArea}`;
                  const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(timeData.duration, 2)} hr`;
                  tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                }
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label:  !isCompareBy ? hrAcLabel : "Comparison " + hrAcLabel,
        displayedAxis: hrAcLabel,
        data: hrPerAc,
        borderWidth: 1,
        yAxisID: hrAcLabel.replace('/', ''),
        backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${hrAcLabel}`];
              if (showDetailedTooltipData) {
                const timeData = taskDataObject[context.dataIndex];
                if (timeData?.acreage && timeData?.duration) {
                  const areaValue =
                    unitsAreaSystem == 'acre' ? timeData.acreage : unitsAreaConversion(timeData.acreage, 'ha', 'ac');
                  const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(areaValue, 2)} ${unitsArea}`;
                  const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(timeData.duration, 2)} hr`;
                  tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                }
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: !isCompareBy ? 'Speed' : "Comparison " + 'Speed',
        data: speeds,
        borderWidth: 1,
        yAxisID: 'Speed',
        displayedAxis: 'Speed', // Need this to display the correct axis in measure by
        backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} ${unitsLength}/hr`;
            },
          },
        },
      },
      {
        label:  !isCompareBy ? 'Total $' : "Comparison " + 'Total $',
        data: totalDollars,
        borderWidth: 1,
        yAxisID: 'Total $',
        displayedAxis: 'Total $', // Need this to display the correct axis in measure by
        backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
            },
          },
        },
      },
      {
        type: 'line',
        label: 'Target',
        backgroundColor: 'red',
        data: [],
        yAxisID: 'TargetAxis',
      },
    ],
  };

  return chartDataToReturn;
}

function generateChartVehicleXAxis(
  efficiencyVehiclesObject, // Should be already pre-filtered
  hourDollarRate,
  areaDollarRate,
  efficiencyFilters,
  displayedAxis,
  selectedInnerSubset,
  selectedZoneLevel,
  showDetailedTooltipData,
  unitsLength,
  unitsArea,
  unitsAreaSystem,
  acHrLabel,
  hrAcLabel,
  acreageLabel,
  taskConfigDict,
  sortingDataset,
  isCompareBy
) {

  let keys = [];
  const opsCombined = {};
  const opsCombinedSortingObject = {}
  Object.keys(efficiencyVehiclesObject).forEach((vehiclesn) => {

    // Filter out tasks selected in the task filter
    if (selectedInnerSubset !== 'Total' && selectedInnerSubset[0] !== 'Total' && !selectedInnerSubset.includes(vehiclesn)) {
      return;
    }

    // Get vehicle name
    const vehicleName = efficiencyVehiclesObject[vehiclesn]['vehicleName'];
    
    // Stats
    const combinedUnitObj = {
      'acreage': efficiencyVehiclesObject[vehiclesn]['acreage'],
      'distance': efficiencyVehiclesObject[vehiclesn]['distance'],
      'duration': efficiencyVehiclesObject[vehiclesn]['drivingDuration'], // Use drivingDuration or totalDuration ?
      'acPerHr': efficiencyVehiclesObject[vehiclesn]['acPerHr'],
      'avgSpeed': efficiencyVehiclesObject[vehiclesn]['avgSpeed'],
    };

    const sortingCombinedUnitObj = {
      'acreage': sortingDataset[vehiclesn]['acreage'],
      'distance': sortingDataset[vehiclesn]['distance'],
      'duration': sortingDataset[vehiclesn]['drivingDuration'], // Use drivingDuration or totalDuration ?
      'acPerHr': sortingDataset[vehiclesn]['acPerHr'],
      'avgSpeed': sortingDataset[vehiclesn]['avgSpeed'],
    };

    // Index by task name
    opsCombined[vehicleName] = combinedUnitObj;
    opsCombinedSortingObject[vehicleName] = sortingCombinedUnitObj
    keys.push(vehicleName);
  });

  // Data values
  const acHrs = [];
  const hrPerAc = [];
  const speeds = [];
  const totalDollars = [];
  const vehicleDataObject = {};

  // Sort keys based on selection
  keys.sort((keyA, keyB) => {
    const opsCombinedObjA = opsCombinedSortingObject[keyA];
    const opsCombinedObjB = opsCombinedSortingObject[keyB];
    if (displayedAxis == 'Ac/Hr' || displayedAxis == 'Ha/Hr') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? opsCombinedObjB.acPerHr - opsCombinedObjA.acPerHr
        : opsCombinedObjA.acPerHr - opsCombinedObjB.acPerHr;
    } else if (displayedAxis == 'Speed') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? opsCombinedObjB.avgSpeed - opsCombinedObjA.avgSpeed
        : opsCombinedObjA.avgSpeed - opsCombinedObjB.avgSpeed;
    } else if (displayedAxis == 'Hr/Ac' || displayedAxis == 'Hr/Ha') {
      if (opsCombinedObjB.acPerHr == 0 && opsCombinedObjA.acPerHr == 0) {
        return 0;
      } else if (opsCombinedObjA.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
      } else if (opsCombinedObjB.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
      } else {
        return efficiencyFilters.efficiencyZoneSort == 'Desc'
          ? 1 / opsCombinedObjB.acPerHr - 1 / opsCombinedObjA.acPerHr
          : 1 / opsCombinedObjA.acPerHr - 1 / opsCombinedObjB.acPerHr;
      }
    } else if (displayedAxis == 'Total $') {
      let calculatedDollarAmountA = 0;
      let calculatedDollarAmountB = 0;
      if (hourDollarRate != 0 && hourDollarRate != '') {
        calculatedDollarAmountA = hourDollarRate * opsCombinedObjA['duration'];
        calculatedDollarAmountB = hourDollarRate * opsCombinedObjB['duration'];
      }
      if (areaDollarRate  != 0 && areaDollarRate  != '') {
        calculatedDollarAmountA = areaDollarRate  * opsCombinedObjA['acreage'];
        calculatedDollarAmountB = areaDollarRate  * opsCombinedObjB['acreage'];
      }
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? calculatedDollarAmountB - calculatedDollarAmountA
        : calculatedDollarAmountA - calculatedDollarAmountB;
    }
  });

  if (efficiencyFilters.efficiencyZoneSort == 'Chronological') {
    keys.sort((keyA, keyB) => {
      const opsCombinedObjA = opsCombinedSortingObject[keyA];
      const opsCombinedObjB = opsCombinedSortingObject[keyB];
      return opsCombinedObjA.start > opsCombinedObjB.start ? 1 : -1;
    });
  }

  if (efficiencyFilters.efficiencyZoneCount > 0) {
    if (keys.length > efficiencyFilters.efficiencyZoneCount) {
      keys = keys.slice(0, efficiencyFilters.efficiencyZoneCount);
    }
  }

  // Check unit conversions and dollar rates
  keys.forEach((key, idx) => {
    vehicleDataObject[idx] = opsCombined[key];
    if (unitsAreaSystem == 'acre') {
      acHrs.push(opsCombined[key]['acPerHr']);
      hrPerAc.push(opsCombined[key]['acPerHr'] != 0 ? 1 / opsCombined[key]['acPerHr'] : 0);
    } else {
      const acPerHrConverted = unitsAreaConversion(opsCombined[key]['acPerHr'], 'ha', 'ac');
      acHrs.push(acPerHrConverted);
      hrPerAc.push(acPerHrConverted != 0 ? 1 / acPerHrConverted : 0);
    }

    speeds.push(opsCombined[key]['avgSpeed']);
    let calculatedDollarAmount = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmount = hourDollarRate * opsCombined[key]['duration'];
    }
    if (areaDollarRate  != 0 && areaDollarRate  != '') {
      const areaValue =
        unitsAreaSystem == 'acre'
          ? opsCombined[key]['acreage']
          : unitsAreaConversion(opsCombined[key]['acreage'], 'ha', 'ac');
      calculatedDollarAmount = areaDollarRate  * areaValue;
    }
    totalDollars.push(calculatedDollarAmount);
  });

  const chartDataToReturn = {
    labels: keys,
    datasets: [
      {
        label: !isCompareBy ? acHrLabel : "Comparison " + acHrLabel,
        displayedAxis: acHrLabel,
        data: acHrs,
        borderWidth: 1,
        yAxisID: acHrLabel.replace('/', ''),
        backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${acHrLabel}`];
              if (showDetailedTooltipData) {
                const timeData = vehicleDataObject[context.dataIndex];
                if (timeData?.acreage && timeData?.duration) {
                  const areaValue =
                    unitsAreaSystem == 'acre' ? timeData.acreage : unitsAreaConversion(timeData.acreage, 'ha', 'ac');
                  const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(areaValue, 2)} ${unitsArea}`;
                  const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(timeData.duration, 2)} hr`;
                  tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                }
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: !isCompareBy ? hrAcLabel : "Comparison " + hrAcLabel,
        displayedAxis: hrAcLabel,
        data: hrPerAc,
        borderWidth: 1,
        yAxisID: hrAcLabel.replace('/', ''),
        backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${hrAcLabel}`];
              if (showDetailedTooltipData) {
                const timeData = vehicleDataObject[context.dataIndex];
                if (timeData?.acreage && timeData?.duration) {
                  const areaValue =
                    unitsAreaSystem == 'acre' ? timeData.acreage : unitsAreaConversion(timeData.acreage, 'ha', 'ac');
                  const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(areaValue, 2)} ${unitsArea}`;
                  const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(timeData.duration, 2)} hr`;
                  tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                }
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: !isCompareBy ? 'Speed' : 'Comparison Speed',
        data: speeds,
        borderWidth: 1,
        yAxisID: 'Speed',
        displayedAxis: 'Speed', // Need this to display the correct axis in measure by
        backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 1)} ${unitsLength}/hr`;
            },
          },
        },
      },
      {
        label: !isCompareBy ? 'Total $' : 'Comparison Total $',
        data: totalDollars,
        borderWidth: 1,
        yAxisID: 'Total $',
        displayedAxis: 'Total $', // Need this to display the correct axis in measure by
        backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
            },
          },
        },
      },
      {
        type: 'line',
        label: 'Target',
        backgroundColor: 'red',
        data: [],
        yAxisID: 'TargetAxis',
      },
    ],
  };

  return chartDataToReturn;
}

export function genEfficiencyData(
  taskInfoObject,
  blocksObj,
  fieldsObj,
  regionsObj,
  efficiencyVehiclesObject,
  efficiencyFilters,
  displayedAxis,
  unitsLengthSystem,
  unitsAreaSystem,
  showDetailedTooltipData,
  hourDollarRate,
  areaDollarRate ,
  taskConfigDict,
  opsByDay,
  opsByWeek,
  opsByMonth,
  opsByYear,
  selectedZoneLevel,
  selectedInnerSubset,
  sortingDataset,
  isCompareBy = false
) {
  // Get units
  let unitsLength = 'km';
  let unitsSpeed = 'kph';
  if (unitsLengthSystem == 'imperial') {
    unitsLength = 'mi';
    unitsSpeed = 'mph';
  }
  let unitsArea = 'ac';
  if (unitsAreaSystem == 'hectare') {
    unitsArea = 'ha';
  }
  const acHrLabel = unitsAreaSystem == 'hectare' ? 'Ha/Hr' : 'Ac/Hr';
  const hrAcLabel = unitsAreaSystem == 'hectare' ? 'Hr/Ha' : 'Hr/Ac';
  const acreageLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';

  const opsByDayArray = Object.keys(opsByDay).map((dayKey) => {
    return opsByDay[dayKey];
  });
  const opsByWeekArray = Object.keys(opsByWeek).map((weekKey) => {
    return opsByWeek[weekKey];
  });
  const opsByMonthArray = Object.keys(opsByMonth).map((monthKey) => {
    return opsByMonth[monthKey];
  });
  const opsByYearArray = Object.keys(opsByYear).map((yearKey) => {
    return opsByYear[yearKey];
  });
  const effDataDays = generateChartTimeXAxis(
    opsByDayArray,
    hourDollarRate,
    areaDollarRate ,
    efficiencyFilters,
    displayedAxis,
    selectedInnerSubset,
    selectedZoneLevel,
    showDetailedTooltipData,
    unitsLength,
    unitsArea,
    unitsAreaSystem,
    acHrLabel,
    hrAcLabel,
    acreageLabel,
  );
  const effDataWeeks = generateChartTimeXAxis(
    opsByWeekArray,
    hourDollarRate,
    areaDollarRate ,
    efficiencyFilters,
    displayedAxis,
    selectedInnerSubset,
    selectedZoneLevel,
    showDetailedTooltipData,
    unitsLength,
    unitsArea,
    unitsAreaSystem,
    acHrLabel,
    hrAcLabel,
    acreageLabel,
  );
  const effDataMonths = generateChartTimeXAxis(
    opsByMonthArray,
    hourDollarRate,
    areaDollarRate ,
    efficiencyFilters,
    displayedAxis,
    selectedInnerSubset,
    selectedZoneLevel,
    showDetailedTooltipData,
    unitsLength,
    unitsArea,
    unitsAreaSystem,
    acHrLabel,
    hrAcLabel,
    acreageLabel,
  );
  const effDataYears = generateChartTimeXAxis(
    opsByYearArray,
    hourDollarRate,
    areaDollarRate ,
    efficiencyFilters,
    displayedAxis,
    selectedInnerSubset,
    selectedZoneLevel,
    showDetailedTooltipData,
    unitsLength,
    unitsArea,
    unitsAreaSystem,
    acHrLabel,
    hrAcLabel,
    acreageLabel,
  );

  // Data broken down by task
  const effDataTask = generateChartTaskXAxis(
    taskInfoObject,
    hourDollarRate,
    areaDollarRate ,
    efficiencyFilters,
    displayedAxis,
    selectedInnerSubset,
    selectedZoneLevel,
    showDetailedTooltipData,
    unitsLength,
    unitsArea,
    unitsAreaSystem,
    acHrLabel,
    hrAcLabel,
    acreageLabel,
    taskConfigDict,
    sortingDataset.efficiencyTasksObject,
    isCompareBy
  );

  // Data broken down by vehicle
  const effDataVehicle = generateChartVehicleXAxis(
    efficiencyVehiclesObject,
    hourDollarRate,
    areaDollarRate ,
    efficiencyFilters,
    displayedAxis,
    selectedInnerSubset,
    selectedZoneLevel,
    showDetailedTooltipData,
    unitsLength,
    unitsArea,
    unitsAreaSystem,
    acHrLabel,
    hrAcLabel,
    acreageLabel,
    taskConfigDict,
    sortingDataset.efficiencyVehiclesObject,
    isCompareBy
  );

  const effData = {};
  effData['Days'] = effDataDays;
  effData['Weeks'] = effDataWeeks;
  effData['Months'] = effDataMonths;
  effData['Years'] = effDataYears;
  effData['Task'] = effDataTask;
  effData['Vehicle'] = effDataVehicle;
  const taskIds = Object.keys(taskInfoObject);

  const zoneTypes = ['Block', 'Field', 'Region'];
  taskIds.forEach((taskId) => {
    const taskInfo = taskInfoObject[taskId];
    let taskName = '';
    if (typeof taskConfigDict[taskId] !== 'undefined') {
      taskName = taskConfigDict[taskId].name;
    }
    zoneTypes.forEach((zoneType) => {
      let zones = Object.keys(taskInfo[zoneType]);

      // Sort task zones
      zones.sort((a, b) => {
        const zoneTaskInfoA = sortingDataset.efficiencyTasksObject[taskId][zoneType][a];
        const zoneTaskInfoB = sortingDataset.efficiencyTasksObject[taskId][zoneType][b];
        let calculatedDollarAmountA = 0;
        let calculatedDollarAmountB = 0;
        if (hourDollarRate != 0 && hourDollarRate != '') {
          calculatedDollarAmountA = hourDollarRate * zoneTaskInfoA.duration;
          calculatedDollarAmountB = hourDollarRate * zoneTaskInfoB.duration;
        }
        if (areaDollarRate  != 0 && areaDollarRate  != '') {
          calculatedDollarAmountA = areaDollarRate  * zoneTaskInfoA.acreage;
          calculatedDollarAmountB = areaDollarRate  * zoneTaskInfoB.acreage;
        }
        if (displayedAxis == 'Ac/Hr' || displayedAxis == 'Ha/Hr') {
          return efficiencyFilters.efficiencyZoneSort == 'Desc'
            ? zoneTaskInfoB.acPerHr - zoneTaskInfoA.acPerHr
            : zoneTaskInfoA.acPerHr - zoneTaskInfoB.acPerHr;
        } else if (displayedAxis == 'Speed') {
          return efficiencyFilters.efficiencyZoneSort == 'Desc'
            ? zoneTaskInfoB.avgSpeed - zoneTaskInfoA.avgSpeed
            : zoneTaskInfoA.avgSpeed - zoneTaskInfoB.avgSpeed;
        } else if (displayedAxis == 'Hr/Ac' || displayedAxis == 'Hr/Ha') {
          if (zoneTaskInfoB.acPerHr == 0 && zoneTaskInfoA.acPerHr == 0) {
            return 0;
          } else if (zoneTaskInfoA.acPerHr == 0) {
            return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
          } else if (zoneTaskInfoB.acPerHr == 0) {
            return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
          } else {
            return efficiencyFilters.efficiencyZoneSort == 'Desc'
              ? 1 / zoneTaskInfoB.acPerHr - 1 / zoneTaskInfoA.acPerHr
              : 1 / zoneTaskInfoA.acPerHr - 1 / zoneTaskInfoB.acPerHr;
          }
        } else if (displayedAxis == 'Total $') {
          return efficiencyFilters.efficiencyZoneSort == 'Desc'
            ? calculatedDollarAmountB - calculatedDollarAmountA
            : calculatedDollarAmountA - calculatedDollarAmountB;
        }
      });
      if (efficiencyFilters.efficiencyZoneSort == 'Alphabetical') {
        zones.sort((a, b) => {
          return a.localeCompare(b);
        });
      }
      if (efficiencyFilters.efficiencyZoneCount > 0) {
        if (zones.length > efficiencyFilters.efficiencyZoneCount) {
          zones = zones.slice(0, efficiencyFilters.efficiencyZoneCount);
        }
      }

      const acHrs = [];
      const hrPerAc = [];
      const speeds = [];
      const totalDollars = [];
      zones.forEach((zone) => {
        const zoneTaskInfo = taskInfo[zoneType][zone];
        let calculatedDollarAmount = 0;
        if (hourDollarRate != 0 && hourDollarRate != '') {
          calculatedDollarAmount = hourDollarRate * zoneTaskInfo.duration;
        }
        if (areaDollarRate  != 0 && areaDollarRate  != '') {
          const areaValue =
            unitsAreaSystem == 'acre' ? zoneTaskInfo.acreage : unitsAreaConversion(zoneTaskInfo.acreage, 'ha', 'ac');
          calculatedDollarAmount = areaDollarRate  * areaValue;
        }

        // Convert units if needed
        if (unitsAreaSystem == 'acre') {
          acHrs.push(zoneTaskInfo.acPerHr);
          hrPerAc.push(1 / zoneTaskInfo.acPerHr);
        } else {
          const acPerHrConverted = unitsAreaConversion(zoneTaskInfo.acPerHr, 'ha', 'ac');
          acHrs.push(acPerHrConverted);
          hrPerAc.push(1 / acPerHrConverted);
        }

        speeds.push(unitsLengthDisplayConversion(zoneTaskInfo.avgSpeed, unitsLength));
        totalDollars.push(calculatedDollarAmount);
      });

      if (!Object.prototype.hasOwnProperty.call(effData, zoneType)) {
        effData[zoneType] = {};
      }

      effData[zoneType][taskId] = {
        labels: zones,
        datasets: [
          {
            label: !isCompareBy ? acHrLabel : 'Comparison ' + acHrLabel,
            displayedAxis: acHrLabel,
            data: acHrs,
            borderWidth: 1,
            yAxisID: acHrLabel.replace('/', ''),
            backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
            tooltip: {
              callbacks: {
                label: (context) => {
                  const formattedValue = context.formattedValue;
                  const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${acHrLabel}`];
                  if (showDetailedTooltipData) {
                    const taskData = taskInfo[zoneType][zones[context.dataIndex]];
                    if (taskData?.acreage && taskData?.duration) {
                      const areaValue =
                        unitsAreaSystem == 'acre'
                          ? taskData.acreage
                          : unitsAreaConversion(taskData.acreage, 'ha', 'ac');
                      const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(
                        areaValue,
                        2
                      )} ${unitsArea}`;
                      const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                      tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                    }

                    if (zoneType == 'Block') {
                      let rowSpacingString = `Not Assigned`;
                      const blockObj = blocksObj[zones[context.dataIndex]];
                      if (blockObj.rowSpacingMeters) {
                        rowSpacingString =
                          unitsLengthSystem == 'imperial'
                            ? `${roundDecimalPlaces(
                                unitsLengthDisplayConversion(blockObj.rowSpacingMeters, 'ft'),
                                2
                              )} ft`
                            : `${roundDecimalPlaces(blockObj.rowSpacingMeters, 2)} m`;
                      }
                      const rowSpacingTooltip = `Row Spacing: ${rowSpacingString}`;
                      tooltipRows.push(rowSpacingTooltip);
                    }
                  }
                  return tooltipRows;
                },
              },
            },
          },
          {
            label: !isCompareBy ? hrAcLabel : 'Comparison ' + hrAcLabel,
            displayedAxis: hrAcLabel,
            data: hrPerAc,
            borderWidth: 1,
            yAxisID: hrAcLabel.replace('/', ''),
            backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
            tooltip: {
              callbacks: {
                label: (context) => {
                  const formattedValue = context.formattedValue;
                  const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${hrAcLabel}`];
                  if (showDetailedTooltipData) {
                    const taskData = taskInfo[zoneType][zones[context.dataIndex]];
                    if (taskData?.acreage && taskData?.duration) {
                      const areaValue =
                        unitsAreaSystem == 'acre'
                          ? taskData.acreage
                          : unitsAreaConversion(taskData.acreage, 'ha', 'ac');
                      const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(
                        areaValue,
                        2
                      )} ${unitsArea}`;
                      const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                      tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                    }
                    if (zoneType == 'Block') {
                      let rowSpacingString = `Not Assigned`;
                      const blockObj = blocksObj[zones[context.dataIndex]];
                      if (blockObj.rowSpacingMeters) {
                        rowSpacingString =
                          unitsLengthSystem == 'imperial'
                            ? `${roundDecimalPlaces(
                                unitsLengthDisplayConversion(blockObj.row_rowSpacingMetersspacing_meters, 'ft'),
                                2
                              )} ft`
                            : `${roundDecimalPlaces(blockObj.rowSpacingMeters, 2)} m`;
                      }
                      const rowSpacingTooltip = `Row Spacing: ${rowSpacingString}`;
                      tooltipRows.push(rowSpacingTooltip);
                    }
                  }
                  return tooltipRows;
                },
              },
            },
          },
          {
            label: !isCompareBy ? 'Speed' : 'Comparison Speed',
            displayedAxis: 'Speed',
            data: speeds,
            borderWidth: 1,
            yAxisID: 'Speed',
            backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
            tooltip: {
              callbacks: {
                label: (context) => {
                  const formattedValue = context.formattedValue;
                  return ` ${roundDecimalPlaces(formattedValue, 1)} ${unitsSpeed}`;
                },
              },
            },
          },
          {
            label: !isCompareBy ? 'Total $' : 'Comparison Total $' ,
            displayedAxis: 'Total $',
            data: totalDollars,
            borderWidth: 1,
            yAxisID: 'Total $',
            backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
            tooltip: {
              callbacks: {
                label: (context) => {
                  const formattedValue = context.formattedValue;
                  return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
                },
              },
            },
          },
          {
            type: 'line',
            label: 'Target',
            displayedAxis: 'Target',
            backgroundColor: 'red',
            data: [],
            yAxisID: 'TargetAxis',
          },
        ],
      };
    });
  });

  // Add totals task agnostic for blocks
  let blocks = Object.keys(blocksObj);
  let acHrs = [];
  let hrPerAc = [];
  let speeds = [];
  let totalDollars = [];
  // Sort Fields totals
  blocks.sort((a, b) => {
    const blockA = sortingDataset.efficiencyBlocksObject[a];
    const blockB = sortingDataset.efficiencyBlocksObject[b];
    let calculatedDollarAmountA = 0;
    let calculatedDollarAmountB = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmountA = hourDollarRate * blockA.duration;
      calculatedDollarAmountB = hourDollarRate * blockB.duration;
    }
    if (areaDollarRate  != 0 && areaDollarRate  != '') {
      calculatedDollarAmountA = areaDollarRate  * blockA.acreage;
      calculatedDollarAmountB = areaDollarRate  * blockB.acreage;
    }
    if (displayedAxis == 'Ac/Hr' || displayedAxis == 'Ha/Hr') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? blockB.acPerHr - blockA.acPerHr
        : blockA.acPerHr - blockB.acPerHr;
    } else if (displayedAxis == 'Speed') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? blockB.avgSpeed - blockA.avgSpeed
        : blockA.avgSpeed - blockB.avgSpeed;
    } else if (displayedAxis == 'Hr/Ac' || displayedAxis == 'Hr/Ha') {
      if (blockB.acPerHr == 0 && blockA.acPerHr == 0) {
        return 0;
      } else if (blockA.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
      } else if (blockB.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
      } else {
        return efficiencyFilters.efficiencyZoneSort == 'Desc'
          ? 1 / blockB.acPerHr - 1 / blockA.acPerHr
          : 1 / blockA.acPerHr - 1 / blockB.acPerHr;
      }
    } else if (displayedAxis == 'Total $') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? calculatedDollarAmountB - calculatedDollarAmountA
        : calculatedDollarAmountA - calculatedDollarAmountB;
    }
  });
  if (efficiencyFilters.efficiencyZoneSort == 'Alphabetical') {
    blocks.sort((a, b) => {
      return a.localeCompare(b);
    });
  }
  if (efficiencyFilters.efficiencyZoneCount > 0) {
    if (blocks.length > efficiencyFilters.efficiencyZoneCount) {
      blocks = blocks.slice(0, efficiencyFilters.efficiencyZoneCount);
    }
  }

  blocks.forEach((blockName) => {
    let calculatedDollarAmount = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmount = hourDollarRate * blocksObj[blockName].duration;
    }
    if (areaDollarRate  != 0 && areaDollarRate  != '') {
      const areaValue =
        unitsAreaSystem == 'acre'
          ? blocksObj[blockName].acreage
          : unitsAreaConversion(blocksObj[blockName].acreage, 'ha', 'ac');
      calculatedDollarAmount = areaDollarRate  * areaValue;
    }

    // Convert units if needed
    if (unitsAreaSystem == 'acre') {
      acHrs.push(blocksObj[blockName].acPerHr);
      hrPerAc.push(1 / blocksObj[blockName].acPerHr);
    } else {
      const acPerHrConverted = unitsAreaConversion(blocksObj[blockName].acPerHr, 'ha', 'ac');
      acHrs.push(acPerHrConverted);
      hrPerAc.push(1 / acPerHrConverted);
    }

    speeds.push(unitsLengthDisplayConversion(blocksObj[blockName].avgSpeed, unitsLength));
    totalDollars.push(calculatedDollarAmount);
  });
  if (effData.hasOwnProperty('Block')) {
    effData['Block']['Total'] = {
      labels: blocks,
      datasets: [
        {
          label: !isCompareBy ? acHrLabel : 'Comparison ' + acHrLabel,
          displayedAxis: acHrLabel,
          data: acHrs,
          borderWidth: 1,
          yAxisID: acHrLabel.replace('/', ''),
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${acHrLabel}`];
                if (showDetailedTooltipData) {
                  const taskData = blocksObj[blocks[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const areaValue =
                      unitsAreaSystem == 'acre' ? taskData.acreage : unitsAreaConversion(taskData.acreage, 'ha', 'ac');
                    const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(
                      areaValue,
                      2
                    )} ${unitsArea}`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    let rowSpacingString = `Not Assigned`;
                    if (taskData.rowSpacingMeters) {
                      rowSpacingString =
                        unitsLengthSystem == 'imperial'
                          ? `${roundDecimalPlaces(
                              unitsLengthDisplayConversion(taskData.rowSpacingMeters, 'ft'),
                              2
                            )} ft`
                          : `${roundDecimalPlaces(taskData.rowSpacingMeters, 2)} m`;
                    }
                    const rowSpacingTooltip = `Row Spacing: ${rowSpacingString}`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip, rowSpacingTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: !isCompareBy ? hrAcLabel : 'Comparison ' + hrAcLabel,
          displayedAxis: hrAcLabel,
          data: hrPerAc,
          borderWidth: 1,
          yAxisID: hrAcLabel.replace('/', ''),
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${hrAcLabel}`];
                if (showDetailedTooltipData) {
                  const taskData = blocksObj[blocks[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const areaValue =
                      unitsAreaSystem == 'acre' ? taskData.acreage : unitsAreaConversion(taskData.acreage, 'ha', 'ac');
                    const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(
                      areaValue,
                      2
                    )} ${unitsArea}`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    let rowSpacingString = `Not Assigned`;
                    if (taskData.rowSpacingMeters) {
                      rowSpacingString =
                        unitsLengthSystem == 'imperial'
                          ? `${roundDecimalPlaces(
                              unitsLengthDisplayConversion(taskData.rowSpacingMeters, 'ft'),
                              2
                            )} ft`
                          : `${roundDecimalPlaces(taskData.rowSpacingMeters, 2)} m`;
                    }
                    const rowSpacingTooltip = `Row Spacing: ${rowSpacingString}`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip, rowSpacingTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: !isCompareBy ? 'Speed' : 'Comparison Speed',
          displayedAxis: 'Speed',
          data: speeds,
          borderWidth: 1,
          yAxisID: 'Speed',
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return ` ${roundDecimalPlaces(formattedValue, 1)} ${unitsSpeed}`;
              },
            },
          },
        },
        {
          label: !isCompareBy ? 'Total $' : 'Comparison Total $',
          displayedAxis: 'Total $',
          data: totalDollars,
          borderWidth: 1,
          yAxisID: 'Total $',
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
              },
            },
          },
        },
        {
          type: 'line',
          label: 'Target',
          displayedAxis: 'Target',
          backgroundColor: 'red',
          data: [],
          yAxisID: 'TargetAxis',
        },
      ],
    };
  }

  // Add totals task agnostic for fields
  let fields = Object.keys(fieldsObj);
  acHrs = [];
  hrPerAc = [];
  speeds = [];
  totalDollars = [];
  // Sort Fields totals
  fields.sort((a, b) => {
    const fieldA = sortingDataset.efficiencyFieldsObject[a];
    const fieldB = sortingDataset.efficiencyFieldsObject[b];
    let calculatedDollarAmountA = 0;
    let calculatedDollarAmountB = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmountA = hourDollarRate * fieldA.duration;
      calculatedDollarAmountB = hourDollarRate * fieldB.duration;
    }
    if (areaDollarRate  != 0 && areaDollarRate  != '') {
      calculatedDollarAmountA = areaDollarRate  * fieldA.acreage;
      calculatedDollarAmountB = areaDollarRate  * fieldB.acreage;
    }
    if (displayedAxis == 'Ac/Hr' || displayedAxis == 'Ha/Hr') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? fieldB.acPerHr - fieldA.acPerHr
        : fieldA.acPerHr - fieldB.acPerHr;
    } else if (displayedAxis == 'Speed') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? fieldB.avgSpeed - fieldA.avgSpeed
        : fieldA.avgSpeed - fieldB.avgSpeed;
    } else if (displayedAxis == 'Hr/Ac' || displayedAxis == 'Hr/Ha') {
      if (fieldB.acPerHr == 0 && fieldA.acPerHr == 0) {
        return 0;
      } else if (fieldA.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
      } else if (fieldB.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
      } else {
        return efficiencyFilters.efficiencyZoneSort == 'Desc'
          ? 1 / fieldB.acPerHr - 1 / fieldA.acPerHr
          : 1 / fieldA.acPerHr - 1 / fieldB.acPerHr;
      }
    } else if (displayedAxis == 'Total $') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? calculatedDollarAmountB - calculatedDollarAmountA
        : calculatedDollarAmountA - calculatedDollarAmountB;
    }
  });
  if (efficiencyFilters.efficiencyZoneSort == 'Alphabetical') {
    fields.sort((a, b) => {
      return a.localeCompare(b);
    });
  }
  if (efficiencyFilters.efficiencyZoneCount > 0) {
    if (fields.length > efficiencyFilters.efficiencyZoneCount) {
      fields = fields.slice(0, efficiencyFilters.efficiencyZoneCount);
    }
  }

  fields.forEach((fieldName) => {
    let calculatedDollarAmount = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmount = hourDollarRate * fieldsObj[fieldName].duration;
    }
    if (areaDollarRate  != 0 && areaDollarRate  != '') {
      const areaValue =
        unitsAreaSystem == 'acre'
          ? fieldsObj[fieldName].acreage
          : unitsAreaConversion(fieldsObj[fieldName].acreage, 'ha', 'ac');
      calculatedDollarAmount = areaDollarRate  * areaValue;
    }

    // Convert units if needed
    if (unitsAreaSystem == 'acre') {
      acHrs.push(fieldsObj[fieldName].acPerHr);
      hrPerAc.push(1 / fieldsObj[fieldName].acPerHr);
    } else {
      const acPerHrConverted = unitsAreaConversion(fieldsObj[fieldName].acPerHr, 'ha', 'ac');
      acHrs.push(acPerHrConverted);
      hrPerAc.push(1 / acPerHrConverted);
    }

    speeds.push(unitsLengthDisplayConversion(fieldsObj[fieldName].avgSpeed * 100, unitsLength) / 100);
    totalDollars.push(calculatedDollarAmount);
  });
  if (effData.hasOwnProperty('Field')) {
    effData['Field']['Total'] = {
      labels: fields,
      datasets: [
        {
          label: !isCompareBy ? acHrLabel : 'Comparison ' + acHrLabel,
          displayedAxis: acHrLabel,
          data: acHrs,
          borderWidth: 1,
          yAxisID: acHrLabel.replace('/', ''),
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${acHrLabel}`];
                if (showDetailedTooltipData) {
                  const taskData = fieldsObj[fields[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const areaValue =
                      unitsAreaSystem == 'acre' ? taskData.acreage : unitsAreaConversion(taskData.acreage, 'ha', 'ac');
                    const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(
                      areaValue,
                      2
                    )} ${unitsArea}`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: !isCompareBy ? hrAcLabel : 'Comparison ' + hrAcLabel,
          displayedAxis: hrAcLabel,
          data: hrPerAc,
          borderWidth: 1,
          yAxisID: hrAcLabel.replace('/', ''),
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${hrAcLabel}`];
                if (showDetailedTooltipData) {
                  const taskData = fieldsObj[fields[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const areaValue =
                      unitsAreaSystem == 'acre' ? taskData.acreage : unitsAreaConversion(taskData.acreage, 'ha', 'ac');
                    const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(
                      areaValue,
                      2
                    )} ${unitsArea}`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: !isCompareBy ? 'Speed' : 'Comparison Speed',
          displayedAxis: 'Speed',
          data: speeds,
          borderWidth: 1,
          yAxisID: 'Speed',
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return ` ${roundDecimalPlaces(formattedValue, 1)} ${unitsLength}/hr`;
              },
            },
          },
        },
        {
          label: !isCompareBy ? 'Total $' : 'Comparison Total $',
          displayedAxis: 'Total $',
          data: totalDollars,
          borderWidth: 1,
          yAxisID: 'Total $',
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
              },
            },
          },
        },
        {
          type: 'line',
          label: 'Target',
          displayedAxis: 'Target',
          backgroundColor: 'red',
          data: [],
          yAxisID: 'TargetAxis',
        },
      ],
    };
  }

  // Add totals task agnostic for regions
  let regions = Object.keys(regionsObj);
  // Sort Regions totals
  regions.sort((a, b) => {
    const regionA = sortingDataset.efficiencyRegionsObject[a];
    const regionB = sortingDataset.efficiencyRegionsObject[b];

    let calculatedDollarAmountA = 0;
    let calculatedDollarAmountB = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmountA = hourDollarRate * regionA.duration;
      calculatedDollarAmountB = hourDollarRate * regionB.duration;
    }
    if (areaDollarRate  != 0 && areaDollarRate  != '') {
      calculatedDollarAmountA = areaDollarRate  * regionA.acreage;
      calculatedDollarAmountB = areaDollarRate  * regionB.acreage;
    }
    if (displayedAxis == 'Ac/Hr' || displayedAxis == 'Ha/Hr') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? regionB.acPerHr - regionA.acPerHr
        : regionA.acPerHr - regionB.acPerHr;
    } else if (displayedAxis == 'Speed') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? regionB.avgSpeed - regionA.avgSpeed
        : regionA.avgSpeed - regionB.avgSpeed;
    } else if (displayedAxis == 'Hr/Ac' || displayedAxis == 'Hr/Ha') {
      if (regionB.acPerHr == 0 && regionA.acPerHr == 0) {
        return 0;
      } else if (regionA.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? 1 : -1;
      } else if (regionB.acPerHr == 0) {
        return efficiencyFilters.efficiencyZoneSort == 'Desc' ? -1 : 1;
      } else {
        return efficiencyFilters.efficiencyZoneSort == 'Desc'
          ? 1 / regionB.acPerHr - 1 / regionA.acPerHr
          : 1 / regionA.acPerHr - 1 / regionB.acPerHr;
      }
    } else if (displayedAxis == 'Total $') {
      return efficiencyFilters.efficiencyZoneSort == 'Desc'
        ? calculatedDollarAmountB - calculatedDollarAmountA
        : calculatedDollarAmountA - calculatedDollarAmountB;
    }
  });
  if (efficiencyFilters.efficiencyZoneSort == 'Alphabetical') {
    regions.sort((a, b) => {
      return a.localeCompare(b);
    });
  }
  if (efficiencyFilters.efficiencyZoneCount > 0) {
    if (regions.length > efficiencyFilters.efficiencyZoneCount) {
      regions = regions.slice(0, efficiencyFilters.efficiencyZoneCount);
    }
  }

  acHrs = [];
  hrPerAc = [];
  speeds = [];
  totalDollars = [];
  regions.forEach((regionName) => {
    let calculatedDollarAmount = 0;
    if (hourDollarRate != 0 && hourDollarRate != '') {
      calculatedDollarAmount = hourDollarRate * regionsObj[regionName].duration;
    }
    if (areaDollarRate  != 0 && areaDollarRate  != '') {
      const areaValue =
        unitsAreaSystem == 'acre'
          ? regionsObj[regionName].acreage
          : unitsAreaConversion(regionsObj[regionName].acreage, 'ha', 'ac');
      calculatedDollarAmount = areaDollarRate  * areaValue;
    }

    // Convert units if needed
    if (unitsAreaSystem == 'acre') {
      acHrs.push(regionsObj[regionName].acPerHr);
      hrPerAc.push(1 / regionsObj[regionName].acPerHr);
    } else {
      const acPerHrConverted = unitsAreaConversion(regionsObj[regionName].acPerHr, 'ha', 'ac');
      acHrs.push(acPerHrConverted);
      hrPerAc.push(1 / acPerHrConverted);
    }

    speeds.push(unitsLengthDisplayConversion(regionsObj[regionName].avgSpeed * 100, unitsLength) / 100);
    totalDollars.push(calculatedDollarAmount);
  });
  if (effData.hasOwnProperty('Region')) {
    effData['Region']['Total'] = {
      labels: regions,
      datasets: [
        {
          label: !isCompareBy ? acHrLabel : "Comparison " + acHrLabel,
          displayedAxis: acHrLabel,
          data: acHrs,
          borderWidth: 1,
          yAxisID: acHrLabel.replace('/', ''),
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${acHrLabel}`];
                if (showDetailedTooltipData) {
                  const taskData = regionsObj[regions[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const areaValue =
                      unitsAreaSystem == 'acre' ? taskData.acreage : unitsAreaConversion(taskData.acreage, 'ha', 'ac');
                    const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(
                      areaValue,
                      2
                    )} ${unitsArea}`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                  }
                }
                return tooltipRows;
              },
            },
          },
        },
        {
          label: !isCompareBy ? hrAcLabel : "Comparison " + hrAcLabel,
          displayedAxis: hrAcLabel,
          data: hrPerAc,
          borderWidth: 1,
          yAxisID: hrAcLabel.replace('/', ''),
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} ${hrAcLabel}`];
                if (showDetailedTooltipData) {
                  const taskData = regionsObj[regions[context.dataIndex]];
                  if (taskData?.acreage && taskData?.duration) {
                    const areaValue =
                      unitsAreaSystem == 'acre' ? taskData.acreage : unitsAreaConversion(taskData.acreage, 'ha', 'ac');
                    const totalAcreageTooltip = `Total ${acreageLabel}: ${roundDecimalPlaces(
                      areaValue,
                      2
                    )} ${unitsArea}`;
                    const totalDurationTooltip = `Total Duration: ${roundDecimalPlaces(taskData.duration, 2)} hr`;
                    tooltipRows.push(totalAcreageTooltip, totalDurationTooltip);
                  }
                }

                return tooltipRows;
              },
            },
          },
        },
        {
          label: !isCompareBy ? 'Speed' : 'Comparison Speed',
          displayedAxis: 'Speed',
          data: speeds,
          borderWidth: 1,
          yAxisID: 'Speed',
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return ` ${roundDecimalPlaces(formattedValue, 1)} ${unitsSpeed}`;
              },
            },
          },
        },
        {
          label: !isCompareBy ? 'Total $' : 'Comparison Total $',
          displayedAxis: 'Total $',
          data: totalDollars,
          borderWidth: 1,
          yAxisID: 'Total $',
          backgroundColor: !isCompareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
          tooltip: {
            callbacks: {
              label: (context) => {
                const formattedValue = context.formattedValue;
                return `Total Dollars: ${formatter.format(roundDecimalPlaces(formattedValue, 2))}`;
              },
            },
          },
        },
        {
          type: 'line',
          label: 'Target',
          displayedAxis: 'Target',
          backgroundColor: 'red',
          data: [],
          yAxisID: 'TargetAxis',
        },
      ],
    };
  }

  return effData;
}

/**
 *
 * @param {*} inFieldTotal In field
 * @param {*} outFieldTotal Out Field
 * @param {*} compareBy
 * @return {*}
 */
export function inVsOutFieldData(inFieldTotal, outFieldTotal, compareBy=false) {

  const totalTime = inFieldTotal + outFieldTotal;
  const inFieldPercent = ((inFieldTotal / totalTime) * 100).toFixed(2);
  const outFieldPercent = ((outFieldTotal / totalTime) * 100).toFixed(2);
  let labels = ['Field Operations'];

  // Display no data if both values are 0
  if (inFieldTotal == 0 && outFieldTotal == 0) {
    labels = ['No Data'];
  }

  const datasets = [
    {
      label: !compareBy ? [`In Field`] : [`Comparison In Field`],
      data: [inFieldPercent],
      backgroundColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
      borderColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
      borderWidth: 1,
      stack: compareBy ? "Compare Stack" : "Regular Stack",
      tooltip: {
        callbacks: {
          label: (context) => {
            const formattedValue = context.formattedValue;
            const totalRow = `Total: ${inFieldTotal.toFixed(2)} Hours`
            const percentRow = `Percentage: ${formattedValue}%`;
            const labelRows = ["In Field: ", totalRow, percentRow];
            return labelRows;
          },
        }
      },
    },
    {
      label: !compareBy ? [`Out of Field`] : [`Comparison Out of Field`],
      data: [outFieldPercent],
      backgroundColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
      borderColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
      borderWidth: 1,
      stack: compareBy ? "Compare Stack" : "Regular Stack",
      tooltip: {
        callbacks: {
          label: (context) => {
            const formattedValue = context.formattedValue;
            const totalRow = `Total: ${outFieldTotal.toFixed(2)} Hours`
            const percentRow = `Percentage: ${formattedValue}%`;
            const labelRows = ["Out of Field: ", totalRow, percentRow];
            return labelRows;
          },
        },
      },
    },
  ]

  return {
    labels: labels,
    datasets: datasets
  };
}

export function genTotalMachineUsage(
  operationsData,
  filters,
  daysDiff,
  vehicleSNDict,
  taskConfigs,
  activeDevices,
  unitsLengthSystem,
  hourRate,
  fuelRate,
  fuelCost,
  displayAverageMetrics,
  displayedAxis,
  sortingDataset,
  compareBy = false
) {

  // Get each data portion
  const machineUsageData = operationsData.vehiclesObject
  const machineUsageTaskData = operationsData.tasksObject
  const machineUsageDaysData = operationsData.opsByDay
  const machineUsageWeeksData = operationsData.opsByWeek
  const machineUsageMonthsData = operationsData.opsByMonth
  const machineUsageYearsData = operationsData.opsByYear
  
  // Generate data for vehicles as the x-axis
  const machineUsageVehicles = genMachineUsageVehicleDataset(
    machineUsageData,
    filters,
    vehicleSNDict,
    activeDevices,
    unitsLengthSystem,
    hourRate,
    fuelRate,
    fuelCost,
    displayedAxis,
    sortingDataset.vehiclesObject,
    compareBy
    
  );

  // Generate data for tasks as the x-axis
  const machineUsageTasks = genMachineUsageTaskDataset(
    machineUsageTaskData,
    filters,
    taskConfigs,
    unitsLengthSystem,
    hourRate,
    fuelRate,
    fuelCost,
    displayedAxis,
    sortingDataset.tasksObject,
    compareBy
  )

  // Generate all time based datasets
  const machineUsageDays = genMachineUsageTimeDataset(
    machineUsageDaysData,
    filters,
    vehicleSNDict,
    activeDevices,
    displayAverageMetrics,
    unitsLengthSystem,
    hourRate,
    fuelRate,
    fuelCost,
    displayedAxis,
    compareBy
  )
  const machineUsageWeeks = genMachineUsageTimeDataset(
    machineUsageWeeksData,
    filters,
    vehicleSNDict,
    activeDevices,
    displayAverageMetrics,
    unitsLengthSystem,
    hourRate,
    fuelRate,
    fuelCost,
    displayedAxis,
    compareBy
  )
  const machineUsageMonths = genMachineUsageTimeDataset(
    machineUsageMonthsData,
    filters,
    vehicleSNDict,
    activeDevices,
    displayAverageMetrics,
    unitsLengthSystem,
    hourRate,
    fuelRate,
    fuelCost,
    displayedAxis,
    compareBy
  )
  const machineUsageYears = genMachineUsageTimeDataset(
    machineUsageYearsData,
    filters,
    vehicleSNDict,
    activeDevices,
    displayAverageMetrics,
    unitsLengthSystem,
    hourRate,
    fuelRate,
    fuelCost,
    displayedAxis,
    compareBy
  )

  // Combine all the machine usage datasets
  const combinedMachineUsageData = {
    "Vehicle": machineUsageVehicles,
    "Days": machineUsageDays,
    "Weeks": machineUsageWeeks,
    "Months": machineUsageMonths,
    "Years": machineUsageYears,
    "Task": machineUsageTasks,
  }

  return combinedMachineUsageData;
}

export function genMachineUsageVehicleDataset(
  machineUsageData,
  filters,
  vehicleSNDict,
  activeDevices,
  unitsLengthSystem,
  hourRate,
  fuelRate,
  fuelCost,
  displayedAxis,
  sortingDataset,
  compareBy = false
) {

  // Filter
  let vehicleKeys = Object.keys(machineUsageData).filter((vehicleSN) => {
    return (
      Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN) && vehicleSNDict[vehicleSN].coverageActive == true
    );
  });
  if (filters.usageVehicleSNs.length > 0) {
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      return filters.usageVehicleSNs.indexOf(vehicleSN) !== -1;
    });
  }

  if (filters.usageVehicleType.length > 0) {
    const filterVehicleTypes = filters.usageVehicleType.map((type) => {
      return parseInt(type);
    });
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
      return filterVehicleTypes.includes(vehicleType);
    });
  }

  // Sort
  if (filters.usageVehicleSort == 'Alphabetical') {
    vehicleKeys.sort((a, b) => {
      const nameA = sortingDataset[a].vehicleName;
      const nameB = sortingDataset[b].vehicleName;
      return nameA.localeCompare(nameB);
    });
  } else {
    // Sort based on displayed axis
    if (displayedAxis == 'Distance') {
      vehicleKeys.sort((a, b) => {
        const usageA = sortingDataset[a].distance;
        const usageB = sortingDataset[b].distance;
        return filters.usageVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
      });
    } else {
      vehicleKeys.sort((a, b) => {
        const usageA = sortingDataset[a].totalDuration;
        const usageB = sortingDataset[b].totalDuration;
        return filters.usageVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
      });
    }
  }

  // assign 0 to rates if empty
  hourRate = hourRate == '' || !hourRate ? 0 : hourRate;
  fuelRate = fuelRate == '' || !fuelRate ? 0 : fuelRate;
  fuelCost = fuelCost == '' || !fuelCost ? 0 : fuelCost;
  const fuelRateImperial = fuelRate / 3.785;
  if (unitsLengthSystem == 'imperial') {
    // Convert L/Hr to Gal/Hr
    fuelRate = fuelRate / 3.785;
    // Convert $/L to $/Gal
    fuelCost = fuelCost * 3.785;
  }
  const usageValues = [];
  const totalDistance = [];
  const fuelUsage = []; // Machine Hours * Fuel Rate
  const totalFuelCost = []; // Machine Hours * Fuel Rate * Fuel Cost
  const machineOperationCost = []; // Machine Hours * Hour Rate
  const ghg = []; // Machine Hours * Fuel Rate * 10180 / 1000000, CO2 diesel emissions is 10180 gram/gallon

  let vehicleKeysFiltered = [];
  vehicleKeys.forEach((vehicleSN) => {

    // Check if vehicle is a BeSol device
    const vehDoc = vehicleSNDict[vehicleSN];
    const beSolType = checkIfDeviceIsBesol(vehDoc, activeDevices);

    if (!beSolType) {
      const veh = machineUsageData[vehicleSN];
      let vehTotalDistance = veh.distance;
      const vehGHG = (veh.totalDuration * fuelRateImperial * 10180) / 1000000;
      if (unitsLengthSystem == 'imperial') {
        // Convert Distance from km to miles
        vehTotalDistance = parseFloat(unitsLengthDisplayConversion(vehTotalDistance, 'mi'));
      }

      usageValues.push(veh.totalDuration);
      totalDistance.push(vehTotalDistance);
      fuelUsage.push(veh.totalDuration * fuelRate);
      totalFuelCost.push(veh.totalDuration * fuelRate * fuelCost);
      machineOperationCost.push(veh.totalDuration * hourRate);
      ghg.push(vehGHG);
      vehicleKeysFiltered.push(vehicleSN);
    }
  });

  // Slice count if selected
  if (filters.usageVehicleCount > 0) {
    if (vehicleKeysFiltered.length > filters.usageVehicleCount) {
      vehicleKeysFiltered = vehicleKeysFiltered.slice(0, filters.usageVehicleCount);
    }
  }

  const machineUsageVehicle = {
    labels: vehicleKeysFiltered.map((vehicleSN) => {
      return machineUsageData[vehicleSN].vehicleName;
    }),
    datasets: [
      {
        type: 'bar',
        label: !compareBy ? 'Usage Hours' : 'Comparison Usage Hours',
        data: usageValues,
        id: 'Total',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `${roundDecimalPlaces(formattedValue, 2)} Hours`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ?  'Travel Distance' : 'Comparison Travel Distance',
        data: totalDistance,
        id: 'Distance',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} ${unitsLengthSystem == 'imperial' ? 'mi' : 'km'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Fuel Consumption' : 'Comparison Fuel Consumption',
        data: fuelUsage,
        id: 'Fuel',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)}  ${unitsLengthSystem == 'imperial' ? 'Gal' : 'L'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? fuelCost == '' || !fuelCost ? 'Machine Operating Cost' : 'Fuel Cost' : fuelCost == '' || !fuelCost ? 'Comparison Machine Operating Cost' : 'Comparison Fuel Cost',
        data: fuelCost == '' || !fuelCost ? machineOperationCost : totalFuelCost,
        id: 'Cost',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` $${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'GHG emission': 'Comparison GHG emission',
        data: ghg,
        id: 'GHG',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} Tonnes`;
            },
          },
        },
      },
    ],
  };

  return machineUsageVehicle;
}

export function genMachineUsageTaskDataset(
  taskUsageData,
  filters,
  taskConfigs,
  unitsLengthSystem,
  hourRate,
  fuelRate,
  fuelCost,
  displayedAxis,
  sortingDataset,
  compareBy = false
) {
  // Gather stats per each task
  const aggStatsByTask = {};
  Object.keys(taskUsageData).forEach((taskId) => {
    // Get task name
    let taskName = 'No Task Found';
    if (taskId in taskConfigs) {
      taskName = taskConfigs[taskId].name;
    }

    // Check if task is being filtered
    if (filters.usageVehicleSNs.length > 0 && !filters.usageVehicleSNs.includes(taskId)) {
      return;
    }

    // Aggregate all regions for the task
    let aggDistance = 0;
    let aggDuration = 0;
    Object.keys(taskUsageData[taskId]['Region']).forEach((regionName) => {
      aggDistance += taskUsageData[taskId]['Region'][regionName].distance;
      aggDuration += taskUsageData[taskId]['Region'][regionName].duration;
    });

    // Compile
    aggStatsByTask[taskId] = {
      distance: aggDistance,
      duration: aggDuration,
      taskName: taskName,
    };
  });

  // Gather stats per each task
  const aggSortingDataSetByTask = {};
  Object.keys(sortingDataset).forEach((taskId) => {
    // Get task name
    let taskName = 'No Task Found';
    if (taskId in taskConfigs) {
      taskName = taskConfigs[taskId].name;
    }

    // Check if task is being filtered
    if (filters.usageVehicleSNs.length > 0 && !filters.usageVehicleSNs.includes(taskId)) {
      return;
    }

    // Aggregate all regions for the task
    let aggDistance = 0;
    let aggDuration = 0;
    Object.keys(sortingDataset[taskId]['Region']).forEach((regionName) => {
      aggDistance += sortingDataset[taskId]['Region'][regionName].distance;
      aggDuration += sortingDataset[taskId]['Region'][regionName].duration;
    });

    // Compile
    aggSortingDataSetByTask[taskId] = {
      distance: aggDistance,
      duration: aggDuration,
      taskName: taskName,
    };
  });

  // Sort
  const keysTask = Object.keys(aggStatsByTask); // Seperate out keys for sorting
  if (filters.usageVehicleSort == 'Alphabetical') {
    keysTask.sort((a, b) => {
      const taskNameA = aggStatsByTask[a].taskName;
      const taskNameB = aggStatsByTask[b].taskName;
      return taskNameA.localeCompare(taskNameB); // Keys are the task names
    });
  } else {
    // Sort based on displayed axis
    if (displayedAxis == 'Distance') {
      keysTask.sort((a, b) => {
        const usageA = aggSortingDataSetByTask[a].distance;
        const usageB = aggSortingDataSetByTask[b].distance;
        return filters.usageVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
      });
    } else {
      keysTask.sort((a, b) => {
        const usageA = aggSortingDataSetByTask[a].duration;
        const usageB = aggSortingDataSetByTask[b].duration;
        return filters.usageVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
      });
    }
  }

  // assign 0 to rates if empty
  hourRate = hourRate == '' || !hourRate ? 0 : hourRate;
  fuelRate = fuelRate == '' || !fuelRate ? 0 : fuelRate;
  fuelCost = fuelCost == '' || !fuelCost ? 0 : fuelCost;
  const fuelRateImperial = fuelRate / 3.785;
  if (unitsLengthSystem == 'imperial') {
    fuelRate = fuelRate / 3.785; // Convert L/Hr to Gal/Hr
    fuelCost = fuelCost * 3.785; // Convert $/L to $/Gal
  }
  const usageValues = [];
  const totalDistance = [];
  const fuelUsage = []; // Machine Hours * Fuel Rate
  const totalFuelCost = []; // Machine Hours * Fuel Rate * Fuel Cost
  const machineOperationCost = []; // Machine Hours * Hour Rate
  const ghg = []; // Machine Hours * Fuel Rate * 10180 / 1000000, CO2 diesel emissions is 10180 gram/gallon

  // Gather stats per each task
  let labels = [];
  keysTask.forEach((taskId) => {
    // Aggregate all regions for the task
    let aggDistance = aggStatsByTask[taskId].distance;
    const aggDuration = aggStatsByTask[taskId].duration;

    // Calculate GHG & determine units
    const vehGHG = (aggDuration * fuelRateImperial * 10180) / 1000000;
    if (unitsLengthSystem == 'imperial') {
      aggDistance = parseFloat(unitsLengthDisplayConversion(aggDistance, 'mi')); // Convert Distance from km to miles
    }

    usageValues.push(aggDuration);
    totalDistance.push(aggDistance);
    fuelUsage.push(aggDuration * fuelRate);
    totalFuelCost.push(aggDuration * fuelRate * fuelCost);
    machineOperationCost.push(aggDuration * hourRate);
    ghg.push(vehGHG);
    labels.push(aggStatsByTask[taskId].taskName);
  });

  // Slice count if selected
  if (filters.usageVehicleCount > 0) {
    if (labels.length > filters.usageVehicleCount) {
      labels = labels.slice(0, filters.usageVehicleCount);
    }
  }

  const machineUsageTask = {
    labels: labels,
    datasets: [
      {
        type: 'bar',
        label: !compareBy ? 'Usage Hours' : 'Comparison Usage Hours',
        data: usageValues,
        id: 'Total',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} Hours`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Travel Distance' : 'Comparison Travel Distance',
        data: totalDistance,
        id: 'Distance',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} ${unitsLengthSystem == 'imperial' ? 'mi' : 'km'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Fuel Consumption' : 'Comparison Fuel Consumption',
        data: fuelUsage,
        id: 'Fuel',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)}  ${unitsLengthSystem == 'imperial' ? 'Gal' : 'L'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy
          ? fuelCost == '' || !fuelCost
            ? 'Machine Operating Cost'
            : 'Fuel Cost'
          : fuelCost == '' || !fuelCost
          ? 'Comparison Machine Operating Cost'
          : 'Comparison Fuel Cost',
        data: fuelCost == '' || !fuelCost ? machineOperationCost : totalFuelCost,
        id: 'Cost',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` $${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'GHG emission' : 'Comparison GHG emission',
        data: ghg,
        id: 'GHG',
        borderWidth: 1,
        order: 2,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} Tonnes`;
            },
          },
        },
      },
    ],
  };

  return machineUsageTask;
}

export function genMachineUsageTimeDataset(
  timeUsageData,
  filters,
  vehicleSNDict,
  activeDevices,
  displayAverageMetrics,
  unitsLengthSystem,
  hourRate,
  fuelRate,
  fuelCost,
  displayedAxis,
  compareBy = false
) {

  // First aggregate & sort
  const aggStatsByTime = {}
  const filterVehicleTypes = filters.usageVehicleType.map((type) => {
    return parseInt(type);
  });
  Object.keys(timeUsageData).forEach((timestamp) => {

    // Use the vehicles object to aggregate so that the same filters can be used
    const timeVehicleObject = timeUsageData[timestamp].operationsReport.vehiclesObject;
  
    // Aggregate by each vehicle for the day
    // so that the vehicle filters can be applied
    let aggDistance = 0
    let aggDuration = 0
    Object.keys(timeVehicleObject).forEach((vehiclesn) => {

      // Apply the same vehicle filters
      if (filters.usageVehicleSNs.length > 0 && !filters.usageVehicleSNs.includes(vehiclesn)) {
        return;
      }
      const vehicleType = timeVehicleObject[vehiclesn].type;
      if (filterVehicleTypes.length > 0 && !filterVehicleTypes.includes(vehicleType)) {
        return;
      }
      const vehDoc = vehicleSNDict[vehiclesn];
      const beSolType = checkIfDeviceIsBesol(vehDoc, activeDevices)
      if (beSolType) {
        return;
      }

      aggDistance += timeVehicleObject[vehiclesn].distance;
      aggDuration += timeVehicleObject[vehiclesn].duration;
    });

    // Compile
    const vehiclesPerTimeBucket = Object.keys(timeVehicleObject).length;
    aggStatsByTime[timestamp] = {
      distance: aggDistance,
      duration: aggDuration,
      avgDistance: aggDistance / vehiclesPerTimeBucket,
      avgDuration: aggDuration / vehiclesPerTimeBucket
    }

  });

  // Sort
  const keysTime = Object.keys(aggStatsByTime) // Seperate out keys for sorting
  if (filters.usageVehicleSort == 'Chronological') {
    keysTime.sort((a, b) => {
      return a > b ? 1 : -1;
    });
  } else {

    // Sort based on displayed axis
    // If average metrics, need to sort by average
    // let sortKey = displayAverageMetrics ? 'duration': 'avgDuration';
    let sortKey = displayAverageMetrics ? 'avgDuration' : 'duration';
    if (displayedAxis.includes('Distance')) {
      // sortKey = displayAverageMetrics ? 'distance' : 'avgDistance';
      sortKey = displayAverageMetrics ? 'avgDistance' : 'distance';
    }

    // Perform ascending or descending sort
    keysTime.sort((a, b) => {
      const usageA = aggStatsByTime[a][sortKey];
      const usageB = aggStatsByTime[b][sortKey];
      return filters.usageVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
    });
  }

  // assign 0 to rates if empty
  hourRate = hourRate == '' || !hourRate ? 0 : hourRate;
  fuelRate = fuelRate == '' || !fuelRate ? 0 : fuelRate;
  fuelCost = fuelCost == '' || !fuelCost ? 0 : fuelCost;
  const fuelRateImperial = fuelRate / 3.785;
  if (unitsLengthSystem == 'imperial') {
    fuelRate = fuelRate / 3.785; // Convert L/Hr to Gal/Hr
    fuelCost = fuelCost * 3.785; // Convert $/L to $/Gal
  }
  const usageValues = [];
  const totalDistance = [];
  const fuelUsage = []; // Machine Hours * Fuel Rate
  const totalFuelCost = []; // Machine Hours * Fuel Rate * Fuel Cost
  const machineOperationCost = []; // Machine Hours * Hour Rate
  const ghg = []; // Machine Hours * Fuel Rate * 10180 / 1000000, CO2 diesel emissions is 10180 gram/gallon

  // Average Values
  const avgHoursPerTime = [];
  const avgDistancePerTime = [];
  const avgFuelUsagePerTime = [];
  const avgFuelCostPerTime = [];
  const avgMachineOperationCostPerTime = [];
  const avgGHGPerTime = [];

  // Gather stats per each time bucket
  let labels = [];
  keysTime.forEach((timestamp) => {

    let aggDistance = aggStatsByTime[timestamp].distance;
    const aggDuration = aggStatsByTime[timestamp].duration;
    let avgDistance = aggStatsByTime[timestamp].avgDistance;
    const avgDuration = aggStatsByTime[timestamp].avgDuration;

    // Calculate GHG & determine units
    const vehGHG = (aggDuration * fuelRateImperial * 10180) / 1000000;
    const avgGHG = (avgDuration * fuelRateImperial * 10180) / 1000000;
    if (unitsLengthSystem == 'imperial') {
      aggDistance = parseFloat(unitsLengthDisplayConversion(aggDistance, 'mi'));// Convert Distance from km to miles
      avgDistance = parseFloat(unitsLengthDisplayConversion(avgDistance, 'mi'));// Convert Distance from km to miles
    }

    // Total values
    usageValues.push(aggDuration);
    totalDistance.push(aggDistance);
    fuelUsage.push(aggDuration * fuelRate);
    totalFuelCost.push(aggDuration * fuelRate * fuelCost);
    machineOperationCost.push(aggDuration * hourRate);
    ghg.push(vehGHG);

    // Average values
    avgHoursPerTime.push(avgDuration);
    avgDistancePerTime.push(avgDistance);
    avgFuelUsagePerTime.push(avgDuration * fuelRate);
    avgFuelCostPerTime.push(avgDuration * fuelRate * fuelCost);
    avgMachineOperationCostPerTime.push(avgDuration * hourRate);
    avgGHGPerTime.push(avgGHG);

    // Shorten timestamp for formatting
    labels.push(timestamp.slice(0, 10));
  });

  // Slice count if selected
  if (filters.usageVehicleCount > 0) {
    if (labels.length > filters.usageVehicleCount) {
      labels = labels.slice(0, filters.usageVehicleCount);
    }
  }

  const machineUsageTime = {
    labels: labels,
    datasets: [
      {
        type: 'bar',
        label: 'Usage Hours',
        data: usageValues,
        id: 'Total',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} Hours`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Travel Distance',
        data: totalDistance,
        id: 'Distance',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} ${unitsLengthSystem == 'imperial' ? 'mi' : 'km'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Fuel Consumption',
        data: fuelUsage,
        id: 'Fuel',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)}  ${unitsLengthSystem == 'imperial' ? 'Gal' : 'L'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: fuelCost == '' || !fuelCost ? 'Machine Operating Cost' : 'Fuel Cost',
        data: fuelCost == '' || !fuelCost ? machineOperationCost : totalFuelCost,
        id: 'Cost',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` $${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'GHG emission',
        data: ghg,
        id: 'GHG',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} Tonnes`;
            },
          },
        },
      },

      // Average datasets
      {
        type: 'bar',
        label: 'Average Hours',
        data: avgHoursPerTime,
        id: 'Avg Hours',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} Hours`;
            },
          },
        },
      },
      {
        type: 'bar',
        label:'Average Distance',
        data: avgDistancePerTime,
        id: 'Avg Distance',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} ${unitsLengthSystem == 'imperial' ? 'mi' : 'km'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Average Fuel Consumption',
        data: avgFuelUsagePerTime,
        id: 'Avg Fuel',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)}  ${unitsLengthSystem == 'imperial' ? 'Gal' : 'L'}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: fuelCost == '' || !fuelCost ? 'Average Machine Operating Cost' : 'Average Fuel Cost',
        data: fuelCost == '' || !fuelCost ? avgMachineOperationCostPerTime : avgFuelCostPerTime,
        id: 'Avg Cost',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` $${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: 'Average GHG emission',
        data: avgGHGPerTime,
        id: 'Avg GHG',
        borderWidth: 1,
        order: 2,
        backgroundColor: customChartProperties.colorGreen,
        borderColor: customChartProperties.colorGreen,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} Tonnes`;
            },
          },
        },
      },

    ],
  };

  return machineUsageTime;
}

export function genIdlePercentByBucket(
  machineUsageDataBucketList,
  displayedAxis,
  filters,
  vehicleSNDict,
  installTypes,
  activeDevices,
  showDetailedTooltipData
) {
  const bucketObj = {};

  let labels = [];
  const bucketKeys = Object.keys(machineUsageDataBucketList);
  bucketKeys.forEach((bucketKey) => {
    let bucketIdleHours = 0;
    let bucketTotalDurationHours = 0;
    const vehsObj = machineUsageDataBucketList[bucketKey].operationsReport.vehiclesObject;
    let vehicleKeys = Object.keys(vehsObj).filter((vehicleSN) => {
      return (
        Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN) &&
        vehicleSNDict[vehicleSN].coverageActive == true
      );
    });
    // Create installTypesNameDict
    const installTypesNameDict = {};
    vehicleKeys.forEach((vehicleSN) => {
      installTypesNameDict[vehicleSNDict[vehicleSN].name] = installTypes?.[vehicleSN];
    });

    if (filters.idleVehicleSNs.length > 0) {
      vehicleKeys = vehicleKeys.filter((vehicleSN) => {
        return filters.idleVehicleSNs.indexOf(vehicleSN) !== -1;
      });
    }

    if (filters.idleVehicleType.length > 0) {
      const filterVehicleTypes = filters.idleVehicleType.map((type) => {
        return parseInt(type);
      });
      vehicleKeys = vehicleKeys.filter((vehicleSN) => {
        const vehicleType =
          vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
        return filterVehicleTypes.includes(vehicleType);
      });
    }

    const vehicleKeysFiltered = [];
    vehicleKeys.forEach((vehicleSN) => {
      // Check if vehicle is a BeSol device
      const vehDoc = vehicleSNDict[vehicleSN];
      const beSolType = checkIfDeviceIsBesol(vehDoc, activeDevices);

      if (!beSolType) {
        vehicleKeysFiltered.push(vehicleSN);
      }
    });

    // Okay now we have our filtered list of vehicles
    // We now safely loop through this object and aggregate on the vehicles which passed the filter
    vehicleKeysFiltered.forEach((vehKey) => {
      const item = vehsObj[vehKey];
      bucketIdleHours += item.idleTimeInHours;
      bucketTotalDurationHours += item.duration;
    });
    const idlePercentVal = (Math.round((bucketIdleHours / bucketTotalDurationHours) * 100) / 100) * 100;
    bucketObj[bucketKey] = {
      'bucketIdleHours': bucketIdleHours,
      'bucketTotalDurationHours': bucketTotalDurationHours,
      'bucketIdlePercent': idlePercentVal,
    };
    labels.push(bucketKey);
  });

  const displayedAxisToBucketValueKey = {
    "Idle %" : "bucketIdlePercent",
    "Idle Time (hr)" : "bucketIdleHours",
  }

  if (filters.idleVehicleSort != 'Chronological') {
    // Check sort filters
    labels.sort((a, b) => {
      const valueA = bucketObj[a][displayedAxisToBucketValueKey[displayedAxis]];
      const valueB = bucketObj[b][displayedAxisToBucketValueKey[displayedAxis]];
      return filters.idleVehicleSort == 'Desc' ? valueB - valueA : valueA - valueB;
    });
  }

  if (filters.idleVehicleCount > 0) {
    labels = labels.slice(0, filters.idleVehicleCount);
  }

  const idlePercentValues = labels.map((key) => {
    return bucketObj[key]['bucketIdlePercent'];
  });
  const idleTimeHours = labels.map((key) => {
    return bucketObj[key]['bucketIdleHours'];
  });
  const totalDurationHours = labels.map((key) => {
    return bucketObj[key]['bucketTotalDurationHours'];
  });

  const idlePercent = {
    labels: labels.map((date) => {
      // Format date string
      return date.slice(0, 10);
    }),
    datasets: [
      {
        label: 'Idle %',
        id: 'Idle %',
        data: idlePercentValues,
        backgroundColor: customChartProperties.colorGreen,
        legendBackgroundColor: customChartProperties.colorGreen,
        borderWidth: 0,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 1)}%`];
              if (showDetailedTooltipData) {
                const totalIdleTimeTooltip = `Total Idle Time: ${roundDecimalPlaces(
                  idleTimeHours[context.dataIndex],
                  2
                )} hr`;
                tooltipRows.push(totalIdleTimeTooltip);
                const totalDurationToolTip = `Total Drive Time: ${roundDecimalPlaces(
                  totalDurationHours[context.dataIndex],
                  2
                )} hr`;
                tooltipRows.push(totalDurationToolTip);
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: 'Idle Time (hr)',
        id: 'Idle Time (hr)',
        data: idleTimeHours,
        backgroundColor: customChartProperties.colorGreen,
        legendBackgroundColor: customChartProperties.colorGreen,
        borderWidth: 0,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`${roundDecimalPlaces(formattedValue, 2)} hr`];
              if (showDetailedTooltipData) {
                const totalIdleTimeTooltip = `Idle Percentage: ${roundDecimalPlaces(
                  idlePercentValues[context.dataIndex],
                  1
                )}%`;
                tooltipRows.push(totalIdleTimeTooltip);
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        type: 'line',
        label: 'Missing Idle Data',
        id: 'Missing Idle Data',
        backgroundColor: customChartProperties.colorGrey,
        data: [],
      },
      {
        type: 'line',
        label: 'Target',
        id: 'Target',
        backgroundColor: 'red',
        data: [],
        yAxisID: 'TargetAxis',
      },
    ],
  };

  return idlePercent;
}

/**
 *
 * @param {*} machineUsageData
 * @param {*} displayedAxis
 * @param {*} filters
 * @param {*} vehicleSNDict
 * @param {*} installTypes
 * @param {*} activeDevices
 * @param {boolean} showDetailedTooltipData
 * @param {*} sortingDataset
 * @param {*} compareBy
 * @return {*}
 */
export function genIdlePercentByMachine(
  machineUsageData,
  displayedAxis,
  filters,
  vehicleSNDict,
  installTypes,
  activeDevices,
  showDetailedTooltipData,
  sortingDataset,
  compareBy = false
) {
  let vehicleKeys = Object.keys(machineUsageData).filter((vehicleSN) => {
    return (
      Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN) && vehicleSNDict[vehicleSN].coverageActive == true
    );
  });

  // Create installTypesNameDict
  const installTypesNameDict = {};
  vehicleKeys.forEach((vehicleSN) => {
    installTypesNameDict[vehicleSNDict[vehicleSN].name] = installTypes?.[vehicleSN];
  });

  if (filters.idleVehicleSNs.length > 0) {
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      return filters.idleVehicleSNs.indexOf(vehicleSN) !== -1;
    });
  }

  if (filters.idleVehicleType.length > 0) {
    const filterVehicleTypes = filters.idleVehicleType.map((type) => {
      return parseInt(type);
    });
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
      return filterVehicleTypes.includes(vehicleType);
    });
  }

  if (displayedAxis == 'Idle %') {
    vehicleKeys.sort((a, b) => {
      const usageA = sortingDataset[a].idlePercent;
      const usageB = sortingDataset[b].idlePercent;
      return filters.idleVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
    });
  } else {
    vehicleKeys.sort((a, b) => {
      const usageA = sortingDataset[a].idleTimeInHours;
      const usageB = sortingDataset[b].idleTimeInHours;
      return filters.idleVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
    });
  }

  if (filters.idleVehicleSort == 'Alphabetical') {
    vehicleKeys.sort((a, b) => {
      const nameA = sortingDataset[a].vehicleName;
      const nameB = sortingDataset[b].vehicleName;
      return nameA.localeCompare(nameB);
    });
  }

  const idlePercentValues = [];
  const idleTimeHours = [];
  const colors = [];
  let vehWithNoData = false;
  let vehicleKeysFiltered = [];
  vehicleKeys.forEach((vehicleSN) => {
    const veh = machineUsageData[vehicleSN];

    // Check if vehicle is a BeSol device
    const vehDoc = vehicleSNDict[vehicleSN];
    const beSolType = checkIfDeviceIsBesol(vehDoc, activeDevices);

    if (!beSolType) {
      vehicleKeysFiltered.push(vehicleSN);

      idlePercentValues.push(veh.idlePercent);
      idleTimeHours.push(veh.idleTimeInHours);

      // Determine bar color
      if (installTypesNameDict?.[veh.vehicleName] == 'J1939 / OBD with no CAN') {
        colors.push(customChartProperties.colorGrey);
        vehWithNoData = true;
      } else {
        colors.push(!compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue);
      }
    }
  });

  if (filters.idleVehicleCount > 0) {
    if (vehicleKeysFiltered.length > filters.idleVehicleCount) {
      vehicleKeysFiltered = vehicleKeysFiltered.slice(0, filters.idleVehicleCount);
    }
  }

  const idlePercent = {
    labels: vehicleKeysFiltered.map((vehicleSN) => {
      return machineUsageData[vehicleSN].vehicleName;
    }),

    datasets: [
      {
        label: !compareBy ? 'Idle %' : 'Comparison Idle %',
        id: 'Idle %',
        data: idlePercentValues,
        backgroundColor: colors,
        legendBackgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderWidth: 0,
        order: !compareBy ? 0 : 1,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`Idle Percent: ${roundDecimalPlaces(formattedValue, 2)}%`];
              if (showDetailedTooltipData) {
                const totalIdleTimeTooltip = `Total Idle Time: ${roundDecimalPlaces(
                  idleTimeHours[context.dataIndex],
                  2
                )} Hours`;
                tooltipRows.push(totalIdleTimeTooltip);
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: !compareBy ? 'Idle Time (hr)' : 'Comparison Idle Time (hr)',
        id: 'Idle Time (hr)',
        data: idleTimeHours,
        order: !compareBy ? 0 : 1,
        backgroundColor: colors,
        legendBackgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderWidth: 0,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const tooltipRows = [`Total Idle Time: ${roundDecimalPlaces(formattedValue, 2)} Hours`];
              if (showDetailedTooltipData) {
                const totalIdleTimeTooltip = `Idle Percentage: ${roundDecimalPlaces(
                  idlePercentValues[context.dataIndex],
                  2
                )}%`;
                tooltipRows.push(totalIdleTimeTooltip);
              }
              return tooltipRows;
            },
          },
        },
      },
      ...(!compareBy ? [{
        type: 'line',
        label: 'Target',
        id: 'Target',
        order: 2,
        backgroundColor: 'red',
        data: [],
        yAxisID: 'TargetAxis',
      }] : []),
      ...(vehWithNoData && !compareBy
        ? [
            // Conditionally add dataset to produce legend entry
            {
              type: 'line',
              label: 'Missing Idle Data',
              id: 'Missing Idle Data',
              order: 2,
              backgroundColor: customChartProperties.colorGrey,
              data: [],
            },
          ]
        : [])
        
    ],
  };

  return idlePercent;
}

/**
 *
 * @param {*} servicesData
 * @param {*} displayedAxis
 * @param {*} filters
 * @param {*} vehicleSNDict
 * @param {*} compareBy
 * @return {*}
 */
export function genServiceCostsGraphData(servicesData, displayedAxis, filters, vehicleSNDict, compareBy = false) {
  let vehicleKeys = Object.keys(servicesData.vehicleServices);
  if (filters.serviceCostsVehicleSNs.length > 0) {
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      return filters.serviceCostsVehicleSNs.indexOf(vehicleSN) !== -1;
    });
  }

  if (filters.serviceCostsVehicleType.length > 0) {
    const filterVehicleTypes = filters.serviceCostsVehicleType.map((type) => {
      return parseInt(type);
    });
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
      return filterVehicleTypes.includes(vehicleType);
    });
  }

  vehicleKeys.sort((a, b) => {
    const partsCostsA = servicesData.vehicleServices[a].partsCosts;
    const partsCostsB = servicesData.vehicleServices[b].partsCosts;
    const laborCostsA = servicesData.vehicleServices[a].laborCosts;
    const laborCostsB = servicesData.vehicleServices[b].laborCosts;
    const laborTimeA = servicesData.vehicleServices[a].laborTime;
    const laborTimeB = servicesData.vehicleServices[b].laborTime;
    const nameA = servicesData.vehicleServices[a].vehicleName;
    const nameB = servicesData.vehicleServices[b].vehicleName;
    const totalCostsA = partsCostsA + laborCostsA;
    const totalCostsB = partsCostsB + laborCostsB;

    if (filters.serviceCostsVehicleSort == 'Alphabetical') {
      return nameA.localeCompare(nameB);
    }

    if (displayedAxis == 'Total') {
      return filters.serviceCostsVehicleSort == 'Desc' ? totalCostsB - totalCostsA : totalCostsA - totalCostsB;
    } else if (displayedAxis == 'PartsCosts') {
      return filters.serviceCostsVehicleSort == 'Desc' ? partsCostsB - partsCostsA : partsCostsA - partsCostsB;
    } else if (displayedAxis == 'LaborCosts') {
      return filters.serviceCostsVehicleSort == 'Desc' ? laborCostsB - laborCostsA : laborCostsA - laborCostsB;
    } else {
      return filters.serviceCostsVehicleSort == 'Desc' ? laborTimeB - laborTimeA : laborTimeA - laborTimeB;
    }
  });

  if (filters.serviceCostsVehicleCount > 0) {
    if (vehicleKeys.length > filters.serviceCostsVehicleCount) {
      vehicleKeys = vehicleKeys.slice(0, filters.serviceCostsVehicleCount);
    }
  }

  const totalCostsValues = [];
  const partsCostsValues = [];
  const laborCostsValues = [];
  const laborTimeValues = [];

  const totalScheduledCostsValues = [];
  const partsScheduledCostsValues = [];
  const laborScheduledCostsValues = [];
  const laborScheduledTimeValues = [];

  const totalUnScheduledCostsValues = [];
  const partsUnScheduledCostsValues = [];
  const laborUnScheduledCostsValues = [];
  const laborUnScheduledTimeValues = [];

  const vehicleKeysFiltered = [];
  vehicleKeys.forEach((vehicleSN) => {
    const veh = servicesData.vehicleServices[vehicleSN];

    totalCostsValues.push(veh.partsCosts + veh.laborCosts);
    partsCostsValues.push(veh.partsCosts);
    laborCostsValues.push(veh.laborCosts);
    laborTimeValues.push(veh.laborTime);

    totalScheduledCostsValues.push(veh.scheduledPartsCosts + veh.scheduledLaborCosts);
    partsScheduledCostsValues.push(veh.scheduledPartsCosts);
    laborScheduledCostsValues.push(veh.scheduledLaborCosts);
    laborScheduledTimeValues.push(veh.scheduledLaborTime);

    totalUnScheduledCostsValues.push(veh.unScheduledPartsCosts + veh.unScheduledLaborCosts);
    partsUnScheduledCostsValues.push(veh.unScheduledPartsCosts);
    laborUnScheduledCostsValues.push(veh.unScheduledLaborCosts);
    laborUnScheduledTimeValues.push(veh.unScheduledLaborTime);

    vehicleKeysFiltered.push(vehicleSN);
  });

  const chartLabels = vehicleKeysFiltered.map((vehicleSN) => {
    return servicesData.vehicleServices[vehicleSN].vehicleName;
  });
  

  const serviceCostsGraphData = {
    labels: chartLabels,
    datasets: [
      {
        type: 'bar',
        label: !compareBy ? 'Service Costs' : 'Comparison Service Costs',
        data: totalCostsValues,
        id: 'Total',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalVehCostsStr = `$${roundDecimalPlaces(formattedValue, 2)}`;
              const totalVehTimeStr = `${roundDecimalPlaces(laborTimeValues[context.dataIndex], 2)} Hours`;
              const tooltipRows = [totalVehCostsStr, totalVehTimeStr];
              return tooltipRows;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Parts Costs' : 'Comparison Parts Costs',
        data: partsCostsValues,
        id: 'PartsCosts',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Labor Costs' : 'Comparison Labor Costs',
        data: laborCostsValues,
        id: 'LaborCosts',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Labor Time' : 'Comparison Labor Time',
        data: laborTimeValues,
        id: 'LaborTime',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `${roundDecimalPlaces(formattedValue, 2)} Hours`;
            },
          },
        },
      },
      // ============= UNSCHEDULED

      {
        type: 'bar',
        label: !compareBy ? 'Unscheduled Service Costs' : 'Comparison Unscheduled Service Costs',
        data: totalUnScheduledCostsValues,
        id: 'Total.UnScheduled',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey,
        borderColor: !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalVehCostsStr = `$${roundDecimalPlaces(formattedValue, 2)}`;
              const totalVehTimeStr = `${roundDecimalPlaces(laborUnScheduledTimeValues[context.dataIndex], 2)} Hours`;
              const tooltipRows = [totalVehCostsStr, totalVehTimeStr];
              return tooltipRows;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Unscheduled Parts Costs' : 'Comparison Unscheduled Parts Costs',
        data: partsUnScheduledCostsValues,
        id: 'PartsCosts.UnScheduled',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey,
        borderColor: !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Unscheduled Labour Costs' : 'Comparison Unscheduled Labour Costs',
        data: laborUnScheduledCostsValues,
        id: 'LaborCosts.UnScheduled',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey,
        borderColor: !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Unscheduled Labour Time' : 'Comparison Unscheduled Labour Time',
        data: laborUnScheduledTimeValues,
        id: 'LaborTime.UnScheduled',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey,
        borderColor: !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `${roundDecimalPlaces(formattedValue, 2)} Hours`;
            },
          },
        },
      },
      // ============= SCHEDULED
      {
        type: 'bar',
        label: !compareBy ? 'Scheduled Service Costs' : 'Comparison Scheduled Service Costs',
        data: totalScheduledCostsValues,
        id: 'Total.Scheduled',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalVehCostsStr = `$${roundDecimalPlaces(formattedValue, 2)}`;
              const totalVehTimeStr = `${roundDecimalPlaces(laborScheduledTimeValues[context.dataIndex], 2)} Hours`;
              const tooltipRows = [totalVehCostsStr, totalVehTimeStr];
              return tooltipRows;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Scheduled Parts Costs' : 'Comparison Scheduled Parts Costs',
        data: partsScheduledCostsValues,
        id: 'PartsCosts.Scheduled',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Scheduled Labour Costs' : 'Comparison Scheduled Labour Costs',
        data: laborScheduledCostsValues,
        id: 'LaborCosts.Scheduled',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `$${roundDecimalPlaces(formattedValue, 2)}`;
            },
          },
        },
      },
      {
        type: 'bar',
        label: !compareBy ? 'Scheduled Labour Time' : 'Comparison Scheduled Labour Time',
        data: laborScheduledTimeValues,
        id: 'LaborTime.Scheduled',
        borderWidth: 1,
        order: 2,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        borderColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        yAxisID: 'leftAxis',
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `${roundDecimalPlaces(formattedValue, 2)} Hours`;
            },
          },
        },
      },
    ],
  };

  return serviceCostsGraphData;
}

/**
 * @param {*} vehiclesData
 * @param {*} filters
 * @param {*} sortingDataset
 * @param {*} compareBy
 * @return {*}
 */
export function genVehiclesWithoutTasks(
  vehiclesData,
   filters, 
   sortingDataset,
   compareBy = false
  ) {
  let vehicleKeys = Object.keys(vehiclesData);

  // Check sort filters
  vehicleKeys.sort((a, b) => {
    const missingHoursA = sortingDataset[a].missingTaskHours;
    const missingHoursB = sortingDataset[b].missingTaskHours;
    return filters.missingTaskVehicleSort == 'Desc' ? missingHoursB - missingHoursA : missingHoursA - missingHoursB;
  });
  if (filters.missingTaskVehicleSort == 'Alphabetical') {
    vehicleKeys.sort((a, b) => {
      const nameA = sortingDataset[a].vehicleName;
      const nameB = sortingDataset[b].vehicleName;
      return nameA.localeCompare(nameB);
    });
  }

  // Check count filters
  if (filters.missingTaskVehicleCount > 0) {
    if (vehicleKeys.length > filters.missingTaskVehicleCount) {
      vehicleKeys = vehicleKeys.slice(0, filters.missingTaskVehicleCount);
    }
  }

  // TODO
  const vehicleHrsValues = [];
  const vehicleAcValues = [];
  const vehicleGraph = {
    labels: vehicleKeys.map((vehicleSN) => {
      return vehiclesData[vehicleSN].vehicleName;
    }),

    datasets: [
      {
        label: !compareBy ? 'Hours' : 'Comparison Hours',
        id: 'Hrs',
        data: vehicleHrsValues,
        borderWidth: 0,
        yAxisID: 'leftAxis',
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `${roundDecimalPlaces(formattedValue, 2)} Hours`;
            },
          },
        },
      },
      {
        label: 'Ac',
        id: 'Ac',
        data: vehicleAcValues,
        borderWidth: 0,
        yAxisID: 'rightAxis',
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return ` ${roundDecimalPlaces(formattedValue, 2)} ac`;
            },
          },
        },
      },
    ],
  };
  vehicleKeys.forEach((vehicleSN) => {
    const veh = vehiclesData[vehicleSN];
    vehicleHrsValues.push(veh.missingTaskHours);
    vehicleAcValues.push(veh.missingTaskAcreage);
  });
  return vehicleGraph;
}

/**
 *
 * @param {*} opsData
 * @param {*} filters
 * @return {*}
 */
export function genTotalVehiclesWithoutTasksByTime(opsData, filters) {
  let dateKeys = Object.keys(opsData);
  const totalVehiclesWithoutTasksByDate = {};
  for (let i = 0; i < dateKeys.length; i ++) {
    const vehiclesData = opsData[dateKeys[i]].operationsReport.vehiclesObject;
    let vehicleKeys = Object.keys(vehiclesData);

    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      let vehicleInFilter = true;
      if (filters.missingTaskVehicleSNs.length > 0) {
        vehicleInFilter = filters.missingTaskVehicleSNs.indexOf(vehicleSN) !== -1;
      }
      const vehicleHasMissingTaskHours = vehiclesData[vehicleSN].missingTaskHours > 0;
      return vehicleInFilter && vehicleHasMissingTaskHours;
    });

    let vehicleHrsValues = 0;

    // Add all missing task hours together in the day/week/year
    vehicleKeys.forEach((vehicleSN) => {
      const veh = vehiclesData[vehicleSN];
      vehicleHrsValues += veh.missingTaskHours;
    });

    totalVehiclesWithoutTasksByDate[dateKeys[i]] = vehicleHrsValues;
  }

  if (filters.missingTaskVehicleSort != 'Chronological') {
    // Check sort filters
    dateKeys.sort((a, b) => {
      const missingHoursA = totalVehiclesWithoutTasksByDate[a];
      const missingHoursB = totalVehiclesWithoutTasksByDate[b];
      return filters.missingTaskVehicleSort == 'Desc' ? missingHoursB - missingHoursA : missingHoursA - missingHoursB;
    });
  }

  if (filters.missingTaskVehicleCount > 0) {
    dateKeys = dateKeys.slice(0, filters.missingTaskVehicleCount);
  }

  const sortedTotalVehicleMissingTaskValue = dateKeys.map((key) => {
    return totalVehiclesWithoutTasksByDate[key];
  });

  const vehicleGraph = {
    labels: dateKeys.map((date) => {
      // Format date string
      return date.slice(0, 10);
    }),
    datasets: [
      {
        label: 'Hours',
        id: 'Hrs',
        data: sortedTotalVehicleMissingTaskValue,
        borderWidth: 0,
        yAxisID: 'leftAxis',
        backgroundColor: customChartProperties.colorGreen,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              return `${roundDecimalPlaces(formattedValue, 2)} Hours`;
            },
          },
        },
      },
    ],
  }
  return vehicleGraph;
}

export function genMissingTaskData(regionsData, fieldsData, blocksData, filters, compareBy = false) {
  // Get region data
  let regionEnteredTaskHours = 0;
  let regionMissingTaskHours = 0;
  const regionsNamesList = Object.keys(regionsData);
  regionsNamesList.forEach((region) => {
    if (filters.regions.length == 0 || filters.regions.includes(region)) {
      regionEnteredTaskHours += regionsData[region].enteredTaskHours;
      regionMissingTaskHours += regionsData[region].missingTaskHours;
    }
  });

  // Get field data
  let fieldEnteredTaskHours = 0;
  let fieldMissingTaskHours = 0;
  const fieldsNamesList = Object.keys(fieldsData);
  fieldsNamesList.forEach((field) => {
    if (filters.fields.length == 0 || filters.fields.includes(field)) {
      fieldEnteredTaskHours += fieldsData[field].enteredTaskHours;
      fieldMissingTaskHours += fieldsData[field].missingTaskHours;
    }
  });

  // Additional Values
  const regionLoggedTotal = regionEnteredTaskHours + regionMissingTaskHours;
  const fieldLoggedTotal = fieldEnteredTaskHours + fieldMissingTaskHours;

  // Make region graph
  const regionsGraph = {
    labels: ['Region Tasks Logged'],
    datasets: [
      {
        label: !compareBy ? 'Region Entered Task Hours' : 'Comparison Region Entered Task Hours',
        data: [(regionEnteredTaskHours/regionLoggedTotal)*100],
        backgroundColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
        borderWidth: 1,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalRow = ` ${roundDecimalPlaces((formattedValue/100)*regionLoggedTotal, 1)} Hours`
              const percentRow = ` ${roundDecimalPlaces(formattedValue, 1)} %`;
              const labelRows = ["Entered: ",totalRow, percentRow];
              return labelRows;
            },
          },
        },
      },
      {
        label: !compareBy ? 'Region Missing Task Hours' : 'Comparison Region Missing Task Hours',
        data: [(regionMissingTaskHours/regionLoggedTotal)*100],
        backgroundColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
        borderWidth: 1,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalRow = ` ${roundDecimalPlaces((formattedValue/100)*regionLoggedTotal, 1)} Hours`
              const percentRow = ` ${roundDecimalPlaces(formattedValue, 1)} %`;
              const labelRows = ["Missing: ", totalRow, percentRow];
              return labelRows;            
            },
          },
        },
      },
    ],
  };

  // Make field graph

  const fieldsGraph = {
    labels: ['Field Tasks Logged'],
    datasets: [
      {
        label: !compareBy ? 'Field Entered Task Hours' : 'Comparison Field Entered Task Hours',
        data: [(fieldEnteredTaskHours/fieldLoggedTotal)*100],
        backgroundColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
        borderWidth: 1,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalRow = ` ${roundDecimalPlaces((formattedValue/100)*fieldLoggedTotal, 1)} Hours`
              const percentRow = ` ${roundDecimalPlaces(formattedValue, 1)} %`;
              const labelRows = ["Entered: ",totalRow, percentRow];
              return labelRows;
            },
          },
        },
      },
      {
        label: !compareBy ? 'Field Missing Task Hours' : 'Comparison Field Missing Task Hours',
        data: [(fieldMissingTaskHours/fieldLoggedTotal)*100],
        backgroundColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
        borderWidth: 1,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalRow = ` ${roundDecimalPlaces((formattedValue/100)*fieldLoggedTotal, 1)} Hours`
              const percentRow = ` ${roundDecimalPlaces(formattedValue, 1)} %`;
              const labelRows = ["Entered: ",totalRow, percentRow];
              return labelRows;
            },
          },
        },
      },
    ],
  };


  const missingTaskGraphs = {
    'Field': fieldsGraph,
    'Region': regionsGraph,
    'FieldTotalHours': fieldEnteredTaskHours + fieldMissingTaskHours,
    'RegionTotalHours': regionEnteredTaskHours + regionMissingTaskHours,
  };

  return missingTaskGraphs;
}

export function genTasksByTime(opsData, selectedSpecialView, taskFilters, selectedZoneLevel) {
  const timeData = {
    'Days': opsData.opsByDay,
    'Weeks': opsData.opsByWeek,
    'Months': opsData.opsByMonth,
    'Years': opsData.opsByYear,
  };

  const relevantData = timeData[selectedSpecialView];
  const labels = Object.keys(relevantData);
  let graphValues = [];
  const realValuesEntered = [];
  const realValuesMissing = [];

  labels.sort((a, b) => {
    const taskZonesDataA = genTasksLoggedByZone(
      relevantData[a].operationsReport.regionsObject,
      relevantData[a].operationsReport.fieldsObject,
      taskFilters
    );
    const taskZonesDataB = genTasksLoggedByZone(
      relevantData[b].operationsReport.regionsObject,
      relevantData[b].operationsReport.fieldsObject,
      taskFilters
    );
    const labelTaskEnteredValueA = taskZonesDataA['EnteredTaskHours' + selectedZoneLevel];
    const labelTaskMissingValueA = taskZonesDataA['MissingTaskHours' + selectedZoneLevel];
    const loggedValueA =
      labelTaskEnteredValueA > 0
        ? (labelTaskEnteredValueA / (labelTaskEnteredValueA + labelTaskMissingValueA)) * 100
        : 0;

    const labelTaskEnteredValueB = taskZonesDataB['EnteredTaskHours' + selectedZoneLevel];
    const labelTaskMissingValueB = taskZonesDataB['MissingTaskHours' + selectedZoneLevel];
    const loggedValueB =
      labelTaskEnteredValueB > 0
        ? (labelTaskEnteredValueB / (labelTaskEnteredValueB + labelTaskMissingValueB)) * 100
        : 0;
    return taskFilters.specialSort == 'Desc' ? loggedValueB - loggedValueA : loggedValueA - loggedValueB;
  });

  if (taskFilters.specialSort == 'Chronological') {
    labels.sort((a, b) => {
      return relevantData[a].start > relevantData[b].start ? 1 : -1;
    });
  }

  let labelsFormatted = [];
  labels.forEach((label) => {
    const taskZonesData = genTasksLoggedByZone(
      relevantData[label].operationsReport.regionsObject,
      relevantData[label].operationsReport.fieldsObject,
      taskFilters
    );

    const labelTaskEnteredValue = taskZonesData['EnteredTaskHours' + selectedZoneLevel];
    const labelTaskMissingValue = taskZonesData['MissingTaskHours' + selectedZoneLevel];
    const loggedValue =
      labelTaskEnteredValue > 0 ? (labelTaskEnteredValue / (labelTaskEnteredValue + labelTaskMissingValue)) * 100 : 0;

    realValuesEntered.push(labelTaskEnteredValue);
    realValuesMissing.push(labelTaskMissingValue);
    graphValues.push(loggedValue);
    labelsFormatted.push(label.slice(0, 10)); // Format labels to only be in YYYY-MM-DD format
  });

  if (taskFilters.specialCount > 0) {
    if (labels.length > taskFilters.specialCount) {
      graphValues = graphValues.slice(0, taskFilters.specialCount);
      labelsFormatted = labelsFormatted.slice(0, taskFilters.specialCount);
    }
  }

  const timeGraph = {
    labels: labelsFormatted,
    datasets: [
      {
        label: 'Tasks Logged vs. ' + selectedSpecialView,
        data: graphValues,
        borderWidth: 0,
        backgroundColor: customChartProperties.colorGreen,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const percentRow = `Percent Entered: ${roundDecimalPlaces(formattedValue, 2)}%`;
              const enteredRow = `Task Entered Hours: ${roundDecimalPlaces(realValuesEntered[context.dataIndex], 2)}`;
              const missingRow = `Task Missing Hours: ${roundDecimalPlaces(realValuesMissing[context.dataIndex], 2)}`;
              const labelRows = [percentRow, enteredRow, missingRow];
              return labelRows;
            },
          },
        },
      },
    ],
  };
  return timeGraph;
}

export function genTasksLoggedByZone(regionsData, fieldsData, filters, sortingDataset = null, compareBy = false) {
  // Regions
  let regionsNamesList = Object.keys(regionsData);
  let regionsNames = [];
  let regionsValues = [];
  const realValuesEnteredRegion = [];
  const realValuesMissingRegion = [];

  regionsNamesList.sort((a, b) => {
    const tasksLoggedA =
      regionsData[a].enteredTaskHours > 0
        ? (regionsData[a].enteredTaskHours / (regionsData[a].enteredTaskHours + regionsData[a].missingTaskHours)) * 100
        : 0;
    const tasksLoggedB =
      regionsData[b].enteredTaskHours > 0
        ? (regionsData[b].enteredTaskHours / (regionsData[b].enteredTaskHours + regionsData[b].missingTaskHours)) * 100
        : 0;

    return filters.regionsSort == 'Desc' ? tasksLoggedB - tasksLoggedA : tasksLoggedA - tasksLoggedB;
  });

  if (filters.regionsSort == 'Alphabetical') {
    regionsNamesList.sort((a, b) => {
      return a.localeCompare(b);
    });
  }

  if (sortingDataset) {
    // Override all filters and sorting using key from main dataset
    regionsNamesList = sortingDataset.Region.labels;
  }

  let allRegionsEnteredTaskHours = 0;
  let allRegionsMissingTaskHours = 0;
  regionsNamesList.forEach((region) => {
    if (filters.regions.length == 0 || filters.regions.includes(region)) {
      const loggedValue =
        regionsData[region].enteredTaskHours > 0
          ? (regionsData[region].enteredTaskHours /
              (regionsData[region].enteredTaskHours + regionsData[region].missingTaskHours)) *
            100
          : 0;
      regionsValues.push(loggedValue);
      realValuesEnteredRegion.push(regionsData[region].enteredTaskHours);
      realValuesMissingRegion.push(regionsData[region].missingTaskHours);
      regionsNames.push(region);
      allRegionsEnteredTaskHours += regionsData[region].enteredTaskHours;
      allRegionsMissingTaskHours += regionsData[region].missingTaskHours;
    }
  });

  if (filters.regionsCount > 0) {
    if (regionsNamesList.length > filters.regionsCount) {
      regionsValues = regionsValues.slice(0, filters.regionsCount);
      regionsNames = regionsNames.slice(0, filters.regionsCount);
    }
  }

  const regionsGraph = {
    labels: regionsNames,
    datasets: [
      {
        label: !compareBy ? 'Tasks Logged vs. Region' : 'Comparison Tasks Logged vs. Region' ,
        data: regionsValues,
        borderWidth: 0,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const enteredRow = `Task Entered Hours: ${roundDecimalPlaces(
                realValuesEnteredRegion[context.dataIndex],
                2
              )}`;
              const missingRow = `Task Missing Hours: ${roundDecimalPlaces(
                realValuesMissingRegion[context.dataIndex],
                2
              )}`;
              const percentRow = `Percent Entered: ${roundDecimalPlaces(formattedValue, 1)}%`;
              const labelRows = [percentRow, enteredRow, missingRow];
              return labelRows;
            },
          },
        },
      },
    ],
  };

  // Fields
  let fieldsNamesList = Object.keys(fieldsData);
  let fieldsNames = [];
  let fieldsValues = [];
  const realValuesEnteredField = [];
  const realValuesMissingField = [];

  fieldsNamesList.sort((a, b) => {
    const tasksLoggedA =
      fieldsData[a].enteredTaskHours > 0
        ? (fieldsData[a].enteredTaskHours / (fieldsData[a].enteredTaskHours + fieldsData[a].missingTaskHours)) * 100
        : 0;
    const tasksLoggedB =
      fieldsData[b].enteredTaskHours > 0
        ? (fieldsData[b].enteredTaskHours / (fieldsData[b].enteredTaskHours + fieldsData[b].missingTaskHours)) * 100
        : 0;

    return filters.fieldsSort == 'Desc' ? tasksLoggedB - tasksLoggedA : tasksLoggedA - tasksLoggedB;
  });

  if (filters.fieldsSort == 'Alphabetical') {
    fieldsNamesList.sort((a, b) => {
      return a.localeCompare(b);
    });
  }

  if (sortingDataset) {
    // Override all filters and sorting using key from main dataset
    fieldsNamesList = sortingDataset.Field.labels;
  }

  let allFieldsEnteredTaskHours = 0;
  let allFieldsMissingTaskHours = 0;
  fieldsNamesList.forEach((field) => {
    if (filters.fields.length == 0 || filters.fields.includes(field)) {
      const loggedValue =
        fieldsData[field].enteredTaskHours > 0
          ? (fieldsData[field].enteredTaskHours /
              (fieldsData[field].enteredTaskHours + fieldsData[field].missingTaskHours)) *
            100
          : 0;
      fieldsValues.push(loggedValue);
      realValuesEnteredField.push(fieldsData[field].enteredTaskHours);
      realValuesMissingField.push(fieldsData[field].missingTaskHours);
      fieldsNames.push(field);
      allFieldsEnteredTaskHours += fieldsData[field].enteredTaskHours;
      allFieldsMissingTaskHours += fieldsData[field].missingTaskHours;
    }
  });

  if (filters.fieldsCount > 0) {
    if (fieldsNamesList.length > filters.fieldsCount) {
      fieldsValues = fieldsValues.slice(0, filters.fieldsCount);
      fieldsNames = fieldsNames.slice(0, filters.fieldsCount);
    }
  }

  const fieldsGraph = {
    labels: fieldsNames,
    datasets: [
      {
        label: !compareBy ? 'Tasks Logged vs. Field' : 'Comparison Tasks Logged vs. Field' ,
        data: fieldsValues,
        borderWidth: 0,
        backgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const enteredRow = `Task Entered Hours: ${roundDecimalPlaces(
                realValuesEnteredField[context.dataIndex],
                2
              )}`;
              const missingRow = `Task Missing Hours: ${roundDecimalPlaces(
                realValuesMissingField[context.dataIndex],
                2
              )}`;
              const percentRow = `Percent Entered: ${roundDecimalPlaces(formattedValue, 1)}%`;
              const labelRows = [percentRow, enteredRow, missingRow];
              return labelRows;
            },
          },
        },
      },
    ],
  };

  const tasksLoggedByZoneGraphs = {
    'Field': fieldsGraph,
    'Region': regionsGraph,
    'MissingTaskHoursField': allFieldsMissingTaskHours,
    'EnteredTaskHoursField': allFieldsEnteredTaskHours,
    'EnteredTaskHoursRegion': allRegionsEnteredTaskHours,
    'MissingTaskHoursRegion': allRegionsMissingTaskHours,
  };

  return tasksLoggedByZoneGraphs;
}

export function genServiceTotalCompletionData(onTimeTotal, lateTotal, compareBy = false) {
  const totalServices = onTimeTotal + lateTotal;
  const onTimePercent = (onTimeTotal / totalServices) * 100;
  const latePercent = (lateTotal / totalServices) * 100;
  return {
    labels: ['Services Completed'],
    datasets: [
      {
        label: !compareBy ? `On Time` : `Comparison On Time`,
        data: [onTimePercent],
        backgroundColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
        borderWidth: 1,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalRow = `Total: ${onTimeTotal}`
              const percentRow = `Percentage: ${formattedValue}%`;
              const labelRows = ["Completed On Time: ",totalRow, percentRow];
              return labelRows;
            },
          },
        },
      },
      {
        label: !compareBy ? `Late` : `Comparison Late`,
        data: [latePercent],
        backgroundColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
        borderWidth: 1,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalRow = `Total: ${lateTotal}`
              const percentRow = `Percentage: ${formattedValue}%`;
              const labelRows = ["Completed Late: ",totalRow, percentRow];
              return labelRows;
            },
          },
        },
      },
    ],
  };
}

export function genInspectionRateData(totalPass, totalFail, compareBy = false) {
  const totalInspections = totalPass + totalFail;
  const passPercent = (totalPass / totalInspections) * 100;
  const failPercent = (totalFail / totalInspections) * 100;
  const totalInspectionsResults = {
    labels: ['Inspection Rates'],
    datasets: [
      {
        label: !compareBy ? 'Pass' : 'Comparison Pass',
        data: [passPercent],
        backgroundColor: !compareBy ? [customChartProperties.colorGreen] : [customChartProperties.colorBlue],
        borderWidth: 1,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalRow = `Total: ${totalPass}`
              const percentRow = `Percent: ${formattedValue}%`;
              const labelRows = ["Passed: ", totalRow, percentRow];
              return labelRows;
            },
          },
        },
      },
      {
        label: !compareBy ? 'Fail' : 'Comparison Fail',
        data: [failPercent],
        backgroundColor: !compareBy ? [customChartProperties.colorGrey] : [customChartProperties.colorLightGrey],
        borderWidth: 1,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const totalRow = `Total: ${totalFail}`
              const percentRow = `Percent: ${formattedValue}%`;
              const labelRows = ["Failed: ", totalRow, percentRow];
              return labelRows;
            },
          },
        },
      },
    ],
  };
  return totalInspectionsResults;
}

export function genServiceHealth(
  serviceHealthGroups,
  sortingServiceHealthGroups,
  filters,
  serviceIntervalSelected,
  serviceIntervalSelectedUnit,
  compareBy = false
) {

  let serviceHealthGroupKeys = Object.keys(serviceHealthGroups);
  serviceHealthGroupKeys.sort((a, b) => {
    const usageA = sortingServiceHealthGroups[a].averageOverValue;
    const usageB = sortingServiceHealthGroups[b].averageOverValue;
    return filters.serviceHealthVehicleSort == 'Desc' ? usageB - usageA : usageA - usageB;
  });
  if (filters.serviceHealthVehicleSort == 'Alphabetical') {
    serviceHealthGroupKeys.sort((a, b) => {
      const nameA = sortingServiceHealthGroups[a].name;
      const nameB = sortingServiceHealthGroups[b].name;
      return nameA.localeCompare(nameB);
    });
  }

  if (!filters.serviceHealthGroupByMachineType && filters.serviceHealthVehicleCount > 0) {
    if (serviceHealthGroupKeys.length > filters.serviceHealthVehicleCount) {
      serviceHealthGroupKeys = serviceHealthGroupKeys.slice(0, filters.serviceHealthVehicleCount);
    }
  }

  const overByValues = serviceHealthGroupKeys.map((key) => {
    if (serviceIntervalSelected == 'odo' && serviceIntervalSelectedUnit.odo == 'mi') {
      return unitsLengthDisplayConversion(serviceHealthGroups[key].averageOverValue, serviceIntervalSelectedUnit.odo);
    } else {
      return serviceHealthGroups[key].averageOverValue;
    }
  });

  const colors = overByValues.map((value) => {
    return value > 0 ? !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey  :  !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue;
  });

  const serviceHealth = {
    labels: serviceHealthGroupKeys.map((key) => {
      return serviceHealthGroups[key].name;
    }),

    datasets: [
      {
        label: !compareBy ? 'Late Service' : 'Comparison Late Service',
        data: overByValues,
        backgroundColor: colors,
        legendBackgroundColor: !compareBy ? customChartProperties.colorGrey : customChartProperties.colorLightGrey,
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        borderWidth: 1,
        borderSkipped: false,
        tooltip: {
          callbacks: {
            label: (context) => {
              const formattedValue = context.formattedValue;
              const serviceUnit = serviceIntervalSelectedUnit[serviceIntervalSelected];
              const valueAbs = Math.abs(formattedValue.replace(/,/g, ''));
              const lateOrEarly = formattedValue < 0 ? 'Early' : 'Late';
              const averageOverByTooltip = `Average ${lateOrEarly} by: ${roundDecimalPlaces(
                valueAbs,
                1
              )} ${serviceUnit}`;

              const highestOverBy = Math.max(...serviceHealthGroups[serviceHealthGroupKeys[context.dataIndex]].overBy);
              let highestOverDueTooltip = `Largest Late value: No Late Services`;
              if (highestOverBy > 0) {
                highestOverDueTooltip = `Largest Late value: ${roundDecimalPlaces(highestOverBy, 1)} ${serviceUnit}`;
              }

              const totalserviceCount = serviceHealthGroups[serviceHealthGroupKeys[context.dataIndex]].serviceCount;
              const totalServiceCountTooltip = `Total Service Count: ${totalserviceCount}`;

              const tooltipRows = [averageOverByTooltip, highestOverDueTooltip, totalServiceCountTooltip];
              if (filters.serviceHealthGroupByMachineType) {
                const vehicleCount = serviceHealthGroups[serviceHealthGroupKeys[context.dataIndex]].vehicleCount;
                const vehicleCountTooltip = `Total Vehicles Count: ${vehicleCount}`;
                tooltipRows.push(vehicleCountTooltip);
              }
              return tooltipRows;
            },
          },
        },
      },
      {
        label: !compareBy ? 'Early Service' : 'Comparison Early Service',
        data: [],
        stack: compareBy ? "Compare Stack" : "Regular Stack",
        backgroundColor: colors,
        legendBackgroundColor: !compareBy ? customChartProperties.colorGreen : customChartProperties.colorBlue,
      },
      {
        // Hide the Line element while keeping legend
        type: 'line',
        label: 'Target',
        backgroundColor: customChartProperties.colorRed,
        pointRadius: 0,
        borderWidth: 0,
        data: serviceHealthGroupKeys.map(() => {
          return 0;
        }),
        yAxisID: 'TargetAxis',
      },
    ],
  };
  if(compareBy){
    serviceHealth.datasets = serviceHealth.datasets.filter((item) => {
      return item.label != 'Target'
    })
  }

  return serviceHealth;
}

export function genServiceHealthGroupedData(
  vehicleServices,
  filters,
  vehicleSNDict,
  serviceIntervalSelected,
) {

  let vehicleKeys = Object.keys(vehicleServices).filter((vehicleSN) => {
    const vehicleExist = Object.prototype.hasOwnProperty.call(vehicleSNDict, vehicleSN);
      const vehicleHasSelectedService = vehicleServices[vehicleSN].serviceStats[serviceIntervalSelected].overBy.length > 0;
      return vehicleExist && vehicleHasSelectedService;
  });

  // Filter By VehicleSN
  if (filters.serviceHealthVehicleSNs.length > 0) {
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      return filters.serviceHealthVehicleSNs.indexOf(vehicleSN) !== -1;
    });
  }

  // Filter By Machine Type
  if (filters.serviceHealthVehicleType.length > 0) {
    const filterVehicleTypes = filters.serviceHealthVehicleType.map((type) => {
      return parseInt(type);
    });
    vehicleKeys = vehicleKeys.filter((vehicleSN) => {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;
      return filterVehicleTypes.includes(vehicleType);
    });
  }

  // Aggregate ServiceStats from vehicles
  const serviceHealthGroups = {};
  for (let i = 0; i < vehicleKeys.length; i++) {
    const vehicleSN = vehicleKeys[i];
    const vehicleServiceStats = {...vehicleServices[vehicleSN].serviceStats[serviceIntervalSelected]};

    // If vehicle had Services of the selected Interval Type
    if (vehicleServiceStats.overBy.length > 0) {
      const vehicleType =
        vehicleSNDict[vehicleSN]?.machineType != undefined ? vehicleSNDict[vehicleSN]?.machineType : 0;

      if (filters.serviceHealthGroupByMachineType) {
        //  If vehicle type does not have Services Stat Object Initialized
        if (!Object.hasOwnProperty.call(serviceHealthGroups, vehicleType)) {
          serviceHealthGroups[vehicleType] = {
            intervals: [],
            overBy: [],
            vehicleCount: 0,
            name: machineTypeMapping[vehicleType],
          };
        }
        serviceHealthGroups[vehicleType].intervals.push(...vehicleServiceStats.intervals);
        serviceHealthGroups[vehicleType].overBy.push(...vehicleServiceStats.overBy);
        serviceHealthGroups[vehicleType].vehicleCount += 1;
      } else {
        vehicleServiceStats.averageOverValue =
          vehicleServiceStats.overBy.reduce((a, b) => {
            return a + b;
          }, 0) / vehicleServiceStats.overBy.length;
        vehicleServiceStats.averageInterval =
          vehicleServiceStats.overBy.reduce((a, b) => {
            return a + b;
          }, 0) / vehicleServiceStats.intervals.length;
        vehicleServiceStats.serviceCount = vehicleServiceStats.overBy.length;
        vehicleServiceStats.name = vehicleServices[vehicleSN].vehicleName;
        serviceHealthGroups[vehicleSN] = vehicleServiceStats;
      }
    }
  }
  let serviceHealthGroupKeys = vehicleKeys; // Needs to be based on sorting groups

  // Add key stats to machineType grouped stats
  if (filters.serviceHealthGroupByMachineType) {

    // Base data
    const serviceMachineTypes = Object.keys(serviceHealthGroups);
    serviceMachineTypes.forEach((machineType) => {
      const groupedServiceStats = serviceHealthGroups[machineType];

      groupedServiceStats.averageOverValue =
        groupedServiceStats.overBy.reduce((a, b) => {
          return a + b;
        }, 0) / groupedServiceStats.overBy.length;
      groupedServiceStats.averageInterval =
        groupedServiceStats.overBy.reduce((a, b) => {
          return a + b;
        }, 0) / groupedServiceStats.intervals.length;
      groupedServiceStats.serviceCount = groupedServiceStats.overBy.length;
      serviceHealthGroups[machineType] = groupedServiceStats;
    });
    serviceHealthGroupKeys = serviceMachineTypes;
  }

  return serviceHealthGroups;
}
