import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tailselect} from '../../../components/Tailselect';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {CustomTablePagination} from '../../../components/CustomTablePagination';

import {RuleEditModal} from './RuleEditModal';

import {searchFind, KM_TO_MI} from '../../../app/utils';

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Modal,
  Box,
  Button,
  Chip,
  CircularProgress,
} from '@mui/material';

function RulesTab(props) {
  const loading = useSelector((state) => {
    return state.alertview.loading;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });

  const [filters, setFilters] = useState({
    name: [],
    notifications: [],
    type: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    name: [],
    notifications: ['Yes', 'No'],
    type: [],
  });
  const [tableData, setTableData] = useState([]);
  const [tableDataUnfiltered, setTableDataUnfiltered] = useState([]);
  const [rulesDict, setRulesDict] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [ruleConfig, setRuleConfig] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [rulesLoading, setRulesLoading] = useState(true);

  useEffect(() => {
    getRules();
  }, []);

  useEffect(() => {
    filterTableRows(tableDataUnfiltered);
  }, [tableDataUnfiltered, filters, searchText]);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  function filterTableRows(allTableData) {
    const tempTableData = allTableData.filter((rowData) => {
      return rowFilter(rowData, <div>{JSON.stringify(rowData)}</div>);
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }

    setTableData(tempTableData);
  }

  function rowFilter(rowData, row) {
    const nameInFilter = filters.name.includes(rowData.name) || filters.name.length == 0;
    const notificationInFilter =
      filters.notifications.includes(rowData.notifications) || filters.notifications.length == 0;
    const typeInFilter = filters.type.includes(rowData.type) || filters.type.length == 0;
    const search = searchFind(row, searchText.toLowerCase().trim());

    return nameInFilter && notificationInFilter && typeInFilter && search;
  }

  function handleModalOpen() {
    setModalOpen(!modalOpen);
  }

  function handleResetRuleConfig() {
    setRuleConfig({});
  }

  function generateMenu() {
    return (
      <React.Fragment>
        <div className='row my-2'>
          <div className='row ml-auto mx-0 col-md-8 col-xl-4'>
            <button
              className='btn border-dark btn-light ml-auto col-6 px-0'
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Create Rule
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  function generateTableData() {
    if (loading || rulesLoading) {
      return (
        <TableBody id='table-body'>
          <TableRow>
            <TableCell colSpan={100}>
              <div className='m-2'>
                <div className='loader mx-auto'></div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }

    let colorFlip = false;
    const paginatedData =
      rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData;

    return (
      <TableBody id='table-body'>
        {paginatedData.map((rowData, index) => {
          if (!rowData) {
            return null;
          }

          colorFlip = !colorFlip;
          const row = (
            <TableRow key={index} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
              <TableCell>
                <div className='mb-2'>
                  <div className='text-secondary font-weight-bold'>{rowData.name}</div>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <div className='text-secondary font-weight-bold'>{rowData.notifications}</div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div>
                  <div className='text-secondary font-weight-bold'>{rowData.type}</div>
                </div>
              </TableCell>
              <TableCell className='d-none d-md-table-cell'>
                <div>
                  <div className='text-secondary'>{rowData.conditionString}</div>
                </div>
              </TableCell>
              <TableCell className='text-center align-right'>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm'
                    onClick={() => {
                      setRuleConfig(rulesDict[rowData.id]);
                      setModalOpen(true);
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-edit' />
                  </button>
                </div>
              </TableCell>
            </TableRow>
          );
          return row;
        })}
      </TableBody>
    );
  }

  function generateTableHeaders() {
    return (
      <TableHead>
        <TableRow>
          <TableCell width='20%'>
            <TableHeadFilterButtonTitleWrapper title='Rule Name'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#alertview-rule-name-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.name.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='alertview-rule-name-select'
                name='name'
                multiple={true}
                search={true}
                value={filters.name}
                options={filterOptions.name}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell width='15%'>
            <TableHeadFilterButtonTitleWrapper title='Notifications'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#alertview-rule-notifications-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.notifications.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='alertview-rule-notifications-select'
                name='notifications'
                multiple={true}
                search={true}
                value={filters.notifications}
                options={filterOptions.notifications}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell className='d-none d-md-table-cell' width='20%'>
            <TableHeadFilterButtonTitleWrapper title='Rule Type'>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#alertview-rule-type-select').toggle();
                }}
              >
                <FontAwesomeIcon icon='fas fa-filter' style={{color: filters.type.length > 0 && '#4e73df'}} />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='alertview-rule-type-select'
                name='type'
                multiple={true}
                search={true}
                value={filters.type}
                options={filterOptions.type}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell className='d-none d-md-table-cell' width='40%'>
            Conditions
          </TableCell>
          <TableCell width='5%'></TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div className='tab-wrapper'>
      <RuleEditModal
        modalOpen={modalOpen}
        handleModalOpen={handleModalOpen}
        ruleConfig={ruleConfig}
        rulesDict={rulesDict}
        handleResetRuleConfig={handleResetRuleConfig}
        getRules={getRules}
      />

      <div className='table-responsive'>
        <TabMenuTableWrapper
          menu={generateMenu()}
          table={
            <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
              {generateTableHeaders()}
              {generateTableData()}
            </Table>
          }
          pagination={
            <CustomTablePagination
              count={tableData.length}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          }
        />
      </div>
    </div>
  );

  async function getRules() {
    setRulesLoading(true);

    const rulesDictResponse = await fetch('/alertview/getRules', {cache: 'no-store'});
    const rulesDict = await rulesDictResponse.json();

    // Format rules into table data
    const tempTableData = [];
    const tempRulesDict = {};
    const nameFilterOptions = [];
    const typeFilterOptions = [];

    Object.values(rulesDict).forEach((rule) => {
      // Skip rule if disabled
      if (rule.disabled) {
        return;
      }

      // Determine speed unit conversion
      const speedUnits = unitsLengthSystem == 'imperial' ? 'MPH' : 'KPH';
      let ruleValue = rule.conditions.value;
      if (
        ['farmSpeeding', 'roadSpeeding'].includes(rule.type) &&
        ruleValue != 'taskTargetSpeed' &&
        unitsLengthSystem == 'imperial'
      ) {
        ruleValue = Math.round(ruleValue * KM_TO_MI);
        rule.conditions.value = ruleValue;
      }

      // Determine the condition string
      let logicText;
      if (rule.conditions.logic == 'greaterThan') {
        logicText = 'greater than';
      } else if (rule.conditions.logic == 'lessThan') {
        logicText = 'less than';
      } else if (rule.conditions.logic == 'notEqualTo') {
        logicText = 'not equal to';
      }

      let ruleTypeText;
      let conditionString;
      if (rule.type == 'farmSpeeding') {
        ruleTypeText = 'Farm Speeding';

        let speedText = `${ruleValue} ${speedUnits}`;
        if (ruleValue == 'taskTargetSpeed') {
          speedText = 'task target speed';
        }

        conditionString = `If farm speed is ${logicText} ${speedText}`;
      } else if (rule.type == 'roadSpeeding') {
        ruleTypeText = 'Road Speeding';
        conditionString = `If road speed is greater than speed limit by ${ruleValue} ${speedUnits}`;
      } else if (rule.type == 'idling') {
        ruleTypeText = 'Idling';
        conditionString = `If idling duration is greater than ${ruleValue} minutes`;
      } else if (rule.type == 'afterHours') {
        ruleTypeText = 'After Hours Work';

        const startTimeText = DateTime.now()
          .set({
            hour: ruleValue.startHour,
            minute: ruleValue.startMinute,
          })
          .toLocaleString(DateTime.TIME_SIMPLE);

        const endTimeText = DateTime.now()
          .set({
            hour: ruleValue.endHour,
            minute: ruleValue.endMinute,
          })
          .toLocaleString(DateTime.TIME_SIMPLE);

        conditionString = `If after hours work is performed outside of the hours ${startTimeText} and ${endTimeText}`;
      }

      // Rule data formatted
      const formattedRuleData = {
        id: rule.id,
        name: rule.name,
        notifications: rule.notifications ? 'Yes' : 'No',
        type: ruleTypeText,
        conditionString: conditionString,
      };

      tempTableData.push(formattedRuleData);
      tempRulesDict[rule.id] = rule;

      // Add to filter options
      nameFilterOptions.push({
        value: rule.name,
        text: rule.name,
      });
      typeFilterOptions.push({
        value: ruleTypeText,
        text: ruleTypeText,
      });
    });

    setTableDataUnfiltered(tempTableData);
    setRulesDict(tempRulesDict);
    setFilterOptions({
      ...filterOptions,
      name: nameFilterOptions,
      type: typeFilterOptions,
    });
    setRulesLoading(false);
  }
}

export {RulesTab};
