import React, {useState, useEffect, useMemo, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateTime} from 'luxon';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';

import {
  MS_PER_VEHICLEDAY,
  PATH_VEHICLE_DAYS_MAP_RENDER_THRESHOLD,
  unitsAreaConversion,
  unitsLengthDisplayConversion,
} from '../../app/utils';
import {updateZoneZoom, updateVehicleZoom} from './cropviewSlice';

import {Map, useMap} from '@vis.gl/react-google-maps';
import {ScatterplotLayer, GeoJsonLayer, PathLayer, IconLayer, TextLayer} from '@deck.gl/layers';
import {GoogleMapsOverlay} from '@deck.gl/google-maps';

// Icons for vehicle markers
const machineIconMapping = {
  0: '/img/tractor_green.png',
  1: '/img/passenger-vehicle.png',
  2: '/img/tractor_green.png',
  3: '/img/trailer.png',
  4: '/img/atv.png',
  5: '/img/platform.png',
  6: '/img/construction.png',
};

const imgTracGreen = new Image();
imgTracGreen.src = '/img/tractor_green.png';
const imgPassenger = new Image();
imgPassenger.src = '/img/passenger-vehicle.png';
const imgTrailer = new Image();
imgTrailer.src = '/img/trailer.png';
const imgATV = new Image();
imgATV.src = '/img/atv.png';
const imgPlatform = new Image();
imgPlatform.src = '/img/platform.png';
const imgConstruction = new Image();
imgConstruction.src = '/img/construction.png';

const machineIconImageMapping = {
  0: imgTracGreen,
  1: imgPassenger,
  2: imgTracGreen,
  3: imgTrailer,
  4: imgATV,
  5: imgPlatform,
  6: imgConstruction,
};

// Colors to be used for plotting paths
const colorsList = ['#000080', '#b03060', '#ff0000', '#ffd700', '#00ff00', '#00bfff', '#ff00ff', '#ffe4c4', '#000000'];
const zoneColorsList = ['#4daf4a', '#4a4daf', '#af4a99', '#000000', '#ffffff'];

function DeckGLOverlay(props) {
  const map = useMap();
  const overlay = useMemo(() => {
    return new GoogleMapsOverlay(props);
  }, []);

  useEffect(() => {
    overlay.setMap(map);
    return () => {
      return overlay.setMap(null);
    };
  }, [map]);

  overlay.setProps(props);

  return null;
}

function MapControlButtons(props) {
  const map = useMap();
  const ref = useRef();

  useEffect(() => {
    if (map && ref) {
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(ref.current);
    }
  }, [map, ref]);

  return (
    <div ref={ref}>
      <div>
        <button className='btn-lg bg-light mt-2 mr-2' onClick={props.showAll}>
          <FontAwesomeIcon icon='fas fa-map-marker-alt' fixedWidth />
        </button>
      </div>
      <div>
        <button
          className='btn-lg bg-light mr-2'
          onClick={() => {
            return props.setDisplayZones(!props.displayZones);
          }}
        >
          <FontAwesomeIcon icon={props.displayZones ? 'fas fa-clone' : 'far fa-clone'} fixedWidth />
        </button>
      </div>
      {props.displayZones && (
        <React.Fragment>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                props.changeZoneLabels();
              }}
            >
              <FontAwesomeIcon icon={'fas fa-comment-alt'} fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                props.changeZonesColor();
              }}
            >
              <FontAwesomeIcon icon={'fas fa-palette'} fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                props.changeOutOfZoneDisplay();
              }}
            >
              <FontAwesomeIcon icon='fa-solid fa-road' fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                props.setShowGpsTime(!props.showGpsTime);
              }}
            >
              <span className='fa-layers fa-fw'>
                <FontAwesomeIcon icon='fas fa-location-dot' />
                <FontAwesomeIcon
                  icon='far fa-clock'
                  transform='shrink-5 up-8 right-8'
                  style={{color: props.showGpsTime ? 'green' : 'red'}}
                />
              </span>
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

function MapLoadStatusDisplay(props) {
  const map = useMap();
  const ref = useRef();

  const pathsLoading = useSelector((state) => {
    return state.cropview.pathsLoading;
  });
  const pathsVehicleDays = useSelector((state) => {
    return state.cropview.pathsVehicleDays;
  });
  const pathQueryStatus = useSelector((state) => {
    return state.cropview.pathQueryStatus;
  });

  const [displayPathStatus, setDisplayPathStatus] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (map && ref) {
      map.controls[google.maps.ControlPosition.CENTER].push(ref.current);
    }
  }, [map, ref]);

  useEffect(() => {
    setProgress(0);

    if (pathsVehicleDays) {
      const incrementAmount = 5; // %
      const totalMs = pathsVehicleDays * MS_PER_VEHICLEDAY;
      const intervalTime = Math.round(totalMs / (100 / incrementAmount)); // Time to increment by incrementAmount

      // Set a timer that increments the progress
      const timer = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(timer); // Stop the timer once it reaches 100%
            return 100;
          }
          return prev + incrementAmount;
        });
      }, intervalTime);

      // Clean up the timer when the component unmounts or when progress reaches 100
      return () => {
        return clearInterval(timer);
      };
    }
  }, [pathsVehicleDays]);

  useEffect(() => {
    if (!pathsLoading && pathQueryStatus.success && !pathQueryStatus.pathDataTooLarge && props.pathDataWithinMapLimit) {
      setDisplayPathStatus(false);
    } else {
      setDisplayPathStatus(true);
    }
  }, [pathsLoading, pathQueryStatus, props.pathDataWithinMapLimit]);

  if (displayPathStatus) {
    return (
      <div ref={ref}>
        <Collapse in={displayPathStatus} className='w-100'>
          <Alert
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setDisplayPathStatus(false);
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            icon={pathsLoading ? false : undefined}
            variant='outlined'
            severity={
              pathQueryStatus.pathDataTooLarge || !props.pathDataWithinMapLimit
                ? 'warning'
                : !pathQueryStatus.success
                ? 'error'
                : 'success'
            }
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              width: map.getDiv().offsetWidth * 0.65,
              '.MuiAlert-action': {
                paddingLeft: '4px',
              },
              '.MuiAlert-message': {
                display: 'contents',
              },
            }}
          >
            {pathsLoading ? (
              <div className='row d-flex p-2 w-100 align-items-center justify-content-center text-center'>
                <CircularProgressWithLabel value={progress}></CircularProgressWithLabel>
                <div className='pl-1'>Loading Coverage Paths...</div>
              </div>
            ) : (
              <div className='row d-flex p-2 w-100 align-items-center justify-content-center text-center'>
                {pathQueryStatus.pathDataTooLarge
                  ? `Path data request too large, please select a smaller time range`
                  : !pathQueryStatus.success
                  ? `Failed to retrieve all path data, please try again or select a smaller data set.`
                  : !props.pathDataWithinMapLimit
                  ? `Too many paths to display on the map - please apply some filters`
                  : ''}
              </div>
            )}
          </Alert>
        </Collapse>
      </div>
    );
  } else return <div></div>;
}

function CropViewMap(props) {
  const dispatch = useDispatch();

  const zonesDicts = useSelector((state) => {
    return state.cropview.zonesDicts;
  });
  const geofencesDict = useSelector((state) => {
    return state.cropview.geofencesDict;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.cropview.vehicleSNDict;
  });
  const reiActiveDict = useSelector((state) => {
    return state.cropview.reiActiveDict;
  });
  const todayOnly = useSelector((state) => {
    return state.cropview.todayOnly;
  });
  const zoneZoom = useSelector((state) => {
    return state.cropview.zoneZoom;
  });
  const vehicleZoom = useSelector((state) => {
    return state.cropview.vehicleZoom;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const displayedTable = useSelector((state) => {
    return state.cropview.displayedTable;
  });
  const inZonePathDataIn = useSelector((state) => {
    return state.cropview.inZonePathData;
  });
  const outOfZonePathDataIn = useSelector((state) => {
    return state.cropview.outOfZonePathData;
  });
  const vehicleLatestLocationDict = useSelector((state) => {
    return state.cropview.vehicleLatestLocationDict;
  });
  const numDaysInReq = useSelector((state) => {
    return state.cropview.numDaysInReq;
  });

  // NOTE: Use this is we want to use what is in the table as a basis for determining what to show on the map
  const vehicleSerialNumbersInDisplayedAnalytics = useSelector((state) => {
    return state.cropview.vehicleSerialNumbersInDisplayedAnalytics;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  // Map states
  const [zoneColorIndex, setZoneColorIndex] = useState(0);
  const [vehicleColorIndexDict, setVehicleColorIndexDict] = useState({});
  const [zoneLabelsIndex, setZoneLabelsIndex] = useState(1);

  const [zoneInfoWindow, setZoneInfoWindow] = useState(null);
  const [vehInfoWindow, setVehInfoWindow] = useState(null);
  const [loaded, setLoaded] = useState(null);

  const [inZonePathData, setInZonePathData] = useState([]);
  const [outOfZonePathData, setOutOfZonePathData] = useState([]);
  const [inZonePathArrowData, setInZonePathArrowData] = useState([]);
  const [outOfZonePathArrowData, setOutOfZonePathArrowData] = useState([]);
  const [inZoneGpsPointsData, setInZoneGpsPointsData] = useState([]);
  const [outOfZoneGpsPointsData, setOutOfZoneGpsPointsData] = useState([]);
  const [geofenceLayerData, setGeofenceLayerData] = useState(null);
  const [markerLayerData, setMarkerLayerData] = useState(null);
  const [labelLayerData, setLabelLayerData] = useState(null);

  const [pathDataWithinMapLimit, setPathDataWithinMapLimit] = useState(false);
  const [displayZones, setDisplayZones] = useState(true);
  const [displayOutOfZoneTravel, setDisplayOutOfZoneTravel] = useState(false);
  const [showGpsTime, setShowGpsTime] = useState(false);
  const [mapZoomLevel, setMapZoomLevel] = useState(0);
  const [vehicleZoomed, setVehicleZoomed] = useState('');
  const [pathExtremes, setPathExtremes] = useState({
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
  });

  const map = useMap();

  const zoneInfoEnabled =
    typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;

  useEffect(() => {
    if (map != null) {
      showAll();
    }
  }, [pathExtremes]);

  useEffect(() => {
    if (map != null) {
      zoomToZone();
    }
  }, [zoneZoom]);

  useEffect(() => {
    if (map != null) {
      locateVehicle();
    }
  }, [vehicleZoom]);

  useEffect(() => {
    const vehicleSNKeys = Object.keys(vehicleSNDict);
    const vehicleColorIndexDict = {};
    vehicleSNKeys.forEach((vehicleSNKey, index) => {
      vehicleColorIndexDict[vehicleSNKey] = index % colorsList.length;
    });
    setVehicleColorIndexDict(vehicleColorIndexDict);
  }, [vehicleSNDict]);

  // Onload function
  useEffect(() => {
    if (loaded) {
      const zoneInfoWin = new google.maps.InfoWindow();
      const vehInfoWin = new google.maps.InfoWindow();
      setZoneInfoWindow(zoneInfoWin);
      setVehInfoWindow(vehInfoWin);

      map.addListener('zoom_changed', function () {
        setMapZoomLevel(map.getZoom());
      });

      setMapZoomLevel(map.getZoom());
    }
  }, [loaded]);

  // Filter funciton
  function checkDataInFilter(data, filters, outofZone = false) {
    let matchFilter = filters.equipment.includes(data.vehiclesn) || filters.equipment.length == 0;

    if (data.intelliblockNum != -1) {
      matchFilter =
        matchFilter &&
        (filters.tasks.includes(data.taskId) || filters.tasks.length == 0) &&
        (filters.zones.includes(data.blockId.toString()) || filters.zones.length == 0);
    }

    return matchFilter;
  }

  function checkVehicleInFilter(data, filters) {
    return filters.equipment.includes(data.serialNumber) || filters.equipment.length == 0;
  }

  // Generate path data and check if limit exceeded
  useEffect(() => {
    // Get the paths of all in zone vehicles that pass filters
    const filteredInZone = inZonePathDataIn.filter((pathObj) => {
      return checkDataInFilter(pathObj, props.filters);
    });

    // Use the total number of unqiue vehicles that pass the current
    // filter to determine if we are below the vehicle day threshold
    const vehicleSNList = filteredInZone.map((pathObj) => {
      return pathObj.vehiclesn;
    });
    const uniqueVehicleSNList = [...new Set(vehicleSNList)];
    const numVehs = uniqueVehicleSNList.length;
    const numVehicleDays = numDaysInReq * numVehs;

    // If we are below the threshold, set the path data
    if (numVehicleDays < PATH_VEHICLE_DAYS_MAP_RENDER_THRESHOLD) {
      // Set the in zone path data to the already filtered paths (no need to filter again)
      setInZonePathData(filteredInZone);

      // Set out of zone path data to be everything (any specific filters will be applied later)
      setOutOfZonePathData(outOfZonePathDataIn);
      setPathDataWithinMapLimit(true);
    } else {
      setInZonePathData([]);
      setOutOfZonePathData([]);
      setPathDataWithinMapLimit(false);
    }
  }, [inZonePathDataIn, outOfZonePathDataIn, vehicleSerialNumbersInDisplayedAnalytics]);

  // Update path extremes
  useEffect(() => {
    const inZonePathExtremes = {
      latMax: null,
      latMin: null,
      lngMax: null,
      lngMin: null,
    };

    inZonePathDataIn.forEach((pathObj) => {
      pathObj.path.forEach((point) => {
        const lat = point[1];
        const lng = point[0];

        if (lat > inZonePathExtremes.latMax || inZonePathExtremes.latMax == null) {
          inZonePathExtremes.latMax = lat;
        }
        if (lat < inZonePathExtremes.latMin || inZonePathExtremes.latMin == null) {
          inZonePathExtremes.latMin = lat;
        }
        if (lng > inZonePathExtremes.lngMax || inZonePathExtremes.lngMax == null) {
          inZonePathExtremes.lngMax = lng;
        }
        if (lng < inZonePathExtremes.lngMin || inZonePathExtremes.lngMin == null) {
          inZonePathExtremes.lngMin = lng;
        }
      });
    });

    setPathExtremes(inZonePathExtremes);
  }, [inZonePathDataIn]);

  // Generate data for in zone paths points and arrows
  useEffect(() => {
    const inZoneGps = inZonePathData.flatMap((d) => {
      if (!Object.prototype.hasOwnProperty.call(d, 'dateTime')) {
        return [];
      }
      const scatterData = d.path.map((point, index) => {
        return {
          vehiclesn: d.vehiclesn,
          blockId: d.blockId,
          taskId: d.taskId,
          position: point,
          dateTime: d.dateTime[index],
        };
      });
      return scatterData;
    });

    const inZonePathArrow = inZonePathData.flatMap((d) => {
      return generateArrowPositions(d.path, 0.00073).map((pos) => {
        return {
          vehiclesn: d.vehiclesn,
          blockId: d.blockId,
          taskId: d.taskId,
          position: pos,
        };
      });
    });

    setInZoneGpsPointsData(inZoneGps);
    setInZonePathArrowData(inZonePathArrow);
  }, [inZonePathData]);

  // Generate data for out of zone paths points and arrows
  useEffect(() => {
    const outOfZoneGps = outOfZonePathData.flatMap((d) => {
      if (!Object.prototype.hasOwnProperty.call(d, 'dateTime')) {
        return [];
      }

      const scatterData = d.path.map((point, index) => {
        return {
          vehiclesn: d.vehiclesn,
          blockId: d.blockId,
          intelliblockNum: d.intelliblockNum,
          taskId: d.taskId,
          position: point,
          dateTime: d.dateTime[index],
        };
      });
      return scatterData;
    });

    const outOfZonePathArrow = outOfZonePathData.flatMap((d) => {
      return generateArrowPositions(d.path, 0.00073).map((pos) => {
        return {
          vehiclesn: d.vehiclesn,
          blockId: d.blockId,
          intelliblockNum: d.intelliblockNum,
          taskId: d.taskId,
          position: pos,
        };
      });
    });

    setOutOfZoneGpsPointsData(outOfZoneGps);
    setOutOfZonePathArrowData(outOfZonePathArrow);
  }, [outOfZonePathData]);

  // Generate data for geofence and label layers
  useEffect(() => {
    if (typeof zonesDicts.blocks === 'undefined') {
      return;
    }

    // Use geojson layer becaus eof multipolygons, after new zone management is implement
    // consider using polygon layer since geofence structure changed
    const geojsonBase = {
      type: 'FeatureCollection',
      name: 'Geofences Layer',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
        },
      },
      features: [],
    };

    // Create list for labels
    const labelData = [];

    // Iterate through blocks
    Object.values(zonesDicts.blocks).forEach((block) => {
      const fieldName = zonesDicts.fields[block.fieldId] ? zonesDicts.fields[block.fieldId].name : '';
      const regionName = zonesDicts.regions[block.regionId] ? zonesDicts.regions[block.regionId].name :  '';

      const blockIntelliBlockNums = block.intelliblockNums;

      // Determine if REI warning should show
      let reiWarningShow = false;
      let remRei = 0;
      if (todayOnly && Object.prototype.hasOwnProperty.call(reiActiveDict, block.id)) {
        const reiObj = reiActiveDict[block.id];
        const reiEndTime = DateTime.fromISO(reiObj.exitTime['@ts']).plus({hours: reiObj.reiHours});
        remRei = reiEndTime.diff(DateTime.now(), 'hours').toObject()?.hours;

        // Final check to ensure rei interval is valid
        if (remRei && remRei > 0 && remRei <= reiObj.reiHours) {
          reiWarningShow = true;
          remRei = Math.ceil(remRei);
        }
      }

      // Get center point of block
      const latCenter = (block.latMax + block.latMin) / 2;
      const lngCenter = (block.lngMax + block.lngMin) / 2;

      // Determine acreage
      // Convert acreage area to desired units
      let unitsArea = 'ac';
      if (unitsAreaSystem == 'hectare') {
        unitsArea = 'ha';
      }
      const convertedAcreage = unitsAreaConversion(block.areaMeters2, unitsArea, 'meters2');
      const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;

      // Iterate through each multipolygon for plotting
      let labelAdded = false;
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geofencesDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geofence = geofencesDict[blockIntelliBlockNums[j]];

        // Determine row spacing
        let rowSpacingWithUnit = 'N/A';
        if (geofence.rowSpacingMeters > 0) {
          rowSpacingWithUnit =
            unitsLengthSystem == 'imperial'
              ? `${unitsLengthDisplayConversion(geofence.rowSpacingMeters, 'ft').toFixed(2)} ft`
              : `${geofence.rowSpacingMeters.toFixed(2)} m`;
        }

        // Add to label list, once per block
        if (!labelAdded) {
          labelAdded = true;
          labelData.push({
            position: [lngCenter, latCenter],
            blockName: block.name,
            fieldName: fieldName,
            regionName: regionName,
            rowSpacingWithUnit: rowSpacingWithUnit,
            acreageWithUnit: acreageWithUnit,
            reiWarningShow: reiWarningShow,
            remRei: remRei,
          });
        }

        // Add additional data to geofences needed for displaying
        const newGeofenceObj = {...geofence};
        newGeofenceObj.reiWarningShow = reiWarningShow;
        newGeofenceObj.remRei = remRei;
        newGeofenceObj.rowSpacingWithUnit = rowSpacingWithUnit;
        newGeofenceObj.acreageWithUnit = acreageWithUnit;
        newGeofenceObj.blockName = block.name;
        newGeofenceObj.fieldName = fieldName;
        newGeofenceObj.regionName = regionName;

        geojsonBase.features.push(newGeofenceObj);
      }
    });

    setGeofenceLayerData(geojsonBase);
    setLabelLayerData(labelData);
  }, [geofencesDict, zonesDicts, reiActiveDict]);

  // Generate data for vehicle marker layer
  useEffect(() => {
    const vehicleMarkers = [];

    Object.keys(vehicleLatestLocationDict).forEach((vehicleSN) => {
      const locationData = vehicleLatestLocationDict[vehicleSN];

      const vehicle = vehicleSNDict[vehicleSN];
      if (locationData.latestGpsPoint.length > 0) {
        const vehicleMarkerData = {
          name: vehicle.name,
          serialNumber: vehicle.serialNumber,
          bearing: locationData.bearing,
          position: [locationData.latestGpsPoint[0].longitude, locationData.latestGpsPoint[0].latitude],
          machineType: vehicle.machineType,
        };
        vehicleMarkers.push(vehicleMarkerData);
      }
    });

    setMarkerLayerData(vehicleMarkers);
  }, [vehicleLatestLocationDict]);

  // Function to generate arrow positions
  const generateArrowPositions = (path, spacing) => {
    const positions = [];
    let noArrowDistance = 0;

    for (let i = 0; i < path.length - 1; i++) {
      const start = path[i];
      const end = path[i + 1];
      const distance = Math.hypot(end[0] - start[0], end[1] - start[1]);
      const angle = 90 - Math.atan2(end[0] - start[0], end[1] - start[1]) * (180 / Math.PI);
      const numArrows = Math.floor(distance / spacing);

      if (numArrows == 0) {
        if (noArrowDistance + distance > spacing) {
          // Inject an arrow if the total distance without arrow in this path is over the spacing
          const x = start[0] + 0.5 * (end[0] - start[0]);
          const y = start[1] + 0.5 * (end[1] - start[1]);
          positions.push([x, y, angle]);
          noArrowDistance = 0;
        } else {
          noArrowDistance += distance;
        }
      } else {
        noArrowDistance = 0;
      }

      for (let j = 0; j < numArrows; j++) {
        const ratio = (j + 1) / (numArrows + 1); // Position of the arrow
        const x = start[0] + ratio * (end[0] - start[0]);
        const y = start[1] + ratio * (end[1] - start[1]);
        positions.push([x, y, angle]);
      }
    }

    return positions;
  };

  // Button control functions
  function showAll() {
    if (pathExtremes.latMin == null) {
      return;
    }
    const sw = {lat: pathExtremes.latMin, lng: pathExtremes.lngMin};
    const ne = {lat: pathExtremes.latMax, lng: pathExtremes.lngMax};

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(sw);
    bounds.extend(ne);

    map.fitBounds(bounds);
    map.setZoom(map.getZoom() + 0.0);
  }

  function zoomToZone() {
    Object.values(zonesDicts).forEach((zoneDict) => {
      Object.values(zoneDict).forEach((zoneObj) => {
        if (zoneObj.name == zoneZoom) {
          const latMax = parseFloat(zoneObj.latMax);
          const latMin = parseFloat(zoneObj.latMin);
          const lngMax = parseFloat(zoneObj.lngMax);
          const lngMin = parseFloat(zoneObj.lngMin);

          const sw = {lat: latMin, lng: lngMin};
          const ne = {lat: latMax, lng: lngMax};

          const bounds = new google.maps.LatLngBounds();
          bounds.extend(sw);
          bounds.extend(ne);

          map.fitBounds(bounds);
          map.setZoom(map.getZoom() + 0.0);

          dispatch(updateZoneZoom(''));
        }
      });
    });
  }

  function locateVehicle() {
    // Zoom into the selected vehicle
    if (markerLayerData) {
      markerLayerData.forEach((vehicleMarkerData) => {
        if (vehicleMarkerData.serialNumber == vehicleZoom) {
          map.setZoom(17);
          map.panTo(new google.maps.LatLng(vehicleMarkerData.position[1], vehicleMarkerData.position[0]));
          setVehicleZoomed(vehicleZoom);
          dispatch(updateVehicleZoom(''));
        }
      });
    }
  }

  function changeZoneLabels() {
    const zoneInfoEnabled =
      typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;
    const labelCycleLength = zoneInfoEnabled ? 6 : 4;
    setZoneLabelsIndex((zoneLabelsIndex + 1) % labelCycleLength);
  }

  function changeZonesColor() {
    setZoneColorIndex((zoneColorIndex + 1) % zoneColorsList.length);
  }

  function changeOutOfZoneDisplay() {
    setDisplayOutOfZoneTravel(!displayOutOfZoneTravel);
  }

  // Layers for map
  const layers = [
    new GeoJsonLayer({
      id: 'geofences',
      data: geofenceLayerData,
      visible: displayZones,
      stroked: true,
      filled: true,
      pickable: true,
      getFillColor: (geofence) => {
        let hex = zoneColorsList[zoneColorIndex];
        if (geofence.reiWarningShow) {
          hex = '#af4a4d';
        }
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        rgb.push(70); // Opacity
        return rgb;
      },
      getLineColor: (geofence) => {
        let hex = zoneColorsList[zoneColorIndex];
        if (geofence.reiWarningShow) {
          hex = '#ff0000';
        }
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        return rgb;
      },
      getLineWidth: (geofence) => {
        let strokeWeight = 0.2;
        if (geofence.reiWarningShow) {
          strokeWeight = 8;
        }
        return strokeWeight;
      },
      onClick: (clickedObj) => {
        const geofence = clickedObj.object;

        // Create info window content
        let infoWindowContent = `<div><b>${geofence.blockName}</b></div>`;

        // Display remaining rei
        if (geofence.reiWarningShow) {
          infoWindowContent +=
            `<div style="font-size:12px;color:red"><b>DO NOT ENTER</b></div>` +
            `<div style="font-size:10px;color:red"><b>Remaining REI:</b> ${geofence.remRei} hours</div>`;
        }

        // Display field and region
        infoWindowContent +=
          `<div style="font-size:10px"><b>Field:</b> ${geofence.fieldName}</div>` +
          `<div style="font-size:10px"><b>Region:</b> ${geofence.regionName}</div>`;

        // Display zone info
        if (zoneInfoEnabled) {
          const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';
          infoWindowContent +=
            `<div style="font-size:10px"><b>Row Spacing:</b> ${geofence.rowSpacingWithUnit}</div>` +
            `<div style="font-size:10px"><b>${areaLabel}:</b> ${geofence.acreageWithUnit}</div>`;
        }

        if (geofence) {
          zoneInfoWindow.setContent(infoWindowContent);
          zoneInfoWindow.setPosition(new google.maps.LatLng(clickedObj.coordinate[1], clickedObj.coordinate[0]));
          zoneInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          zoneInfoWindow.close();
        }
      },
      updateTriggers: {
        getFillColor: [zoneColorIndex],
        getLineColor: [zoneColorIndex],
      },
    }),

    // In Zone Layers
    new PathLayer({
      id: `inZonePaths`,
      data: inZonePathData,
      visible: !displayOutOfZoneTravel,
      getColor: (d) => {
        const hex = colorsList[vehicleColorIndexDict[d.vehiclesn]];
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });

        return rgb;
      },
      getPath: (d) => {
        return checkDataInFilter(d, props.filters) ? d.path : null;
      },
      getWidth: 1,
      opacity: 0.7,
      widthUnits: 'meters',
      widthMinPixels: 2,
      widthMaxPixels: 3,
      pickable: true,
      onHover: (hoverObj) => {
        if (hoverObj.picked) {
          const infoWindowContent = `<div>${vehicleSNDict[hoverObj.object.vehiclesn].name}</div>`;
          vehInfoWindow.setContent(infoWindowContent);
          vehInfoWindow.setPosition(new google.maps.LatLng(hoverObj.coordinate[1], hoverObj.coordinate[0]));
          vehInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          vehInfoWindow.close();
        }
      },
      updateTriggers: {
        getPath: [props.filters],
      },
    }),

    new IconLayer({
      id: `inZoneArrows`,
      data: inZonePathArrowData,
      visible: !displayOutOfZoneTravel,
      getIcon: () => {
        return {
          url: '/img/arrow.png', // Your arrow icon URL
          width: 100,
          height: 100,
        };
      },
      getPosition: (d) => {
        return checkDataInFilter(d, props.filters) ? [d.position[0], d.position[1]] : null;
      },
      getAngle: (d) => {
        return d.position[2];
      },
      sizeScale: 10,
      sizeUnits: 'meters',
      sizeMinPixels: 0,
      sizeMaxPixels: 15,
      updateTriggers: {
        getPosition: [props.filters],
      },
    }),

    new ScatterplotLayer({
      id: `inZoneGpsPoints`,
      data: inZoneGpsPointsData,
      visible: !displayOutOfZoneTravel && showGpsTime,
      radiusUnits: 'meters',
      getRadius: 1,
      opacity: 0.5,
      getFillColor: (d) => {
        const hex = colorsList[vehicleColorIndexDict[d.vehiclesn]];
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        return rgb;
      },
      getPosition: (d) => {
        return checkDataInFilter(d, props.filters) ? d.position : null;
      },
      pickable: true,
      onHover: (hoverObj) => {
        if (hoverObj.picked) {
          const infoWindowContent = `<div>${vehicleSNDict[hoverObj.object.vehiclesn].name}</div>
                <div>${DateTime.fromISO(hoverObj.object.dateTime)
                  .setZone(customerSettings.general.timeZone)
                  .toLocaleString(DateTime.DATETIME_SHORT)}</div>`;
          vehInfoWindow.setContent(infoWindowContent);
          vehInfoWindow.setPosition(new google.maps.LatLng(hoverObj.coordinate[1], hoverObj.coordinate[0]));
          vehInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          vehInfoWindow.close();
        }
      },
      updateTriggers: {
        getPosition: [props.filters],
      },
    }),

    // Out of Zone Layers
    new PathLayer({
      id: `outOfZonePaths`,
      data: outOfZonePathData,
      visible: displayOutOfZoneTravel,
      getColor: (d) => {
        const hex = colorsList[vehicleColorIndexDict[d.vehiclesn]];
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        return rgb;
      },
      getPath: (d) => {
        return checkDataInFilter(d, props.filters) ? d.path : null;
      },
      getWidth: 1,
      opacity: 0.7,
      widthUnits: 'meters',
      widthMinPixels: 2,
      widthMaxPixels: 3,
      pickable: true,
      onHover: (hoverObj) => {
        if (hoverObj.picked) {
          const infoWindowContent = `<div>${vehicleSNDict[hoverObj.object.vehiclesn].name}</div>`;
          vehInfoWindow.setContent(infoWindowContent);
          vehInfoWindow.setPosition(new google.maps.LatLng(hoverObj.coordinate[1], hoverObj.coordinate[0]));
          vehInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          vehInfoWindow.close();
        }
      },
      updateTriggers: {
        getPath: [props.filters],
      },
    }),

    new IconLayer({
      id: `outOfZoneArrows`,
      data: outOfZonePathArrowData,
      visible: displayOutOfZoneTravel,
      getIcon: () => {
        return {
          url: '/img/arrow.png', // Your arrow icon URL
          width: 200,
          height: 200,
        };
      },
      getPosition: (d) => {
        return checkDataInFilter(d, props.filters) ? [d.position[0], d.position[1]] : null;
      },
      getAngle: (d) => {
        return d.position[2];
      },
      sizeScale: 10,
      sizeUnits: 'meters',
      sizeMinPixels: 0,
      sizeMaxPixels: 15,
      updateTriggers: {
        getPosition: [props.filters],
      },
    }),

    new ScatterplotLayer({
      id: `outOfZoneGpsPoints`,
      data: outOfZoneGpsPointsData,
      visible: displayOutOfZoneTravel && showGpsTime,
      radiusUnits: 'meters',
      getRadius: 1,
      opacity: 0.5,
      getFillColor: (d) => {
        const hex = colorsList[vehicleColorIndexDict[d.vehiclesn]];
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        return rgb;
      },
      getPosition: (d) => {
        return checkDataInFilter(d, props.filters) ? d.position : null;
      },
      pickable: true,
      onHover: (hoverObj) => {
        if (hoverObj.picked) {
          const infoWindowContent = `<div>${vehicleSNDict[hoverObj.object.vehiclesn].name}</div>
                <div>${DateTime.fromISO(hoverObj.object.dateTime)
                  .setZone(customerSettings.general.timeZone)
                  .toLocaleString(DateTime.DATETIME_SHORT)}</div>`;
          vehInfoWindow.setContent(infoWindowContent);
          vehInfoWindow.setPosition(new google.maps.LatLng(hoverObj.coordinate[1], hoverObj.coordinate[0]));
          vehInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          vehInfoWindow.close();
        }
      },
      updateTriggers: {
        getPosition: [props.filters],
      },
    }),

    new IconLayer({
      id: 'vehicleMarkers',
      data: markerLayerData,
      visible: displayedTable == 'equipment',
      pickable: true,
      getSize: 60,
      getIcon: (vehicleMarkerData) => {
        // Determine icon based on vehicle type
        let iconUrl = machineIconMapping[vehicleMarkerData.machineType];

        // Add outline to icon
        if (vehicleZoomed == vehicleMarkerData.serialNumber) {
          const img = machineIconImageMapping[vehicleMarkerData.machineType];
          const offset = 3; // Offset for outline
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = img.width + offset * 2;
          canvas.height = img.height + offset * 2;
          context.drawImage(img, 0, 0);

          // Loop to offset shadow in both x and y direction to generate outline
          for (let x = -offset; x <= offset; x++) {
            for (let y = -offset; y <= offset; y++) {
              context.shadowColor = '#ff9900';
              context.shadowBlur = 0;
              context.shadowOffsetX = x;
              context.shadowOffsetY = y;
              context.drawImage(img, 0, 0);
            }
          }
          iconUrl = canvas.toDataURL();
        }

        return {
          url: iconUrl,
          width: 256,
          height: 256,
        };
      },
      getPosition: (vehicleMarkerData) => {
        return checkVehicleInFilter(vehicleMarkerData, props.filters) ? vehicleMarkerData.position : null;
      },
      getAngle: (vehicleMarkerData) => {
        return vehicleMarkerData.bearing;
      },
      onClick: (clickedObj) => {
        const vehicleMarker = clickedObj.object;

        // Create info window content
        const infoWindowContent = `<div><b>${vehicleMarker.name}</b></div>`;

        if (vehicleMarker) {
          vehInfoWindow.setContent(infoWindowContent);
          vehInfoWindow.setPosition(new google.maps.LatLng(clickedObj.coordinate[1], clickedObj.coordinate[0]));
          vehInfoWindow.open(map);
        } else {
          vehInfoWindow.close();
        }
      },
      updateTriggers: {
        getIcon: [vehicleZoomed],
        getPosition: [props.filters],
      },
    }),

    new TextLayer({
      id: 'labels',
      data: labelLayerData,
      visible: displayZones && zoneLabelsIndex != 0 && mapZoomLevel > 14,
      getPosition: (labelData) => {
        return labelData.position;
      },
      getText: (labelData) => {
        let labelText = labelData.blockName;
        if (zoneLabelsIndex == 1) {
          labelText = labelData.fieldName;
        } else if (zoneLabelsIndex == 2) {
          labelText = labelData.regionName;
        } else if (zoneLabelsIndex == 3) {
          labelText = `Row spacing: ${labelData.rowSpacingWithUnit}`;
        } else if (zoneLabelsIndex == 4) {
          const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';
          labelText = `${areaLabel}: ${labelData.acreageWithUnit}`;
        } else {
          // Default to block name if index is invalid
          labelText = labelData.blockName;
        }

        if (labelData.reiWarningShow) {
          labelText += `\n \nRemaining REI: ${labelData.remRei}h`;
        }

        return labelText;
      },
      getColor: (labelData) => {
        let textColor = [0, 0, 0];
        if (labelData.reiWarningShow) {
          textColor = [200, 0, 0];
        }
        return textColor;
      },
      background: true,
      backgroundPadding: [3, 3],
      getBackgroundColor: [255, 255, 255, 128],
      getSize: 12,
      maxWidth: 6,
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
      updateTriggers: {
        getText: [zoneLabelsIndex],
      },
    }),
  ];

  return (
    <React.Fragment>
      <Map
        defaultCenter={{lat: 37.9718, lng: -122.7651}}
        defaultZoom={9}
        mapTypeId={'hybrid'} // Set map type to satellite
        gestureHandling={'greedy'}
        disableDefaultUI={true} // Optional: removes default UI controls
        styles={[]} // Optional: empty styles array to ensure no style overrides
        reuseMaps={false}
      >
        <DeckGLOverlay
          layers={layers}
          onLoad={() => {
            setLoaded(true);
          }}
        />
        <MapControlButtons
          showGpsTime={showGpsTime}
          setShowGpsTime={setShowGpsTime}
          displayZones={displayZones}
          setDisplayZones={setDisplayZones}
          changeZoneLabels={changeZoneLabels}
          changeZonesColor={changeZonesColor}
          changeOutOfZoneDisplay={changeOutOfZoneDisplay}
          showAll={showAll}
        />
        <MapLoadStatusDisplay pathDataWithinMapLimit={pathDataWithinMapLimit} />
      </Map>
    </React.Fragment>
  );
}

export {CropViewMap};
