// Import for framework tools
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sendGAPageview} from '../../app/utils';
import {getAlertsData} from './alertviewSlice';

// Import dependent components
import {Framework} from '../../components/Framework';
import {AlertsTab} from './Alerts/AlertsTab';
import {RulesTab} from './Rules/RulesTab';
import {Tabs} from '../../components/Tabs';
import {TabContent} from '../../components/TabContent';

function Alertview() {
  const dispatch = useDispatch();

  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [alertviewTabs, setAlertviewTabs] = useState([]);
  const [alertviewIcons, setAlertviewIcons] = useState([]);
  const [alertviewContent, setAlertviewContent] = useState([]);

  useEffect(async () => {
    document.title = 'IntelliCulture | AlertView';
    sendGAPageview(document.title);
    determineTabDisplay();
    dispatch(getAlertsData());
  }, []);

  function determineTabDisplay() {
    const tabs = [];
    const icons = [];
    const content = [];

    // Alerts tab
    tabs.push('Alerts');
    icons.push('fa-solid fa-circle-exclamation');
    content.push(<AlertsTab key='Alerts' />);

    // Rules tab
    if (userSettings.roleViewAccess['alertviewManagement']) {
      tabs.push('Rules');
      icons.push('fa-solid fa-gavel');
      content.push(<RulesTab key='Rules' />);
    }

    setAlertviewTabs(tabs);
    setAlertviewIcons(icons);
    setAlertviewContent(content);
  }

  return (
    <React.Fragment>
      <Framework activePage='alertview' pageName='AlertView'>
        <div className='container-fluid' id='tab-wrapper'>
          <div className='row'>
            <div className='col-12'>
              <Tabs tabs={alertviewTabs} icons={alertviewIcons} />
            </div>
          </div>
          <TabContent content={alertviewContent} />
        </div>
      </Framework>
    </React.Fragment>
  );
}

export {Alertview};
