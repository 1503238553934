import React, {useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {DateTime} from 'luxon';
import {
  sendGAEvent,
  unitsLengthSubmitConversion,
  fetchPostAuthSafe,
  sortVehicleNamesHelper,
  deepCopy,
  dollarRegex,
  handleAllowOnlyNumbers,
} from '../../../app/utils';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalColumnSection,
  ModalWideColumnSection,
  ModalInputSection,
  ModalBody,
  ModalWarning,
  ModalNote,
  ModalNoteInspectionLinks,
  ModalCollapsableRow,
} from '../../../components/Modal';
import {Tailselect} from '../../../components/Tailselect';
import {getServiceData} from '../shopviewSlice';

const defaultInputs = {
  vehicle: '',
  vehicleSN: '',
  task: [],
  taskId: [],
  engineHours: '',
  date: '',
  odometer: '',
  reviewedBy: '',
  notes: '',
  serviceTaskObj: [],
};

function AddHistoryModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const vehiclesLookUp = useSelector((state) => {
    return state.shopview.vehiclesLookUp;
  });
  const serviceTasks = useSelector((state) => {
    return state.shopview.serviceTasks;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });

  const [selectOptions, setSelectOptions] = useState({
    vehicles: [],
    tasks: [],
  });
  const [inputs, setInputs] = useState(defaultInputs);
  const [warnings, setWarnings] = useState({});
  const [forceTriggered, setForceTriggered] = useState(false);
  const [unitsLength, setUnitsLength] = useState('km');

  const [submitting, setSubmitting] = useState(false);
  const multiServiceEnabled =
    customerSettings.shopview.addHistoryMultiServiceAdminOnly == false ||
    ['customer_admin', 'ic_admin'].includes(userSettings.role);

  const [collapseOpen, setCollapseOpen] = useState({});
  const [modalOpenTrigger, setModalOpenTrigger] = useState(false);

  useEffect(() => {
    // Determine length units based on customer length units system
    if (unitsLengthSystem == 'imperial') {
      setUnitsLength('mi');
    } else {
      setUnitsLength('km');
    }
  }, [unitsLengthSystem]);

  useEffect(() => {
    function modalOpen() {
      sendGAEvent('modal_open', 'AddHistory', 'shopview');
      setModalOpenTrigger(true);
      setModalOpenTrigger(false);
    }
    // Set default values when modal opened
    $('#shopview-add-history-modal').on('show.bs.modal', modalOpen);
    return () => {
      // Remove event listener when component is unmounted
      $('#shopview-add-history-modal').off('show.bs.modal', modalOpen);
    };
  }, []);

  useEffect(() => {
    if (modalOpenTrigger) {
      setDefault();
    }
  }, [modalOpenTrigger]);

  useEffect(() => {
    vehicleOptions();
  }, [vehiclesLookUp]);

  useEffect(() => {
    // Clear warnings if values change
    setWarnings({});
    setForceTriggered(false);
  }, [inputs.engineHours, inputs.odometer, inputs.date, inputs.vehicle]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (
      ['laborCost', 'laborTime', 'partsCost', 'engineHours', 'odometer'].includes(name) &&
      value &&
      (!dollarRegex.test(value) || value.includes('-'))
    ) {
      return;
    }

    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  function handleServiceDetailsObjectChange(event, index) {
    const name = event.target.name;
    const value = event.target.value;

    if (
      ['laborCost', 'laborTime', 'partsCost', 'engineHours', 'odometer'].includes(name) &&
      value &&
      (!dollarRegex.test(value) || value.includes('-'))
    ) {
      return;
    }

    setInputs((values) => {
      const tempNewValues = {
        ...values,
      };

      tempNewValues.serviceTaskObj[index].serviceDetailsObject[name] = value;
      return tempNewValues;
    });
  }

  function setDefault() {
    setWarnings({});
    setInputs(defaultInputs);
    setInputs((values) => {
      return {
        ...values,
        date: DateTime.local().toISODate(),
      };
    });
    vehicleOptions();
  }

  async function submitModal() {
    /*
     Check the criteria for accepting the modal entry
    Since warningDataObj has the serviceTask schema,
    we check against it to determine what the serviceTask's required intervals are
    */
    const vehicleEmpty = inputs.vehicle.trim() == '';
    // check if any service task is selected
    const taskEmpty = inputs.task.length == 0 || inputs.serviceTaskObj.length == 0;

    // check if any service task require engine hour/odometer
    let engineHoursRequired = false;
    let odometerRequired = false;
    if (!taskEmpty) {
      for (let i = 0; i < inputs.serviceTaskObj.length; i++) {
        const serviceTaskObj = inputs.serviceTaskObj[i];
        if (serviceTaskObj.engine_hours_interval && serviceTaskObj.engine_hours_interval != '') {
          engineHoursRequired = true;
        }
        if (serviceTaskObj.odometer_interval && serviceTaskObj.odometer_interval != '') {
          odometerRequired = true;
        }
      }
    }

    const engineHoursEmpty = inputs.engineHours === '' && engineHoursRequired;
    const odometerEmpty = inputs.odometer === '' && odometerRequired;

    // Always require date
    const dateEmpty = inputs.date == '';

    const localTimeZone = DateTime.local().zoneName;
    // Get the current dateTime at the system local timezone midnight
    const currentDate = DateTime.local({zone: localTimeZone}).set({hour: 0, minute: 0, second: 0, millisecond: 0});
    // Get the entryDate dateTime at the system local timezone midnight
    const entryDate = DateTime.fromFormat(inputs.date, 'yyyy-LL-dd', {zone: localTimeZone});

    // Determine if the entered date is in the future
    const dateFuture = entryDate > currentDate;

    // Set any warnings
    setWarnings((values) => {
      return {
        ...values,
        warningVehicle: vehicleEmpty,
        warningTask: taskEmpty,
        warningEngineHours: engineHoursEmpty,
        warningOdometer: odometerEmpty,
        warningDate: dateEmpty,
        warningDateGreaterFault: dateFuture,
      };
    });

    // If all submit criterias are met POST the data to the server
    if (
      !vehicleEmpty &&
      !taskEmpty &&
      !engineHoursEmpty &&
      !odometerEmpty &&
      !dateEmpty &&
      !dateFuture &&
      !submitting
    ) {
      setSubmitting(true);
      // Differentiate between '' vs 0 values
      let engineHours = parseFloat(inputs.engineHours);
      if (isNaN(engineHours)) {
        engineHours = '';
      }
      let odometer = parseFloat(unitsLengthSubmitConversion(inputs.odometer, unitsLength));
      if (isNaN(odometer)) {
        odometer = '';
      }

      // // Interpret cost
      // let laborCostVal = parseFloat(inputs.laborCost);
      // if (isNaN(laborCostVal)) {
      //   const laborCostString = `${inputs.laborCost}`;
      //   laborCostVal = parseFloat(laborCostString.replace(/[^0-9.\-]/g, '')) || 0;
      // }

      // let partsCostVal = parseFloat(inputs.partsCost);
      // if (isNaN(partsCostVal)) {
      //   const partsCostString = `${inputs.partsCost}`;
      //   partsCostVal = parseFloat(partsCostString.replace(/[^0-9.\-]/g, '')) || 0;
      // }

      const serviceTaskObjInterpreted = inputs.serviceTaskObj.map((serviceTask) => {
        return {
          ...serviceTask,
          serviceDetailsObject: {
            ...serviceTask.serviceDetailsObject,
            laborCost: parseFloat(serviceTask.serviceDetailsObject.laborCost) || 0,
            partsCost: parseFloat(serviceTask.serviceDetailsObject.partsCost) || 0,
            laborTime: parseFloat(serviceTask.serviceDetailsObject.laborTime) || '',
            workOrder: serviceTask.serviceDetailsObject.workOrder.trim(),
            invoice: serviceTask.serviceDetailsObject.invoice.trim(),
            supplier: serviceTask.serviceDetailsObject.supplier.trim(),
          },
        };
      });

      const postData = {
        vehicle: inputs.vehicle.trim(),
        vehicleSN: inputs.vehicleSN.trim(),
        task: inputs.task,
        taskId: inputs.taskId,
        engineHours: engineHours,
        odometer: odometer,
        date: inputs.date,
        reviewedBy: inputs.reviewedBy.trim(),
        notes: inputs.notes.trim(),
        serviceTaskObj: serviceTaskObjInterpreted,
      };

      // If warnings showing a submission still happens, use the force method to update
      if (forceTriggered) {
        postData.force = true;
      }

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };

      const addEntryReq = await fetchPostAuthSafe(
        '/shopview/addEntry',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const addEntryRepData = await addEntryReq.json();
      if (addEntryRepData.errorMsg) {
        navigate('/error', {state: {errorMsg: addEntryRepData.errorMsg}});
      }

      if (
        addEntryRepData.odometerFault ||
        addEntryRepData.engineHourFault ||
        addEntryRepData.odometerGreaterThanCurrent ||
        addEntryRepData.engineHourGreaterThanCurrent ||
        addEntryRepData.engineHoursExpectedValue ||
        addEntryRepData.engineHoursMaximumValue
      ) {
        const warningEngineHoursExpectedValue = addEntryRepData.engineHoursExpectedValue;
        const warningEngineHoursMaximumValue = addEntryRepData.engineHoursMaximumValue;
        const warningOdometerMoreThanCurrent = addEntryRepData.odometerGreaterThanCurrent == 1;
        const warningEngineHoursMoreThanCurrent = addEntryRepData.engineHourGreaterThanCurrent == 1;

        setWarnings((values) => {
          return {
            ...values,
            warningEngineHoursExpectedValue: warningEngineHoursExpectedValue,
            warningEngineHoursMaximumValue: warningEngineHoursMaximumValue,
            warningOdometerMoreThanCurrent: warningOdometerMoreThanCurrent,
            warningEngineHoursMoreThanCurrent: warningEngineHoursMoreThanCurrent,
            warningOdometerGreaterFault: addEntryRepData.odometerFault == 1,
            warningOdometerLesserFault: addEntryRepData.odometerFault == -1,
            warningEngineHoursGreaterFault: addEntryRepData.engineHourFault == 1,
            warningEngineHoursLesserFault: addEntryRepData.engineHourFault == -1,
          };
        });

        // Set forceTriggered for resubmitting
        if (
          warningOdometerMoreThanCurrent ||
          warningEngineHoursMoreThanCurrent ||
          warningEngineHoursExpectedValue ||
          warningEngineHoursMaximumValue
        ) {
          setForceTriggered(true);
        }
      } else {
        $('#shopview-add-history-modal').modal('hide');
        setTimeout(function () {
          dispatch(getServiceData());
        }, 500);
      }

      sendGAEvent('modal_submit', 'AddHistory', 'shopview');
      setSubmitting(false);
    }
  }

  function vehicleOptions() {
    // Add options to vehicle select dropdown
    const vehicleOptions = [];
    for (const serialNumber in vehiclesLookUp) {
      if (vehiclesLookUp.hasOwnProperty(serialNumber) && !vehiclesLookUp[serialNumber].archived) {
        vehicleOptions.push({value: serialNumber, text: vehiclesLookUp[serialNumber].name});
      }
    }

    vehicleOptions.sort((a, b) => {
      return sortVehicleNamesHelper(a.text, b.text);
    });

    setSelectOptions((values) => {
      return {...values, vehicles: vehicleOptions};
    });

    // Trigger a vehicle select once populated
    if (vehicleOptions.length > 0) {
      vehicleSelect(document.getElementById('shopview-add-history-vehicle'));
    }
  }

  function vehicleSelect(select) {
    // Get vehicle name given serialNumber
    const vehicleSN = select.value;
    let vehicleName = '';
    if (Object.prototype.hasOwnProperty.call(vehiclesLookUp, vehicleSN)) {
      vehicleName = vehiclesLookUp[vehicleSN].name;
    }
    const historicalOptions = [];
    const activeOptions = [];

    // Get service tasks that correspond to selected vehicle
    const filteredServiceTasks = serviceTasks.filter((serviceTask) => {
      return serviceTask.data.vehicleSN == vehicleSN;
    });

    // Separate service tasks between active and historical based on deleted status
    for (let i = 0; i < filteredServiceTasks.length; i++) {
      const taskName = filteredServiceTasks[i].data.name;
      const taskId = filteredServiceTasks[i].data.id;
      if (filteredServiceTasks[i].data.deleted) {
        historicalOptions.push({value: taskId, text: taskName, group: 'Unscheduled Services'});
      } else {
        activeOptions.push({value: taskId, text: taskName, group: 'Scheduled Services'});
      }
    }
    activeOptions.sort((a, b) => {
      return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : b.text.toLowerCase() > a.text.toLowerCase() ? -1 : 0;
    });
    historicalOptions.sort((a, b) => {
      return a.text.toLowerCase() > b.text.toLowerCase() ? 1 : b.text.toLowerCase() > a.text.toLowerCase() ? -1 : 0;
    });
    // Want to see the unscheduled tasks first as per Ami / Paul / Cole in product meeting
    const options = historicalOptions.concat(activeOptions);

    setInputs((values) => {
      return {
        ...values,
        vehicle: vehicleName,
        vehicleSN: vehicleSN,
      };
    });
    setSelectOptions((values) => {
      return {...values, tasks: options};
    });

    // Trigger a task select once populated
    if (options.length > 0) {
      taskSelectDefault(document.getElementById('shopview-add-history-task'), options[0]);
    } else {
      taskSelectDefault(document.getElementById('shopview-add-history-task'));
    }
  }

  function taskSelect(select, item) {
    const selectedTaskNames = [];
    const selectedTaskIds = [];
    const selectedTaskObjects = [];

    const selectObj = tail.select(`#${'shopview-add-history-task'}`);
    const selectedOptions = [...selectObj.options.selected];
    for (let i = 0; i < selectedOptions.length; i++) {
      const selectedOptionId = selectedOptions[i].value;
      const selectedOptionName = selectedOptions[i].label;
      // Get service task doc given task name
      let serviceTask = serviceTasks.find((serviceTask) => {
        return serviceTask.data.name == selectedOptionName && serviceTask.data.id == selectedOptionId;
      });

      // Default Service Details Object
      const defaultServiceDetailsObject = {
        workOrder: '',
        laborCost: '',
        laborTime: '',
        invoice: '',
        partsCost: '',
        supplier: '',
      };

      // If no service task exists for given task name, allow the creation of a new service task
      if (serviceTask === undefined) {
        serviceTask = {
          createNewServiceTask: true,
          name: selectedOptionName,
          id: selectedTaskIds,
          serviceDetailsObject: {...defaultServiceDetailsObject},
        };
      } else {
        serviceTask = deepCopy(serviceTask.data);
        serviceTask.serviceDetailsObject = {
          ...defaultServiceDetailsObject,
          ...(serviceTask.hasOwnProperty('average_labor_hours') ? {laborTime: serviceTask['average_labor_hours']} : {}),
          ...(serviceTask.hasOwnProperty('average_labor_cost') ? {laborCost: serviceTask['average_labor_cost']} : {}),
          ...(serviceTask.hasOwnProperty('average_parts_cost') ? {partsCost: serviceTask['average_parts_cost']} : {}),
        };
      }
      selectedTaskNames.push(selectedOptionName);
      selectedTaskIds.push(selectedOptionId);
      selectedTaskObjects.push(serviceTask);
    }

    setInputs((values) => {
      return {
        ...values,
        task: selectedTaskNames,
        taskId: selectedTaskIds,
        serviceTaskObj: selectedTaskObjects,
      };
    });
  }

  function taskSelectDefault(select, item = undefined) {
    if (multiServiceEnabled) {
      setInputs((values) => {
        return {
          ...values,
          task: [],
          taskId: [],
          serviceTaskObj: [],
        };
      });
    } else {
      const selectObj = tail.select(`#${'shopview-add-history-task'}`);

      let taskId;
      let taskName;

      if (selectObj.options.selected.length > 0) {
        taskId = selectObj.options.selected[0].value;
        taskName = selectObj.options.selected[0].text;
      }

      // Get service task doc given task name
      let serviceTask = serviceTasks.find((serviceTask) => {
        return serviceTask.data.name == taskName && serviceTask.data.id == taskId;
      });

      // Default Service Details Object
      const defaultServiceDetailsObject = {
        workOrder: '',
        laborCost: '',
        laborTime: '',
        invoice: '',
        partsCost: '',
        supplier: '',
      };

      // If no service task exists for given task name, allow the creation of a new service task
      if (serviceTask === undefined && taskName != '') {
        serviceTask = {
          createNewServiceTask: true,
          name: taskName,
          id: taskId,
          serviceDetailsObject: {...defaultServiceDetailsObject},
        };
      } else if (serviceTask !== undefined) {
        serviceTask = deepCopy(serviceTask.data);
        serviceTask.serviceDetailsObject = {
          ...defaultServiceDetailsObject,
          ...(serviceTask.hasOwnProperty('average_labor_hours') ? {laborTime: serviceTask['average_labor_hours']} : {}),
          ...(serviceTask.hasOwnProperty('average_labor_cost') ? {laborCost: serviceTask['average_labor_cost']} : {}),
          ...(serviceTask.hasOwnProperty('average_parts_cost') ? {partsCost: serviceTask['average_parts_cost']} : {}),
        };
      }

      if (typeof taskId === 'undefined') {
        setInputs((values) => {
          return {
            ...values,
            task: [],
            taskId: [],
            serviceTaskObj: [],
          };
        });
      } else {
        setInputs((values) => {
          return {
            ...values,
            task: [taskName],
            taskId: [taskId],
            serviceTaskObj: [serviceTask],
          };
        });
      }
    }
  }

  function generateServiceDetailsSection() {
    if (inputs.task.length == 0) {
      // No Service Task Selected
      return (
        <ModalRowSection underline={true}>
          <ModalWideColumnSection>
            <h5>Service Details</h5>
            <p>No Service Selected</p>
          </ModalWideColumnSection>
        </ModalRowSection>
      );
    }
    if (inputs.task.length > 1) {
      // Multiple Service Task Selected
      let totalCost = 0;
      let totalLaborHours = 0;

      for (let i = 0; i < inputs.serviceTaskObj.length; i++) {
        const tempServiceCostObj = inputs.serviceTaskObj[i].serviceDetailsObject;
        totalCost += parseFloat(tempServiceCostObj?.laborCost) || 0;
        totalCost += parseFloat(tempServiceCostObj?.partsCost) || 0;
        totalLaborHours += parseFloat(tempServiceCostObj?.laborTime) || 0;
      }

      return (
        <ModalRowSection underline={true}>
          <ModalWideColumnSection>
            <h5>Service Details</h5>
          </ModalWideColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Total Cost ($)'>
              <input
                type='text'
                name='workOrder'
                className='form-control flex-fill'
                value={Math.round(totalCost * 100) / 100 || ''}
                disabled
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Total Labor Time (Hrs)'>
              <input
                type='text'
                name='workOrder'
                className='form-control flex-fill'
                value={Math.round(totalLaborHours * 100) / 100 || ''}
                disabled
              />
            </ModalInputSection>
          </ModalColumnSection>
          {inputs?.serviceTaskObj.map((serviceTask, index) => {
            return (
              <ModalCollapsableRow
                title={serviceTask.name}
                collapseOpen={collapseOpen}
                setCollapseOpen={setCollapseOpen}
                section={serviceTask.name}
                key={serviceTask.name}
              >
                {generateServiceDetailsInputs(serviceTask, index)}
              </ModalCollapsableRow>
            );
          })}
        </ModalRowSection>
      );
    }

    // Default Case Single Service Task Selected
    return (
      <React.Fragment>
        <ModalRowSection>
          <ModalWideColumnSection>
            <h5>Service Details ({inputs?.serviceTaskObj[0].name})</h5>
          </ModalWideColumnSection>
        </ModalRowSection>
        {generateServiceDetailsInputs(inputs?.serviceTaskObj[0], 0)}
      </React.Fragment>
    );
  }

  function generateServiceDetailsInputs(serviceTask, index) {
    return (
      <ModalRowSection underline={true}>
        <ModalColumnSection>
          <ModalInputSection label='Work Order'>
            <input
              type='text'
              name='workOrder'
              className='form-control flex-fill'
              value={serviceTask.serviceDetailsObject.workOrder}
              onChange={(e) => {
                handleServiceDetailsObjectChange(e, index);
              }}
            />
          </ModalInputSection>
          <ModalInputSection label='Labor Cost ($)'>
            <input
              name='laborCost'
              className='form-control flex-fill'
              value={serviceTask.serviceDetailsObject.laborCost}
              onChange={(e) => {
                handleServiceDetailsObjectChange(e, index);
              }}
            />
          </ModalInputSection>
          <ModalInputSection label='Labor Time (Hrs)'>
            <input
              type='text'
              name='laborTime'
              className='form-control flex-fill'
              value={serviceTask.serviceDetailsObject.laborTime}
              step={0.01}
              onChange={(e) => {
                handleServiceDetailsObjectChange(e, index);
              }}
              onKeyDown={handleAllowOnlyNumbers}
            />
          </ModalInputSection>
        </ModalColumnSection>
        <ModalColumnSection>
          <ModalInputSection label='Invoice Number'>
            <input
              type='text'
              name='invoice'
              className='form-control flex-fill'
              value={serviceTask.serviceDetailsObject.invoice}
              onChange={(e) => {
                handleServiceDetailsObjectChange(e, index);
              }}
            />
          </ModalInputSection>
          <ModalInputSection label='Parts Cost ($)'>
            <input
              name='partsCost'
              className='form-control flex-fill'
              value={serviceTask.serviceDetailsObject.partsCost}
              onChange={(e) => {
                handleServiceDetailsObjectChange(e, index);
              }}
            />
          </ModalInputSection>
          <ModalInputSection label='Supplier Name'>
            <input
              type='text'
              name='supplier'
              className='form-control flex-fill'
              value={serviceTask.serviceDetailsObject.supplier}
              onChange={(e) => {
                handleServiceDetailsObjectChange(e, index);
              }}
            />
          </ModalInputSection>
        </ModalColumnSection>
      </ModalRowSection>
    );
  }

  return createPortal(
    <ModalFramework id='shopview-add-history-modal'>
      <ModalHeader title='Record Service' />
      <ModalBody submitting={submitting}>
        {warnings.warningVehicle && <ModalWarning text='Select a vehicle' />}
        {warnings.warningTask && <ModalWarning text='Select a task' />}
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <ModalInputSection label='Vehicle'>
              <Tailselect
                class='col-12'
                id='shopview-add-history-vehicle'
                name='vehicle'
                search={true}
                value={inputs.vehicle}
                options={selectOptions.vehicles}
                onChange={vehicleSelect}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Service'>
              <Tailselect
                class='col-12'
                id='shopview-add-history-task'
                name='task'
                search={true}
                value={multiServiceEnabled ? inputs.taskId : inputs.taskId[0]}
                options={selectOptions.tasks}
                onChange={taskSelect}
                multiple={multiServiceEnabled}
                addCustom={true}
                addCustomDefaultGroup={'Unscheduled Services'}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <div className='d-flex flex-column w-100 col-12'>
            {inputs?.serviceTaskObj.map((serviceTask) => {
              return (
                <React.Fragment key={serviceTask.name}>
                  {serviceTask?.notes && <ModalNote text={`${serviceTask.name}: ${serviceTask.notes}`} />}
                  {serviceTask.inspectionReportIds && serviceTask.inspectionReportIds.length > 0 && (
                    <ModalNoteInspectionLinks
                      modalId='shopview-add-history-modal'
                      setLinkedInspectionReportId={props.setLinkedInspectionReportId}
                      inspectionReportIdsList={serviceTask.inspectionReportIds}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </ModalRowSection>
        {warnings.warningEngineHours && (
          <ModalWarning text='One or more of the selected Service Tasks require Engine Hours, Please enter a valid Engine Hours value' />
        )}
        {warnings.warningOdometer && (
          <ModalWarning text='One or more of the selected Service Tasks require Odometer, Please enter a valid Odometer value' />
        )}
        {warnings.warningDate && <ModalWarning text='Enter Date Completed' />}
        {warnings.warningDateGreaterFault && <ModalWarning text='Entry of future dates is not enabled' />}
        {warnings.warningOdometerGreaterFault && (
          <ModalWarning text='The input odometer value is greater than a following entry' />
        )}
        {warnings.warningOdometerLesserFault && (
          <ModalWarning text='The input odometer value is less than a previous entry' />
        )}
        {warnings.warningEngineHoursGreaterFault && (
          <ModalWarning text='The input engine hours value is greater than a following entry' />
        )}
        {warnings.warningEngineHoursLesserFault && (
          <ModalWarning text='The input engine hours value is less than a previous entry' />
        )}
        {warnings.warningOdometerMoreThanCurrent && (
          <ModalWarning
            text={`The input odometer value is more than the measured on the vehicle. 
              Please click submit again to confirm.`}
          />
        )}
        {warnings.warningEngineHoursMoreThanCurrent && (
          <ModalWarning
            text={`The input engine hours value is more than the measured on the vehicle. 
              Please click submit again to confirm.`}
          />
        )}
        {!!warnings.warningEngineHoursExpectedValue && (
          <ModalWarning
            text={`Your input (${inputs.engineHours} hrs) is much different than IntelliCulture's reading
              (${Math.floor(warnings.warningEngineHoursExpectedValue)} hrs). 
              Clicking submit will override IntelliCulture's hours.`}
          />
        )}
        {!!warnings.warningEngineHoursMaximumValue && (
          <ModalWarning
            text={`Your input (${inputs.engineHours} hrs) is very large. Please verify your input. 
              Clicking submit will override IntelliCulture's hours.`}
          />
        )}
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <ModalInputSection label='Engine Hours'>
              <input
                type='text'
                name='engineHours'
                className='form-control flex-fill'
                value={inputs.engineHours}
                step={0.01}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
            <ModalInputSection label='Date Completed'>
              <input
                type='date'
                name='date'
                className='form-control flex-fill'
                value={inputs.date}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label={`Odometer (${unitsLength})`}>
              <input
                type='text'
                name='odometer'
                className='form-control flex-fill'
                value={inputs.odometer}
                step={0.01}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        {generateServiceDetailsSection()}
        <ModalRowSection underline={false}>
          <ModalWideColumnSection>
            <ModalInputSection label='Reviewed By'>
              <input
                type='text'
                name='reviewedBy'
                className='form-control flex-fill'
                value={inputs.reviewedBy}
                onChange={handleChange}
              />
            </ModalInputSection>
            <ModalInputSection label='Notes'>
              <input
                type='textarea'
                name='notes'
                className='form-control flex-fill'
                value={inputs.notes}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter onSubmit={submitModal} submitting={submitting} />
    </ModalFramework>,
    document.getElementById('app')
  );
}

export {AddHistoryModal};
