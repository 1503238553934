import React, {useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  sendGAEvent,
  unitsLengthDisplayConversion,
  unitsLengthSubmitConversion,
  fetchPostAuthSafe,
  intRegex,
  dollarRegex,
  isNumeric,
  handleAllowOnlyNumbers,
} from '../../../app/utils';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalColumnSection,
  ModalWideColumnSection,
  ModalInputSection,
  ModalBody,
  ModalWarning,
  ModalNoteInspectionLinks,
} from '../../../components/Modal';
import {Tailselect} from '../../../components/Tailselect';
import {getServiceData} from '../shopviewSlice';

const defaultInputs = {
  task: '',
  recurring: true,
  timeInterval: '',
  timeIntervalUnits: '',
  engineHoursInterval: '',
  odometerInterval: '',
  averageLaborHours: '',
  averageLaborCost: '',
  averagePartsCost: '',
  notes: '',
};
let currValues = defaultInputs;

function EditTaskModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = useSelector((state) => {
    return state.scheduleModal;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [inputs, setInputs] = useState(defaultInputs);
  const [warnings, setWarnings] = useState({});
  const [unitsLength, setUnitsLength] = useState('km');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    // Determine length units based on customer length units system
    if (unitsLengthSystem == 'imperial') {
      setUnitsLength('mi');
    } else {
      setUnitsLength('km');
    }
  }, [unitsLengthSystem]);

  useEffect(() => {
    function modalOpen() {
      sendGAEvent('modal_open', 'EditTask', 'shopview');
    }
    // Set default values when modal opened
    $('#shopview-edit-task-modal').on('show.bs.modal', modalOpen);
    return () => {
      // Remove event listener when component is unmounted
      $('#shopview-edit-task-modal').off('show.bs.modal', modalOpen);
    };
  }, []);

  useEffect(() => {
    currValues = initialValues;
    setDefault();
  }, [initialValues]);

  // Handle changes in form values
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (['timeInterval', 'engineHoursInterval', 'odometerInterval'].includes(name) && value && !intRegex.test(value)) {
      return;
    }
    if (
      ['averageLaborHours', 'averageLaborCost', 'averagePartsCost'].includes(name) &&
      value &&
      (!dollarRegex.test(value) || value.includes('-'))
    ) {
      return;
    }
    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  const handleSelectChange = (select) => {
    const name = select.name;
    const value = select.value;
    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  function setDefault() {
    setWarnings({});

    let odometerIntervalDisplay = '';
    if (currValues.odometerInterval !== '') {
      odometerIntervalDisplay = parseFloat(
        unitsLengthDisplayConversion(currValues.odometerInterval, unitsLength)
      ).toFixed(0);
    }

    // Set input values to those of the selcted task to edit
    setInputs((values) => {
      return {
        ...values,
        task: currValues.task,
        recurring: currValues.recurring,
        timeInterval: currValues.timeInterval,
        timeIntervalUnits: currValues.timeIntervalUnits,
        engineHoursInterval: currValues.engineHoursInterval,
        averageLaborHours: currValues.averageLaborHours,
        averageLaborCost: currValues.averageLaborCost,
        averagePartsCost: currValues.averagePartsCost,
        odometerInterval: odometerIntervalDisplay,
        notes: currValues.notes,
      };
    });
  }

  async function submitModal() {
    /*
    Check the criteria for accepting the modal entry
    Since warningDataObj has the serviceTask schema,
    we check against it to determine what the serviceTask's required intervals are
    */
    const taskEmpty = inputs.task.trim() == '';
    const intervalsEmpty =
      inputs.timeInterval == '' && inputs.engineHoursInterval == '' && inputs.odometerInterval == '';

    const zeroOdo = parseInt(inputs.odometerInterval) === 0;
    const zeroEngHours = parseInt(inputs.engineHoursInterval) === 0;
    const zeroTimeInterval = parseInt(inputs.timeInterval) === 0;
    // combining all checks into a single variable
    const zeroIntervals = zeroOdo || zeroEngHours || zeroTimeInterval;

    // Set any warnings
    setWarnings((values) => {
      return {
        ...values,
        warningTask: taskEmpty,
        warningIntervals: intervalsEmpty && inputs.recurring,
        warningZeroIntervals: zeroIntervals,
      };
    });

    // If all submit criterias are met POST the data to the server
    if (!taskEmpty && !(intervalsEmpty && inputs.recurring) && !zeroIntervals && !submitting) {
      setSubmitting(true);
      // Differentiate between '' vs 0 values
      let engineHoursInterval = parseFloat(inputs.engineHoursInterval);
      if (isNaN(engineHoursInterval)) {
        engineHoursInterval = '';
      }
      let odometerInterval = parseFloat(unitsLengthSubmitConversion(inputs.odometerInterval, unitsLength));
      if (isNaN(odometerInterval)) {
        odometerInterval = '';
      }

      let timeInterval = parseFloat(inputs.timeInterval);
      if (isNaN(timeInterval)) {
        timeInterval = '';
      }

      const postData = {
        name: inputs.task.trim(),
        vehicle: initialValues.vehicle.trim(),
        vehicleSN: initialValues.vehicleSN.trim(),
        recurring: inputs.recurring,
        timeInterval: timeInterval,
        timeUnits: inputs.timeIntervalUnits.trim(),
        odometerInterval: odometerInterval,
        engineHoursInterval: engineHoursInterval,
        averageLaborHours: isNumeric(inputs.averageLaborHours) ? parseFloat(inputs.averageLaborHours) : '',
        averageLaborCost: isNumeric(inputs.averageLaborCost) ? parseFloat(inputs.averageLaborCost) : '',
        averagePartsCost: isNumeric(inputs.averagePartsCost) ? parseFloat(inputs.averagePartsCost) : '',
        notes: inputs.notes.trim(),
        oldServiceTask: initialValues.serviceTask,
      };

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };

      const updateTaskReq = await fetchPostAuthSafe(
        '/shopview/updateTask',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const updateTaskReqData = await updateTaskReq.json();
      if (updateTaskReqData.errorMsg) {
        navigate('/error', {state: {errorMsg: updateTaskReqData.errorMsg}});
      }

      if (!updateTaskReqData.taskUpdated || updateTaskReqData.taskExists) {
        if (updateTaskReqData.taskExists) {
          setWarnings((values) => {
            return {
              ...values,
              warningExists: true,
            };
          });
        } else {
          setWarnings((values) => {
            return {
              ...values,
              warningError: true,
            };
          });
        }
      } else {
        $('#shopview-edit-task-modal').modal('hide');
        setTimeout(function () {
          dispatch(getServiceData());
        }, 500);
      }

      sendGAEvent('modal_submit', 'EditTask', 'shopview');
      setSubmitting(false);
    }
  }

  async function deleteModal() {
    const postData = {
      oldServiceTask: initialValues.serviceTask,
    };

    postData.oldServiceTask = {
      ...postData.oldServiceTask,
      vehicle: initialValues.vehicle,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    };

    if (!submitting) {
      setSubmitting(true);
      const deleteTaskReq = await fetchPostAuthSafe(
        '/shopview/deleteTask',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const deleteTaskReqData = await deleteTaskReq.json();
      if (deleteTaskReqData.errorMsg) {
        navigate('/error', {state: {errorMsg: deleteTaskReqData.errorMsg}});
      }

      $('#shopview-edit-task-modal').modal('hide');
      setTimeout(function () {
        dispatch(getServiceData());
      }, 500);

      sendGAEvent('modal_submit', 'EditTask', 'shopview');
      setSubmitting(false);
    }
  }

  if (initialValues.serviceTask.inspectionReportIds && initialValues.serviceTask.inspectionReportIds.length > 0) {
    return createPortal(
      <ModalFramework id='shopview-edit-task-modal'>
        <ModalHeader title='Edit Service' />
        <ModalBody>
          <ModalRowSection>
            <ModalColumnSection>
              <p>Vehicle Name</p>
              <p className='font-weight-bold'>{initialValues.vehicle}</p>
            </ModalColumnSection>
            <ModalColumnSection>
              <ModalInputSection label='Service Name'>
                <input
                  disabled={true}
                  type='text'
                  name='task'
                  className='form-control flex-fill'
                  value={inputs.task}
                  onChange={handleChange}
                />
              </ModalInputSection>
            </ModalColumnSection>
            <ModalNoteInspectionLinks
              modalId='shopview-edit-task-modal'
              setLinkedInspectionReportId={props.setLinkedInspectionReportId}
              inspectionReportIdsList={initialValues.serviceTask.inspectionReportIds}
            />
          </ModalRowSection>
        </ModalBody>
        <ModalFooter onDelete={deleteModal} />
      </ModalFramework>,
      document.getElementById('app')
    );
  }

  return createPortal(
    <ModalFramework id='shopview-edit-task-modal'>
      <ModalHeader title='Edit Service' />
      <ModalBody>
        {warnings.warningTask && <ModalWarning text='Enter a task name' />}
        {warnings.warningError && <ModalWarning text='Task failed to update' />}
        {warnings.warningExists && <ModalWarning text='New task name already exists' />}
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <p>Vehicle Name</p>
            <p className='font-weight-bold'>{initialValues.vehicle}</p>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Service Name'>
              <input
                type='text'
                name='task'
                className='form-control flex-fill'
                value={inputs.task}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        {warnings.warningIntervals && (
          <ModalWarning text='Fill out at least 1 of the 3 interval fields for recurring tasks.' />
        )}
        {warnings.warningZeroIntervals && <ModalWarning text='Cannot have an interval of 0' />}
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <div>
              <label>
                Recurring:{' '}
                <input
                  type='checkbox'
                  name='recurring'
                  className='ml-2'
                  checked={inputs.recurring}
                  onChange={handleChange}
                />
              </label>
            </div>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <ModalInputSection label='Time Interval'>
              <input
                type='text'
                name='timeInterval'
                className='form-control flex-fill'
                value={inputs.timeInterval}
                step={1}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Interval Units'>
              <Tailselect
                class='col-12'
                id='shopview-edit-task-time-unit'
                name='timeIntervalUnits'
                value={inputs.timeIntervalUnits}
                options={['days', 'weeks', 'months']}
                onChange={handleSelectChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <ModalInputSection label='Engine Hours Interval'>
              <input
                type='text'
                name='engineHoursInterval'
                className='form-control flex-fill'
                value={inputs.engineHoursInterval}
                step={1}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label={`Odometer Interval (${unitsLength})`}>
              <input
                type='text'
                name='odometerInterval'
                className='form-control flex-fill'
                value={inputs.odometerInterval}
                step={1}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <ModalInputSection label='Average Labor Cost ($)'>
              <input
                type='text'
                name='averageLaborCost'
                className='form-control flex-fill'
                value={inputs.averageLaborCost}
                step={1}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Average Parts Cost ($)'>
              <input
                type='text'
                name='averagePartsCost'
                className='form-control flex-fill'
                value={inputs.averagePartsCost}
                step={1}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <ModalInputSection label='Average Labor Time (Hrs)'>
              <input
                type='text'
                name='averageLaborHours'
                className='form-control flex-fill'
                value={inputs.averageLaborHours}
                step={1}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalWideColumnSection>
            <ModalInputSection label='Notes'>
              <input
                type='textarea'
                name='notes'
                className='form-control flex-fill'
                value={inputs.notes}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter onDelete={deleteModal} onSubmit={submitModal} />
    </ModalFramework>,
    document.getElementById('app')
  );
}

export {EditTaskModal};
