import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import {useSelector, useDispatch} from 'react-redux';
import {DateTime} from 'luxon';
import {Table, TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../components/Table';
import {Tailselect} from '../../components/Tailselect';
import {
  searchFind,
  capitalizeFirstLetter,
  sendGAEvent,
  handleAllowOnlyNumbers,
  unitsAreaConversion,
  unitsLengthDisplayConversion,
} from '../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {updateZoneZoom, updateMapView, updateEditZoneInfoValues} from './zoneInfoSlice';
import MyPagination from '../../components/MyPagination';

import {TextField, Switch} from '@mui/material';

function ZoneInfoTable(props) {
  const dispatch = useDispatch();

  const zonesDicts = useSelector((state) => {
    return state.zoneinfo.zonesDicts;
  });
  const displayedTable = useSelector((state) => {
    return state.zoneinfo.displayedTable;
  });
  const cropTypesDropdownOptions = useSelector((state) => {
    return state.zoneinfo.cropTypesDropdownOptions;
  });
  const editZoneInfo = useSelector((state) => {
    return state.zoneinfo.editZoneInfo;
  });
  const editZoneInfoValues = useSelector((state) => {
    return state.zoneinfo.editZoneInfoValues;
  });
  const mapView = useSelector((state) => {
    return state.zoneinfo.mapView;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });
  const MAX_ITEMS_PER_PAGE = 50;

  const [items, setItems] = useState(0); // Number of pages in pagination
  const [curPage, setCurPage] = useState(1); // Current page in pagination
  const [tableData, setTableData] = useState([]);

  const afterPageClicked = (pageNumber) => {
    setCurPage(pageNumber);
  };

  useEffect(() => {
    // Initial Load Auto zoom to first zone
    if (props.loading == false) {
      if (props.tableData.length > 0 && (!smallScreen || (smallScreen && mapView >= 1))) {
        zoomToZone(props.tableData[0].name);
      }
    }
  }, [props.loading]);

  useEffect(() => {
    filterTableRows(props.tableData);
  }, [props.tableData, curPage, props.filters, searchText]);

  useEffect(() => {}, [editZoneInfo]);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    props.setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  async function filterTableRows(tableData) {
    // Filter props.tablerows based on filters and pagination to render limited amount of data on tables
    // Promise.all is used to avoid desync between states update and rerendering due to slow filter on large array
    await Promise.all(
      // Generate an array based on rowFilter function (return true or false) base on filters
      tableData.map((rowData) => {
        return rowFilter(rowData);
      })
    ).then((result) => {
      // Filter rows of data based on filter results
      const resultRows = tableData.filter((row, index) => {
        return result[index] === true;
      });
      // Remove exisitng span from rows
      resultRows.map((row) => {
        delete row.span;
        delete row.taskSpan;
      });

      // Find merge span of rows
      let span = 0;
      let displayedColSpan = 0;
      for (let i = resultRows.length - 1; i > -1; i--) {
        // Span of zone column
        if (i != resultRows.length - 1 && resultRows[i].name != resultRows[i + 1].name) {
          resultRows[i + 1].span = span;
          span = 0;
        }
        if (i == 0) {
          resultRows[i].span = span + 1;
        }
        span += 1;

        // Span of displayed column on small screens
        if (i != resultRows.length - 1 && resultRows[i].name != resultRows[i + 1].name) {
          resultRows[i + 1].taskSpan = displayedColSpan;
          displayedColSpan = 0;
        }
        if (i == 0) {
          resultRows[i].taskSpan = displayedColSpan + 1;
        }
        displayedColSpan += 1;
      }

      // Updating Pagination States based on number of items/rows of data
      let numPages = Math.floor(resultRows.length / MAX_ITEMS_PER_PAGE);
      if (resultRows.length % MAX_ITEMS_PER_PAGE != 0) {
        numPages++;
      }
      setItems(numPages);
      // Navigate to the 1st page if current page is larger than total number of pages
      if (curPage > numPages) {
        setCurPage(1);
      }

      // Update tableData State to render partial rows of data from pagination
      setTableData(resultRows.slice(curPage * MAX_ITEMS_PER_PAGE - MAX_ITEMS_PER_PAGE, curPage * MAX_ITEMS_PER_PAGE));
    });
  }

  function rowFilter(rowData) {
    // Check if zone in filter
    const zoneInFilter = props.filters[props.type].includes(rowData.name) || props.filters[props.type].length == 0;

    // Check if parent zones in parent filters
    let parentZoneInFilter = true;
    if (props.type == 'blocks') {
      const fieldName = zonesDicts.fields[rowData.fieldId] ? zonesDicts.fields[rowData.fieldId].name : '';
      const regionName = zonesDicts.regions[rowData.regionId] ? zonesDicts.regions[rowData.regionId].name : '';
      parentZoneInFilter =
        (props.filters.fields.includes(fieldName) || props.filters.fields.length == 0) && 
        (props.filters.regions.includes(regionName) || props.filters.regions.length == 0);
    } else if (props.type == 'fields') {
      const regionNamesList = rowData.regionIds.map((regionId) => {
        return zonesDicts.regions[regionId] ? zonesDicts.regions[regionId].name : '';
      });
      parentZoneInFilter =
        regionNamesList.some((regionName) => {return props.filters.regions.includes(regionName)}) ||
        props.filters.regions.length == 0;
    }

    let rowSpacingText = '';
    if (typeof rowData.rowSpacingMeters === 'number') {
      let unitsLength = 'm';
      if (unitsLengthSystem == 'imperial') {
        unitsLength = 'ft';
      }

      rowSpacingText = `${unitsLengthDisplayConversion(rowData.rowSpacingMeters, unitsLength).toFixed(
        2
      )} ${unitsLength}`;
    }
    const row = Object.assign(
      {},
      {
        name: rowData.name,
        acreage: unitsAreaConversion(rowData.areaMeters2, 'ac', 'meters2').toFixed(2),
        rowSpacing: rowSpacingText,
      }
    );
    const search = searchFind(<div>{JSON.stringify(Object.values(row))}</div>, searchText.toLowerCase().trim());

    return zoneInFilter && parentZoneInFilter && search;
  }

  // function rowFilter(rowData) {
  //   let zoneInFilter = true;

  //   zoneInFilter = props.filters[props.type].includes(rowData.name) || props.filters[props.type].length == 0;
  //   let parentZoneInFilter = true;
  //   if (props.type != 'regions') {
  //     const parentZoneType = props.type == 'blocks' ? 'fields' : 'regions';
  //     if (parentZoneType == 'fields') {
  //       parentZoneInFilter =
  //         props.filters[parentZoneType].includes(parentFieldLookup[rowData.name]) ||
  //         props.filters[parentZoneType].length == 0;
  //     } else if (parentZoneType == 'regions') {
  //       parentZoneInFilter =
  //         props.filters[parentZoneType].includes(parentRegionLookup[rowData.name]) ||
  //         props.filters[parentZoneType].length == 0;
  //     }
  //   }

  //   let rowSpacingText = '';
  //   if (typeof rowData.row_spacing_meters === 'number') {
  //     if (userSettings.general.units == 'imperial') {
  //       rowSpacingText = `${(rowData.row_spacing_meters * 3.28084).toFixed(2)} ft`;
  //     } else {
  //       rowSpacingText = `${rowData.row_spacing_meters.toFixed(2)} m`;
  //     }
  //   }
  //   const row = Object.assign(
  //     {},
  //     {
  //       name: rowData.name,
  //       acreage: (rowData.area_meters2 / 4046.856422).toFixed(2),
  //       row_spacing: rowSpacingText,
  //     }
  //   );
  //   const search = searchFind(<div>{JSON.stringify(Object.values(row))}</div>, searchText.toLowerCase().trim());

  //   return zoneInFilter && parentZoneInFilter && search;
  // }

  function zoomToZone(zone) {
    let delay = 0;
    // If map was hidden on small screen, delay the zoom until the map loads
    if (mapView == 0 && smallScreen) {
      delay = 10;
      dispatch(updateMapView(1));
    }
    setTimeout(() => {
      dispatch(updateZoneZoom(zone));
    }, delay);
    sendGAEvent('zoom_to_zone', displayedTable, 'zoneInfo');
  }

  /**
   * Create a dropdown select for crop types that is searchable, clearable
   * and groups the options.
   *
   * @param {object} rowData
   * @return {null}
   */
  function subCropTypeSelect(rowData) {
    // Get the current row data
    let currentPrimaryClass = rowData.cropPrimaryClass;
    let currentSubClass = rowData.cropSubClass;

    // Check if the row data has been edited and fetch last edit
    if (
      Object.prototype.hasOwnProperty.call(editZoneInfoValues, rowData.id) &&
      Object.prototype.hasOwnProperty.call(editZoneInfoValues[rowData.id], 'currentPrimaryClass')
    ) {
      currentPrimaryClass = editZoneInfoValues[rowData.id].currentPrimaryClass;
    }

    if (
      Object.prototype.hasOwnProperty.call(editZoneInfoValues, rowData.id) &&
      Object.prototype.hasOwnProperty.call(editZoneInfoValues[rowData.id], 'currentSubClass')
    ) {
      currentSubClass = editZoneInfoValues[rowData.id].currentSubClass;
    }

    // Return the react component
    return (
      // React-select component
      <Select
        isClearable
        isSearchable
        ignoreCase
        defaultValue={{
          label: currentSubClass,
          value: {'subClass': currentSubClass, 'primaryClass': currentPrimaryClass},
        }}
        options={cropTypesDropdownOptions}
        // Handle selection changes
        // Update the redux store with the new values
        onChange={(selectedOption) => {
          // Ensure not null, value is null when its cleared
          if (selectedOption !== null) {
            const tempValues = {...editZoneInfoValues};
            if (Object.prototype.hasOwnProperty.call(tempValues, rowData.id)) {
              tempValues[rowData.id] = {
                ...tempValues[rowData.id],
                cropSubClass: selectedOption.value.subClass,
                cropPrimaryClass: selectedOption.value.primaryClass,
              };
            } else {
              tempValues[rowData.id] = {
                cropSubClass: selectedOption.value.subClass,
                cropPrimaryClass: selectedOption.value.primaryClass,
              };
            }

            // Cache edited values
            dispatch(updateEditZoneInfoValues(tempValues));
          }
        }}
        // Control style
        styles={{
          // Overall form styling
          control: (styles) => {
            return {
              ...styles,
              backgroundColor: 'white', // can set to 'clear'
              fontSize: 12,
            };
          },

          // Styling for the group labels in the dropdown
          groupHeading: (provided) => {
            return {
              ...provided,
              color: 'black',
            };
          },

          option: (provided) => {
            return {
              ...provided,
              // fontSize: 8
            };
          },
        }}
      />
    );
  }

  /**
   * Create a text input for crop variety.
   *
   * @param {object} rowData
   * @return {null}
   */
  function cropVarietyTextInput(rowData) {
    let currentCropVariety = rowData.cropVariety;

    // Check if the row data has been edited and fetch last edit
    if (
      Object.prototype.hasOwnProperty.call(editZoneInfoValues, rowData.id) &&
      Object.prototype.hasOwnProperty.call(editZoneInfoValues[rowData.id], 'cropVariety')
    ) {
      currentCropVariety = editZoneInfoValues[rowData.id].cropVariety;
    }

    // Return the react component
    return (
      // MUI text field component
      <TextField
        size='small'
        onChange={(e) => {
          const tempValues = {...editZoneInfoValues};
          if (Object.prototype.hasOwnProperty.call(tempValues, rowData.id)) {
            tempValues[rowData.id] = {
              ...tempValues[rowData.id],
              cropVariety: e.target.value,
            };
          } else {
            tempValues[rowData.id] = {
              cropVariety: e.target.value,
            };
          }

          dispatch(updateEditZoneInfoValues(tempValues));
        }}
        value={currentCropVariety}
        // Custom styling
        style={{
          backgroundColor: 'white',
          fontSize: 12,
        }}
        // Input styling
        inputProps={{style: {fontSize: 12}}} // font size of input text
        InputLabelProps={{style: {fontSize: 12}}} // font size of input label
      />
    );
  }

  /**
   * Create a text input for row spacing.
   *
   * @param {object} rowData
   * @return {null}
   */
  function rowSpacingInput(rowData) {
    let unitsLength = 'm';
    if (unitsLengthSystem == 'imperial') {
      unitsLength = 'ft';
    }

    let rowSpacing = unitsLengthDisplayConversion(rowData.rowSpacingMeters, unitsLength);

    // Check if the row data has been edited and fetch last edit
    if (
      Object.prototype.hasOwnProperty.call(editZoneInfoValues, rowData.id) &&
      Object.prototype.hasOwnProperty.call(editZoneInfoValues[rowData.id], 'rowSpacingMeters')
    ) {
      rowSpacing = editZoneInfoValues[rowData.id].rowSpacingMeters;
    }

    let displayString = '';
    if (typeof rowSpacing !== 'undefined' && rowSpacing !== '') {
      displayString = `${rowSpacing.toFixed(2)}`;
    }

    // Return the react component
    return (
      // MUI text field component
      <TextField
        size='small'
        onChange={(e) => {
          const tempValues = {...editZoneInfoValues};
          const inputValue = e.target.value;

          if (typeof inputValue === 'undefined' || inputValue === '') {
            if (Object.prototype.hasOwnProperty.call(tempValues, rowData.id)) {
              tempValues[rowData.id] = {
                ...tempValues[rowData.id],
                rowSpacingMeters: inputValue,
              };
            } else {
              tempValues[rowData.id] = {
                rowSpacingMeters: inputValue,
              };
            }
          } else {
            if (userSettings.general.units === 'imperial') {
              // Adjust submission value to correct unit
              inputValue = unitsSubmitConversion(inputValue, 'ft');
            }

            if (Object.prototype.hasOwnProperty.call(tempValues, rowData.id)) {
              tempValues[rowData.id] = {
                ...tempValues[rowData.id],
                rowSpacingMeters: parseFloat(inputValue),
              };
            } else {
              tempValues[rowData.id] = {
                rowSpacingMeters: parseFloat(inputValue),
              };
            }
          }

          dispatch(updateEditZoneInfoValues(tempValues));
        }}
        onKeyDown={handleAllowOnlyNumbers}
        type='text'
        inputProps={{
          min: '0',
          max: '100',
        }}
        value={displayString}
        variant='outlined'
        // Custom styling
        style={{backgroundColor: 'white'}}
      />
    );
  }

  /**
   * Create a text input for row spacing.
   *
   * @param {object} rowData
   * @return {null}
   */
  function activeDateInput(rowData) {
    if (rowData.active) {
      // Check if the row data has been edited and fetch last edit
      let currDateVal = rowData.blockActiveToDate;
      if (
        Object.prototype.hasOwnProperty.call(editZoneInfoValues, rowData.id) &&
        Object.prototype.hasOwnProperty.call(editZoneInfoValues[rowData.id], 'blockActiveToDate')
      ) {
        currDateVal = editZoneInfoValues[rowData.id].blockActiveToDate;
      }     

      return (
        <React.Fragment>
          <div className='mt-n4'>Archive on</div>
          <TextField
            size='small'
            type='datetime-local'
            InputProps={{
              inputProps: {
                min: DateTime.local({zone: customerSettings.general.timeZone}).plus({days: 1}).toISO().slice(0, 16),
                max: '2050-12-31T00:00',
              },
            }}
            value={currDateVal}
            style={{backgroundColor: 'white'}}
            onChange={(e) => {
              const tempValues = {...editZoneInfoValues};
              if (Object.prototype.hasOwnProperty.call(tempValues, rowData.id)) {
                tempValues[rowData.id] = {
                  ...tempValues[rowData.id],
                  blockActiveToDate: e.target.value,
                };
              } else {
                tempValues[rowData.id] = {
                  blockActiveToDate: e.target.value,
                };
              }

              dispatch(updateEditZoneInfoValues(tempValues));
            }}
          />
        </React.Fragment>
      );

    } else {
      // Check if the row data has been edited and fetch last edit
      let currActivateVal = false;
      if (
        Object.prototype.hasOwnProperty.call(editZoneInfoValues, rowData.id) &&
        Object.prototype.hasOwnProperty.call(editZoneInfoValues[rowData.id], 'blockActivate')
      ) {
        currActivateVal = editZoneInfoValues[rowData.id].blockActivate;
      }
      
      return (
        <React.Fragment>
          <div className='mt-n4'>Activate</div>
          <Switch
            checked={currActivateVal}
            onChange={(e) => {
              const tempValues = {...editZoneInfoValues};
              if (Object.prototype.hasOwnProperty.call(tempValues, rowData.id)) {
                tempValues[rowData.id] = {
                  ...tempValues[rowData.id],
                  blockActivate: e.target.checked,
                };
              } else {
                tempValues[rowData.id] = {
                  blockActivate: e.target.checked,
                };
              }

              dispatch(updateEditZoneInfoValues(tempValues));
            }}
          />
        </React.Fragment>
      );
    }    
  }

  function generateTableHeaders() {
    return (
      <tr>
        <th width='15%'>
          <TableHeadFilterButtonTitleWrapper title={capitalizeFirstLetter(props.type)}>
            <button
              className='btn btn-transparent btn-sm'
              onClick={() => {
                return tail.select('#cropview-zone-select').toggle();
              }}
            >
              <FontAwesomeIcon
                icon='fas fa-filter'
                style={{color: props.filters[props.type].length > 0 && '#4e73df'}}
              />
            </button>
          </TableHeadFilterButtonTitleWrapper>
          <TableHeadHiddenDropdownWrapper>
            <Tailselect
              id='cropview-zone-select'
              name={props.type}
              multiple={true}
              search={true}
              value={props.filters[props.type]}
              options={props.filterOptions[props.type]}
              onChange={handleFilters}
            />
          </TableHeadHiddenDropdownWrapper>
        </th>
        <th width='10%'>{unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage'}</th>
        {props.type == 'blocks' && userSettings.roleViewAccess['zoneInfoRowSpacingManagement'] && (
          <th width='12.5%'>Row Spacing {unitsLengthSystem == 'imperial' ? '(ft)' : '(m)'}</th>
        )}
        {props.type == 'blocks' && <th width='17.5%'>Crop Type</th>}
        {props.type == 'blocks' && <th width='12.5%'>Crop Variety</th>}
        {props.type == 'blocks' && userSettings.role == 'ic_admin' && <th width='10%'>Row Pass Enabled</th>}
        {/* {props.type == 'blocks' && <th width='10%'>Status</th>} */}
        {(props.type == 'fields' || (props.type === 'blocks' && !smallScreen)) && (
          <th width='20%'>
            <TableHeadFilterButtonTitleWrapper
              title={`Parent ` + capitalizeFirstLetter(props.type == 'blocks' ? 'fields' : 'regions')}
            >
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#cropview-parent-zone-select').toggle();
                }}
              >
                <FontAwesomeIcon
                  icon='fas fa-filter'
                  style={{color: props.filters[props.type == 'blocks' ? 'fields' : 'regions'].length > 0 && '#4e73df'}}
                />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='cropview-parent-zone-select'
                name={props.type == 'blocks' ? 'fields' : 'regions'}
                multiple={true}
                search={true}
                value={props.filters[props.type == 'blocks' ? 'fields' : 'regions']}
                options={props.filterOptions[props.type == 'blocks' ? 'fields' : 'regions']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </th>
        )}
        <th width='2.5%'>
          <div className='table-header-map-marker'>
            <FontAwesomeIcon icon='fas fa-map-marker-alt' />
          </div>
        </th>
      </tr>
    );
  }

  function generateTableData() {
    if (tableData.length == 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={100} style={{textAlign: 'center', fontSize: '25px'}}>
              No Zone for the selected Zone Type
            </td>
          </tr>
        </tbody>
      );
    }

    let colorFlip = false;
    return (
      <tbody>
        {tableData.map((rowData, index) => {
          if (!rowData) {
            return null;
          }
          if (rowData.span) {
            colorFlip = !colorFlip;
          }

          // Parent zone name
          let parentName = '';
          if (props.type == 'blocks') {
            parentName = zonesDicts.fields[rowData.fieldId] ? zonesDicts.fields[rowData.fieldId].name : '';
          } else if (props.type == 'fields') {            
            const parentNamesList = [];
            if (typeof rowData.regionIds !== 'undefined') {
              rowData.regionIds.forEach((regionId) => {
                parentNamesList.push(zonesDicts.regions[regionId] ? zonesDicts.regions[regionId].name : '')
              });
            }
            parentName = parentNamesList.join(', ');
          }

          let rowSpacingText = '';
          if (typeof rowData.rowSpacingMeters === 'number') {
            let unitsLength = 'm';
            if (unitsLengthSystem == 'imperial') {
              unitsLength = 'ft';
            }

            rowSpacingText = `${unitsLengthDisplayConversion(rowData.rowSpacingMeters, unitsLength).toFixed(
              2
            )} ${unitsLength}`;
          }

          let acreageConverted = unitsAreaConversion(rowData.areaMeters2, 'ac', 'meters2');
          let unitsArea = 'ac';

          if (unitsAreaSystem == 'hectare') {
            acreageConverted = unitsAreaConversion(rowData.areaMeters2, 'ha', 'meters2');
            unitsArea = 'ha';
          }

          const row = (
            <tr key={index} style={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : 'rgba(255, 255, 255, 0)'}}>
              {rowData.span && (
                <td rowSpan={rowData.span}>
                  <div className='font-weight-bold'>{rowData.name}</div>
                </td>
              )}
              {rowData.span && (
                <td rowSpan={rowData.span}>
                  <div className='font-weight-bold'>
                    {acreageConverted.toFixed(2)} {unitsArea}
                  </div>
                </td>
              )}
              {props.type == 'blocks' && userSettings.roleViewAccess['zoneInfoRowSpacingManagement'] && (
                <td className={''}>
                  {editZoneInfo && userSettings.roleAccess['zoneInfoRowSpacingManagement'] ? (
                    // Fetch the populated edit text input component
                    <div className='d-inline mx-1'>{rowSpacingInput(rowData)}</div>
                  ) : (
                    <div className='font-weight-bold'>{rowSpacingText}</div>
                  )}
                </td>
              )}
              {props.type == 'blocks' && (
                <td className={''}>
                  {editZoneInfo ? (
                    // Fetch the populated edit select component
                    // class cannot use d-inline as its wizardry is causes the component to not align properly
                    <div className='mx-1'>{subCropTypeSelect(rowData)}</div>
                  ) : (
                    <div className='font-weight-bold'>{rowData.cropSubClass}</div>
                  )}
                </td>
              )}
              {props.type == 'blocks' && (
                <td className={''}>
                  {editZoneInfo ? (
                    // Fetch the populated text edit input component
                    <div className='d-inline mx-1'>{cropVarietyTextInput(rowData)}</div>
                  ) : (
                    <div className='font-weight-bold'>{rowData.cropVariety}</div>
                  )}
                </td>
              )}
              {props.type == 'blocks' && userSettings.role == 'ic_admin' && (
                <td className={''}>
                  <div className='font-weight-bold'>{rowData.rowPassEnabled}</div>
                </td>
              )}
              {/* {props.type == 'blocks' && (
                <td>
                  {editZoneInfo ? (
                    <div className='d-inline mx-1'>{activeDateInput(rowData)}</div>
                  ) : (
                    <div className='font-weight-bold'>{rowData.activeText}</div>
                  )}
                </td>
              )} */}
              {(props.type == 'fields' || (props.type === 'blocks' && !smallScreen)) && (
                <td className={''}>
                  <div className='font-weight-bold'>{parentName}</div>
                </td>
              )}
              {rowData.span && (
                <td rowSpan={rowData.span}>
                  <button
                    type='button'
                    className='btn'
                    onClick={(e) => {
                      e.stopPropagation();
                      zoomToZone(rowData.name);
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-map-marker-alt' />
                  </button>
                </td>
              )}
            </tr>
          );
          return row;
        })}
      </tbody>
    );
  }

  return (
    <div className={`table-responsive`} style={{width: smallScreen ? '100%' : props.dims.w + 'px'}}>
      <Table headers={generateTableHeaders()} body={generateTableData(smallScreen)} loading={props.loading} />
      <MyPagination
        totPages={items}
        currentPage={curPage}
        pageClicked={(ele) => {
          afterPageClicked(ele);
        }}
      ></MyPagination>
    </div>
  );
}

export {ZoneInfoTable};
