import React, {useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

import {initGA, disableGA} from './app/utils';

import ProtectedRoute from './components/ProtectedRoute';
import BackendRoute from './components/BackendRoute';
import {Home} from './features/Home';

import {Login} from './features/Login';
import {CreateUser} from './features/CreateUser';
import {InviteUser} from './features/InviteUser';
import {DeleteAccount} from './features/DeleteAccount';
import {CreateAccount} from './features/CreateAccount';
import {ForgotPassword} from './features/ForgotPassword';
import {ResetPassword} from './features/ResetPassword';
import {Error} from './features/Error';

import {Shopview} from './features/Shopview';
import {Cabview} from './features/Cabview';
import {Landing} from './features/Landing';
import {Cropview} from './features/Cropview';
import {Mapview} from './features/Mapview';
import {InspectionForm} from './features/InspectionForm';
import {Statsview} from './features/Statsview';
import {Alertview} from './features/Alertview';

import {Settings} from './features/Settings';
import {PersonalSettings} from './features/PersonalSettings';

function App() {
  const location = useLocation();

  const reloadApp = useSelector((state) => {
    return state.app.reloadApp;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  useEffect(() => {
    if (typeof userSettings != 'undefined' && userSettings.ignoreProcess === false) {
      initGA();
    } else {
      disableGA();
    }
  }, [location, userSettings]);

  useEffect(() => {
    if (reloadApp) {
      alert('New app version available. Reloading app');
      window.location.reload(true);
    }
  }, [reloadApp]);

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true} // If false, the library is disabled.
      isVerboseMode={true} // If true, the library writes verbose logs to console.
    >
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/create_account' element={<CreateAccount />} />
        <Route path='/forgot_password' element={<ForgotPassword />} />
        <Route path='/reset_password' element={<ResetPassword />} />
        <Route path='/error' element={<Error />} />
        <Route element={<ProtectedRoute />}>
          <Route exact path='/' element={<Home />} />
          <Route path='/shopview' element={<Shopview />} />
          <Route path='/cabview' element={<Cabview />} />
          <Route path='/landing' element={<Landing />} />
          <Route path='/cropview' element={<Cropview gpsModeEnabled={true} mapModeEnabled={true} />} />
          <Route path='/cropview-export' element={<Cropview gpsModeEnabled={false} mapModeEnabled={false} />} />
          <Route path='/cropview-export-map' element={<Cropview gpsModeEnabled={false} mapModeEnabled={true} />} />
          <Route path='/statsview' element={<Statsview />} />
          <Route path='/alertview' element={<Alertview />} />
          <Route path='/mapview' element={<Mapview />} />
          <Route path='/inspectionForm' element={<InspectionForm />} />
          <Route path='/delete_account' element={<DeleteAccount />} />
          <Route path='/personal_settings' element={<PersonalSettings />} />
          <Route path='/organization_settings' element={<Settings />} />
        </Route>
        <Route element={<ProtectedRoute adminReq={true} />}>
          <Route path='/create_user' element={<CreateUser />} />
          <Route path='/invite_user' element={<InviteUser />} />
        </Route>

        {/* Routes only defined for react development server */}
        {window.location.port == 3000 && (
          <React.Fragment>
            <Route element={<ProtectedRoute />}>
              <Route path='/settings' element={<BackendRoute />} />
              <Route path='/scan' element={<BackendRoute />} />
              <Route path='/files' element={<BackendRoute />} />
            </Route>
            <Route path='/logout' element={<BackendRoute />} />
          </React.Fragment>
        )}

        <Route path='*' element={<Error />} />
      </Routes>
    </CacheBuster>
  );
}

export default App;
