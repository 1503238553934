import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  fetchPostAuthSafe,
  machineTypeMapping,
  unitsLengthDisplayConversion,
  unitsLengthSubmitConversion,
  handleAllowOnlyNumbers,
  decimalLimitRegex,
  unitsAreaConversion,
  sortVehicleNamesHelper,
} from '../../../app/utils';
import {getCustomerAndUserInfo} from '../../../appSlice';
import {getSettingsData, updateLoading, getTaskConfigsData} from '../settingsSlice';
import {CollapsableSection, TargetText, SettingsText} from '../../../components/GeneralComponents';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {Tailselect} from '../../../components/Tailselect';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Table, TableHead, TableBody, TableRow, TableCell, Button, CircularProgress, TextField} from '@mui/material';

function KPIsTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.loading;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const vehicles = useSelector((state) => {
    return state.settings.vehicles;
  });
  const databaseUsers = useSelector((state) => {
    return state.settings.databaseUsers;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  const [collapseOpen, setCollapseOpen] = useState({
    taskAcPerHr: false,
    totalTargets: false,
    vehicleTargets: false,
    typeTargets: false,
  });
  const [formValues, setFormValues] = useState({});
  const [filters, setFilters] = useState({
    task: [],
    vehicle: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    task: [],
    vehicle: [],
  });

  const [vehicleSNDict, setVehicleSNDict] = useState({});
  const [taskTargetsTableData, setTaskTargetsTableData] = useState([]);
  const [vehicleTargets, setVehicleTargetsTableData] = useState([]);

  useEffect(async () => {
    await dispatch(getSettingsData());
    const vehicleSNDictTemp = {};
    vehicles.forEach((vehicle) => {
      const vehicleSN = vehicle.serialNumber;
      vehicleSNDictTemp[vehicleSN] = vehicle;
    });
    setVehicleSNDict(vehicleSNDictTemp);
  }, []);

  useEffect(async () => {
    resetFormValues();
  }, [customerSettings, databaseUsers, searchText, taskConfigDict]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    if (!decimalLimitRegex(2).test(event.target.value) || event.target.value.includes('-')) {
      return;
    }

    setFormValues((values) => {
      return {...values, [name]: value};
    });
  };

  const handleNestedChange = (event, extra) => {
    const name = event.target.name;
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    value = value;

    if (!decimalLimitRegex(2).test(event.target.value) || event.target.value.includes('-')) {
      return;
    }

    setFormValues((values) => {
      const valuesCopy = {...values};
      if (extra == 'taskAcPerHr') {
        // Need to convert this back to ac for the backend

        let unitsArea = 'ac';
        if (unitsAreaSystem == 'hectares') {
          unitsArea = 'ha';
        }
        valuesCopy[extra][name].acPerHrTarget = unitsAreaConversion(value, 'ac', unitsArea);
      } else if (extra == 'taskAvgSpeed') {
        valuesCopy[extra][name].avgSpeedTarget = value;
      } else if (extra == 'vehicleIdlePercent') {
        valuesCopy[extra][name].idleTarget = value;
      } else {
        valuesCopy[extra][name].target = value;
      }

      return valuesCopy;
    });
  };

  function resetFormValues() {
    const taskConfigDictKeys = Object.keys(taskConfigDict);
    const taskAcPerHrCombined = {};
    const taskAvgSpeedCombined = {};

    let unitsLength = 'km';
    let unitsSpeed = 'kph';
    if (unitsLengthSystem == 'imperial') {
      unitsLength = 'mi';
      unitsSpeed = 'mph';
    }

    taskConfigDictKeys.forEach((taskId) => {
      taskAcPerHrCombined[taskId] = {
        taskId: taskId,
        taskName: taskConfigDict[taskId].name,
        acPerHrTarget:
          typeof taskConfigDict[taskId].acPerHrTarget != 'undefined' ? taskConfigDict[taskId].acPerHrTarget : 0,
      };
      taskAvgSpeedCombined[taskId] = {
        taskId: taskId,
        taskName: taskConfigDict[taskId].name,
        avgSpeedTarget:
          typeof taskConfigDict[taskId].avgSpeedTarget != 'undefined'
            ? parseFloat(unitsLengthDisplayConversion(taskConfigDict[taskId].avgSpeedTarget, unitsSpeed).toFixed(1))
            : 0,
      };
    });

    const vehiclesObject = {};
    vehicles.forEach((vehicle) => {
      if (!vehicle.archived) {
        const vehicleObj = {
          idleTarget: typeof vehicle?.idleTimeTarget != 'undefined' ? vehicle.idleTimeTarget : 0,
          vehicleName: vehicle.name,
          vehicleSN: vehicle.serialNumber,
        };
        vehiclesObject[vehicle.serialNumber] = vehicleObj;
      }
    });

    const typeInFieldPercentKeys =
      typeof customerSettings?.targets?.typeInFieldPercent != 'undefined'
        ? Object.keys(customerSettings?.targets?.typeInFieldPercent)
        : Object.keys(machineTypeMapping);
    const typeInFieldPercentTargets = {};
    typeInFieldPercentKeys.forEach((key) => {
      if (typeof customerSettings?.targets?.typeInFieldPercent == 'undefined') {
        typeInFieldPercentTargets[key] = {
          target: 0,
        };
      } else {
        typeInFieldPercentTargets[key] = {
          target:
            typeof customerSettings?.targets?.typeInFieldPercent[key] != 'undefined'
              ? customerSettings?.targets?.typeInFieldPercent[key]
              : 0,
        };
      }
    });

    const typeIdleTimePercentPercentKeys =
      typeof customerSettings?.targets?.typeIdleTimePercent != 'undefined'
        ? Object.keys(customerSettings?.targets?.typeIdleTimePercent)
        : Object.keys(machineTypeMapping);
    const typeIdleTimePercentTargets = {};
    typeIdleTimePercentPercentKeys.forEach((key) => {
      if (typeof customerSettings?.targets?.typeIdleTimePercent == 'undefined') {
        typeIdleTimePercentTargets[key] = {
          target: 0,
        };
      } else {
        typeIdleTimePercentTargets[key] = {
          target:
            typeof customerSettings?.targets?.typeIdleTimePercent[key] != 'undefined'
              ? customerSettings?.targets?.typeIdleTimePercent[key]
              : 0,
        };
      }
    });

    setFormValues((values) => {
      return {
        ...values,
        taskAcPerHr: taskAcPerHrCombined,
        taskAvgSpeed: taskAvgSpeedCombined,
        totalIdlePercent:
          typeof customerSettings?.targets?.totalIdlePercent != 'undefined'
            ? customerSettings?.targets?.totalIdlePercent
            : 0,
        vehicleIdlePercent: vehiclesObject,
        typeIdleTimePercent: typeIdleTimePercentTargets,
        totalInFieldPercent:
          typeof customerSettings?.targets?.totalInFieldPercent != 'undefined'
            ? customerSettings?.targets?.totalInFieldPercent
            : 0,
        typeInFieldPercent: typeInFieldPercentTargets,
      };
    });
    const taskFilterOptionsTemp = [];
    const taskAvgSpeedTableData = taskConfigDictKeys.map((taskId) => {
      taskFilterOptionsTemp.push(taskAvgSpeedCombined[taskId].taskName);
      taskAvgSpeedCombined[taskId].acPerHrTarget = taskAcPerHrCombined[taskId].acPerHrTarget;
      return taskAvgSpeedCombined[taskId];
    });

    setTaskTargetsTableData(taskAvgSpeedTableData);
    const vehicleKeys = Object.keys(vehiclesObject);
    const vehicleFilterOptionsTemp = [];
    const vehicleTableData = vehicleKeys.map((vehicleSN) => {
      vehicleFilterOptionsTemp.push(vehiclesObject[vehicleSN].vehicleName);
      return vehiclesObject[vehicleSN];
    });
    const vehicleTableDataSorted = vehicleTableData.sort((a, b) => {
      return sortVehicleNamesHelper(a.vehicleName, b.vehicleName);
    });
    setFilterOptions({...filterOptions, task: taskFilterOptionsTemp, vehicle: vehicleFilterOptionsTemp});
    setVehicleTargetsTableData(vehicleTableDataSorted);
  }

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects

    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  async function handleSubmit() {
    // Set loading state
    dispatch(updateLoading(true));

    let updateDoc = {};
    let options = {};
    console.log('submitting');
    // Total Targets
    updateDoc = {};

    // TOTAL TARGETS
    if (
      formValues.hasOwnProperty('totalIdlePercent') &&
      formValues.totalIdlePercent != customerSettings?.targets?.totalIdlePercent
    ) {
      updateDoc.totalIdlePercent = Number(formValues.totalIdlePercent);
    }
    if (
      formValues.hasOwnProperty('totalInFieldPercent') &&
      formValues.totalInFieldPercent != customerSettings?.targets?.totalInFieldPercent
    ) {
      updateDoc.totalInFieldPercent = Number(formValues.totalInFieldPercent);
    }

    // IN FIELD TYPE TARGETS
    const typeInFieldPercentTargets = {};
    if (formValues.hasOwnProperty('typeInFieldPercent')) {
      Object.keys(formValues.typeInFieldPercent).forEach((vehicleType) => {
        const typeTarget = formValues.typeInFieldPercent[vehicleType].target;
        // If we didn't have a target before and we do now, or we did have a target before but its different now
        if (
          (typeof customerSettings?.targets?.typeInFieldPercent == 'undefined' && typeof typeTarget != 'undefined') ||
          (typeof customerSettings?.targets?.typeInFieldPercent != 'undefined' &&
            typeTarget != customerSettings?.targets?.typeInFieldPercent[vehicleType])
        ) {
          typeInFieldPercentTargets[vehicleType] = Number(typeTarget);
        }
      });
    }

    if (Object.keys(typeInFieldPercentTargets).length > 0) {
      updateDoc.typeInFieldPercent = typeInFieldPercentTargets;
    }

    // IN FIELD TYPE TARGETS
    const typeIdleTimePercentTargets = {};
    if (formValues.hasOwnProperty('typeIdleTimePercent')) {
      Object.keys(formValues.typeIdleTimePercent).forEach((vehicleType) => {
        const typeTarget = formValues.typeIdleTimePercent[vehicleType].target;
        // If we didn't have a target before and we do now, or we did have a target before but its different now
        if (
          (typeof customerSettings?.targets?.typeIdleTimePercent == 'undefined' && typeof typeTarget != 'undefined') ||
          (typeof customerSettings?.targets?.typeIdleTimePercent != 'undefined' &&
            typeTarget != customerSettings?.targets?.typeIdleTimePercent[vehicleType])
        ) {
          typeIdleTimePercentTargets[vehicleType] = Number(typeTarget);
        }
      });
    }

    if (Object.keys(typeIdleTimePercentTargets).length > 0) {
      updateDoc.typeIdleTimePercent = typeIdleTimePercentTargets;
    }

    // SEND TOTAL AND TYPE TARGETS TO UPDATE CUSTOMER INFO
    let updateTotalTargetRequest = {success: false, status: 400, error: 'Request body is empty'};
    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          settings: {
            targets: updateDoc,
          },
        }),
      };
      updateTotalTargetRequest = fetchPostAuthSafe(
        '/settings/updateCustomerInfo',
        options,
        userSettings.username,
        userSettings.databaseName
      );
    }

    updateDoc = {};
    if (formValues.hasOwnProperty('vehicleIdlePercent')) {
      const vehicleIdleTargets = formValues.vehicleIdlePercent;
      updateDoc.vehicleTargets = {};
      Object.keys(vehicleIdleTargets).forEach((vehicleSN) => {
        if (
          vehicleIdleTargets.hasOwnProperty(vehicleSN) &&
          typeof vehicleIdleTargets[vehicleSN].idleTarget != undefined &&
          vehicleIdleTargets[vehicleSN].idleTarget != vehicleSNDict[vehicleSN]?.idleTarget
        ) {
          updateDoc.vehicleTargets[vehicleSN] = {};
          updateDoc.vehicleTargets[vehicleSN].idleTimeTarget = Number(vehicleIdleTargets[vehicleSN].idleTarget);
        }
      });
      if (Object.keys(updateDoc.vehicleTargets).length == 0) {
        delete updateDoc.vehicleTargets;
      }
    }

    let updateVehicleTargetsRequest = {success: false, status: 400, error: 'Request body is empty'};

    if (Object.keys(updateDoc).length > 0) {
      const vehicleTargetsDeliveryObject = {vehicleTargets: updateDoc.vehicleTargets};
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(vehicleTargetsDeliveryObject),
      };
      if (typeof vehicleTargetsDeliveryObject.vehicleTargets != 'undefined') {
        updateVehicleTargetsRequest = fetchPostAuthSafe(
          '/settings/updateVehicleTargets',
          options,
          userSettings.username,
          userSettings.databaseName
        );
      }
    }

    // Task Targets
    updateDoc = {};
    let taskAcPerHr;

    if (formValues.hasOwnProperty('taskAcPerHr')) {
      taskAcPerHr = formValues.taskAcPerHr;
      updateDoc.taskAcPerHr = {};
      Object.keys(taskAcPerHr).forEach((taskId) => {
        if (
          taskAcPerHr.hasOwnProperty(taskId) &&
          typeof taskAcPerHr[taskId].acPerHrTarget != undefined &&
          taskAcPerHr[taskId].acPerHrTarget != taskConfigDict[taskId].acPerHrTarget
        ) {
          updateDoc.taskAcPerHr[taskId] = {};
          updateDoc.taskAcPerHr[taskId].acPerHrTarget = Number(taskAcPerHr[taskId].acPerHrTarget);
        }
      });
      if (Object.keys(updateDoc.taskAcPerHr).length == 0) {
        delete updateDoc.taskAcPerHr;
      }
    }

    let unitsLength = 'km';
    let unitsSpeed = 'kph';
    if (unitsLengthSystem == 'imperial') {
      unitsLength = 'mi';
      unitsSpeed = 'mph';
    }

    let taskAvgSpeed;
    if (formValues.hasOwnProperty('taskAvgSpeed')) {
      taskAvgSpeed = formValues.taskAvgSpeed;
      updateDoc.taskAvgSpeed = {};
      Object.keys(taskAvgSpeed).forEach((taskId) => {
        if (
          taskAvgSpeed.hasOwnProperty(taskId) &&
          typeof taskAvgSpeed[taskId].avgSpeedTarget != undefined &&
          taskAvgSpeed[taskId].avgSpeedTarget != taskConfigDict[taskId].avgSpeedTarget
        ) {
          updateDoc.taskAvgSpeed[taskId] = {};
          updateDoc.taskAvgSpeed[taskId].avgSpeedTarget = unitsLengthSubmitConversion(
            Number(taskAvgSpeed[taskId].avgSpeedTarget),
            unitsSpeed
          );
        }
      });
      if (Object.keys(updateDoc.taskAvgSpeed).length == 0) {
        delete updateDoc.taskAvgSpeed;
      }
    }

    // Combined task target objects into one target object
    updateDoc.taskTargets = {};
    if (updateDoc.hasOwnProperty('taskAvgSpeed')) {
      Object.keys(updateDoc.taskAvgSpeed).forEach((taskId) => {
        if (!updateDoc.taskTargets.hasOwnProperty(taskId)) {
          updateDoc.taskTargets[taskId] = {};
        }
        updateDoc.taskTargets[taskId].avgSpeedTarget = updateDoc.taskAvgSpeed[taskId].avgSpeedTarget;
      });
    }

    if (updateDoc.hasOwnProperty('taskAcPerHr')) {
      Object.keys(updateDoc.taskAcPerHr).forEach((taskId) => {
        if (!updateDoc.taskTargets.hasOwnProperty(taskId)) {
          updateDoc.taskTargets[taskId] = {};
        }
        updateDoc.taskTargets[taskId].acPerHrTarget = updateDoc.taskAcPerHr[taskId].acPerHrTarget;
      });
    }
    // Delete if there is no targets updated
    if (Object.keys(updateDoc.taskTargets).length == 0) {
      delete updateDoc.taskTargets;
    }

    // Validate request body is not empty before sending
    let updateTaskTargetRequest = {success: false, status: 400, error: 'Request body is empty'};

    const targetDeliveryObject = {taskTargets: updateDoc.taskTargets};
    if (Object.keys(updateDoc).length > 0) {
      options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(targetDeliveryObject),
      };
      if (typeof targetDeliveryObject.taskTargets != 'undefined') {
        updateTaskTargetRequest = fetchPostAuthSafe(
          '/settings/updateTaskTargets',
          options,
          userSettings.username,
          userSettings.databaseName
        );
      }
    }

    // Make all calls
    const responses = await Promise.all([
      updateTaskTargetRequest,
      updateTotalTargetRequest,
      updateVehicleTargetsRequest,
    ]);
    const responsesJsons = responses.map((response) => {
      // If the request was prevented from
      // being sent due to an empty request body
      // then the response will be a json object already
      try {
        return response.json();
      } catch (error) {
        if (response.success == false) {
          return response;
        }
      }
    });
    const results = await Promise.all(responsesJsons);
    for (let i = 0; i < results.length; i++) {
      if (results[i].status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
        break;
      } else if (results[i].errorMsg) {
        navigate('/error', {state: {errorMsg: results[i].errorMsg}});
      } else if (results[i].status == 400 || results[i].success == false) {
        continue; // Empty request body, just skip over
      }
    }
    await dispatch(getCustomerAndUserInfo());
    await dispatch(getSettingsData());
    await dispatch(getTaskConfigsData());
  }

  function generateTotalTargetsTable() {
    const viewOnly = !userSettings.roleAccess['dashboardManagement'];

    if (Object.keys(formValues.typeInFieldPercent).length > 0) {
      return (
        <Table size='small' aria-label='simple table'>
          <TableBody sx={{width: '100%'}}>
            <SettingsText
              label='Total Idle Percent'
              setting='totalIdlePercent'
              formValues={formValues}
              handleChange={handleChange}
              disabled={viewOnly}
            />
            <SettingsText
              label='Total In Field Percent'
              setting='totalInFieldPercent'
              formValues={formValues}
              handleChange={handleChange}
              disabled={viewOnly}
            />
          </TableBody>
        </Table>
      );
    }
  }

  function generateTaskTargetsTable() {
    let colorFlip = false;
    const viewOnly = !userSettings.roleAccess['dashboardManagement'];

    return (
      <React.Fragment>
        <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Task'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#kpi-targets-task-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-filter' style={{color: filters['task'].length > 0 && '#4e73df'}} />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='kpi-targets-task-select'
                    name={'task'}
                    multiple={true}
                    search={true}
                    value={filters['task']}
                    options={filterOptions['task']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper
                  title={`Target ${unitsAreaSystem == 'hectare' ? 'Ha' : 'Ac'} Per Hr`}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#kpi-targets-acPerHrTarget-select').toggle();
                    }}
                  ></button>
                </TableHeadFilterButtonTitleWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper
                  title={`Target Avg Speed (${unitsLengthSystem == 'imperial' ? 'mph' : 'kph'})`}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#kpi-targets-avgSpeedTarget-select').toggle();
                    }}
                  ></button>
                </TableHeadFilterButtonTitleWrapper>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={100}>
                  <CircularProgress className='mt-4 mx-auto d-block' size={80} />
                </TableCell>
              </TableRow>
            ) : (
              <React.Fragment>
                {taskTargetsTableData.map((row) => {
                  colorFlip = !colorFlip;
                  const rowKey = row.taskId;
                  const taskAcPerHrValue = formValues['taskAcPerHr'][row.taskId.toString()]['acPerHrTarget'];

                  let unitsArea = 'ac';
                  if (unitsAreaSystem == 'hectares') {
                    unitsArea = 'ha';
                  }

                  const taskAcPerHrValueConverted = unitsAreaConversion(taskAcPerHrValue, unitsArea, 'ac'); // Need to convert to correct units
                  const taskAvgSpeedValue = formValues['taskAvgSpeed'][row.taskId.toString()]['avgSpeedTarget'];
                  if (filters['task'].includes(row.taskName) || filters['task'].length == 0) {
                    return (
                      <TableRow key={rowKey} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                        <TableCell>{row.taskName}</TableCell>
                        <TableCell>
                          {formValues.taskAcPerHr && (
                            <TextField
                              disabled={viewOnly}
                              size='small'
                              fullWidth
                              type='text'
                              name={row.taskId.toString()}
                              value={taskAcPerHrValueConverted}
                              onChange={(e) => {
                                handleNestedChange(e, 'taskAcPerHr');
                              }}
                              onKeyDown={handleAllowOnlyNumbers}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {formValues.taskAvgSpeed && (
                            <TextField
                              disabled={viewOnly}
                              size='small'
                              fullWidth
                              type='text'
                              name={row.taskId.toString()}
                              value={taskAvgSpeedValue}
                              onChange={(e) => {
                                handleNestedChange(e, 'taskAvgSpeed');
                              }}
                              onKeyDown={handleAllowOnlyNumbers}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  function generateTypeTargetsTable() {
    const viewOnly = !userSettings.roleAccess['dashboardManagement'];
    const machineTypes = Object.keys(machineTypeMapping);
    if (Object.keys(formValues.typeInFieldPercent).length > 0) {
      return (
        <Table size='small' aria-label='simple table'>
          <TableBody sx={{width: '100%'}}>
            {machineTypes.map((type) => {
              return (
                <React.Fragment key={type}>
                  <TargetText
                    showLabel={true}
                    label={machineTypeMapping[type] + ' - In Field Percent Target'}
                    innerTarget={type}
                    outerTarget='typeInFieldPercent'
                    type='number'
                    targetName='target'
                    formValues={formValues}
                    handleChange={(e) => {
                      handleNestedChange(e, 'typeInFieldPercent');
                    }}
                    disabled={viewOnly}
                  />
                  <TargetText
                    showLabel={true}
                    label={machineTypeMapping[type] + ' - Idle Time Percent Target'}
                    innerTarget={type}
                    outerTarget='typeIdleTimePercent'
                    type='number'
                    targetName='target'
                    formValues={formValues}
                    handleChange={(e) => {
                      handleNestedChange(e, 'typeIdleTimePercent');
                    }}
                    disabled={viewOnly}
                  />
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      );
    }
  }

  function generateVehicleTargetsTable() {
    let colorFlip = false;
    const viewOnly = !userSettings.roleAccess['dashboardManagement'];

    return (
      <React.Fragment>
        <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Vehicle'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#kpi-targets-vehicle-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-filter' style={{color: filters['vehicle'].length > 0 && '#4e73df'}} />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='kpi-targets-vehicle-select'
                    name={'vehicle'}
                    multiple={true}
                    search={true}
                    value={filters['vehicle']}
                    options={filterOptions['vehicle']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Target Idle Percent'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#kpi-targets-vehicleIdlePercent-select').toggle();
                    }}
                  ></button>
                </TableHeadFilterButtonTitleWrapper>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={100}>
                  <CircularProgress className='mt-4 mx-auto d-block' size={80} />
                </TableCell>
              </TableRow>
            ) : (
              <React.Fragment>
                {vehicleTargets.map((row) => {
                  colorFlip = !colorFlip;
                  const rowKey = row.vehicleSN;
                  if (filters['vehicle'].includes(row.vehicleName) || filters['vehicle'].length == 0) {
                    const vehicleIdlePercentValue = formValues['vehicleIdlePercent'][row.vehicleSN]['idleTarget'];
                    return (
                      <TableRow key={rowKey} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                        <TableCell>{row.vehicleName}</TableCell>
                        <TableCell>
                          {formValues.vehicleIdlePercent && (
                            <TextField
                              disabled={viewOnly}
                              size='small'
                              fullWidth
                              type='text'
                              name={row.vehicleSN}
                              value={vehicleIdlePercentValue}
                              onChange={(e) => {
                                handleNestedChange(e, 'vehicleIdlePercent');
                              }}
                              onKeyDown={handleAllowOnlyNumbers}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  } else {
                    return null;
                  }
                })}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  function generateTargetSettings() {
    const access = userSettings.roleViewAccess['dashboardManagement'];
    const viewOnly = !userSettings.roleAccess['dashboardManagement'];
    let viewOnlyText = '';
    if (viewOnly) {
      viewOnlyText = ' - View Only';
    }

    return (
      <React.Fragment>
        {access && (
          <div>
            {userSettings.roleViewAccess['dashboardManagement'] && (
              <React.Fragment>
                <CollapsableSection
                  title={'Overall Targets' + viewOnlyText}
                  collapseOpen={collapseOpen}
                  setCollapseOpen={setCollapseOpen}
                  section='totalTargets'
                >
                  {generateTotalTargetsTable()}
                </CollapsableSection>
                <CollapsableSection
                  title={'Task Targets' + viewOnlyText}
                  collapseOpen={collapseOpen}
                  setCollapseOpen={setCollapseOpen}
                  section='taskAcPerHr'
                >
                  {generateTaskTargetsTable()}
                </CollapsableSection>
                <CollapsableSection
                  title={'Vehicle Type Targets' + viewOnlyText}
                  collapseOpen={collapseOpen}
                  setCollapseOpen={setCollapseOpen}
                  section='typeTargets'
                >
                  {generateTypeTargetsTable()}
                </CollapsableSection>
                <CollapsableSection
                  title={'Vehicle Individual Targets' + viewOnlyText}
                  collapseOpen={collapseOpen}
                  setCollapseOpen={setCollapseOpen}
                  section='vehicleTargets'
                >
                  {generateVehicleTargetsTable()}
                </CollapsableSection>
              </React.Fragment>
            )}
          </div>
        )}
      </React.Fragment>
    );
  }

  return (
    <div className='tab-wrapper'>
      <div className='mt-1'>
        <React.Fragment>
          <Button variant='ic-button' color='secondary' title='Reset changes' onClick={resetFormValues}>
            Reset
          </Button>
          <Button
            variant='ic-button'
            color='primary'
            title='Submit changes'
            className='ml-1'
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit
          </Button>
        </React.Fragment>
      </div>
      {loading ? (
        <CircularProgress className='mt-2 mx-auto d-block' size={80} />
      ) : (
        <React.Fragment>
          <div className='tab-wrapper overflow-auto'>
            {/* GENERAL */}
            {generateTargetSettings()}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export {KPIsTab};
