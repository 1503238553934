import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {squareButton, deepCopy, fetchPostAuthSafe, unitsLengthSubmitConversion} from '../../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import {setImplementsBulkEditErrors, updateTableLoading} from '../../settingsSlice';

function Menu(props) {
  const dispatch = useDispatch();
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const implementsBulkEditMode = useSelector((state) => {
    return state.settings.implementsBulkEditMode;
  });
  const bulkEditImplements = useSelector((state) => {
    return state.settings.bulkEditImplements;
  });
  const implementSNDict = useSelector((state) => {
    return state.settings.implementSNDict;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });

  async function postImplementUpdates(updateDoc) {
    if (updateDoc.length > 0) {
      const postData = updateDoc;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        body: JSON.stringify(postData),
      };
      const url = '/settings/updateImplementDocs';
      const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
      const result = await response.json();
      if (result.errorMsg) {
        navigate('/error', {state: {errorMsg: result.errorMsg}});
      }

      if (result.status == 401) {
        navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
      }

      return result;
    } else {
      return {success: false, status: 400, error: 'Request body is empty'};
    }
  }

  async function submitBulkEdits() {
    const validationErrors = {};
    const editSubmissionList = [];
    Object.keys(bulkEditImplements).forEach((impSN) => {
      const newImp = deepCopy(bulkEditImplements[impSN]);
      const oldImp = deepCopy(implementSNDict[impSN]);
      const otherNames = Object.keys(implementSNDict)
        .filter((SN) => {
          return SN != impSN;
        })
        .map((SN) => {
          return bulkEditImplements[SN].name;
        });

      const impUpdateObj = {};
      // Check for name change
      if (oldImp.name != newImp.name) {
        // Validate imp name
        if (otherNames.includes(newImp.name)) {
          if (!validationErrors.hasOwnProperty(impSN)) {
            validationErrors[impSN] = {};
          }
          validationErrors[impSN]['name'] = 'Name exists on another implement';
        } else {
          impUpdateObj.name = newImp.name;
        }
      }
      const parsedOldTaskId = typeof oldImp.linkedTaskId != undefined && oldImp.linkedTaskId.toString();
      const parsedNewTaskId = typeof newImp.linkedTaskId != undefined && newImp.linkedTaskId.toString();
      if (parsedOldTaskId != parsedNewTaskId) {
        impUpdateObj.linkedTaskId = parsedNewTaskId;
      }
      if (oldImp.vin != newImp.vin) {
        impUpdateObj.vin = newImp.vin;
      }
      if (oldImp.make != newImp.make) {
        impUpdateObj.make = newImp.make;
      }

      // TODO IMPORTANT NEED TO PARSE THE UNITS OF THE WIDTH VALUE
      const parsedRowsOrWidthValue =
        newImp.rowsOrWidthValue != undefined ? parseFloat(newImp.rowsOrWidthValue) : undefined;
      if (oldImp.rowApplicationType != newImp.rowApplicationType) {
        if (newImp.rowApplicationType == 2 && parsedRowsOrWidthValue == 0) {
          if (!validationErrors.hasOwnProperty(impSN)) {
            validationErrors[impSN] = {};
          }
          validationErrors[impSN]['rowsOrWidthValue'] = 'Rows value must be 0.5 or a whole number';
        } else {
          impUpdateObj.rowApplicationType = parseInt(newImp.rowApplicationType);
        }
      }

      if (newImp.rowApplicationType == 0) {
        // TODO NEED TO VALIDATE NEW VALUE
        if (oldImp.dimensions.width != parsedRowsOrWidthValue) {
          impUpdateObj.dimensions = {
            ...oldImp.dimensions,
            width:
              unitsLengthSystem == 'imperial'
                ? unitsLengthSubmitConversion(parsedRowsOrWidthValue, 'ft')
                : parsedRowsOrWidthValue,
          };
        }
      } else if (newImp.rowApplicationType == 2) {
        if (oldImp.applicationRows != parsedRowsOrWidthValue)
          if (Number.isInteger(parsedRowsOrWidthValue) || parsedRowsOrWidthValue == 0.5) {
            impUpdateObj.applicationRows = parsedRowsOrWidthValue;
          } else {
            if (!validationErrors.hasOwnProperty(impSN)) {
              validationErrors[impSN] = {};
            }
            validationErrors[impSN]['rowsOrWidthValue'] = 'Rows value must be 0.5 or a whole number';
          }
      }

      if (oldImp.archived != newImp.archived) {
        impUpdateObj.archived = newImp.archived;
      }
      if (oldImp.notes != newImp.notes) {
        impUpdateObj.notes = newImp.notes;
      }
      if (Object.keys(impUpdateObj).length > 0) {
        impUpdateObj.serialNumber = impSN;
        editSubmissionList.push(impUpdateObj);
      }
    });
    dispatch(setImplementsBulkEditErrors(validationErrors));

    if (Object.keys(validationErrors).length == 0 && editSubmissionList.length != 0) {
      props.handleBulkEditModeToggle(false);
      dispatch(updateTableLoading({table: 'implements', status: true}));
      const editSubmissionListParsed = editSubmissionList.map((editObj) => {
        const diffDoc = deepCopy(editObj);
        delete diffDoc['serialNumber'];
        return {serialNumber: editObj.serialNumber, diffDoc: diffDoc};
      });
      const results = await postImplementUpdates(editSubmissionListParsed);
      props.getTaskConfigsImplementsDataFromApi();
    } else if (Object.keys(validationErrors).length == 0 && editSubmissionList.length == 0) {
      props.handleBulkEditModeToggle(false);
    }
  }

  useEffect(() => {}, [props.sortMethod]);

  // The class names with 'md' are specifically for small screen sizes (< 767 px)
  return (
    <React.Fragment>
      <div className='pt-2 pb-0'>
        <h5 style={{color: 'black'}}>Implements Management</h5>
        <div className='row my-1 mx-0 justify-content-start'>
          {userSettings.roleAccess['implementManagement'] && (
            <React.Fragment>
              <Button
                sx={squareButton}
                variant='ic-button'
                color='success'
                title='Add implement'
                onClick={() => {
                  const emptyImplement = {
                    name: '',
                    serialNumber: '',
                    dimensions: {
                      'width': 0,
                      'height': 0,
                      'length': 0,
                    },
                    linkedTaskId: '',
                    archived: false,
                    rowApplicationType: undefined,
                    applicationRows: 1,
                  };
                  props.handleUpdateEditImplement(emptyImplement);
                  props.handleEditModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon='fas fa-plus' />
              </Button>
              <Button
                sx={squareButton}
                variant='ic-button'
                color='success'
                title='Bulk Upload'
                className='ml-1'
                onClick={() => {
                  props.handleBulkUploadModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon='fas fa-upload' />
              </Button>
              <Button
                sx={{...squareButton, marginLeft: '3px'}}
                variant='ic-button'
                color={implementsBulkEditMode ? 'danger' : 'secondary'}
                onClick={() => {
                  props.handleBulkEditModeToggle(!implementsBulkEditMode);
                }}
                title='Bulk Edit Mode'
              >
                {implementsBulkEditMode ? (
                  <FontAwesomeIcon icon='fas fa-xmark-circle' />
                ) : (
                  <FontAwesomeIcon icon='fas fa-edit' />
                )}
              </Button>
              {implementsBulkEditMode && (
                <React.Fragment>
                  <Button
                    sx={squareButton}
                    variant='ic-button'
                    color='primary'
                    title='Bulk Edit Submit'
                    className='ml-1'
                    onClick={() => {
                      submitBulkEdits();
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-floppy-disk' />
                  </Button>
                  <Button
                    sx={squareButton}
                    variant='ic-button'
                    color='secondary'
                    title='Bulk Edit Reset'
                    className='ml-1'
                    onClick={() => {
                      props.processImplementsData();
                      dispatch(setImplementsBulkEditErrors({}));
                    }}
                  >
                    <FontAwesomeIcon icon='fas fa-trash-can' />
                  </Button>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <div className='px-1 px-md-1 dropdown' style={{maxWidth: '25%'}}>
            <button className='btn border-dark btn-light' data-toggle='dropdown' title='Sort'>
              <FontAwesomeIcon icon='fas fa-sort-amount-down' />
            </button>
            <div className='dropdown-menu border-dark' style={{minWidth: '0'}}>
              <div className='dropdown-header cropview-menu-text px-3'>Sort By</div>
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  props.handleSetSortMethod('name');
                }}
              >
                Implement Name {props.sortMethod == 'name' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  props.handleSetSortMethod('linkedTaskId');
                }}
              >
                Linked Task {props.sortMethod == 'linkedTaskId' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  props.handleSetSortMethod('vin');
                }}
              >
                VIN {props.sortMethod == 'vin' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  props.handleSetSortMethod('make');
                }}
              >
                Make {props.sortMethod == 'make' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  props.handleSetSortMethod('rowApplicationType');
                }}
              >
                Acre Calculation {props.sortMethod == 'rowApplicationType' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
              <button
                className='dropdown-item cropview-menu-text px-3'
                onClick={() => {
                  props.handleSetSortMethod('notes');
                }}
              >
                Notes {props.sortMethod == 'notes' && <FontAwesomeIcon icon='fas fa-check' />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export {Menu};
