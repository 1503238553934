// Import for framework tools
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sendGAPageview} from '../../app/utils';
import {sendGAEvent, fetchPostAuthSafe} from '../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CircularProgress, Grid, Paper, Typography, Link} from '@mui/material';
import {setSelectedScorecardId, setkpiLandingPageOn, setSelectedKPI} from './dashboardSlice';

function KpiLanding(props) {
  const dispatch = useDispatch();

  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const customerInfo = useSelector((state) => {
    return state.app.customerSettings.general;
  });

  const kpiLandingPageOn = useSelector((state) => {
    return state.dashboard.kpiLandingPageOn;
  });

  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const scorecardViewAccess = userSettings.roleViewAccess['dashboardScorecardManagement'];

  const [landingLoading, setLandingLoading] = useState(true);

  useEffect(async () => {
    document.title = 'IntelliCulture | StatsView';

    sendGAPageview(document.title);
    setLandingLoading(false);
  }, []);

  useEffect(() => {
    const urlSearch = window.location.search;
    if (urlSearch.includes('scorecardId')) {
      dispatch(setSelectedKPI('Scorecards'));
      dispatch(setkpiLandingPageOn(false));
    }
  }, [])

  // Styles
  const gridStyle = {
    marginTop: '5vh',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const cardStyle = {
    cursor: 'pointer',
    height: smallScreen ? '10vh' : '20vh',
    borderRadius: 5,
    backgroundColor: '#056905',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const cardTextStyle = {
    fontSize: '34px',
    fontWeight: 'bold',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  };

  const welcomeMessageStyle = {
    fontSize: '4vmax',
    color: '#056905',
    fontWeight: 'bold',
    userSelect: 'none',
  };

  return (
    <React.Fragment>
      <div>
        <div className='mt-5 text-center' style={welcomeMessageStyle}>
          Welcome to IntelliCulture,
        </div>
        <div className='mb-5 text-center' style={welcomeMessageStyle}>
          {customerInfo.organizationName}
        </div>
        <Grid sx={gridStyle} container spacing={1}>
          {props.dashboardTabs.includes('Farming') && (
            <Grid item xs={10} md={3.5}>
              <Paper
                onClick={() => {
                  dispatch(setkpiLandingPageOn(false));
                  dispatch(setSelectedScorecardId(null));
                  props.updateSelectedKpi('Farming');
                }}
                sx={cardStyle}
                elevation={3}
                data-toggle='tab'
                href={'#farming'}
              >
                <Typography sx={cardTextStyle} variant='h5'>
                  <FontAwesomeIcon style={{marginRight: '10px'}} icon='fa-solid fa-seedling' /> Farming
                </Typography>
              </Paper>
            </Grid>
          )}
          {props.dashboardTabs.includes('Machinery') && (
            <Grid item xs={10} md={3.5}>
              <Paper
                onClick={() => {
                  dispatch(setkpiLandingPageOn(false));
                  dispatch(setSelectedScorecardId(null));
                  props.updateSelectedKpi('Machinery');
                }}
                sx={cardStyle}
                elevation={3}
                data-toggle='tab'
                href={'#machinery'}
              >
                <Typography sx={cardTextStyle} variant='h5'>
                  <FontAwesomeIcon style={{marginRight: '10px'}} icon='fa-solid fa-tractor' /> Machinery
                </Typography>
              </Paper>
            </Grid>
          )}
          {/* Scorecards */}
          {scorecardViewAccess && (
            <Grid item xs={10} md={7}>
              <Paper
                onClick={() => {
                  dispatch(setkpiLandingPageOn(false));
                  props.updateSelectedKpi('Scorecards');
                }}
                sx={cardStyle}
                elevation={3}
                data-toggle='tab'
                href={'#Scorecards'}
              >
                <Typography sx={cardTextStyle} variant='h5'>
                  <FontAwesomeIcon style={{marginRight: '10px'}} icon='fa-solid fa-file-lines' /> Scorecards
                </Typography>
              </Paper>
            </Grid>
          )}
          {/* Scorecards */}
        </Grid>
      </div>
    </React.Fragment>
  );
}

export {KpiLanding};
