import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Framework} from '../components/Framework';
import {getCustomerAndUserInfo} from '../appSlice';
import {sendGAPageview, fetchPostAuthSafe} from '../app/utils';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  InputAdornment,
  Select,
  TextField,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function PersonalSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  const [submitting, setSubmitting] = useState({
    user: false,
    password: false,
    settings: false,
  });
  const [formValues, setFormValues] = useState({
    username: '',
    email: '',
    passwordCurrent: '',
    passwordNew: '',
    passwordConfirm: '',
    unitsLength: '',
    unitsArea: '',
    database: '',
  });
  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);
  const [passwordsNoMatch, setPasswordsNoMatch] = useState(false);
  const [usernameInvalid, setUsernameInvalid] = useState(false);
  const [usernameExists, setUsernameExists] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  useEffect(() => {
    document.title = 'IntelliCulture | Personal Settings';
    // sendGAPageview(document.title, true);
  }, []);

  useEffect(() => {
    populateForm();
  }, [userSettings]);

  useEffect(() => {
    validatePassword();
  }, [formValues.passwordNew, formValues.passwordConfirm]);

  function populateForm() {
    resetUserProfile();
    resetPassword();
    resetSettings();
    resetDatabase();
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setFormValues((values) => {
      return {...values, [name]: value};
    });
  };

  async function submitUserProfile() {
    setSubmitting((values) => {
      return {...values, user: true};
    });

    const postData = {
      username: formValues.username,
      email: formValues.email,
    };
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(postData),
    };
    const response = await fetchPostAuthSafe(
      '/settings/updateUserProfile',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();

    // Clear warnings
    setUsernameInvalid(false);
    setUsernameExists(false);
    setEmailInvalid(false);
    setEmailExists(false);

    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }
    if (result.usernameInvalid) {
      setUsernameInvalid(true);
    } else if (result.usernameExists) {
      setUsernameExists(true);
    }
    if (result.emailInvalid) {
      setEmailInvalid(true);
    } else if (result.emailExists) {
      setEmailExists(true);
    }

    if (!(result.usernameInvalid || result.usernameExists || result.emailInvalid || result.emailExists)) {
      await dispatch(getCustomerAndUserInfo());
    }

    setSubmitting((values) => {
      return {...values, user: false};
    });
  }

  async function resetUserProfile() {
    setFormValues((values) => {
      return {
        ...values,
        username: userSettings.username,
        email: userSettings.email,
      };
    });
    // Clear warnings
    setUsernameInvalid(false);
    setUsernameExists(false);
    setEmailInvalid(false);
    setEmailExists(false);
  }

  async function submitPassword() {
    if (formValues.passwordNew.length > 0 && !passwordsNoMatch) {
      setSubmitting((values) => {
        return {...values, password: true};
      });

      const postData = {
        passwordCurrent: formValues.passwordCurrent,
        passwordNew: formValues.passwordNew,
        passwordConfirm: formValues.passwordConfirm,
      };
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(postData),
      };
      const response = await fetchPostAuthSafe(
        '/settings/updateUserPassword',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const result = await response.json();

      // Clear warnings
      setPasswordIncorrect(false);

      if (result.errorMsg) {
        navigate('/error', {state: {errorMsg: result.errorMsg}});
      }
      if (result.passwordIncorrect) {
        setPasswordIncorrect(true);
      } else {
        await dispatch(getCustomerAndUserInfo());
        resetPassword();
      }

      setSubmitting((values) => {
        return {...values, password: false};
      });
    }
  }

  async function resetPassword() {
    setFormValues((values) => {
      return {
        ...values,
        passwordCurrent: '',
        passwordNew: '',
        passwordConfirm: '',
      };
    });
    setPasswordIncorrect(false);
  }

  async function submitSettings() {
    setSubmitting((values) => {
      return {...values, settings: true};
    });

    const postData = {
      username: userSettings.username,
      unitsLength: formValues.unitsLength,
      unitsArea: formValues.unitsArea,
    };
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(postData),
    };
    const response = await fetchPostAuthSafe(
      '/settings/updateUserGeneralSettings',
      options,
      userSettings.username,
      userSettings.databaseName
    );

    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }
    await dispatch(getCustomerAndUserInfo());

    setSubmitting((values) => {
      return {...values, settings: false};
    });
  }

  async function resetSettings() {
    setFormValues((values) => {
      return {
        ...values,
        unitsLength: userSettings.general.userUnitsLengthSet,
        unitsArea: userSettings.general.userUnitsAreaSet,
      };
    });
  }

  async function submitDatabase() {
    const postData = {
      database: formValues.database,
    };
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(postData),
    };
    const response = await fetchPostAuthSafe(
      '/settings/changeDatabase',
      options,
      userSettings.username,
      userSettings.databaseName
    );

    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }
    await dispatch(getCustomerAndUserInfo());
    resetDatabase();
  }

  async function resetDatabase() {
    setFormValues((values) => {
      return {
        ...values,
        database: userSettings.databaseName,
      };
    });
  }

  function validatePassword() {
    if (formValues.passwordNew.length > 0 && formValues.passwordNew != formValues.passwordConfirm) {
      setPasswordsNoMatch(true);
    } else {
      setPasswordsNoMatch(false);
    }
  }

  return (
    <React.Fragment>
      <Framework activePage='Settings' pageName='Settings'>
        <div className='container-fluid tab-wrapper overflow-auto'>
          <div className='col-12'>
            {/* USER PROFILE */}
            <h5 className='mt-4' style={{color: 'black'}}>
              User Profile
            </h5>
            {submitting.user ? (
              <CircularProgress className='mt-4 mx-auto d-block' size={80} />
            ) : (
              <div>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <TableRow>
                      <TableCell width='50%'>Username</TableCell>
                      <TableCell>
                        <TextField
                          size='small'
                          fullWidth
                          name='username'
                          value={formValues.username}
                          onChange={handleChange}
                          error={usernameInvalid || usernameExists}
                          helperText={
                            usernameInvalid ? 'Username is invalid' : usernameExists ? 'Username is already in use' : ''
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width='50%'>Email</TableCell>
                      <TableCell>
                        <TextField
                          size='small'
                          fullWidth
                          name='email'
                          value={formValues.email}
                          onChange={handleChange}
                          error={emailInvalid || emailExists}
                          helperText={emailInvalid ? 'Email is invalid' : emailExists ? 'Email is already in use' : ''}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className='mt-2'>
                  <Button variant='ic-button' color='secondary' onClick={resetUserProfile}>
                    Reset
                  </Button>
                  <Button variant='ic-button' color='primary' className='ml-1' onClick={submitUserProfile}>
                    Submit
                  </Button>
                </div>
              </div>
            )}

            {/* CHANGE PASSWORD */}
            <h5 className='mt-4' style={{color: 'black'}}>
              Change Password
            </h5>
            {submitting.password ? (
              <CircularProgress className='mt-4 mx-auto d-block' size={80} />
            ) : (
              <div>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <TableRow>
                      <TableCell width='50%'>Current Password</TableCell>
                      <TableCell>
                        <TextField
                          size='small'
                          fullWidth
                          type={showPasswordCurrent ? 'text' : 'password'}
                          name='passwordCurrent'
                          value={formValues.passwordCurrent}
                          onChange={handleChange}
                          error={passwordIncorrect}
                          helperText={passwordIncorrect ? 'Password is incorrect' : ''}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={() => {
                                    setShowPasswordCurrent(!showPasswordCurrent);
                                  }}
                                >
                                  {showPasswordCurrent ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width='50%'>New Password</TableCell>
                      <TableCell>
                        <TextField
                          size='small'
                          fullWidth
                          type={showPasswordNew ? 'text' : 'password'}
                          name='passwordNew'
                          value={formValues.passwordNew}
                          onChange={handleChange}
                          error={passwordsNoMatch}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={() => {
                                    setShowPasswordNew(!showPasswordNew);
                                  }}
                                >
                                  {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width='50%'>Confirm New Password</TableCell>
                      <TableCell>
                        <TextField
                          size='small'
                          fullWidth
                          type={showPasswordConfirm ? 'text' : 'password'}
                          name='passwordConfirm'
                          value={formValues.passwordConfirm}
                          onChange={handleChange}
                          error={passwordsNoMatch}
                          helperText={passwordsNoMatch ? 'Password confirmation does not match' : ''}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={() => {
                                    setShowPasswordConfirm(!showPasswordConfirm);
                                  }}
                                >
                                  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className='mt-2'>
                  <Button variant='ic-button' color='secondary' onClick={resetPassword}>
                    Reset
                  </Button>
                  <Button variant='ic-button' color='primary' className='ml-1' onClick={submitPassword}>
                    Submit
                  </Button>
                </div>
              </div>
            )}

            {/* PERSONALIZED SETTINGS */}
            <h5 className='mt-4' style={{color: 'black'}}>
              Personalized Settings
            </h5>
            {submitting.settings ? (
              <CircularProgress className='mt-4 mx-auto d-block' size={80} />
            ) : (
              <div>
                <Table size='small' aria-label='simple table'>
                  <TableBody>
                    <TableRow>
                      <TableCell width='50%'>Units of Length</TableCell>
                      <TableCell>
                        <Select
                          value={formValues.unitsLength}
                          name='unitsLength'
                          size='small'
                          fullWidth
                          onChange={handleChange}
                        >
                          <MenuItem value={'default'}>Database Setting</MenuItem>
                          <MenuItem value={'metric'}>Metric</MenuItem>
                          <MenuItem value={'imperial'}>Imperial</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell width='50%'>Units of Area</TableCell>
                      <TableCell>
                        <Select
                          value={formValues.unitsArea}
                          name='unitsArea'
                          size='small'
                          fullWidth
                          onChange={handleChange}
                        >
                          <MenuItem value={'default'}>Database Setting</MenuItem>
                          <MenuItem value={'acre'}>Acre</MenuItem>
                          <MenuItem value={'hectare'}>Hectare</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <div className='mt-2'>
                  <Button variant='ic-button' color='secondary' onClick={resetSettings}>
                    Reset
                  </Button>
                  <Button variant='ic-button' color='primary' className='ml-1' onClick={submitSettings}>
                    Submit
                  </Button>
                </div>
              </div>
            )}

            {/* DATABASE CURRENTLY DISABLED */}
            {/* <h5 className='mt-4' style={{color: 'black'}}>Database</h5>          
            <Table size='small' aria-label="simple table">
              <TableBody>
                <TableRow>
                  <TableCell width='50%'>Current Database</TableCell>
                  <TableCell>
                    <Select value={formValues.database} name='database' size='small' fullWidth onChange={handleChange}>
                      {userSettings.databaseOptions.map((database, idx) => {
                        return <MenuItem key={idx} value={database}>{database}</MenuItem>
                      })}
                    </Select>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div className='mt-2'>
              <Button variant='ic-button' color='secondary' onClick={resetDatabase}>
                Reset
              </Button>
              <Button variant='ic-button' color='primary' className='ml-1' onClick={submitDatabase}>
                Submit
              </Button>
            </div> */}

            {/* DELETE ACCOUNT */}
            <h5 className='mt-4' style={{color: 'black'}}>
              Account Deletion
            </h5>
            <Button
              variant='ic-button'
              color='danger'
              className='mt-2'
              onClick={() => {
                navigate('/delete_account');
              }}
            >
              Delete Account
            </Button>
          </div>
        </div>
      </Framework>
    </React.Fragment>
  );
}

export {PersonalSettings};
