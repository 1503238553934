import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {BulkEditInputs} from '../BulkEditInputs';
import {getCustomerAndUserInfo} from '../../../appSlice';
import {
  getSettingsData,
  setAssetLabelsBulkEditErrors,
  setAssetLabelsBulkEditMode,
  initializeBulkEditAssetLabels,
  updateBulkEditAssetLabels,
  updateTableLoading,
  updateLabelsData,
} from '../settingsSlice';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../components/Table';
import {Tailselect} from '../../../components/Tailselect';
import {TabMenuTableWrapper} from '../../../components/TabMenuTableWrapper';
import {CustomTablePagination} from '../../../components/CustomTablePagination';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  searchFind,
  modalBoxStyle,
  hideCell,
  squareButton,
  generateCsv,
  fetchPostAuthSafe,
  sortVehicleNamesHelper,
  deepCopy,
} from '../../../app/utils';
import {
  ModalSubmitting,
  SettingsSwitch,
  SettingsSelect,
  SettingsSearchSelect,
  ViewOnlyText,
  SettingsText,
} from '../../../components/GeneralComponents';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Modal,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
} from '@mui/material';

function LabelsTab(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => {
    return state.settings.tableLoading.assetLabels;
  });
  const labels = useSelector((state) => {
    return state.settings.labels;
  });
  const btTags = useSelector((state) => {
    return state.settings.btTags;
  });
  const vehicles = useSelector((state) => {
    return state.settings.vehicles;
  });
  const implementSNDict = useSelector((state) => {
    return state.settings.implementSNDict;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const assetLabelsBulkEditErrors = useSelector((state) => {
    return state.settings.assetLabelsBulkEditErrors;
  });
  const assetLabelsBulkEditMode = useSelector((state) => {
    return state.settings.assetLabelsBulkEditMode;
  });

  const [submitting, setSubmitting] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [implementsWithTags, setImplementsWithTags] = useState([]);
  const [implementWarning, setImplementWarning] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    assetLabelCodeId: [],
    assetName: [],
    assetType: ['Vehicle', 'Implement'],
    assetLabelStatus: ['Active', 'Archived'],
  });

  const [filters, setFilters] = useState({
    assetLabelCodeId: [],
    assetName: [],
    assetType: [],
    assetLabelStatus: [],
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [activeVehicles, setActiveVehicles] = useState([]);
  const [multiCreateFormValues, setMultiCreateFormValues] = useState({quantity: 0});
  const [propagateAllColumns, setPropagateAllColumns] = useState({});

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [modalCode, setModalCode] = useState('');

  const [multiCreate, setMultiCreate] = useState(false);

  const [modalFormValues, setModalFormValues] = useState({
    code: '',
    type: '',
    asset: '',
    archived: false,
  });

  function resetBulkEdits() {
    const assetLabelsDict = {};

    const tempTableData = labels.filter((label) => {
      let assetName = '';
      let assetType = '';
      if (label.data.assetType == 'vehicle') {
        assetType = 'Vehicle';
        const asset = vehicles.find((veh) => {
          return veh.serialNumber == label.data.assetId;
        });
        if (asset) {
          assetName = asset.name;
        }
      } else if (label.data.assetType == 'implement') {
        assetType = 'Implement';
        const asset = implementSNDict[label.data.assetId];
        if (asset) {
          assetName = asset.name;
        }
      }
      const labelInFilter = filterRow(assetName, assetType, label);
      return labelInFilter;
    });

    tempTableData.forEach((label) => {
      assetLabelsDict[label.data.id] = label.data;
    });
    dispatch(initializeBulkEditAssetLabels(assetLabelsDict));
  }

  useEffect(async () => {
    const newCode = !modalCode || modalCode == 'TBD';
    const codeData = labels.find((code) => {
      return code.data.id == modalCode;
    });

    setModalFormValues({
      code: newCode ? 'TBD' : codeData.data.id,
      databaseLabel: newCode ? 'TBD' : codeData.data.databaseLabel,
      type: newCode ? '' : codeData.data.assetType,
      asset: newCode ? '' : codeData.data.assetId,
      archived: newCode ? false : codeData.data.archived,
    });
  }, [modalCode]);

  useEffect(async () => {
    const newImpWithTagsList = [];
    btTags.forEach((btTagDoc) => {
      if (btTagDoc.data.implementSN) {
        newImpWithTagsList.push(btTagDoc.data.implementSN);
      }
    });
    setImplementsWithTags(newImpWithTagsList);
  }, [btTags]);

  useEffect(async () => {
    const tempTableData = labels.filter((label) => {
      let assetName = '';
      let assetType = '';
      if (label.data.assetType == 'vehicle') {
        assetType = 'Vehicle';
        const asset = vehicles.find((veh) => {
          return veh.serialNumber == label.data.assetId;
        });
        if (asset) {
          assetName = asset.name;
        }
      } else if (label.data.assetType == 'implement') {
        assetType = 'Implement';
        const asset = implementSNDict[label.data.assetId];
        if (asset) {
          assetName = asset.name;
        }
      }
      const labelInFilter = filterRow(assetName, assetType, label);
      return labelInFilter;
    });
    if (page > parseInt(tempTableData.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(tempTableData);

    const assetLabelsDict = {};

    tempTableData.forEach((label) => {
      assetLabelsDict[label.data.id] = label.data;
    });

    dispatch(initializeBulkEditAssetLabels(assetLabelsDict));

    const activeVehTemp = [];
    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].icActive) {
        activeVehTemp.push(vehicles[i]);
      }
    }

    setActiveVehicles(activeVehTemp);
  }, [labels, vehicles, implementSNDict, filters, searchText]);

  useEffect(async () => {
    const codeFilterOptions = [];
    const nameFilterOptions = [];

    labels.forEach((label) => {
      codeFilterOptions.push(`${label.data.databaseLabel}`);
      if (label.data.assetType == 'vehicle') {
        const asset = vehicles.find((veh) => {
          return veh.serialNumber == label.data.assetId;
        });
        if (asset) {
          nameFilterOptions.push(asset.name);
        }
      } else if (label.data.assetType == 'implement') {
        const asset = implementSNDict[label.data.assetId];
        if (asset) {
          nameFilterOptions.push(asset.name);
        }
      }
    });
    setFilterOptions({
      ...filterOptions,
      assetName: nameFilterOptions,
      assetLabelCodeId: codeFilterOptions,
    });
  }, [labels, vehicles, implementSNDict]);

  useEffect(async () => {
    setImplementWarning(modalFormValues.type == 'implement' && implementsWithTags.includes(modalFormValues.asset));
  }, [modalFormValues, implementsWithTags]);

  const handleModalChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (name == 'type') {
      setModalFormValues((values) => {
        return {
          ...values,
          [name]: value,
          asset: '',
        };
      });
    } else {
      setModalFormValues((values) => {
        return {...values, [name]: value};
      });
    }
  };

  async function handleModalSubmit() {
    setSubmitting(true);

    // Create doc for asset label
    const updateDoc = {
      id: modalFormValues.code,
      assetType: modalFormValues.type,
      assetId: modalFormValues.asset,
      archived: modalFormValues.archived,
    };

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(updateDoc),
    };

    const response = await fetchPostAuthSafe(
      '/settings/updateAssetLabel',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    } else if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    dispatch(getCustomerAndUserInfo());
    dispatch(getSettingsData());
    setModalOpen(false);
    setSubmitting(false);
    setModalCode('');
  }

  function downloadCsv() {
    const baseUrl = window.location.origin;

    // Push the Asset Label data into the csv data table
    const csvList = [];
    labels.forEach(function (label) {
      const code = label.data.id;
      let databaseLabel = '';
      let labelledCode = code;
      if (typeof label.data.databaseLabel != 'undefined') {
        databaseLabel = label.data.databaseLabel;
        labelledCode += `-${databaseLabel}`;
      }
      const scanUrl = `${baseUrl}/scan?code=${code}`;

      let assetName = '';
      if (label.data.assetType == 'vehicle') {
        const asset = vehicles.find((veh) => {
          return veh.serialNumber == label.data.assetId;
        });
        if (asset) {
          assetName = asset.name;
        }
      } else if (label.data.assetType == 'implement') {
        const asset = implementSNDict[label.data.assetId];
        if (asset) {
          assetName = asset.name;
        }
      }

      // Compile Data into array in the same order the CSV header listed below
      const labelData = [databaseLabel, labelledCode, label.data.archived, label.data.assetType, assetName, scanUrl];
      csvList.push(labelData);
    });

    const filename = 'AssetLabels';
    const csvHeaders = ['Label Number', 'Display Text', 'Archived Status', 'Asset Type', 'Asset Name', 'Scan URL'];

    generateCsv(filename, csvHeaders, csvList);
  }

  function handleMultiCreateChange(e) {
    setMultiCreateFormValues({quantity: parseInt(e.target.value)});
  }

  async function handleMultiCreateSubmit() {
    setSubmitting(true);

    // Create doc for asset label
    const updateDoc = {
      quantityToCreate: parseInt(multiCreateFormValues.quantity),
      uploadType: 'qr',
    };

    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(updateDoc),
    };

    const response = await fetchPostAuthSafe(
      '/settings/bulkUpload',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    } else if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    dispatch(getCustomerAndUserInfo());
    dispatch(getSettingsData());
    setModalOpen(false);
    setSubmitting(false);
    setModalCode('');
    setMultiCreate(false);
    setMultiCreateFormValues({quantity: 0});
  }

  function sortByBooleanValue(a, b) {
    // If "isTrue" is true, the item should come before (lower index) the other item (b).
    // If "isTrue" is false, the item should come after (higher index) the other item (b).
    // return implementSNDict[a].archived === implementSNDict[b].archived ? 0 : implementSNDict[a].archived ? 1 : -1;
    if (implementSNDict[a].archived) {
      return 1;
    }
    if (implementSNDict[a].archived === implementSNDict[b].archived) {
      return implementSNDict[a].name.localeCompare(implementSNDict[b].name);
    }
    return 0;
  }

  function renderAssetOptions(type) {
    let options = [];
    if (type == 'vehicle') {
      options = deepCopy(vehicles).map((vehicleObj) => {
        return {
          label: vehicleObj.name,
          value: vehicleObj.serialNumber,
        };
      });
    } else if (type == 'implement') {
      options = Object.keys(deepCopy(implementSNDict)).map((SN) => {
        return {
          label: implementSNDict[SN].name,
          value: SN,
        };
      });
    }
    options.unshift({
      value: '',
      label: '----',
    });
    return options;
  }

  function renderAssetTypeOptions() {
    return [
      {
        label: 'Implement',
        value: 'implement',
      },
      {
        label: 'Vehicle',
        value: 'vehicle',
      },
    ];
  }

  function renderArchivedOptions() {
    return [
      {label: 'Archived', value: true},
      {label: 'Active', value: false},
    ];
  }

  function handleBulkEditTextFields(entityIdName, entityId, propertyName, propertyValue) {
    const assetLabelsBulkEditCopy = deepCopy(assetLabelsBulkEditErrors);
    let updateRowsId = [entityId];
    if (propagateAllColumns.hasOwnProperty(propertyName) && propagateAllColumns[propertyName]) {
      updateRowsId = [];

      for (let i = 0; i < tableData.length; i++) {
        const propValueMatch = tableData[i].data[propertyName] === propertyValue;

        if (!propValueMatch) {
          updateRowsId.push(tableData[i].data.id);
        }
      }
    }

    updateRowsId.forEach((assetLabelId) => {
      const assetLabelHasError =
        assetLabelsBulkEditErrors.hasOwnProperty(assetLabelId) &&
        assetLabelsBulkEditErrors[assetLabelId].hasOwnProperty(propertyName);
      if (assetLabelHasError) {
        delete assetLabelsBulkEditCopy[assetLabelId][propertyName];
        if (Object.keys(assetLabelsBulkEditCopy[assetLabelId]).length == 0) {
          delete assetLabelsBulkEditCopy[assetLabelId];
        }
      }
      dispatch(
        updateBulkEditAssetLabels({
          [entityIdName]: assetLabelId,
          data: {
            [propertyName]: propertyValue,
            ...(propertyName == 'assetType' ? {'assetId': ''} : {}),
          },
        })
      );
    });

    dispatch(setAssetLabelsBulkEditErrors(assetLabelsBulkEditCopy));
  }

  function handleToggleBulkEditPropagateColumn(propertyName) {
    setPropagateAllColumns((value) => {
      return {
        ...value,
        [propertyName]: value.hasOwnProperty(propertyName) ? !value[propertyName] : true,
      };
    });
  }

  function generateModal() {
    const implementsKeysList = Object.keys(implementSNDict);
    implementsKeysList.sort(sortByBooleanValue);

    const vehiclesForSort = activeVehicles.filter((vehicleObj) => {
      return !vehicleObj.archived || vehicleObj.serialNumber == modalFormValues.asset;
    });

    const vehicleListSorted = vehiclesForSort.sort((a, b) => {
      return sortVehicleNamesHelper(a.name, b.name);
    });

    // Determine asset options to display
    let assetOptions = [{value: '', label: '----'}];
    if (modalFormValues.type == 'vehicle') {
      assetOptions = assetOptions.concat(
        vehicleListSorted.map((veh) => {
          return {
            value: veh.serialNumber,
            label: veh.name + (veh.archived ? ' (Archived)' : '')
          };
        })
      );
    } else if (modalFormValues.type == 'implement') {
      assetOptions = assetOptions.concat(
        implementsKeysList.map((impSN) => {
          return {
            value: impSN,
            label: implementSNDict[impSN].name + (implementSNDict[impSN].archived ? ' (Archived)' : ''),
          };
        })
      );
    }

    const viewOnly = !userSettings.roleAccess['labelManagement'];

    return (
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setModalCode('');
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='overflow-auto'
      >
        <Box sx={modalBoxStyle}>
          {submitting ? (
            <ModalSubmitting />
          ) : multiCreate ? (
            <React.Fragment>
              <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                <h3>
                  <strong>Create QR Codes</strong>
                </h3>
                {viewOnly && <ViewOnlyText />}
                <Table aria-label='simple table'>
                  <TableBody>
                    <SettingsText
                      label='Quantity to create'
                      setting='quantity'
                      type='number'
                      formValues={multiCreateFormValues}
                      handleChange={handleMultiCreateChange}
                      disabled={viewOnly}
                    />
                  </TableBody>
                </Table>
                <div className='flex-row my-3 col-12 px-1'>
                  <Button
                    variant='ic-button'
                    color='secondary'
                    className='mx-1'
                    onClick={() => {
                      setMultiCreate(false);
                      setMultiCreateFormValues({quantity: 0});
                    }}
                  >
                    Cancel
                  </Button>
                  {!viewOnly && (
                    <Button
                      variant='ic-button'
                      color='primary'
                      className='mx-1'
                      onClick={handleMultiCreateSubmit}
                      disabled={!multiCreateFormValues.quantity || multiCreateFormValues.quantity > 500}
                    >
                      Submit
                    </Button>
                  )}
                </div>
                {multiCreateFormValues.quantity > 500 && (
                  <p style={{color: 'red'}}> You may only create a maximum of 500 QR codes at a time.</p>
                )}
              </div>
            </React.Fragment>
          ) : (
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
              <Grid container>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                  <h3>
                    <strong>Asset Label Settings</strong>
                  </h3>
                </Grid>
                <Grid style={{textAlign: 'right'}} item xs={3}>
                  {modalCode == 'TBD' && (
                    <Button
                      variant='ic-button'
                      color='success'
                      className='mx-1'
                      onClick={() => {
                        setMultiCreate(true);
                      }}
                    >
                      Multi QR Create
                    </Button>
                  )}
                </Grid>
              </Grid>

              {viewOnly && <ViewOnlyText />}
              <Table aria-label='simple table'>
                <TableBody>
                  <TableRow>
                    <TableCell width='50%'>Code ID</TableCell>
                    <TableCell>{`${modalFormValues.code}` + `-${modalFormValues.databaseLabel}`}</TableCell>
                  </TableRow>
                  <SettingsSelect
                    label='Type'
                    setting='type'
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                    options={[
                      {value: '', label: '----'},
                      {value: 'vehicle', label: 'Vehicle'},
                      {value: 'implement', label: 'Implement'},
                    ]}
                  />
                  <SettingsSearchSelect
                    label='Asset'
                    setting='asset'
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                    options={assetOptions}
                    error={implementWarning}
                    helperText={implementWarning ? 'Implement already has a BT tag assigned' : ''}
                  />
                  <SettingsSwitch
                    label='Archive Label'
                    setting='archived'
                    formValues={modalFormValues}
                    handleChange={handleModalChange}
                    disabled={viewOnly}
                  />
                </TableBody>
              </Table>

              <div className='flex-row my-3 col-12 px-1'>
                <Button
                  variant='ic-button'
                  color='secondary'
                  className='mx-1'
                  onClick={() => {
                    setModalOpen(false);
                    setModalCode('');
                  }}
                >
                  Cancel
                </Button>
                {!viewOnly && (
                  <Button
                    variant='ic-button'
                    color='primary'
                    className='mx-1'
                    onClick={handleModalSubmit}
                    disabled={implementWarning}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  function filterRow(assetName, assetType, label) {
    const labelStatus = label.data.archived ? 'Archived' : 'Active';
    const labelIdDisplay = `${label.data.databaseLabel}`;

    // Convert Properties to string for search
    const rowObj = {
      ...label.data,
      assetName: assetName,
      assetType: assetType,
      status: labelStatus,
    };
    // remove 'archived' properties from rowObj so searching 'archived' does not show both status
    delete rowObj.archived;

    const search = searchFind(<div>{JSON.stringify(rowObj)}</div>, searchText.toLowerCase().trim());
    const assetNameInFilter = filters.assetName.includes(assetName) || filters.assetName.length == 0;
    const assetTypeInFilter = filters.assetType.includes(assetType) || filters.assetType.length == 0;
    const labelCodeInFilter = filters.assetLabelCodeId.includes(labelIdDisplay) || filters.assetLabelCodeId.length == 0;
    const labelStatusInFilter = filters.assetLabelStatus.includes(labelStatus) || filters.assetLabelStatus.length == 0;
    const labelInFilter = assetNameInFilter && assetTypeInFilter && labelCodeInFilter && labelStatusInFilter;

    return labelInFilter && search;
  }

  function generateTable() {
    let colorFlip = false;
    return (
      <React.Fragment>
        <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper title={'Code ID'}>
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#asset-label-code-id-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['assetLabelCodeId'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='asset-label-code-id-select'
                    name={'assetLabelCodeId'}
                    multiple={true}
                    search={true}
                    value={filters['assetLabelCodeId']}
                    options={filterOptions['assetLabelCodeId']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell>
                <TableHeadFilterButtonTitleWrapper
                  title={'Asset'}
                  bulkEditMode={assetLabelsBulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'assetId'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#asset-label-asset-name-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['assetName'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='asset-label-asset-name-select'
                    name={'assetName'}
                    multiple={true}
                    search={true}
                    value={filters['assetName']}
                    options={filterOptions['assetName']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={hideCell}>
                <TableHeadFilterButtonTitleWrapper
                  title={'Type'}
                  bulkEditMode={assetLabelsBulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'assetType'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#asset-label-asset-type-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['assetType'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='asset-label-asset-type-select'
                    name={'assetType'}
                    multiple={true}
                    search={true}
                    value={filters['assetType']}
                    options={filterOptions['assetType']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              <TableCell sx={hideCell}>
                <TableHeadFilterButtonTitleWrapper
                  title={'Status'}
                  bulkEditMode={assetLabelsBulkEditMode}
                  propagateAllColumns={propagateAllColumns}
                  handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                  bulkEditPropertyName={'archived'}
                >
                  <button
                    className='btn btn-transparent btn-sm'
                    onClick={() => {
                      return tail.select('#asset-label-asset-status-select').toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      icon='fas fa-filter'
                      style={{color: filters['assetLabelStatus'].length > 0 && '#4e73df'}}
                    />
                  </button>
                </TableHeadFilterButtonTitleWrapper>
                <TableHeadHiddenDropdownWrapper>
                  <Tailselect
                    id='asset-label-asset-status-select'
                    name={'assetLabelStatus'}
                    multiple={true}
                    search={true}
                    value={filters['assetLabelStatus']}
                    options={filterOptions['assetLabelStatus']}
                    onChange={handleFilters}
                  />
                </TableHeadHiddenDropdownWrapper>
              </TableCell>
              {!assetLabelsBulkEditMode && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={100}>
                  <CircularProgress className='mt-4 mx-auto d-block' size={80} />
                </TableCell>
              </TableRow>
            ) : (
              <React.Fragment>
                {(rowsPerPage > 0
                  ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : tableData
                ).map((label) => {
                  const rowBulkEditErrors = assetLabelsBulkEditErrors.hasOwnProperty(label.data.id)
                    ? assetLabelsBulkEditErrors[label.data.id]
                    : {};
                  let assetName = '';
                  let assetType = '';
                  if (label.data.assetType == 'vehicle') {
                    assetType = 'Vehicle';
                    const asset = vehicles.find((veh) => {
                      return veh.serialNumber == label.data.assetId;
                    });
                    if (asset) {
                      assetName = asset.name;
                    }
                  } else if (label.data.assetType == 'implement') {
                    assetType = 'Implement';
                    const asset = implementSNDict[label.data.assetId];
                    if (asset) {
                      assetName = asset.name;
                    }
                  }
                  colorFlip = !colorFlip;
                  return (
                    <TableRow key={label.data.id} sx={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : ''}}>
                      <TableCell>{`${label.data.id}-${label.data.databaseLabel}`}</TableCell>
                      {!assetLabelsBulkEditMode ? (
                        <TableCell>{assetName}</TableCell>
                      ) : (
                        <TableCell>
                          <BulkEditInputs
                            type='select'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditAssetLabels'
                            entityId={label.data.id}
                            entityIdName='id'
                            renderOptions={renderAssetOptions}
                            defaultValue={label.data.assetId}
                            propertyName='assetId'
                            submissionErrors={rowBulkEditErrors['assetId']}
                          />
                        </TableCell>
                      )}
                      {!assetLabelsBulkEditMode ? (
                        <TableCell sx={hideCell}>{assetType}</TableCell>
                      ) : (
                        <TableCell>
                          <BulkEditInputs
                            type='select'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditAssetLabels'
                            entityId={label.data.id}
                            entityIdName='id'
                            renderOptions={renderAssetTypeOptions}
                            defaultValue={label.data.assetType}
                            propertyName='assetType'
                            submissionErrors={rowBulkEditErrors['assetType']}
                          />
                        </TableCell>
                      )}
                      {!assetLabelsBulkEditMode ? (
                        <TableCell sx={hideCell}>
                          {label.data.archived ? (
                            <Chip label='Archived' color='error' />
                          ) : (
                            <Chip label='Active' color='success' />
                          )}
                        </TableCell>
                      ) : (
                        <TableCell>
                          <BulkEditInputs
                            type='select'
                            handleBulkEditUpdate={handleBulkEditTextFields}
                            bulkEditStateName='bulkEditAssetLabels'
                            entityId={label.data.id}
                            entityIdName='id'
                            renderOptions={renderArchivedOptions}
                            defaultValue={label.data.archived}
                            propertyName='archived'
                            submissionErrors={rowBulkEditErrors['archived']}
                          />
                        </TableCell>
                      )}
                      {!assetLabelsBulkEditMode && (
                        <TableCell>
                          <button
                            className='btn btn-light'
                            style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                            onClick={() => {
                              setModalOpen(true);
                              setMultiCreate(false);
                              setModalCode(label.data.id);
                            }}
                          >
                            <FontAwesomeIcon icon='fa fa-edit' />
                          </button>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  function generateMenu() {
    return (
      <React.Fragment>
        <div className='pt-2 pb-0'>
          <h5 style={{color: 'black'}}>Asset Labels Management</h5>
          <div>
            {userSettings.roleAccess['labelManagement'] && (
              <Button
                sx={squareButton}
                variant='ic-button'
                color='success'
                title='Add label'
                onClick={() => {
                  setModalOpen(true);
                  setModalCode('TBD');
                }}
              >
                <FontAwesomeIcon icon='fas fa-plus' />
              </Button>
            )}
            <Button
              sx={squareButton}
              variant='ic-button'
              color='secondary'
              className='ml-1'
              title='Download CSV'
              onClick={downloadCsv}
            >
              <FontAwesomeIcon icon='fas fa-download' />
            </Button>
            <Button
              sx={{...squareButton, marginLeft: '3px'}}
              variant='ic-button'
              color={assetLabelsBulkEditMode ? 'danger' : 'secondary'}
              onClick={() => {
                dispatch(setAssetLabelsBulkEditMode(!assetLabelsBulkEditMode));
              }}
              title='Bulk Edit Mode'
            >
              {assetLabelsBulkEditMode ? (
                <FontAwesomeIcon icon='fas fa-xmark-circle' />
              ) : (
                <FontAwesomeIcon icon='fas fa-edit' />
              )}
            </Button>

            {assetLabelsBulkEditMode && (
              <React.Fragment>
                <BulkEditSubmitButton />
                <Button
                  sx={squareButton}
                  variant='ic-button'
                  color='secondary'
                  title='Bulk Edit Reset'
                  className='ml-1'
                  onClick={() => {
                    resetBulkEdits();
                    dispatch(setAssetLabelsBulkEditErrors({}));
                  }}
                >
                  <FontAwesomeIcon icon='fas fa-trash-can' />
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {generateModal()}
      <TabMenuTableWrapper
        menu={generateMenu()}
        table={generateTable()}
        pagination={
          <CustomTablePagination
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      />
    </React.Fragment>
  );
}

function BulkEditSubmitButton(props) {
  const dispatch = useDispatch();
  const bulkEditAssetLabels = useSelector((state) => {
    return state.settings.bulkEditAssetLabels;
  });
  const assetLabelsDict = useSelector((state) => {
    return state.settings.assetLabelsDict;
  });
  const implementSNDict = useSelector((state) => {
    return state.settings.implementSNDict;
  });
  const vehicles = useSelector((state) => {
    return state.settings.vehicles;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });

  async function submitBulkEdits() {
    const validationErrors = {};
    const editSubmissionList = [];
    const implementSNList = Object.keys(implementSNDict);
    const vehicleSNList = vehicles.map((veh) => {
      return veh.serialNumber;
    });

    Object.keys(bulkEditAssetLabels).forEach((labelId) => {
      const newLabel = bulkEditAssetLabels[labelId];
      const oldLabel = assetLabelsDict[labelId];
      const assetLabelUpdateObj = {};

      if (newLabel.assetId != oldLabel.assetId) {
        // Ensure the asset Id belong to the type of asset selected
        if (
          // label is implement and in implement list
          (newLabel.assetType == 'implement' && implementSNList.includes(newLabel.assetId)) ||
          // Or label is vehicle and in vehicle list
          (newLabel.assetType == 'vehicle' && vehicleSNList.includes(newLabel.assetId)) ||
          // Or were trying to clear the QR code and the asset Id is blank
          newLabel.assetId == ''
        ) {
          assetLabelUpdateObj.assetId = newLabel.assetId;
        }
        // If we didn't pass then we get validation error
        else {
          if (!validationErrors.hasOwnProperty(labelId)) {
            validationErrors[labelId] = {};
          }
          validationErrors[labelId].assetId = 'Please select an asset which corresponds to the type you have selected';
        }
      }

      if (newLabel.assetType != oldLabel.assetType) {
        assetLabelUpdateObj.assetType = newLabel.assetType;
      }

      if (newLabel.archived != oldLabel.archived) {
        assetLabelUpdateObj.archived = newLabel.archived;
      }
      if (Object.keys(assetLabelUpdateObj).length > 0) {
        assetLabelUpdateObj.id = labelId;
        editSubmissionList.push(assetLabelUpdateObj);
      }
    });

    dispatch(setAssetLabelsBulkEditErrors(validationErrors));

    if (Object.keys(validationErrors).length == 0 && editSubmissionList.length != 0) {
      dispatch(setAssetLabelsBulkEditMode(false));
      dispatch(updateTableLoading({table: 'assetLabels', status: true}));

      const editSubmissionListParsed = editSubmissionList.map((editObj) => {
        const diffDoc = deepCopy(editObj);
        return diffDoc;
      });

      const postObj = {diffDocs: editSubmissionListParsed};
      const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(postObj),
      };

      const response = await fetchPostAuthSafe(
        '/settings/updateAssetLabels',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const result = await response.json();

      // REFRESH DATA
      const getLabelsRequest = await fetch('/getAssetLabels', {cache: 'no-store'});
      const res = await getLabelsRequest.json();
      dispatch(updateLabelsData(res));
      dispatch(updateTableLoading({table: 'assetLabels', status: false}));
    } else if (Object.keys(validationErrors).length == 0 && editSubmissionList.length == 0) {
      dispatch(setAssetLabelsBulkEditMode(false));
    } else {
      console.log(validationErrors);
    }
  }

  async function postBulkEditAssetLabelsUpdate(obj) {
    const options = {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(obj),
    };

    const response = await fetchPostAuthSafe(
      '/settings/updateAssetLabels',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const result = await response.json();
    return result;
  }

  return (
    <Button
      sx={squareButton}
      variant='ic-button'
      color='primary'
      title='Bulk Edit Submit'
      className='ml-1'
      onClick={() => {
        submitBulkEdits();
      }}
    >
      <FontAwesomeIcon icon='fas fa-floppy-disk' />
    </Button>
  );
}

export {LabelsTab};
